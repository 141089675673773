import Typography from "@mui/joy/Typography";
import {Button, Stack} from "@mui/joy";
import * as React from "react";
import {ArrowForward} from "@mui/icons-material";
import {useBroadcastingListStore} from "../Stores/BroadcastingListStore";
import {PAGE} from "../types";
import {useNavigate} from "react-router-dom";

export const StartScreen = () => {
    const navigate = useNavigate();
    const [list, loaded] = useBroadcastingListStore(state => [state.list, state.loaded]);

    if (!loaded || list.length > 0) {
        return <></>
    }

    return (
        <Stack
            flexDirection={{sm: 'row', xs: 'column'}}
            gap={3}
            maxWidth={'700px'}
            margin={'auto'}
            mt={{
                xl: '15%',
                lg: '17%',
                md: '25%',
                sm: '25%',
                xs: '10%'
            }}
        >
            <img height={220} src="/images/illustrations/marketing_1.svg" alt=""/>
            <Stack gap={2} alignItems={{sm: 'inherit', xs: 'center'}} sx={{px: {sm: 'inherit', xs: 2}}}>
                <Typography level="h4">
                    Создайте вашу первую рассылку
                </Typography>

                <Typography level="title-sm" textColor="text.secondary">
                    Рассылка - это массовая отправка сообщений всем вашим активным пользователям бота.
                </Typography>

                <Typography level="title-sm" textColor="text.secondary">
                    Создание сценария рассылки происходит по тому же принципу, что и создание обычных сценариев, и
                    имеет почти такой же набор возможностей.
                </Typography>

                <Stack>
                    <Button
                        size="md"
                        endDecorator={<ArrowForward fontSize="medium"/>}
                        onClick={() => navigate(PAGE.BROADCASTING_CREATE)}
                        sx={{mt: 1, maxWidth: 'fit-content'}}
                    >
                        Создать рассылку
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
}
