import {create} from "zustand";
import {ApiClient} from "../api-client";
import {API_METHOD, Scenario} from "../types";

interface ScenariosPreviewStore {
    isPreviewMode: boolean;
    loadingScenario: boolean;
    loadingFailed: boolean;
    copyingScenario: boolean;
    copyModalOpened: boolean;
    scenario?: Scenario;

    setPreviewMode(v: boolean): void;

    setCopyModalOpened(v: boolean): void;

    fetchScenario(uuid: string): Promise<void>;

    copyScenario(uuid: string, projectId: number): Promise<Scenario>;
}

export const useScenariosPreviewStore = create<ScenariosPreviewStore>((set) => ({
    isPreviewMode: false,
    loadingScenario: false,
    copyingScenario: false,
    loadingFailed: false,
    copyModalOpened: false,
    scenario: undefined,


    setCopyModalOpened(v: boolean) {
        set({copyModalOpened: v})
    },

    setPreviewMode(v: boolean) {
        set({isPreviewMode: v})
    },

    fetchScenario: async (uuid: string) => {
        set({loadingScenario: true, loadingFailed: false, scenario: undefined});

        return ApiClient.get(API_METHOD.SCENARIO_GET_ONE_PREVIEW, {params: {uuid}})
            .then(r => set({scenario: r.data as Scenario, loadingScenario: false}))
            .catch((error) => {
                ApiClient.toastError(error);
                set({loadingFailed: true});
            })
            .finally(() => set({loadingScenario: false}));
    },

    copyScenario: async(uuid: string, projectId: number)=> {
        const params = {uuid, projectId};
        let scenario = {} as Scenario;

        set({copyingScenario: true})

        await ApiClient.post(API_METHOD.SCENARIO_COPY, null, {params})
            .then(r => {
                scenario = r.data as Scenario;
            })
            .catch(ApiClient.toastError)
            .finally(() => set({copyingScenario: false}));

        return Promise.resolve(scenario)
    }
}));
