import 'reactflow/dist/style.css';
import './Flow.css';
import ReactFlow, {
    addEdge,
    Background,
    Connection,
    Controls,
    Edge,
    FitViewOptions,
    MarkerType,
    MiniMap,
    Node,
    ReactFlowProvider,
    updateEdge,
    useEdgesState,
    useNodesState,
    useReactFlow
} from "reactflow";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ConstructorMode, EdgeType, EdgeTypes, NodeDragHandleClass, NodeType, NodeTypes} from "./Nodes/types";
import {useScenariosStore} from "../Stores/ScenarioStore";
import {createNodeTitle} from "../Scenarios/ConstructorControls";
import {LS_KEY, Project} from "../types";
import {Box, IconButton, Tooltip} from "@mui/joy";
import {useScenarioUpdateStore} from "../Stores/ScenarioUpdateStore";
import {useScenariosPreviewStore} from "../Stores/ScenarioPreviewStore";
import {NodeHandleRole} from "./Edges/NodeHandle";
import {toastError} from "../utils";
import {Map, X} from "react-feather";
import {useConstructorStore} from "../Stores/ConstructorStore";

export const edgeOptions = {
    markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
    },
    style: {
        strokeWidth: 2,
    },
};

export const fitViewOptions: FitViewOptions = {
    padding: 0.17, // Влияет на масштаб зума при стартовой загрузке экрана сценария.
};

export interface FlowScheme {
    nodes: Node[];
    edges: Edge[]
}

type props = {
    scheme: FlowScheme;
    project: Project;
    mode: ConstructorMode;
    controls?: React.JSX.Element;
    previewMode?: boolean;
}

export const Constructor = (props: props) => {
    const [setMode] = useConstructorStore(state => [state.setMode])

    useEffect(() => {
        setMode(props.mode);
    }, [props.mode, setMode]);

    return (
        <ReactFlowProvider>
            <Flow {...props}/>
        </ReactFlowProvider>
    );
}

const Flow = (props: props) => {
    const reactFlowInstance = useReactFlow();
    const edgeUpdateSuccessful = useRef(true);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [setReactFlowInstance, clearReactFlowInstance] = useScenariosStore(state => [state.setReactFlowInstance, state.clearReactFlowInstance]);
    const [setConstructorProjectType] = useConstructorStore(state => [state.setProjectType]);
    const [setPreviewMode] = useScenariosPreviewStore(state => [state.setPreviewMode]);

    useEffect(() => {
        setReactFlowInstance(reactFlowInstance);
        setPreviewMode(props.previewMode ?? false);
        setConstructorProjectType(props.project.type);

        return () => clearReactFlowInstance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setReactFlowInstance(reactFlowInstance);
    }, [reactFlowInstance, setReactFlowInstance]);

    useEffect(() => {
        const scheme = props.scheme

        if (!scheme) {
            return;
        }

        scheme.nodes = scheme.nodes.map((node: Node) => {
            if (!node.data.title && node.type !== NodeType.ScenarioStartNode) {
                node.data.title = createNodeTitle(node.type ? node.type : '');
            }

            return {...node, dragHandle: NodeDragHandleClass};
        });

        setNodes(scheme.nodes);
        setEdges(scheme.edges);
    }, [props.scheme, setEdges, setNodes]);

    const onConnect = useCallback((connection: Connection) => setEdges((eds: Edge[]) => {
        let targetRole: NodeHandleRole | null | undefined = undefined;
        let sourceRole: NodeHandleRole | null | undefined = undefined;

        if (connection.targetHandle) {
            const info = document.getElementById(`${connection.targetHandle}-target`);
            const role = info?.getAttribute('data-handle-role');

            if (role) {
                targetRole = role as NodeHandleRole;
            }
        }

        if (connection.sourceHandle) {
            let info = document.getElementById(connection.sourceHandle);

            if (!info) {
                info = document.getElementById(`${connection.sourceHandle}-source`);
            }

            const role = info?.getAttribute('data-handle-role');

            if (role) {
                sourceRole = role as NodeHandleRole;
            }

            if (info) {
                const buttonName = info.getAttribute('data-button-name');

                if (buttonName) {
                    // @ts-ignore Там приходит объект Edge, а не Connection, можно создать issue на Github?
                    connection.label = buttonName;
                }
            }
        }

        if (sourceRole === NodeHandleRole.MessageNodeButton && targetRole === NodeHandleRole.AnswerWaitingNode) {
            toastError('Нельзя соединить кнопку с блоком ожидания ответа.');
            return eds;
        }

        useScenarioUpdateStore.getState().updated();

        // @ts-ignore
        connection.type = EdgeType.Removable;

        return addEdge(connection, eds);
    }), [setEdges]);

    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
    }, []);

    const onEdgeUpdate = useCallback((oldEdge: Edge, newConnection: Connection) => {
        edgeUpdateSuccessful.current = true;
        setEdges((els) => updateEdge(oldEdge, newConnection, els));
    }, [setEdges]);

    const onEdgeUpdateEnd = useCallback((_: any, edge: Edge) => {
        if (!edgeUpdateSuccessful.current) {
            setEdges((eds) => eds.filter((e) => e.id !== edge.id));
        }

        edgeUpdateSuccessful.current = true;
    }, [setEdges]);

    return (
        <Box
            sx={{
                pt: {
                    xs: `var(--Header-height)`,
                    sm: `var(--Header-height)`,
                },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
            }}
        >
            {props.controls && props.controls}
            <ReactFlow
                id={"scenario-container"}
                defaultEdgeOptions={edgeOptions}
                nodes={nodes}
                edges={edges}
                nodeTypes={NodeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                edgeTypes={EdgeTypes}
                onEdgeUpdate={onEdgeUpdate}
                onEdgeUpdateStart={onEdgeUpdateStart}
                onEdgeUpdateEnd={onEdgeUpdateEnd}
                proOptions={{hideAttribution: true}}
                fitView
                fitViewOptions={fitViewOptions}
                minZoom={0.2}
                maxZoom={1.2}
            >
                <Controls/>
                <Background/>
                <Minimap/>

            </ReactFlow>
        </Box>
    );
}

export const Minimap = () => {
    const key = LS_KEY.ConstructorMapHidden;
    const [hidden, setHidden] = useState<boolean>(
        localStorage.getItem(key) ? Boolean(Number(localStorage.getItem(key))) : false
    );

    return (
        <>
            <Box sx={{position: 'absolute', right: '10px', margin: 0, bottom: '15px', zIndex: 10}}>
                <Tooltip
                    title={hidden ? 'Показать карту' : 'Скрыть карту'}
                    color={"neutral"}
                    arrow={true}
                    placement="left"
                    size="sm"
                >
                    <IconButton
                        variant={hidden ? 'outlined' : 'plain'}
                        color={hidden ? 'neutral' : 'neutral'}
                        onClick={() => {
                            const value = !hidden;
                            setHidden(value);
                            localStorage.setItem(key, value ? '1' : '0');
                        }}
                    >
                        {hidden ? <Map size={16}/> : <X size={18}/>}
                    </IconButton>
                </Tooltip>
            </Box>

            {!hidden &&
                <MiniMap zoomable pannable nodeColor={(node) => {
                    switch (node.type) {
                        case NodeType.ScenarioStartNode:
                            return 'var(--joy-palette-success-300)';
                        default:
                            return 'var(--joy-palette-primary-200)';
                    }
                }}/>
            }
        </>
    );
}
