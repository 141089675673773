import {useProjectStore} from "../Stores/ProjectStore";
import * as React from "react";
import {Constructor} from "../Constructor/Flow";
import {v4 as uuidv4} from "uuid";
import {
    BroadcastingStartNodeData,
    ConstructorMode,
    MomentDayTimeFormat,
    NodeDragHandleClass,
    NodeType
} from "../Constructor/Nodes/types";
import {Node} from "reactflow";
import {
    BroadcastingAudienceMode,
    BroadcastingStartRule
} from "../Constructor/Nodes/BroadcastingStartNode/BroadcastingStartNode";
import moment from "moment/moment";
import {BroadcastingConstructorControls} from "./BroadcastingConstructorControls";
import {BroadcastingStatus} from "./BroadcastingList";

export const BroadcastingCreate = () => {
    const project = useProjectStore(state => state.selectedProject);

    if (!project) {
        return <></>
    }

    return (
        <Constructor
            project={project}
            mode={ConstructorMode.Broadcasting}
            scheme={{
                nodes: [
                    { // Зададим сразу дефолтную стартовую ноду.
                        id: uuidv4(),
                        type: NodeType.BroadcastingStartNode,
                        dragHandle: NodeDragHandleClass,
                        data: {
                            name: "",
                            startRule: BroadcastingStartRule.Manually,
                            audienceMode: BroadcastingAudienceMode.All,
                            startDatetime: moment().add(1, 'days').format(MomentDayTimeFormat),
                        } as BroadcastingStartNodeData,
                        position: {x: 5, y: 5},
                    } as Node
                ],
                edges: []
            }}
            controls={
                <BroadcastingConstructorControls
                    projectId={project.id}
                    broadcastingId={0}
                    broadcastingStatus={BroadcastingStatus.Created}
                />
            }
        />
    );
}

