import {Form} from "react-router-dom";
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import {AUTH_COOKIE, PAGE} from "../types";
import React, {FormEvent, useCallback, useEffect, useRef, useState} from "react";
import {getCookie} from "../utils";
import {AuthContainer} from "./AuthContainer";
import {
    useInputErrors,
    validateAlphaNumeric,
    validateEmail,
    validateInput,
    validateMaxLength,
    validateMinLength,
    validateRequired
} from "../Components/Form/helpers";
import {FormInput} from "../Components/Form/FormInput";
import {useAccountStore} from "../Stores/AccountStore";
import {ApiClient} from "../api-client";
import * as VKID from '@vkid/sdk';
import {Box} from "@mui/joy";
import Stack from "@mui/joy/Stack";
import {v4 as uuidv4} from "uuid";
import {TermsLink} from "./SignUp";

export const SignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [inputErrors, setInputErrors] = useInputErrors();
    const [isSending, setIsSending] = useState(false);
    const [signIn] = useAccountStore(state => [state.signIn]);

    useEffect(() => {
        if (getCookie(AUTH_COOKIE) !== undefined) {
            return ApiClient.redirect(PAGE.MAIN); // Раз юзер авторизован, то отправим его внутрь приложения.
        }
    }, []);

    const validators = useCallback(() => {
        return {
            name: (value: any) => validateInput(
                "name", value, setInputErrors,
                {func: validateRequired},
                {func: validateAlphaNumeric},
                {func: validateMinLength, threshold: 3},
                {func: validateMaxLength, threshold: 32}
            ),
            email: (value: any) => validateInput(
                "email", value, setInputErrors,
                {func: validateRequired}, {func: validateEmail}, {func: validateMaxLength, threshold: 128}
            ),
            password: (value: any) => validateInput(
                "password", value, setInputErrors,
                {func: validateRequired}, {func: validateMaxLength, threshold: 64}
            ),
        }
    }, [setInputErrors]);

    const send = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = [
            validators().email(email),
            validators().password(password),
        ].filter((error) => error !== null);

        if (errors.length > 0) {
            return
        }

        setIsSending(true);
        signIn(email, password).finally(() => setIsSending(false))
    }, [email, password, signIn, validators]);

    return (
        <AuthContainer title="Вход в систему.">
            <Form onSubmit={send}>
                <Stack direction="column" spacing="15px">
                    <FormInput
                        label="Почта"
                        type="email"
                        value={email}
                        setValue={setEmail}
                        validate={validators().email}
                        errorText={inputErrors.get("email")}
                        placeholder="Адрес электронной почты"
                    />

                    <FormInput
                        label="Пароль"
                        labelEndDecorator={
                            <Typography level="body-sm">
                                <Link onClick={() => ApiClient.redirect(PAGE.RESTORE_PASSWORD)}>Забыли
                                    пароль?</Link>
                            </Typography>
                        }
                        type="password"
                        value={password}
                        autoComplete="on"
                        trimSpaces
                        setValue={setPassword}
                        validate={validators().password}
                        errorText={inputErrors.get("password")}
                        placeholder="Пароль от вашего аккаунта"
                    />

                    <Button sx={{mt: 1}} type="submit" loading={isSending}>Войти</Button>
                </Stack>
            </Form>

            <Stack direction="column" spacing="15px">
                <Button variant="outlined" onClick={() => ApiClient.redirect(PAGE.SIGN_UP)}>Регистрация</Button>
                <VKConnectButton/>
                <VKConnectButtonTerms/>
            </Stack>
        </AuthContainer>
    );
}

export const VKConnectButton = ({redirectPage}: { redirectPage?: string }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref || !ref.current || ref.current.innerHTML.length > 0) {
            return
        }

        VKID.Config.set({
            app: Number(process.env.REACT_APP_VK_AUTH_APP_ID),
            redirectUrl: process.env.REACT_APP_CLIENT_URL + (redirectPage || PAGE.SIGN_IN_VK),
            state: uuidv4(),
        });

        const oneTap = new VKID.OneTap();

        oneTap.render({
            container: ref.current,
            scheme: VKID.Scheme.LIGHT,
            lang: VKID.Languages.RUS,
            skin: VKID.OneTapSkin.Primary,
            styles: {borderRadius: 6, height: 36}
        });
    }, [redirectPage]);

    return (
        <Box id="vk-connect-button" ref={ref}/>
    );
}

export const VKConnectButtonTerms = () => {
    return (
        <Typography level="body-xs" textColor="neutral.400" textAlign="center" fontWeight={400}>
            Выполнив вход через учетную запись ВКонтакте (VK ID), вы принимаете{' '}

            <TermsLink
                url="https://legal.chatgun.io/ru-terms-of-use.pdf"
                title="пользовательское соглашение"
            />

            {' '}и{' '}
            <TermsLink
                url="https://legal.chatgun.io/ru-privacy-policy.pdf"
                title="политику конфиденциальности"
            />.
        </Typography>
    );
}
