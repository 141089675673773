import {Grid, Sheet, Typography} from "@mui/joy";
import React from "react";
import {useProjectStore} from "../../Stores/ProjectStore";
import Skeleton from "react-loading-skeleton";
import {Tiles} from "./Tiles";
import {Footer} from "./Footer";

export const Subscription = () => {
    const [project] = useProjectStore(state => [state.selectedProject]);

    return (
        <>
            <Grid md={12} sx={{mt: 2}}>
                <Typography level="title-lg">Подписка и оплата</Typography>
            </Grid>

            <Grid xs={12} md={12} lg={8} xl={8} sx={{p: 1}}>
                <Sheet variant="outlined" sx={{borderRadius: 'sm', p: 1.5}}>
                    {!project && <Skeleton height={180}/>}

                    {project && <>
                        <Tiles/>
                        <Footer/>
                    </>}
                </Sheet>
            </Grid>
        </>
    );
}
