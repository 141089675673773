import {Form, useNavigate} from "react-router-dom";
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import {AUTH_COOKIE, PAGE} from "../types";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {getCookie} from "../utils";
import {AuthContainer} from "./AuthContainer";
import {
    useInputErrors,
    validateEmail,
    validateInput,
    validateMaxLength,
    validateMinLength,
    validateNumeric,
    validateRequired
} from "../Components/Form/helpers";
import {FormInput} from "../Components/Form/FormInput";
import {Step, useRestorePasswordStore} from "../Stores/RestorePasswordStore";


export const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [inputErrors, setInputErrors] = useInputErrors();
    const [
        isSending,
        step,
        emailFound,
        codeAccepted,
        createRestoreCode,
        checkRestoreCode,
        restorePassword
    ] = useRestorePasswordStore(state => [
        state.sending, state.step, state.emailFound, state.codeAccepted, state.createRestoreCode, state.checkRestoreCode, state.restorePassword
    ])

    useEffect(() => {
        if (getCookie(AUTH_COOKIE) !== undefined) {
            navigate(PAGE.MAIN); // Раз юзер авторизован, то отправим его внутрь приложения.
            return;
        }
    }, [navigate]);

    const validators = useCallback(() => {
        return {
            email: (value: any) => validateInput(
                "email", value, setInputErrors,
                {func: validateRequired}, {func: validateEmail}, {func: validateMaxLength, threshold: 128}
            ),
            code: (value: any) => validateInput(
                "code", value, setInputErrors,
                {func: validateRequired}, {func: validateNumeric}, {func: validateMaxLength, threshold: 10}
            ),
            password: (value: any) => validateInput(
                "password", value, setInputErrors,
                {func: validateRequired},
                {func: validateMinLength, threshold: 8},
                {func: validateMaxLength, threshold: 64}
            ),
        }
    }, [setInputErrors]);

    const sendCreateRestoreCode = useCallback(() => {
        const errors = [validators().email(email)].filter((error) => error !== null);

        if (errors.length > 0) {
            return
        }

        createRestoreCode(email);
    }, [email, createRestoreCode, validators]);

    const sendCheckRestoreCode = useCallback(() => {
        const errors = [validators().email(email), validators().code(code)].filter((error) => error !== null);

        if (errors.length > 0) {
            return
        }

        checkRestoreCode(email, code);
    }, [code, email, checkRestoreCode, validators]);

    const sendRestorePassword = useCallback(() => {
        const errors = [
            validators().email(email),
            validators().code(code),
            validators().password(password)
        ].filter((error) => error !== null);

        if (errors.length > 0) {
            return
        }

        restorePassword(email, code, password);
    }, [code, email, password, restorePassword, validators]);

    const send = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (step === Step.CreateCode) {
            return sendCreateRestoreCode();
        } else if (step === Step.SendCode) {
            return sendCheckRestoreCode();
        } else if (step === Step.ChangePassword) {
            return sendRestorePassword();
        }
    }, [sendCheckRestoreCode, sendCreateRestoreCode, sendRestorePassword, step]);

    return (
        <Form onSubmit={send}>
            <AuthContainer title="Восстановление пароля">
                <FormInput
                    label="Почта"
                    type="email"
                    value={email}
                    disabled={emailFound}
                    setValue={setEmail}
                    validate={validators().email}
                    errorText={inputErrors.get("email")}
                    placeholder="Адрес электронной почты"
                />

                {step >= Step.SendCode && <FormInput
                    label="Проверочный код из письма"
                    type="text"
                    disabled={codeAccepted}
                    value={code}
                    setValue={setCode}
                    validate={validators().code}
                    errorText={inputErrors.get("code")}
                    placeholder="Отправили вам на почту"
                />}


                {step === Step.ChangePassword && <FormInput
                    label="Новый пароль"
                    type="password"
                    value={password}
                    autoComplete="on"
                    trimSpaces
                    setValue={setPassword}
                    validate={validators().password}
                    errorText={inputErrors.get("password")}
                    placeholder="Придумайте пароль"
                />}

                <Button type="submit" loading={isSending} sx={{mt: 1}}>Далее</Button>
                <Typography
                    endDecorator={<Link onClick={() => navigate(PAGE.SIGN_IN)}>Авторизация</Link>}
                    fontSize="sm"
                    sx={{alignSelf: 'center'}}
                >
                    Вспомнили пароль?
                </Typography>
            </AuthContainer>
        </Form>
    )
}