import {create} from "zustand";
import {API_METHOD, Broadcasting} from "../types";
import {ApiClient} from "../api-client";
import {toastSuccess} from "../utils";
import {FlowScheme} from "../Constructor/Flow";

interface SaveResponse {
    id: number;
}

interface BroadcastingStore {
    saving: boolean;
    loading: boolean;
    deleting: boolean;
    starting: boolean;
    testing: boolean;
    copying: boolean;

    save: (projectId: number, broadcastingId: number, scheme: FlowScheme, afterSave: () => void | undefined) => Promise<Number>;
    load: (projectId: number, broadcastingId: number) => Promise<Broadcasting | null>;
    delete: (projectId: number, broadcastingId: number) => Promise<void>;
    start: (projectId: number, broadcastingId: number) => Promise<void>;
    test: (projectId: number, broadcastingId: number) => Promise<void>;
    copy: (projectId: number, broadcastingId: number) => Promise<Number>;
}

export const useBroadcastingStore = create<BroadcastingStore>((set) => ({
    saving: false,
    loading: false,
    deleting: false,
    starting: false,
    testing: false,
    copying: false,

    load: async (projectId: number, broadcastingId: number) => {
        let broadcasting = null;

        set({loading: true});

        await ApiClient.get(API_METHOD.BROADCASTING_GET, {params: {projectId, broadcastingId}}).then(r => {
            broadcasting = r.data as Broadcasting;
        }).catch(ApiClient.toastError).finally(() => set({loading: false}));

        return Promise.resolve(broadcasting)
    },

    save: async (projectId: number, broadcastingId: number, scheme: FlowScheme, afterSave: () => void | undefined): Promise<Number> => {
        const data = {scheme: JSON.stringify(scheme)};

        set({saving: true});

        await ApiClient.post(API_METHOD.BROADCASTING_SAVE, data, {params: {projectId, broadcastingId}}).then((r) => {
            const response = r.data as SaveResponse
            broadcastingId = response.id

            toastSuccess('Рассылка успешно сохранена!');

            if (typeof afterSave === 'function') {
                afterSave();
            }
        }).catch(ApiClient.toastError).finally(() => set({saving: false}));

        return Promise.resolve(broadcastingId);
    },

    delete: (projectId: number, broadcastingId: number) => {
        set({deleting: true});

        return ApiClient.post(API_METHOD.BROADCASTING_DELETE, null, {params: {projectId, broadcastingId}}).then(() => {
            toastSuccess('Рассылка успешно удалена!');
        }).catch(ApiClient.toastError).finally(() => set({deleting: false}));
    },

    start: (projectId: number, broadcastingId: number) => {
        set({starting: true});

        return ApiClient.post(API_METHOD.BROADCASTING_START, null, {params: {projectId, broadcastingId}}).then(() => {
            toastSuccess('Рассылка скоро будет запущена!');
        }).catch(ApiClient.toastError).finally(() => set({starting: false}));
    },

    test: (projectId: number, broadcastingId: number) => {
        set({testing: true});

        return ApiClient.post(API_METHOD.SCENARIO_TEST, null, {
            params: {
                projectId: projectId,
                scenarioId: -broadcastingId
            }
        }).then(() => {
            toastSuccess('Тест рассылки успешно запущен!');
        }).catch(ApiClient.toastError).finally(() => set({testing: false}));
    },

    copy: async (projectId: number, broadcastingId: number) => {
        set({copying: true});
        let createdBroadcastingId = 0;

        await ApiClient.post(API_METHOD.BROADCASTING_COPY, null, {params: {projectId, broadcastingId}}).then((r) => {
            const response = r.data as SaveResponse
            createdBroadcastingId = response.id

            toastSuccess('Рассылка успешно скопирована!');
        }).catch(ApiClient.toastError).finally(() => set({copying: false}));

        return createdBroadcastingId;
    },
}));
