import {ProjectType} from "../../../../types";

export enum ButtonType {
    COMMON = 'common',
    URL = 'url',
}

export enum VKButtonColor {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    NEGATIVE = 'negative',
    POSITIVE = 'positive',
}

export type ButtonData = {
    id: string;
    name: string;
    type: ButtonType;
    vkColor?: VKButtonColor;
    url: string;
}

export type ButtonRow = {
    id: string;
    buttons: ButtonData[]
};

export type KeyboardRows = ButtonRow[];

export const VKButtonColorMap = {
    [VKButtonColor.PRIMARY]: 'primary',
    [VKButtonColor.SECONDARY]: 'neutral',
    [VKButtonColor.NEGATIVE]: 'danger',
    [VKButtonColor.POSITIVE]: 'success',
}

export const VKButtonReversedColorMap = {
    'primary': VKButtonColor.PRIMARY,
    'neutral': VKButtonColor.SECONDARY,
    'danger': VKButtonColor.NEGATIVE,
    'warning': VKButtonColor.NEGATIVE, // для совместимости с ts
    'success': VKButtonColor.POSITIVE,
}

/**
 * Максимальное количество рядов.
 */
export const getMaxButtonRows = (projectType: ProjectType, isInline: boolean): number => {
    if (projectType === ProjectType.VK) {
        return isInline ? 6 : 10;
    }

    return 8;
}

/**
 * Максимальное количество кнопок в каждом ряду.
 */
export const getMaxRowButtons = (projectType: ProjectType): number => {
    if (projectType === ProjectType.VK) {
        return 5;
    }

    return 8;
}
