import {CommentNodeData} from "../types";
import React, {BaseSyntheticEvent, useCallback, useEffect, useRef, useState} from "react";
import {useScenarioUpdateStore} from "../../../Stores/ScenarioUpdateStore";
import {useUpdateNode} from "../../utils";
import {NodeDragHandle, NodeRemoveButton} from "../../NodeHeader";
import {Box, Card, FormHelperText, Stack, Textarea} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {useInputErrors, validateInput, validateMaxLength} from "../../../Components/Form/helpers";
import FormControl from "@mui/joy/FormControl";
import {useScenariosPreviewStore} from "../../../Stores/ScenarioPreviewStore";


export const CommentNode = ({id, data}: { id: string; data: CommentNodeData; }) => {
    const MaxLength = 1000;
    const updateNode = useUpdateNode();
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [textareaHidden, setTextareaHidden] = useState(data.text.length > 0);
    const [text, setText] = useState(data.text);
    const [inputErrors, setInputErrors] = useInputErrors();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    const errorText = inputErrors.get("text");
    const hasError = errorText !== undefined && errorText.length > 0;
    const hideTextarea = (text.length > 0 && textareaHidden) || isPreviewMode;

    const validators = useCallback(() => {
        return {
            text: (value: any) => validateInput(
                "text",
                value,
                setInputErrors,
                {func: validateMaxLength, threshold: MaxLength}
            )
        }
    }, [setInputErrors]);

    const onChange = useCallback((evt: BaseSyntheticEvent) => {
        let value = evt.target.value
        setText(value)

        validators().text(value)
    }, [validators]);

    const onClickText = useCallback(() => {
        if (isPreviewMode) {
            return false;
        }

        setTextareaHidden(false);

        if (!textareaRef.current) {
            return;
        }

        const field = textareaRef.current;

        setTimeout(() => {

            field.focus();

            if (field.setSelectionRange) {
                field.setSelectionRange(text.length, text.length);
                field.focus();
            }
        }, 50);

    }, [isPreviewMode, text.length])

    useEffect(() => {
        useScenarioUpdateStore.getState().clear();
    }, []);

    useEffect(() => {
        updateNode(id, {
            text: text,
        } as CommentNodeData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);

    const ErrorHelper = () => {
        if (errorText !== undefined && errorText.length > 0) {
            return (
                <FormHelperText sx={{color: "var(--joy-palette-danger-500)"}}>{errorText}</FormHelperText>
            );
        }

        return null;
    };

    return (
        <div className="CommentNode nowheel">
            <Card variant="soft" color="warning" sx={{width: '340px', padding: 0}} invertedColors>
                <Stack direction="row" sx={{position: 'absolute', width: '100%', padding: 0.75}}>
                    <NodeDragHandle/>
                    <NodeRemoveButton nodeId={id}/>
                </Stack>

                <Box sx={{padding: 3.7}}>
                    {hideTextarea &&
                        <Box onClick={onClickText} sx={{cursor: 'pointer', wordBreak: 'break-word'}}>
                            <Typography level="body-md" sx={{whiteSpace: 'pre-line'}}>{text}</Typography>
                        </Box>
                    }

                    <FormControl>
                        <Textarea
                            slotProps={{textarea: {ref: textareaRef}}}
                            color={hasError ? undefined : 'neutral'}
                            sx={{display: hideTextarea ? 'none' : 'inherit'}}
                            size={'sm'}
                            onChange={onChange}
                            value={text}
                            error={hasError}
                            maxRows={10}
                            onBlur={() => setTextareaHidden(true)}
                            variant={"soft"}
                            placeholder="Введите комментарий.."
                            endDecorator={
                                <Typography level="body-xs" sx={{ml: 'auto'}}>
                                    {text.length} / {MaxLength}
                                </Typography>
                            }
                        />
                        <ErrorHelper/>
                    </FormControl>
                </Box>
            </Card>
        </div>
    );
}
