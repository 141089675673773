import {useSearchParams} from "react-router-dom";
import {AuthContainer} from "./AuthContainer";
import CircularProgress from "@mui/joy/CircularProgress";
import {LogIn} from "react-feather";
import Stack from "@mui/joy/Stack";
import React, {useEffect, useRef, useState} from "react";
import {useAccountStore} from "../Stores/AccountStore";
import {Box, Card} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import {ApiClient, ApiError} from "../api-client";
import {PAGE} from "../types";
import {isAuthorized} from "../utils";

export const VKConnect = ({redirectPage}: {redirectPage?: PAGE}) => {
    const [searchParams] = useSearchParams();
    const [payload] = useState(String(searchParams.get('payload')));
    const [loginOneTapVK, oneTapLoginProcessing, bindOneTapVK, oneTapVKBindProcessing] = useAccountStore(state => [state.loginOneTapVK, state.oneTapVKLoginProcessing, state.bindOneTapVK, state.oneTapVKBindProcessing])
    const [error, setError] = useState('');
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (oneTapLoginProcessing || oneTapVKBindProcessing || !searchParams.get('payload') || !ref.current) {
            return;
        }

        if (ref.current && ref.current.getAttribute('data-signing')) {
            return;
        }

        // Костыль для защиты от двойного срабатывания в dev режиме
        ref.current.setAttribute('data-signing', "1");

        const loginFunc = isAuthorized() ? bindOneTapVK : loginOneTapVK;
        const toPage = redirectPage && redirectPage.length > 0 ? redirectPage as PAGE : undefined;

        loginFunc(JSON.parse(payload), toPage).catch(err => {
            if (err instanceof ApiError) {
                setError(err.response.data);
            } else {
                console.error(err);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthContainer title="Входим через аккаунт ВКонтакте">
            <Box ref={ref}>
                {!error &&
                    <Stack alignItems="center" justifyContent="center" padding={7}>
                        <CircularProgress color="neutral" sx={{'--CircularProgress-size': '80px'}}>
                            <LogIn color="var(--joy-palette-neutral-400)"/>
                        </CircularProgress>
                    </Stack>
                }

                {error &&
                    <Stack direction="column" spacing='15px'>
                        <Card invertedColors variant="soft" color="danger">
                            <Typography level="body-sm">
                                {error}
                            </Typography>
                        </Card>
                        <Button variant="soft" color="neutral" onClick={() => ApiClient.redirect(PAGE.SIGN_IN)}>
                            Вернуться назад
                        </Button>
                    </Stack>
                }
            </Box>
        </AuthContainer>
    )
}
