import {CssVarsProvider} from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import {theme} from "../theme";
import Box from "@mui/joy/Box";
import {Link} from "@mui/joy";
import * as React from "react";

type props = {
    children: any;
    title?: string;
    width?: number;
    hideHeader?: boolean;
}

export const AuthContainer = (props: props) => {
    const Header = () => {
        if (props.hideHeader) {
            return <></>;
        }

        return (
            <div>
                <Typography level="h4" component="h1">
                    <b>Chatgun.io</b>
                </Typography>
                {props.title && <Typography level="body-sm">{props.title}</Typography>}
            </div>
        )
    };

    return (
        <>
            <CssVarsProvider theme={theme}>
                <Box
                    sx={{
                        px: 3,
                        minHeight: '100dvh',
                        display: 'flex',
                    }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        my: 'auto', // margin top & bottom
                        mx: 'auto', // margin left & right
                        py: 2, // padding top & bottom
                    }}>
                        <Sheet
                            sx={{
                                maxWidth: {xs: props.width || 370, md: 'unset'},
                                width: {xs: 'unset', md: props.width || 370},
                                py: 3, // padding top & bottom
                                px: 2, // padding left & right
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                borderRadius: 'sm',
                                boxShadow: 'md',
                            }}
                            variant="outlined"
                        >
                            <Header/>
                            {props.children}
                        </Sheet>
                        <Box sx={{
                            display: 'flex',
                            p: 2,
                            maxWidth: {xs: props.width || 370, md: 'unset'},
                            width: {xs: 'unset', md: props.width || 370},
                            textAlign: 'center',
                        }}>
                            <Typography level="body-xs" textColor="neutral.400">
                                Возникли вопросы? Напишите нам на
                                почту <Link href="mailto:support@chatgun.io" target="_blank">support@chatgun.io</Link>
                                {' '}или в наше{' '}
                                <Link href="https://vk.me/chatguntech" target="_blank">сообщество ВКонтакте</Link>.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </CssVarsProvider>
        </>
    )
}