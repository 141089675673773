import React, {Dispatch, SetStateAction, useCallback, useEffect} from "react";
import {FormTextarea} from "../../../../Components/Form/FormTextarea";
import {useInputErrors, validateInput, validateMaxLength,} from "../../../../Components/Form/helpers";
import {useScenariosPreviewStore} from "../../../../Stores/ScenarioPreviewStore";
import {KeyboardRows} from "../Keyboard/types";
import {NodeFile} from "../Files/types";
import {MessageNodeOptions} from "../types";

type props = {
    nodeId: string;
    message: string;
    keyboardRows: KeyboardRows;
    setKeyboard: Dispatch<SetStateAction<KeyboardRows>>;
    setMessage: Dispatch<SetStateAction<string>>
    files: NodeFile[];
    options: MessageNodeOptions;
    setFiles: Dispatch<SetStateAction<NodeFile[]>>;
    canMultipleFiles: boolean;
}

export const Message = (props: props) => {
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    const MaxLength = 2000;
    const [inputErrors, setInputErrors] = useInputErrors();
    const validators = useCallback(() => {
        return {
            message: (value: any) => validateInput(
                "message",
                value,
                setInputErrors,
                {func: validateMaxLength, threshold: MaxLength}
            )
        }
    }, [setInputErrors]);

    useEffect(() => {
        validators().message(props.message)
    }, [props.message, validators])

    return (
        <div id={`MessageNodeCommonContainer-${props.nodeId}`}>
            <FormTextarea
                color="neutral"
                showMenu={!isPreviewMode}
                minRows={2}
                maxRows={10}
                size="sm"
                placeholder="Введите сообщение.."
                variant="soft"
                maxLength={MaxLength}
                value={props.message}
                setValue={(v) => {
                    if (isPreviewMode) {
                        return
                    }

                    props.setMessage(v);
                }}
                validate={validators().message}
                errorText={inputErrors.get("message")}
                keyboardRows={props.keyboardRows}
                setKeyboard={props.setKeyboard}
                files={props.files}
                options={props.options}
                setFiles={props.setFiles}
                canMultipleFiles={props.canMultipleFiles}
                nodeId={props.nodeId}
            />
        </div>
    );
}