import './Scenarios.css';
import {Box} from "@mui/material";
import {Constructor} from "../Constructor/Flow";
import * as React from "react";
import {useEffect} from "react";
import {useScenariosStore} from "../Stores/ScenarioStore";
import CircularProgress from '@mui/joy/CircularProgress';
import {useProjectStore} from "../Stores/ProjectStore";
import {useParams} from "react-router-dom";
import {Scenario} from "../types";
import {ConstructorControls} from "./ConstructorControls";
import {v4 as uuidv4} from "uuid";
import {
    ConstructorMode,
    KeyMode,
    NodeDragHandleClass,
    NodeType,
    ScenarioStartNodeData
} from "../Constructor/Nodes/types";
import {Edge, Node} from "reactflow";

export const ScenarioEdit = () => {
    const params = useParams();
    const scenarioId = Number(params.id);

    const project = useProjectStore(state => state.selectedProject);
    const [fetchScenario, scenario, scenarioLoading] = useScenariosStore(state => [state.fetchScenario, state.selectedScenario, state.loadingScenario]);

    useEffect(() => {
        useScenariosStore.getState().clear()

        if (!project) {
            return;
        }

        fetchScenario(project.id, scenarioId);
    }, [fetchScenario, project, scenarioId]);

    return (
        <>
            {!scenario && scenarioLoading && <LoadingScenario/>}
            {(!scenario || !project) && !scenarioLoading && <LoadingScenario/>}
            {scenario && project &&
                <Constructor
                    project={project}
                    mode={ConstructorMode.Scenario}
                    scheme={scenario.scheme}
                    controls={<ConstructorControls projectId={project.id} scenario={scenario}/>}
                />
            }
        </>
    );
}

export const ScenarioCreate = () => {
    const project = useProjectStore(state => state.selectedProject);
    const [scenario, setSelectedScenario] = useScenariosStore(state => [state.selectedScenario, state.setSelectedScenario]);

    useEffect(() => {
        if (!project) {
            return;
        }

        const scenario = {
            id: 0,
            name: '',
            projectId: project.id,
            scheme: {
                nodes: [
                    { // Зададим сразу дефолтную стартовую ноду.
                        id: uuidv4(),
                        type: NodeType.ScenarioStartNode,
                        dragHandle: NodeDragHandleClass,
                        data: {keys: "", name: "", keysMode: KeyMode.Strict} as ScenarioStartNodeData,
                        position: {x: 5, y: 5},
                    } as Node
                ],
                edges: [] as Edge[]
            },
            type: 0,
        } as Scenario;

        setSelectedScenario(undefined).then(() => setSelectedScenario(scenario));
    }, [project, setSelectedScenario]);

    return (
        <>
            {scenario && project &&
                <Constructor
                    project={project}
                    mode={ConstructorMode.Scenario}
                    scheme={scenario.scheme}
                    controls={<ConstructorControls projectId={project.id} scenario={scenario}/>}
                />
            }
        </>
    );
}

export const LoadingScenario = (): React.JSX.Element => {
    return (
        <Box sx={{margin: 'auto', textAlign: 'center'}}>
            <Box sx={{mt: -5}}>
                <CircularProgress variant="soft" size="lg" color="primary" sx={{'--CircularProgress-size': '70px'}}/>
            </Box>
        </Box>
    );
}
