import * as React from 'react';
import {FormEvent, useCallback, useEffect, useState} from 'react';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import {ButtonData, ButtonType, VKButtonColor, VKButtonColorMap, VKButtonReversedColorMap} from "./types";
import {ConfirmModal} from "../../../../Components/ConfirmModal";
import {Dropdown} from "../../../../Components/Dropdown";
import {Trash} from "react-feather";
import {FormInput} from "../../../../Components/Form/FormInput";
import {
    useInputErrors,
    validateInput,
    validateMaxLength,
    validateMinLength,
    validateRequired,
    validateUrl
} from "../../../../Components/Form/helpers";
import {Form} from "react-router-dom";
import {ModalBox} from '../../../../Components/ModalBox';
import {Box, Divider, FormLabel, RadioGroup, Sheet} from '@mui/joy';
import Radio, {radioClasses} from '@mui/joy/Radio';
import {Done} from '@mui/icons-material';
import {DefaultColorPalette} from "@mui/joy/styles/types/colorSystem";
import {useConstructorProjectType} from "../../../utils";
import {ProjectType} from "../../../../types";

type props = {
    modalData: ButtonData | null;
    onDelete: (id: string) => void;
    onUpdate: (data: ButtonData) => void;
    setModalData: (value: ButtonData | null) => void;
}

const ButtonTypeOptions = [
    {label: "Обычная", value: ButtonType.COMMON},
    {label: "Переход по ссылке", value: ButtonType.URL},
];

export const UpdateModal = (props: props): React.JSX.Element => {
    const [name, setName] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [type, setType] = useState<ButtonType>(ButtonType.COMMON);
    const [color, setColor] = useState<VKButtonColor>(VKButtonColor.PRIMARY);
    const [inputErrors, setInputErrors] = useInputErrors();
    const projectType = useConstructorProjectType();

    const validators = useCallback(() => {
        return {
            name: (value: any) => validateInput(
                "name", value, setInputErrors,
                {func: validateRequired},
                {func: validateMinLength, threshold: 1},
                {func: validateMaxLength, threshold: 32}
            ),
            url: (value: any) => validateInput(
                "url", String(value).trim(), setInputErrors,
                {func: validateRequired},
                {func: validateUrl},
                {func: validateMaxLength, threshold: 128}
            ),
        }
    }, [setInputErrors]);

    useEffect(() => {
        if (props.modalData) {
            setName(props.modalData.name);
            setUrl(props.modalData.url);
            setType(props.modalData.type);
            setColor(props.modalData.vkColor || VKButtonColor.PRIMARY);
            setInputErrors(new Map());
        }
    }, [props, setInputErrors]);

    const onSave = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = [
            validators().name(name),
            type === ButtonType.URL ? validators().url(url) : null,
        ].filter((error) => error !== null);

        if (errors.length > 0 || !props.modalData) {
            return
        }

        props.onUpdate({
            id: props.modalData.id,
            type: type,
            name: name.trim(),
            vkColor: projectType === ProjectType.VK ? color : undefined,
            url: type === ButtonType.URL ? url.trim() : '',
        });
        props.setModalData(null);
    }, [color, name, projectType, props, type, url, validators]);

    const onDelete = useCallback(() => {
        if (props.modalData) {
            props.onDelete(props.modalData.id)
        }

        props.setModalData(null);
    }, [props]);

    if (props.modalData === null) {
        return <></>;
    }

    return (
        <ModalBox
            open={true}
            onClose={() => props.setModalData(null)}
            title='Редактирование кнопки'
            sx={{maxWidth: 500}}
        >
            <Form onSubmit={onSave}>
                <Stack spacing={2}>
                    <FormInput
                        label="Название"
                        type="text"
                        value={name}
                        setValue={setName}
                        placeholder='Введите название кнопки..'
                        validate={validators().name}
                        errorText={inputErrors.get("name")}
                    />

                    <Dropdown label='Тип кнопки' value={type} options={ButtonTypeOptions} setValue={setType}/>

                    {
                        type === ButtonType.URL && <FormInput
                            label="Ссылка"
                            type="text"
                            value={url}
                            setValue={setUrl}
                            placeholder='Введите ссылку..'
                            validate={validators().url}
                            errorText={inputErrors.get("url")}
                        />
                    }

                    {projectType === ProjectType.VK &&  type === ButtonType.COMMON && <ColorPicker color={color} setColor={setColor}/>}

                    <Divider/>

                    <Stack spacing={1}>
                        <Button type='submit'>Сохранить</Button>

                        <ConfirmModal
                            onConfirm={onDelete}
                            acceptTitle="Удалить"
                            description="Вы уверены, что хотите удалить эту кнопку?"
                        >
                            <Button startDecorator={<Trash size={20}/>} color="danger">
                                Удалить
                            </Button>
                        </ConfirmModal>
                    </Stack>
                </Stack>
            </Form>
        </ModalBox>
    );
}

const ColorPicker = (
    {color, setColor}: { color: VKButtonColor, setColor: (color: VKButtonColor) => void }
) => {

    const onChange = useCallback((color: DefaultColorPalette) => {
        setColor(VKButtonReversedColorMap[color]);
    }, [setColor]);

    return (
        <Box>
            <FormLabel sx={{mb: 1.5}}>Цвет кнопки</FormLabel>
            <RadioGroup
                value={VKButtonColorMap[color]}
                onChange={(e) => onChange(e.target.value as DefaultColorPalette)}
                sx={{gap: 2, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-evenly'}}
            >
                {(['neutral', 'primary', 'success', 'danger'] as const).map(
                    (color) => (
                        <Sheet
                            key={color}
                            sx={{
                                position: 'relative',
                                width: 33,
                                height: 33,
                                flexShrink: 0,
                                bgcolor: `${color}.solidBg`,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Radio
                                overlay
                                variant="solid"
                                color={color}
                                checkedIcon={<Done fontSize="medium"/>}
                                value={color}
                                slotProps={{
                                    input: {'aria-label': color},
                                    radio: {
                                        sx: {
                                            display: 'contents',
                                            '--variant-borderWidth': '2px',
                                        },
                                    },
                                }}
                                sx={{
                                    '--joy-focus-outlineOffset': '4px',
                                    '--joy-palette-focusVisible': (theme) =>
                                        theme.vars.palette[color][500],
                                    [`& .${radioClasses.action}.${radioClasses.focusVisible}`]: {
                                        outlineWidth: '2px',
                                    },
                                }}
                            />
                        </Sheet>
                    ),
                )}
            </RadioGroup>
        </Box>
    );
}