import moment from "moment/moment";

export enum ConditionNodeType {
    Soft = "soft", // Если выполняется хотя бы одно условие.
    Strict = "strict", // Если выполняются все условия.
}

export interface ConditionNodeVariantItem {
    id: string;
    variant: ConditionNodeVariant;
    variantType: ConditionNodeVariantType;
    value: string[];
}

export enum ConditionNodeVariant {
    CurrentTime = "currentTime",
    CurrentDate = "currentDate",
    CurrentWeekday = "currentWeekday"
}

export enum ConditionNodeVariantType {
    Equal = "equal",
    NotEqual = "notEqual",
    GreaterThan = "greaterThan",
    GreaterOrEqual = "greaterOrEqual",
    LessThan = "lessThan",
    LessOrEqual = "lessOrEqual",
}

export const ConditionNodeVariantTypes = {
    [ConditionNodeVariantType.Equal]: 'Равно',
    [ConditionNodeVariantType.NotEqual]: 'Не равно',
    [ConditionNodeVariantType.GreaterThan]: 'Больше чем',
    [ConditionNodeVariantType.GreaterOrEqual]: 'Больше или равно',
    [ConditionNodeVariantType.LessThan]: 'Меньше чем',
    [ConditionNodeVariantType.LessOrEqual]: 'Меньше или равно',
}

export interface ConditionNodeVariantsConfigItem {
    title: string;
    variant: ConditionNodeVariant;
    isAnyDateTime: boolean;
    allowedVariantTypes: ConditionNodeVariantType[];
    defaultValue: () => string[];
}

export interface IConditionNodeVariantsConfig {
    [variant: string]: ConditionNodeVariantsConfigItem;
}

export const ConditionNodeVariantsConfig: IConditionNodeVariantsConfig = {
    [ConditionNodeVariant.CurrentTime]: {
        variant: ConditionNodeVariant.CurrentTime,
        title: "Текущее время",
        isAnyDateTime: true,
        allowedVariantTypes: [
            ConditionNodeVariantType.Equal,
            ConditionNodeVariantType.NotEqual,
            ConditionNodeVariantType.GreaterThan,
            ConditionNodeVariantType.GreaterOrEqual,
            ConditionNodeVariantType.LessThan,
            ConditionNodeVariantType.LessOrEqual,
        ],
        defaultValue: () => {
            return [moment().format('YYYY-MM-DD HH:mm')];
        }
    },
    [ConditionNodeVariant.CurrentDate]: {
        variant: ConditionNodeVariant.CurrentDate,
        title: "Текущая дата",
        isAnyDateTime: true,
        allowedVariantTypes: [
            ConditionNodeVariantType.Equal,
            ConditionNodeVariantType.NotEqual,
            ConditionNodeVariantType.GreaterThan,
            ConditionNodeVariantType.GreaterOrEqual,
            ConditionNodeVariantType.LessThan,
            ConditionNodeVariantType.LessOrEqual,
        ],
        defaultValue: () => {
            return [moment().format('YYYY-MM-DD HH:mm')];
        }
    },
    [ConditionNodeVariant.CurrentWeekday]: {
        variant: ConditionNodeVariant.CurrentWeekday,
        title: "Текущий день недели",
        isAnyDateTime: true,
        allowedVariantTypes: [
            ConditionNodeVariantType.Equal,
            ConditionNodeVariantType.NotEqual,
            ConditionNodeVariantType.GreaterThan,
            ConditionNodeVariantType.GreaterOrEqual,
            ConditionNodeVariantType.LessThan,
            ConditionNodeVariantType.LessOrEqual,
        ],
        defaultValue: () => {
            return [];
        }
    },
}

export const MaxVariants = 15;
