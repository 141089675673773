import "./FormInput.css";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import {Box, Dropdown, FormHelperText, IconButton, MenuButton, Textarea, Typography} from "@mui/joy";
import React, {BaseSyntheticEvent, Dispatch, SetStateAction, useCallback, useRef} from "react";
import {OverridableStringUnion} from "@mui/types";
import {ColorPaletteProp, VariantProp} from "@mui/joy/styles/types";
import {InputPropsVariantOverrides} from "@mui/joy/Input/InputProps";
import {TextareaPropsColorOverrides, TextareaPropsSizeOverrides} from "@mui/joy/Textarea/TextareaProps";
import EmojiPicker, {EmojiClickData, EmojiStyle} from "emoji-picker-react";
import {Code, Smile} from "react-feather";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import {AddRowButtonIcon} from "../../Constructor/Nodes/MessageNode/Keyboard/AddRowButton";
import {KeyboardRows} from "../../Constructor/Nodes/MessageNode/Keyboard/types";
import {FilesIconInput} from "../../Constructor/Nodes/MessageNode/Files/FilesInput";
import {NodeFile} from "../../Constructor/Nodes/MessageNode/Files/types";
import {useConstructorStore} from "../../Stores/ConstructorStore";
import {ProjectType} from "../../types";
import {MessageNodeOptions} from "../../Constructor/Nodes/MessageNode/types";

type props = {
    id?: string;
    label?: string;
    name?: string;
    disabled?: boolean;
    placeholder?: string;
    value?: string;
    errorText?: string;
    trimSpaces?: boolean;
    maxLength?: number;
    onBlur?: () => void;
    showMenu?: boolean
    autoComplete?: string
    validate?: (value: any) => void;
    setValue?: (value: any) => void;
    maxRows?: string | number;
    minRows?: string | number;
    size?: OverridableStringUnion<'sm' | 'md' | 'lg', TextareaPropsSizeOverrides>;
    color?: OverridableStringUnion<ColorPaletteProp, TextareaPropsColorOverrides>;
    variant?: OverridableStringUnion<VariantProp, InputPropsVariantOverrides>;
    keyboardRows: KeyboardRows;
    setKeyboard: Dispatch<SetStateAction<KeyboardRows>>;
    files: NodeFile[];
    options: MessageNodeOptions;
    setFiles: Dispatch<SetStateAction<NodeFile[]>>;
    canMultipleFiles: boolean;
    nodeId: string;
}

/**
 * На самом деле этот компонент заточен под поле ввода текста у MessageNode.
 * todo - перенести в Message.tsx
 */
export const FormTextarea = (props: props) => {
    const hasError = props.errorText !== undefined && props.errorText.length > 0;
    const messageRef = useRef<HTMLTextAreaElement>(null);
    const projectType = useConstructorStore(state => state.projectType);

    const onChange = useCallback((evt: BaseSyntheticEvent) => {
        let value = evt.target.value

        if (props.trimSpaces) {
            value = value.trim();
        }

        if (props.setValue) {
            props.setValue(value);
        }

        if (props.validate) {
            props.validate(value)
        }
    }, [props]);

    const messageInsert = useCallback((val: string) => {
        if (!messageRef.current || !props.setValue) {
            return;
        }

        const field = messageRef.current;
        const start = field.selectionStart;
        const end = field.selectionEnd;
        const value = field.value.substring(0, start) + val + field.value.substring(end, field.value.length);

        field.value = value;
        props.setValue(value);

        if (field.setSelectionRange) {
            field.setSelectionRange(start + val.length, end + val.length);
            field.focus();
        }
    }, [props]);

    const onEmojiClick = useCallback((emoji: EmojiClickData) => {
        messageInsert(emoji.emoji);
    }, [messageInsert]);

    const onVariableClick = useCallback((variable: string) => {
        messageInsert(variable);
    }, [messageInsert]);

    const macros = [
        {
            name: "Имя",
            value: "{%first_name%}",
            projectTypeAllowed: [ProjectType.VK, ProjectType.Telegram]
        },
        {
            name: "Фамилия",
            value: "{%last_name%}",
            projectTypeAllowed: [ProjectType.VK]
        },
        {
            name: "VK ID",
            value: "{%user_id%}",
            projectTypeAllowed: [ProjectType.VK]
        },
        {
            name: "Username",
            value: "{%username%}",
            projectTypeAllowed: [ProjectType.VK, ProjectType.Telegram]
        },
        {
            name: "Медиа вложение",
            value: "{%media:CODE%}",
            projectTypeAllowed: [ProjectType.VK]
        },
    ].filter(macro => macro.projectTypeAllowed.includes(projectType));


    const FooterMenu = () => {
        return (
            <Box sx={{display: 'flex', gap: 0.5, flex: 1, mt: 1}}>
                {props.showMenu &&
                    <Dropdown>
                        <MenuButton
                            slotProps={{root: {variant: 'soft', color: 'neutral'}}}
                            slots={{root: IconButton}}
                            variant="outlined"
                            color="neutral"
                        >
                            <Smile size={16}/>
                        </MenuButton>

                        <Menu
                            sx={{paddingTop: 1.5, backgroundColor: 'transparent'}}
                            placement={"bottom-start"}
                            variant="plain"
                        >
                            <EmojiPicker
                                lazyLoadEmojis={true}
                                previewConfig={{showPreview: false}}
                                emojiStyle={EmojiStyle.NATIVE}
                                skinTonesDisabled={true}
                                onEmojiClick={onEmojiClick}
                            />
                        </Menu>
                    </Dropdown>
                }

                {props.showMenu &&
                    <Dropdown>
                        <MenuButton
                            slotProps={{root: {variant: 'soft', color: 'neutral'}}}
                            slots={{root: IconButton}}
                            variant="outlined"
                            color="neutral"
                        >
                            <Code size={16}/>
                        </MenuButton>

                        <Menu placement={"bottom-start"}>
                            {macros.map((macro) => (
                                <MenuItem key={macro.name} onClick={() => onVariableClick(macro.value)}>
                                    {macro.name}
                                    <Typography level="body-sm" sx={{ml: 'auto'}}>
                                        {macro.value}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Dropdown>
                }

                {props.showMenu &&
                    <AddRowButtonIcon
                        data={props.keyboardRows}
                        setKeyboard={props.setKeyboard}
                        isInline={!props.options.notInlineKeyboard}
                    />
                }

                {props.showMenu &&
                    <FilesIconInput
                        nodeId={props.nodeId}
                        files={props.files}
                        setFiles={props.setFiles}
                        canMultiple={props.canMultipleFiles}
                    />
                }

                {props.maxLength &&
                    <Typography level="body-xs" sx={{ml: 'auto'}}>
                        {props.value?.length} / {props.maxLength}
                    </Typography>
                }
            </Box>
        )
    };

    const ErrorHelper = () => {
        if (props.errorText !== undefined && props.errorText.length > 0) {
            return (
                <FormHelperText sx={{color: "var(--joy-palette-danger-500)"}}>{props.errorText}</FormHelperText>
            );
        }

        return null;
    };

    return (
        <FormControl>
            {props.label && <FormLabel>{props.label}</FormLabel>}
            <Textarea
                id={props.id}
                slotProps={{textarea: {ref: messageRef}}}
                color={hasError ? undefined : props.color}
                minRows={props.minRows}
                maxRows={props.maxRows}
                size={props.size}
                onChange={onChange}
                name={props.name}
                disabled={props.disabled}
                autoComplete={props.autoComplete}
                value={props.value}
                error={hasError}
                onBlur={props.onBlur}
                variant={props.variant}
                placeholder={props.placeholder}
                endDecorator={<FooterMenu/>}
            />
            <ErrorHelper/>
        </FormControl>
    );
}
