import {create} from "zustand";
import {LS_KEY} from "../types";

export enum Preferences {
    SubscribeChatgunTelegramChannel = 1,
}

export enum PreferenceStatus {
    Visible = 'visible',
    Hidden = 'hidden'
}

interface PreferenceState {
    status: PreferenceStatus;
    updatedAt: number;
}

interface PreferencesStore {
    hide(preference: Preferences): void;

    setStatus(preference: Preferences, status: PreferenceStatus): void;

    isVisible(preference: Preferences): boolean;

    getAll(): PreferenceState[];
}

export const usePreferencesStore = create<PreferencesStore>((set) => ({
    getAll() {
        let prefsRaw = localStorage.getItem(LS_KEY.Preferences);
        let prefs = [] as PreferenceState[];

        if (prefsRaw) {
            prefs = JSON.parse(prefsRaw);
        }

        return prefs
    },

    hide(preference: Preferences) {
        this.setStatus(preference, PreferenceStatus.Hidden);
    },

    setStatus(preference: Preferences, status: PreferenceStatus) {
        let prefs = this.getAll()

        prefs[preference] = {
            status,
            updatedAt: Date.now()
        } as PreferenceState;

        localStorage.setItem(LS_KEY.Preferences, JSON.stringify(prefs));
    },

    isVisible(preference: Preferences): boolean {
        const prefs = this.getAll();

        if (!prefs[preference]) {
            return true;
        }

        const state = prefs[preference];

        if (state.status === PreferenceStatus.Visible) {
            return true;
        }

        return state.updatedAt < (Date.now() - 86400 * 1000 * 30);
    },

}));
