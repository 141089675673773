import {Button, Card, CardActions, CardContent, Grid, Typography} from "@mui/joy";
import React, {useMemo, useRef} from "react";
import {toastSuccess, useWindowDimensions} from "../../utils";
import {ScenarioTemplate, TemplateType, useScenarioTemplatesStore} from "../../Stores/ScenarioTemplatesStore";
import {useNavigate} from "react-router-dom";
import {PAGE, ProjectType} from "../../types";
import {useScenariosPreviewStore} from "../../Stores/ScenarioPreviewStore";
import {useProjectStore} from "../../Stores/ProjectStore";
import {ConfirmModal} from "../../Components/ConfirmModal";
import {ModalBox} from "../../Components/ModalBox";

export const CreateScenarioTemplatesBox = () => {
    const sizes = useWindowDimensions();
    const [project] = useProjectStore(state => [state.selectedProject]);
    const [templates, clear] = useScenarioTemplatesStore(state => [state.templates, state.clear])
    const isOpened = templates !== undefined && templates.length > 0;
    const gridRef = useRef<HTMLDivElement>(null);
    let colSize = 4;

    if (gridRef.current) {
        const gridWidth = gridRef.current.offsetWidth;

        if (gridWidth >= 700) {
            colSize = 4;
        } else if (gridWidth > 550 && gridWidth < 700) {
            colSize = 6;
        } else {
            colSize = 12;
        }
    }

    const filteredTemplates = useMemo(() => templates?.filter(template => {
        if (template.type === TemplateType.VK && project?.type === ProjectType.VK) {
            return true;
        }

        if (template.type === TemplateType.Telegram && project?.type === ProjectType.Telegram) {
            return true;
        }

        return template.type === TemplateType.Common;
    }), [templates, project]);

    return (
        <ModalBox
            open={isOpened}
            onClose={clear}
            title='Создание сценария'
            sx={{
                maxWidth: 900,
                maxHeight: sizes.height - sizes.height / 100 * 15
            }}
        >
            <Grid ref={gridRef} container spacing={1.5} alignItems="stretch" sx={{overflowY: 'auto'}}>
                <TemplateItem
                    key="create-from-empty"
                    template={{
                        uuid: "",
                        name: "Пустой шаблон",
                        description: "Создайте сценарий полностью с нуля, без использования готовых шаблонов."
                    } as ScenarioTemplate}
                    size={colSize}
                />

                {filteredTemplates && filteredTemplates.map((template) =>
                    <TemplateItem
                        key={template.uuid}
                        template={template}
                        size={colSize}
                    />
                )}
            </Grid>

        </ModalBox>
    );
}

const TemplateItem = ({template, size}: { template: ScenarioTemplate, size: number }) => {
    const hasUUID = template.uuid.length > 0;

    return (
        <Grid xs={size}>
            <Card variant="outlined" sx={{height: '100%'}}>
                <CardContent>
                    <Typography level="title-lg">{template.name}</Typography>
                    <Typography level="body-sm" sx={{mt: 1}}>{template.description}</Typography>
                </CardContent>

                <CardActions orientation={"vertical"}>
                    {hasUUID &&
                        <Button
                            variant="soft"
                            color="primary"
                            onClick={() => window.open(`/scenario-preview/${template.uuid}`, '_blank')}
                        >
                            Предпросмотр
                        </Button>
                    }

                    {hasUUID ? <CreateButton template={template}/> : <CreateEmptyButton/>}

                </CardActions>
            </Card>
        </Grid>
    )
}

const CreateEmptyButton = () => {
    const navigate = useNavigate();
    const [clear] = useScenarioTemplatesStore(state => [state.clear])

    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={() => {
                navigate(PAGE.SCENARIO_CREATE);
                clear();
            }}
        >
            Создать сценарий с нуля
        </Button>
    );
}

const CreateButton = ({template}: { template: ScenarioTemplate }) => {
    const navigate = useNavigate();
    const [clear] = useScenarioTemplatesStore(state => [state.clear])
    const [copyScenario, copyingScenario] = useScenariosPreviewStore(state => [state.copyScenario, state.copyingScenario]);
    const [project] = useProjectStore(state => [state.selectedProject]);

    const onSubmit = () => copyScenario(template.uuid, Number(project?.id)).then(scenario => {
        if (!scenario.id) {
            return;
        }

        clear();
        navigate(PAGE.SCENARIO_EDIT.replace(':id', String(scenario.id)));
        toastSuccess('Сценарий был успешно создан!');
    });

    return (
        <ConfirmModal
            onConfirm={onSubmit}
            acceptTitle="Создать"
            acceptColor="primary"
            description="Создать из выбранного шаблона сценарий в вашем проекте?"
        >
            <Button variant="outlined" color="primary" loading={copyingScenario}>
                Использовать
            </Button>
        </ConfirmModal>

    );
}
