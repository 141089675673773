import {Avatar, Box, Card, Link, Stack, Tooltip} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {ConfirmModal} from "../Components/ConfirmModal";
import * as React from "react";
import {FormEvent, useCallback, useEffect, useState} from "react";
import {removeCookie, toastSuccess} from "../utils";
import {AUTH_COOKIE, PAGE} from "../types";
import {Form, useNavigate} from "react-router-dom";
import {useAccountStore} from "../Stores/AccountStore";
import Skeleton from "react-loading-skeleton";
import {ModalBox} from "../Components/ModalBox";
import {FormInput} from "../Components/Form/FormInput";
import Button from "@mui/joy/Button";
import {
    useInputErrors,
    validateAlphaNumeric,
    validateInput,
    validateMaxLength,
    validateMinLength,
    validateRequired,
} from "../Components/Form/helpers";
import {VKConnectButton} from "../Auth/SignIn";

export const Account = () => {
    const [
        account,
        loaded,
        fetchMe, editModalOpened,
        openEditModal,
        closeEditModal
    ] = useAccountStore(state => [
        state.account,
        state.loaded,
        state.fetchMe,
        state.editModalOpened,
        state.openEditModal,
        state.closeEditModal
    ]);


    useEffect(() => {
        fetchMe();
        // eslint-disable-next-line
    }, []);

    const Info = (): React.JSX.Element => {
        if (!loaded) {
            return (
                <Box sx={{minWidth: 0, flex: 1, my: -0.75}}>
                    <Skeleton height={40}/>
                </Box>
            )
        }

        return (
            <>
                <Tooltip title="Учетная запись" color={"neutral"} arrow={true} placement="right">
                    <Button
                        variant="plain"
                        color="neutral"
                        sx={{
                            px: 1.25,
                            py: 0.75,
                            mb: -1.75,
                            mt: -0.75,
                            width: '100%',
                            justifyContent: 'flex-start'
                        }}
                        onClick={openEditModal}
                    >
                        <Stack direction="row" gap={0.75} width='100%'  alignItems="flex-start" >
                            <Avatar
                                sx={{mr:0.5, cursor: 'pointer'}}
                                variant="outlined"
                                src={account.photo && account.photo.length === 0 ? undefined : account.photo}
                            />

                            <Stack direction="column" alignItems="flex-start" textAlign='left' width='100%' overflow='hidden'>
                                <Typography fontSize="sm" fontWeight="lg" noWrap width='100%'>{account.name}</Typography>
                                <Typography level="body-xs" noWrap width='100%'>
                                    {account.email.length > 0 ? account.email : 'Учетная запись'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Button>
                </Tooltip>
            </>
        );
    };

    return (
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
            <Info/>
            {editModalOpened && <AccountModal close={closeEditModal}/>}
        </Box>
    )
}

const AccountModal = ({close}: { close: () => void }) => {
    const [account, update, updating] = useAccountStore(state => [state.account, state.update, state.updating]);
    const [name, setName] = useState(account.name)
    const [telegramUsername, setTelegramUsername] = useState(account.telegramUsername)
    const [inputErrors, setInputErrors] = useInputErrors();
    const navigate = useNavigate();

    const validators = useCallback(() => {
        return {
            name: (value: any) => validateInput(
                "name", value, setInputErrors,
                {func: validateRequired},
                {func: validateAlphaNumeric},
                {func: validateMinLength, threshold: 3},
                {func: validateMaxLength, threshold: 32}
            ),
            telegramUsername: (value: any) => validateInput(
                "telegramUsername", value, setInputErrors,
                {func: validateMaxLength, threshold: 128}
            ),
        }
    }, [setInputErrors]);

    const send = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = [
            validators().name(name),
            validators().telegramUsername(telegramUsername),
        ].filter((error) => error !== null);

        if (errors.length > 0) {
            return
        }

        update(name, telegramUsername);
    }, [name, telegramUsername, update, validators]);

    const onLogout = () => {
        removeCookie(AUTH_COOKIE);
        close();
        navigate(PAGE.SIGN_IN);
        return toastSuccess("Вы успешно вышли из системы.");
    }

    return (
        <ModalBox
            open={true}
            onClose={close}
            title='Редактирование профиля'
            sx={{
                maxWidth: 400,
                minWidth: {
                    sm: 300,
                    md: 400,
                },
            }}
        >
            <Stack spacing={2} sx={{mb: 2}}>
                <FormInput type="email" label={'Почта'} value={account.email} disabled/>
            </Stack>

            <Form onSubmit={send}>
                <Stack spacing={2}>

                    <FormInput
                        type={'text'}
                        label={'Имя'}
                        placeholder={'Ваше имя..'}
                        value={name}
                        setValue={setName}
                        validate={validators().name}
                        errorText={inputErrors.get("name")}
                    />

                    <FormInput
                        type={'text'}
                        label={'Telegram'}
                        placeholder={'Ваш логин в Telegram..'}
                        value={telegramUsername}
                        setValue={setTelegramUsername}
                        validate={validators().telegramUsername}
                        errorText={inputErrors.get("telegramUsername")}
                    />

                    {account.vkUserId > 0 &&
                        <Card variant="soft" color="neutral" size="md" sx={{mt: 2}}>
                            <Typography level="body-sm">
                                <Link href={"https://vk.com/id" + account.vkUserId} target="_blank">
                                    Аккаунт ВКонтакте
                                </Link>
                                {' '} привязан к этому профилю.
                            </Typography>
                        </Card>
                    }

                    {account.vkUserId === 0 &&
                        <Card variant="soft" color="neutral" size="md" sx={{mt: 2}}>
                            <Typography level="body-sm">
                                Привяжите аккаунт ВКонтакте, чтобы входить в систему одним кликом.
                            </Typography>
                            <VKConnectButton/>
                        </Card>
                    }
                </Stack>

                <Stack spacing={1} flexWrap="wrap" useFlexGap sx={{mt: 2}} direction="row">
                    <Button loading={updating} sx={{flexGrow: 1}} variant="soft" type='submit'>Сохранить</Button>

                    <ConfirmModal
                        onConfirm={onLogout}
                        acceptTitle="Выйти"
                        acceptColor="primary"
                        description="Вы уверены, что хотите выйти из аккаунта?"
                    >
                        <Button variant="soft" color="danger">Выйти</Button>
                    </ConfirmModal>
                </Stack>
            </Form>
        </ModalBox>
    );
}
