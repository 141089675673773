import {create} from "zustand";
import {API_METHOD} from "../types";
import {ApiClient} from "../api-client";

export enum TemplateType {
    VK = 'vk',
    Telegram = 'tg',
    Common = 'common',
}

export interface ScenarioTemplate {
    description: string;
    name: string;
    uuid: string;
    type: TemplateType;
}

interface ScenarioTemplatesStore {
    loading: boolean;
    templates?: ScenarioTemplate[];
    fetch: () => Promise<ScenarioTemplate[]>;
    clear: () => void;
}

export const useScenarioTemplatesStore = create<ScenarioTemplatesStore>((set) => ({
    loading: false,
    templates: undefined,

    fetch: async (): Promise<ScenarioTemplate[]> => {
        set({loading: true});

        return ApiClient.get(API_METHOD.SCENARIO_TEMPLATES).then(r => {
            set({templates: r.data as ScenarioTemplate[]});
            return r.data as ScenarioTemplate[]
        }).finally(() => set({loading: false}));
    },

    clear: () => {
        set({templates: undefined});
    }
}));
