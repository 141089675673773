import {create} from "zustand";
import {ConstructorMode} from "../Constructor/Nodes/types";
import {ProjectType} from "../types";

interface ConstructorStore {
    mode: any;
    projectType: ProjectType;
    setMode: (mode: ConstructorMode) => void
    setProjectType: (projectType: ProjectType) => void
}

export const useConstructorStore = create<ConstructorStore>((set) => ({
    mode: '',
    projectType: ProjectType.VK,

    setMode: (mode) => {
        set({mode});
    },

    setProjectType: (projectType) => {
        set({projectType});
    }
}));
