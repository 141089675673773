import React, {Dispatch, SetStateAction} from "react";
import './Files.css';
import {NodeFile} from "./types";
import {ImagesList} from "./ImagesList";
import {FilesInput} from "./FilesInput";
import {Box} from "@mui/joy";

type props = {
    nodeId: string;
    files: NodeFile[];
    setFiles: Dispatch<SetStateAction<NodeFile[]>>;
    canMultiple: boolean;
}

export const Files = (props: props) => {
    if (props.files.length <= 0) {
        return <></>;
    }

    return (
        <Box className="MessageNode__files" sx={{marginTop: (props.files.length > 1 ? 0 : '15px')}}>
            <ImagesList files={props.files} setFiles={props.setFiles}/>
            <FilesInput
                nodeId={props.nodeId}
                setFiles={props.setFiles}
                canMultiple={props.canMultiple}
                files={props.files}
            />
        </Box>
    );
};
