import React, {BaseSyntheticEvent, Dispatch, SetStateAction, useCallback, useRef, useState} from "react";
import {ApiResponseUploadUrl} from "../../../../types";
import {MediaGroupMaxFiles, NodeFile} from "./types";
import {Button, IconButton, Tooltip} from "@mui/joy";
import {useProjectStore} from "../../../../Stores/ProjectStore";
import {useUploadStore} from "../../../../Stores/UploadStore";
import {useScenariosPreviewStore} from "../../../../Stores/ScenarioPreviewStore";
import {Image} from "react-feather";
import {toastError} from "../../../../utils";
import Typography from "@mui/joy/Typography";
import CircularProgress from "@mui/joy/CircularProgress";
import {Position} from "reactflow";

type props = {
    nodeId: string;
    files: NodeFile[];
    setFiles: Dispatch<SetStateAction<NodeFile[]>>;
    canMultiple: boolean;
}

export const FilesInput = (props: props) => {
    const [uploadUrlData, setUploadUrlData] = useState<ApiResponseUploadUrl>();
    const [uploading, setUploading] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedProject] = useProjectStore(state => [state.selectedProject]);
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    const uploadImage = useCallback((evt: BaseSyntheticEvent) => {
        const file = evt.target.files[0];

        if (!selectedProject || !file || !uploadUrlData) {
            evt.target.value = '';
            return;
        }

        setUploading(true);

        useUploadStore.getState().uploadObject(uploadUrlData, file, selectedProject.id).then((result) => {
            evt.target.value = '';
            setUploading(false);

            if (result) {
                props.setFiles([...props.files, result]);
            }
        });
    }, [props, selectedProject, uploadUrlData]);

    // По клику на кнопку "Загрузить" сходим за ссылкой на загрузку и доступными типами файлов.
    const onClick = useCallback(async () => {
        if (!inputRef.current || !selectedProject) {
            return;
        }

        setUploading(true);

        const uploadUrlData = await useUploadStore.getState().getUploadUrl(selectedProject.id);

        setUploadUrlData(uploadUrlData);
        setUploading(false);

        inputRef.current.accept = uploadUrlData.types;
        inputRef.current.click();
    }, [selectedProject]);

    if (props.files.length >= MediaGroupMaxFiles) {
        return <></>
    }

    if (isPreviewMode) {
        return <></>
    }

    if (props.files.length <= 1) {
        return <></>; // В этом случае кнопка загрузка показывается в меню поля сообщения.
    }

    return (
        <>
            <Button
                color="neutral"
                size="md"
                variant="outlined"
                loading={uploading}
                onClick={onClick}
                sx={{
                    width: '100%',
                    borderStyle: 'dotted',
                    borderWidth: 2,
                    mt: '15px',
                }}
            >
                {!uploading && <Typography level="body-sm" textColor="neutral.300">Прикрепить изображение</Typography>}
            </Button>
            <input
                ref={inputRef}
                id={`message_node_image_input_${props.nodeId}`}
                onChange={uploadImage}
                type="file"
            />
        </>
    )
}

export const FilesIconInput = (props: props) => {
    const [uploadUrlData, setUploadUrlData] = useState<ApiResponseUploadUrl>();
    const [uploading, setUploading] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedProject] = useProjectStore(state => [state.selectedProject]);

    const uploadImage = useCallback((evt: BaseSyntheticEvent) => {
        const file = evt.target.files[0];

        if (!selectedProject || !file || !uploadUrlData) {
            evt.target.value = '';
            return;
        }

        setUploading(true);

        useUploadStore.getState().uploadObject(uploadUrlData, file, selectedProject.id).then((result) => {
            evt.target.value = '';
            setUploading(false);

            if (result) {
                props.setFiles([...props.files, result]);
            }
        });
    }, [props, selectedProject, uploadUrlData]);

    // По клику на кнопку "Загрузить" сходим за ссылкой на загрузку и доступными типами файлов.
    const onClick = useCallback(async () => {
        if (!inputRef.current || !selectedProject) {
            return;
        }

        if (uploading) {
            return;
        }

        if (props.files.length >= 1 && !props.canMultiple) {
            toastError('Прикрепить несколько вложений можно только к сообщению без текста и клавиатуры.')
            return;
        }

        if (props.files.length >= MediaGroupMaxFiles) {
            toastError('В этот блок уже добавлено максимальное количество изображений.')
            return;
        }

        setUploading(true);

        const uploadUrlData = await useUploadStore.getState().getUploadUrl(selectedProject.id);

        setUploadUrlData(uploadUrlData);
        setUploading(false);

        inputRef.current.accept = uploadUrlData.types;
        inputRef.current.click();
    }, [props.canMultiple, props.files.length, selectedProject, uploading]);

    return (
        <>
            <Tooltip arrow title="Прикрепить изображение" size="sm" placement={Position.Bottom}>
                <IconButton
                    variant="soft"
                    color="neutral"
                    onClick={onClick}
                >
                    {!uploading && <Image size={16}/>}

                    {uploading &&
                        <CircularProgress variant="outlined" color="neutral" sx={{'--CircularProgress-size': '16px'}}/>
                    }
                </IconButton>
            </Tooltip>
            <input
                ref={inputRef}
                style={{display: 'none', position: 'absolute', opacity: 0}}
                id={`message_node_image_input_${props.nodeId}`}
                onChange={uploadImage}
                type="file"
            />
        </>
    )
}
