import * as React from 'react';
import Select, {selectClasses} from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import FormControl from "@mui/joy/FormControl";
import {FormLabel} from "@mui/joy";
import {v4 as uuidv4} from "uuid";
import {Dispatch, SetStateAction} from "react";
import {OverridableStringUnion} from "@mui/types";
import {SelectPropsSizeOverrides, SelectPropsVariantOverrides} from "@mui/joy/Select/SelectProps";
import {VariantProp} from "@mui/joy/styles/types";

export type SelectOption = {
    label: string;
    value: string | number;
};

type props = {
    label?: string;
    value: string | undefined | null | string[] | number,
    options: SelectOption[],
    placeholder?: string;
    setValue: Dispatch<SetStateAction<any>>
    multiple?: boolean;
    disabled?: boolean;
    size?: OverridableStringUnion<'sm' | 'md' | 'lg', SelectPropsSizeOverrides>
    variant?: OverridableStringUnion<VariantProp, SelectPropsVariantOverrides>;
};

export const Dropdown = (props: props) => {
    const labelId = uuidv4();
    const buttonId = uuidv4();

    const label = (
        <FormLabel id={labelId} htmlFor={buttonId}>
            {props.label}
        </FormLabel>
    );

    return (
        <FormControl sx={{width: '100%'}}>
            {props.label ? label : ''}
            <Select
                placeholder={props.placeholder}
                value={props.value}
                multiple={props.multiple}
                indicator={<KeyboardArrowDown/>}
                variant={props.variant}
                size={props.size}
                disabled={props.disabled}
                onChange={(_: any, value) => props.setValue(value)}
                sx={{
                    [`& .${selectClasses.indicator}`]: {
                        transition: '0.2s',
                        [`&.${selectClasses.expanded}`]: {
                            transform: 'rotate(-180deg)',
                        },
                    },
                }}
            >
                {props.options.map((option) => <Option key={option.value} value={option.value}>{option.label}</Option>)}
            </Select>
        </FormControl>
    );
}