import {useReactFlow} from "reactflow";
import {useScenarioUpdateStore} from "../Stores/ScenarioUpdateStore";
import {MouseEvent} from "react";
import {
    AnswerWaitingNodeData,
    BroadcastingStartNodeData,
    CommentNodeData,
    ConditionNodeData,
    EdgeType,
    JumpNodeData,
    MessageNodeData,
    NodeDragHandleClass,
    NodeType,
    PauseNodeData,
    RandomSelectionNodeData,
    ScenarioStartNodeData
} from "./Nodes/types";
import {v4 as uuidv4} from "uuid";
import {ButtonRow, ButtonType, KeyboardRows} from "./Nodes/MessageNode/Keyboard/types";
import {FlowScheme} from "./Flow";
import {useConstructorStore} from "../Stores/ConstructorStore";
import {useScenariosPreviewStore} from "../Stores/ScenarioPreviewStore";

export type UpdateNode = (nodeId: string, data: object) => void;

export const useUpdateNode = (): UpdateNode => {
    const reactFlowInstance = useReactFlow();
    const [setUpdated] = useScenarioUpdateStore((state) => [state.updated]);

    return (nodeId: string, data: object) => reactFlowInstance.setNodes((nodes) => {
        return nodes.map((node) => {
            if (node.id === nodeId) {
                node.data = {...node.data, ...data};
                setUpdated();
            }

            return node;
        });
    });
}

export const useCreateNode = () => {
    const reactFlowInstance = useReactFlow();
    const [scenarioSetUpdated] = useScenarioUpdateStore((state) => [state.updated]);

    return (event: MouseEvent<HTMLDivElement>, nodeType: NodeType, data: any) => {
        const container = document.getElementById('scenario-container');

        if (!container) {
            return;
        }

        const reactFlowBounds = container.getBoundingClientRect();
        // todo - `project` is deprecated. Instead use `screenToFlowPosition`. There is no need to subtract the react flow bounds anymore! https://reactflow.dev/api-reference/types/react-flow-instance#screen-to-flow-position
        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left - 100,
            y: event.clientY + reactFlowBounds.top
        });

        const newNode = {
            id: uuidv4(),
            type: nodeType,
            dragHandle: NodeDragHandleClass,
            data: data,
            position: position,
        };

        reactFlowInstance.addNodes(newNode);
        scenarioSetUpdated();
    };
}

/**
 * Подготавливает данные схемы сценария/рассылки для отправки в бэкенд для сохранения.
 */
export const useSchemePreparer = () => {
    const reactFlowInstance = useReactFlow();

    return (): FlowScheme => {
        const object = reactFlowInstance.toObject();
        const nodes = object.nodes.map((node) => {
            let nodeData = node.data;

            if (node.type === NodeType.ScenarioStartNode) { // Подготовим данные перед отправкой, на случай если сейчас там неактуальный набор полей.
                let data: ScenarioStartNodeData = node.data;

                data = {
                    name: data.name,
                    keys: data.keys,
                    keysMode: data.keysMode,
                };

                nodeData = data;
            } else if (node.type === NodeType.BroadcastingStartNode) {
                let data: BroadcastingStartNodeData = node.data;

                data = {
                    name: data.name,
                    startRule: data.startRule,
                    audienceMode: data.audienceMode,
                    startDatetime: data.startDatetime,
                };

                nodeData = data;
            } else if (node.type === NodeType.MessageNode) {
                let data: MessageNodeData = node.data;

                const buttons = data.buttons.map((row) => {
                    return {
                        id: row.id,
                        buttons: row.buttons.map((button) => {
                            return {
                                id: button.id,
                                name: button.name,
                                type: button.type ? button.type : ButtonType.COMMON,
                                vkColor: button.vkColor ? button.vkColor : '',
                                url: button.url ? button.url : '',
                            };
                        })
                    } as ButtonRow
                }) as KeyboardRows;

                data = {
                    title: data.title,
                    message: data.message ? data.message : '',
                    buttons: buttons,
                    files: data.files ? data.files : [],
                    options: data.options ? data.options : {
                        disableLinkSnippets: false,
                        emptyKeyboard: false,
                        notInlineKeyboard: false,
                        oneTimeKeyboard: false,
                    },
                };

                nodeData = data;
            } else if (node.type === NodeType.PauseNode) {
                let data: PauseNodeData = node.data;

                data = {
                    title: data.title,
                    pauseType: data.pauseType,
                    seconds: data.seconds,
                    timeType: data.timeType,
                    datetime: data.datetime,
                    weekday: data.weekday,
                };

                nodeData = data;
            } else if (node.type === NodeType.JumpNode) {
                const data = node.data as JumpNodeData;

                nodeData = {
                    title: data.title,
                    nodeId: data.nodeId
                } as JumpNodeData;
            } else if (node.type === NodeType.RandomSelectionNode) {
                const data = node.data as RandomSelectionNodeData;

                nodeData = {
                    title: data.title,
                    count: data.count
                } as RandomSelectionNodeData;
            } else if (node.type === NodeType.ConditionNode) {
                const data = node.data as ConditionNodeData;

                nodeData = {
                    title: data.title,
                    type: data.type,
                    variants: data.variants
                } as ConditionNodeData;
            } else if (node.type === NodeType.CommentNode) {
                const data = node.data as CommentNodeData;

                nodeData = {
                    text: data.text,
                } as CommentNodeData;
            } else if (node.type === NodeType.AnswerWaitingNode) {
                const data = node.data as AnswerWaitingNodeData;

                nodeData = {
                    title: data.title,
                    seconds: data.seconds,
                    timeType: data.timeType,
                } as AnswerWaitingNodeData;
            }

            return {
                id: node.id,
                type: node.type,
                data: nodeData,
                position: node.position,
                positionAbsolute: node.positionAbsolute,
            };
        });

        const edges = object.edges.map((edge) => {
            return {
                id: edge.id,
                source: edge.source,
                target: edge.target,
                label: edge.label,
                type: edge.type && edge.type.length > 0 ? edge.type : EdgeType.Removable,
                sourceHandle: edge.sourceHandle,
                targetHandle: edge.targetHandle,
            }
        });

        return {nodes, edges}
    };
}

export const useConstructorProjectType = () => {
    return useConstructorStore(state => state.projectType);
}

export const useIsPreviewMode = () => {
    return useScenariosPreviewStore(state => state.isPreviewMode);
}
