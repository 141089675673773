import React, {Dispatch, SetStateAction} from "react";
import {ButtonData, ButtonRow, KeyboardRows} from "./types";
import {RowHandlers} from "./RowHandlers";
import {KeyboardButton} from "./KeyboardButton";
import {AddButtonToRowButton} from "./AddButtonToRowButton";
import {ReactSortable} from "react-sortablejs";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {useScenariosPreviewStore} from "../../../../Stores/ScenarioPreviewStore";
import IconButton from "@mui/joy/IconButton";

type props = {
    nodeId: string;
    rows: ButtonRow[];
    row: ButtonRow;
    setKeyboardModalData: (value: ButtonData | null) => void;
    updateRow: (buttons: ButtonData[], rowId: string) => void;
    setKeyboard: Dispatch<SetStateAction<KeyboardRows>>;
}

export const Row = (props: props): React.JSX.Element => {
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    const setButtonsSort = (buttons: ButtonData[]) => {
        /**
         * ReactSortable делает фантомные сэты в момент инициализации,
         * что ломает фичу уведомления о несохраненных изменениях.
         *
         * Поэтому, не обновляем кнопки, если они не изменились.
         */
        if (JSON.stringify(buttons) === JSON.stringify(props.row.buttons)) {
            return;
        }

        props.updateRow(buttons, props.row.id);
    }

    return (
        <div className="MessageNodeKeyboardRow">
            {
                /*
                    Пока что получилось сделать только перетаскивание кнопок относительно их ряда.
                    Чтобы включить перетаскивание кнопок между рядами, нужно добавить к ReactSortable опцию
                    group={props.nodeId} @see https://github.com/SortableJS/react-sortablejs.

                    Но в случае перетаскивания между разными группами происходит отправка двух событий setButtonsSort,
                    причем они происходят параллельно, и из-за этого не получается установить корректное состояние
                    многомерного массива с кнопками.
                 */
            }

            {!isPreviewMode &&
                <IconButton
                    className="MessageNodeKeyboardRow__drag"
                    variant="plain" sx={{mr: '5px'}}
                    disabled={props.rows.length <= 1}
                >
                    <DragIndicatorIcon className="" fontSize={"small"}/>
                </IconButton>
            }

            <ReactSortable
                className="MessageNodeKeyboardRow__sortable"
                draggable='.MessageNodeKeyboardButtonContainer'
                list={props.row.buttons}
                setList={setButtonsSort}
                animation={200}
                disabled={isPreviewMode}
                delay={2}
            >
                {
                    props.row.buttons.map((button: ButtonData) => (
                        <KeyboardButton
                            nodeId={props.nodeId}
                            button={button}
                            rowCount={props.row.buttons.length}
                            key={button.id}
                            setModalData={props.setKeyboardModalData}
                        />
                    ))
                }
            </ReactSortable>

            <AddButtonToRowButton
                rowId={props.row.id}
                rowLength={props.row.buttons.length}
                keyboard={props.rows}
                setKeyboard={props.setKeyboard}
            />

            <RowHandlers buttonsRow={props.row.buttons}/>
        </div>
    );
}