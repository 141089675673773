import {useWindowDimensions} from "../../utils";
import {Box, Grid, Tooltip, Typography} from "@mui/joy";
import React, {useMemo} from "react";
import {Briefcase, Calendar, CreditCard, Package} from "react-feather";
import Stack from "@mui/joy/Stack";
import Avatar from "@mui/joy/Avatar";
import Chip from "@mui/joy/Chip";
import {useProjectStore} from "../../Stores/ProjectStore";
import moment from "moment";

export const Tiles = () => {
    const [project] = useProjectStore(state => [state.selectedProject]);

    if (!project) {
        return <></>;
    }

    const balance = project.balance.replace(/\B(?=(\d{3})+(?!\d))/g, " ").split('.')[0];

    return (
        <Grid md={12} container>
            <Tile title="Статус" spacing={0.5} icon={<Briefcase size={20}/>}>
                {project.tariff.leftTime <= 0 &&
                    <Chip color="danger" variant="soft" size="sm" sx={{"--Chip-radius": "6px"}}>
                        Неактивна
                    </Chip>
                }
                {project.tariff.leftTime > 0 &&
                    <Chip color="primary" variant="soft" size="sm" sx={{"--Chip-radius": "6px"}}>
                        Активна
                    </Chip>
                }
            </Tile>

            <Tile title="Дата продления" icon={<Calendar size={20}/>}>
                <Tooltip
                    arrow
                    title={
                        <Box sx={{maxWidth: 400, p: 1}}>
                            {'Подписка автоматически продлится при наличии средств на балансе ' + moment(project.tariff.expiresAt).format('DD.MM.YYYY в HH:mm [GMT+3]') + '.'}
                        </Box>
                    }
                    size="md"
                    placement={'top-end'}
                    color='neutral'
                >
                    <Typography level="body-sm" sx={{fontWeight: 'lg', cursor: 'pointer'}}>
                        {project.tariff.leftTime > 0 && moment(project.tariff.expiresAt).format('D.MM.Y')}
                        {project.tariff.leftTime <= 0 && <Typography textAlign="center">-</Typography>}
                    </Typography>
                </Tooltip>
            </Tile>

            <Tile title="Стоимость" icon={<Package size={20}/>}>
                <Typography level="body-sm" sx={{fontWeight: 'lg'}}>
                    {project.tariff.price}&nbsp;₽
                    <Typography textColor="neutral.400" level="body-xs">
                        &nbsp;/&nbsp;месяц
                    </Typography>
                </Typography>
            </Tile>

            <Tile title="Баланс" icon={<CreditCard size={20}/>}>
                <Typography level="body-sm" sx={{fontWeight: 'lg'}}>
                    {balance}&nbsp;₽
                </Typography>
            </Tile>
        </Grid>
    )
}

const Tile = ({title, children, icon, spacing = 0}: {
    title: string,
    icon: React.JSX.Element
    children: any,
    spacing?: number
}) => {
    const sizes = useWindowDimensions();

    const gridSizes = useMemo(
        () => {
            let xs = 12;
            let md = 6;
            let lg = 3;

            if (sizes.width < 380) {
                xs = 12
            } else if (sizes.width < 450) {
                xs = 6
            } else if (sizes.width < 900) {
                xs = 6;
            } else if (sizes.width < 1100) {
                md = 6;
            }

            return {xs, md, lg}
        },
        [sizes]
    );

    return (
        <Grid xs={gridSizes.xs} md={gridSizes.md} lg={gridSizes.lg} sx={{display: 'flex', justifyContent: 'center'}}>
            <Stack direction="row" alignItems="center" spacing={2} sx={{p: 2}}>
                <Avatar variant="soft" color="primary">{icon}</Avatar>

                <Stack direction="column" spacing={spacing}>
                    <Typography level="body-sm" textColor="neutral.500">{title}</Typography>
                    {children}
                </Stack>
            </Stack>
        </Grid>
    );
}
