import axios, {AxiosError, AxiosResponse} from "axios";
import {useEffect, useState} from "react";
import {ApiResponse, AUTH_COOKIE, PAGE} from "./types";
import {toast} from "react-toastify";
import {useVKMiniAppStore} from "./Stores/VKMiniAppStore";

interface CookieOptions {
    'path'?: string;
    'secure'?: boolean;
    'sameSite'?: 'Strict' | 'Lax' | 'None';
    'max-age'?: number;
}


export const setCookie = (name: string, value: string, options: CookieOptions) => {
    options = {
        'path': '/',
        'secure': true,
        'sameSite': 'Lax',
        'max-age': 3600,
        ...options
    };

    let cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    Object.entries(options).reduce((acc: Record<string, string>, [key, value]) => {
        cookie += "; " + key;

        if (value !== true) {
            cookie += "=" + value;
        }

        return acc
    }, {});

    document.cookie = cookie;
    sessionStorage.setItem(name, value);
}

export const removeCookie = (name: string) => {
    document.cookie = name + "=; expires = Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    sessionStorage.removeItem(name);
}

export const getCookie = (name: string) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
    ));

    if (matches) {
        return decodeURIComponent(matches[1]);
    }

    const sessionItem = sessionStorage.getItem(name);

    return sessionItem ? sessionItem : undefined;
}

export const isAuthorized = (): boolean => { // todo - переиспользовать везде
    return getCookie(AUTH_COOKIE) !== undefined
}

export const useIsVKMiniApp = () => {
    return useVKMiniAppStore(state => state.isMiniApp);
}

export const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0;
}

const storageAxiosInstance = axios.create();

export const storageClient = {
    put: (method: string, data: any, successCallback: (response: AxiosResponse) => void) => {
        return storageAxiosInstance.put(method, data).then(successCallback).catch(error => storageClient._processError(error));
    },

    _processError: (error: AxiosError) => {
        if (!error.response) {
            console.error(error);
            return toastError("Неизвестная ошибка.");
        }

        const response = error.response;
        const data = response.data as ApiResponse;

        return toastError(`${data.data}`);
    },
}

export const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;

    return {width, height};
}

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const numeralsLabel = (numerals: number, one: string, two: string, five: string) => {
    const lastDigit = numerals % 10;
    const lastTwoDigits = numerals % 100;

    if (lastDigit === 1 && lastTwoDigits !== 11) {
        return one;
    }

    if (lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 10 || lastTwoDigits >= 20)) {
        return two;
    }

    return five;
}

export const openSidebar = () => {
    if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
};

export const closeSidebar = () => {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
};

export const toggleSidebar = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        } else {
            openSidebar();
        }
    }
};

export const toastInfo = (message: string) => {
    toast.info(message, {
        autoClose: 7000,
        progress: undefined,
    });
}

export const toastError = (message: string) => {
    toast.error(message, {
        autoClose: 7000,
        progress: undefined,
    });
}

export const toastSuccess = (message: string) => {
    toast.success(message, {
        autoClose: 5000,
        progress: undefined,
    });
}

export const copyToClipboard = (text: string, successMessage?: string) => {
    navigator.clipboard.writeText(text).then(() => {
        if (successMessage) {
            toastSuccess(successMessage)
        }
    }).catch(err => {
        console.error('Copy to clipboard error', err);
    });
}

export const pageLink = (page: PAGE): string => {
    return process.env.REACT_APP_CLIENT_URL + page;
}

export const isDev = (): boolean => {
    return process.env.NODE_ENV === 'development';
}

export const groupConnectAuthUrl = (id: number, redirectUrl: string): string => {
    const params = new URLSearchParams({
        client_id: String(process.env.REACT_APP_VK_AUTH_APP_ID),
        state: String(id),
        display: 'page',
        redirect_uri: redirectUrl,
        group_ids: String(id),
        scope: 'messages,docs,photos,manage,wall',
        response_type: 'code',
        v: '5.131',
    });

    return 'https://oauth.vk.com/authorize?' + params.toString()
}
