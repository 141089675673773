import {AnswerWaitingNodeData, Day, Hour, Minute, TimeType} from "../types";
import {useUpdateNode} from "../../utils";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useScenariosPreviewStore} from "../../../Stores/ScenarioPreviewStore";
import {useScenarioUpdateStore} from "../../../Stores/ScenarioUpdateStore";
import {useReactFlow} from "reactflow";
import {NodeHeader} from "../../NodeHeader";
import {
    SetInputErrors,
    useInputErrors,
    validateInput,
    validateMaxValue,
    validateMinValue,
    validateRequired
} from "../../../Components/Form/helpers";
import {numeralsLabel} from "../../../utils";
import {Card} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {FormInput} from "../../../Components/Form/FormInput";
import {TimerViewTypeSelector} from "../PauseNode/PauseNode";
import Box from "@mui/joy/Box";
import {NodeHandleRole, NodeSourceHandle} from "../../Edges/NodeHandle";

const MaxDays = 7;
const MaxHours = MaxDays * 24;
const MaxMinutes = MaxHours * 60;

export const AnswerWaitingNode = ({id, data}: { id: string, data: AnswerWaitingNodeData }) => {
    useEffect(() => {
        useScenarioUpdateStore.getState().clear();
    }, []);

    return (
        <Box className="Node__container nowheel" sx={{width: '350px'}}>
            <NodeHeader
                title={"Ожидание ответа"}
                nodeTitle={data.title}
                nodeId={id}
                nodeHandleRole={NodeHandleRole.AnswerWaitingNode}
                testable
                deletable
            />

            <Box className="Node__content">
                {useMemo(() => <Content id={id} data={data}/>, [id, data])}
            </Box>

            <NodeSourceHandle id={id} role={NodeHandleRole.AnswerWaitingNode}/>
        </Box>
    );
}

const Content = ({id, data}: { id: string, data: AnswerWaitingNodeData }) => {
    const reactFlowInstance = useReactFlow();
    const [seconds, setSeconds] = useState(data.seconds);
    const [timeType, setTimeType] = useState(data.timeType);
    const [inputErrors, setInputErrors] = useInputErrors();
    const updateNode = useUpdateNode();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    const validators = useCallback(() => timeValidators(timeType, setInputErrors), [setInputErrors, timeType]);

    useEffect(() => {
        updateNode(id, {
            seconds: seconds,
            timeType: timeType,
        } as AnswerWaitingNodeData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, seconds, reactFlowInstance, timeType]);

    const onSecondsChange = useCallback((value: string) => {
        if (timeType === TimeType.Minutes) {
            setSeconds(Number(value) * Minute);
        } else if (timeType === TimeType.Hours) {
            setSeconds(Number(value) * Hour);
        } else {
            setSeconds(Number(value) * Day);
        }
    }, [timeType]);

    const getInputValue = useCallback(() => {
        if (timeType === TimeType.Minutes) {
            return Math.ceil(seconds / Minute);
        } else if (timeType === TimeType.Hours) {
            return Math.ceil(seconds / Hour);
        } else {
            return Math.ceil(seconds / Day);
        }
    }, [seconds, timeType]);

    useEffect(() => {
        onSecondsChange(String(getInputValue()));
        validators().seconds(getInputValue());
    }, [getInputValue, onSecondsChange, seconds, timeType, validators]);

    const typeSelector = useMemo(() => {
        return <TimerViewTypeSelector rawValue={getInputValue()} timeType={timeType} setTimeType={setTimeType}/>;
    }, [getInputValue, timeType]);

    const descriptionCard = useMemo(() => {
        return <DescriptionCard value={getInputValue()} timeType={timeType}/>
    }, [getInputValue, timeType]);

    return (
        <>
            <FormInput
                type="number"
                value={String(getInputValue())}
                endDecorator={typeSelector}
                setValue={onSecondsChange}
                label="Сколько ждать"
                validate={validators().seconds}
                errorText={inputErrors.get("seconds")}
                placeholder="Введите число.."
                disabled={isPreviewMode}
            />

            {descriptionCard}
        </>
    );
}

const DescriptionCard = ({timeType, value}: { timeType: TimeType, value: number }) => {
    const [descriptionLabel, setDescriptionLabel] = useState('');

    useEffect(() => {
        switch (timeType) {
            case TimeType.Minutes:
                setDescriptionLabel(String(value) + ` ${numeralsLabel(value, "минуты", "минут", "минут")}`);
                break;

            case TimeType.Hours:
                setDescriptionLabel(String(value) + ` ${numeralsLabel(value, "часа", "часов", "часов")}`);
                break;

            case TimeType.Days:
                setDescriptionLabel(String(value) + ` ${numeralsLabel(value, "дня", "дней", "дней")}`);
                break;
        }
    }, [value, timeType]);

    return (
        <Card invertedColors variant="soft" color="neutral" sx={{boxShadow: 'none', mt: '15px'}}>
            <Typography level="body-sm">
                Если пользователь не ответит или не нажмет на кнопку в течение{' '}
                <strong style={{whiteSpace: 'nowrap'}}>{descriptionLabel}</strong>,
                то запустится следующий блок.
            </Typography>
        </Card>
    );
}

const timeValidators = (timeType: TimeType, setInputErrors: SetInputErrors) => {
    let maxMessage = `Максимальное значение ${MaxMinutes} ${numeralsLabel(MaxMinutes, "минута", "минуты", "минут")}`;
    let minMessage = `Минимальное значение 1 минута`;
    let maxValue = MaxMinutes;

    if (timeType === TimeType.Hours) {
        maxMessage = `Максимальное значение ${MaxHours} ${numeralsLabel(MaxHours, "час", "часа", "часов")}`;
        minMessage = `Минимальное значение 1 час`;
        maxValue = MaxHours;
    } else if (timeType === TimeType.Days) {
        maxMessage = `Максимальное значение ${MaxDays} ${numeralsLabel(MaxDays, "день", "дня", "дней")}`;
        minMessage = `Минимальное значение 1 день`;
        maxValue = MaxDays;
    }

    return {
        seconds: (value: any) => validateInput(
            "seconds", value, setInputErrors,
            {func: validateRequired},
            {func: validateMinValue, threshold: 1, message: minMessage},
            {func: validateMaxValue, threshold: maxValue, message: maxMessage},
        ),
    }
}
