import {Form, useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Box} from "@mui/material";
import {edgeOptions, fitViewOptions, Minimap} from "../Constructor/Flow";
import {LoadingScenario} from "./Scenarios";
import {ConstructorControlsContainer, createNodeTitle} from "./ConstructorControls";
import ReactFlow, {Background, Controls, Node, useEdgesState, useNodesState} from "reactflow";
import {EdgeTypes, NodeDragHandleClass, NodeType, NodeTypes} from "../Constructor/Nodes/types";
import {AppContainer} from "../Components/AppContainer";
import {useScenariosPreviewStore} from "../Stores/ScenarioPreviewStore";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import {Button, Card} from "@mui/joy";
import {useProjectStore} from "../Stores/ProjectStore";
import {Dropdown} from "../Components/Dropdown";
import {PAGE, Scenario} from "../types";
import {isAuthorized, toastSuccess} from "../utils";
import {useScenariosStore} from "../Stores/ScenarioStore";
import {ModalBox} from "../Components/ModalBox";

export const ScenarioPreview = () => {
    const params = useParams();
    const uuid = params.uuid;

    const [loading, load, scenario, loadingFailed] = useScenariosPreviewStore(state => [
        state.loadingScenario,
        state.fetchScenario,
        state.scenario,
        state.loadingFailed
    ]);

    useEffect(() => {
        load(String(uuid));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    return (
        <AppContainer>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'grid',
                    minWidth: 0,
                    height: '100dvh',
                }}
            >
                {!scenario && loading && <LoadingScenario/>}
                {scenario && <Preview scenario={scenario}/>}
                {!loading && !scenario && loadingFailed && <LoadingError/>}
            </Box>
        </AppContainer>
    );
}

const Preview = ({scenario}: { scenario: Scenario }) => {
    const scheme = scenario.scheme;
    const [nodes, setNodes] = useNodesState([]);
    const [edges, setEdges] = useEdgesState([]);
    const [setPreviewMode] = useScenariosPreviewStore(state => [state.setPreviewMode]);

    useEffect(() => {
        if (!scheme) {
            return;
        }

        scheme.nodes = scheme.nodes.map((node: Node) => {
            if (!node.data.title && node.type !== NodeType.ScenarioStartNode) {
                node.data.title = createNodeTitle(node.type ? node.type : '');
            }

            return {...node, dragHandle: NodeDragHandleClass};
        });

        setNodes(scheme.nodes);
        setEdges(scheme.edges);
        setPreviewMode(true);
    }, [scheme, setEdges, setNodes, setPreviewMode]);

    return (
        <Box
            sx={{
                pt: {
                    xs: `var(--Header-height)`,
                    sm: `var(--Header-height)`,
                },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
            }}
        >
            <PreviewControls scenario={scenario}/>
            <ReactFlow
                id={"scenario-container"}
                defaultEdgeOptions={edgeOptions}
                proOptions={{hideAttribution: true}}
                nodes={nodes}
                edges={edges}
                nodeTypes={NodeTypes}
                edgeTypes={EdgeTypes}
                nodesConnectable={false}
                nodesDraggable={false}
                fitView
                fitViewOptions={fitViewOptions}
                minZoom={0.2}
                maxZoom={1.2}
            >
                <Controls showInteractive={false}/>
                <Background/>
                <Minimap/>
            </ReactFlow>
        </Box>
    )
}

const LoadingError = () => {
    return (
        <Sheet
            sx={{
                maxWidth: 320,
                mx: 'auto', // margin left & right
                my: {xs: 10, md: 18, lg: 20}, // margin top & botom
                py: 3, // padding top & bottom
                px: 2, // padding left & right
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 'sm',
                boxShadow: 'md',
                height: 'max-content',
                textAlign: 'center'
            }}
            variant="outlined"
        >
            <Typography level="h4" textAlign="center">404</Typography>
            <Typography level="body-md">Сценарий не найден</Typography>
        </Sheet>
    )
}

const PreviewControls = ({scenario}: { scenario: Scenario }) => {
    const authorized = isAuthorized();
    const navigate = useNavigate();
    const [setCopyModalOpened] = useScenariosPreviewStore(state => [state.setCopyModalOpened]);
    const [projectsLoading, fetchProjects] = useProjectStore(state => [
        state.projectsLoading,
        state.fetchProjects
    ]);

    return (
        <ConstructorControlsContainer>
            <Stack spacing={1} direction="row" alignItems="center">
                {scenario.publicCopyAllowed &&
                    <Button
                        className="noselect"
                        size="sm"
                        color="primary"
                        variant="soft"
                        onClick={() => {
                            if (!authorized) {
                                window.open(PAGE.SIGN_IN, '_blank');
                                return;
                            }

                            fetchProjects().finally(() => setCopyModalOpened(true));
                        }}
                        loading={projectsLoading}
                    >
                        Скопировать сценарий
                    </Button>
                }

                <Button
                    className="noselect"
                    size="sm"
                    color="neutral"
                    variant="soft"
                    onClick={() => {
                        if (authorized) {
                            navigate(PAGE.SCENARIOS);
                        } else {
                            navigate(PAGE.SIGN_IN);
                        }
                    }}
                >
                    Перейти в конструктор
                </Button>
            </Stack>
            <CopyModal scenario={scenario}/>
        </ConstructorControlsContainer>
    );
}

const CopyModal = ({scenario}: { scenario: Scenario }) => {
    const navigate = useNavigate();
    const [opened, setOpened, copyScenario, copyingScenario] = useScenariosPreviewStore(state => [state.copyModalOpened, state.setCopyModalOpened, state.copyScenario, state.copyingScenario]);
    const [project, setProject] = useState('');
    const [projects, switchProject] = useProjectStore(state => [state.projects, state.switchProject]);
    const [clearScenarioStore] = useScenariosStore(state => [state.clear]);

    const DropdownTypes = projects.map((project) => {
        return {label: project.name, value: project.id}
    });

    const onSubmit = useCallback(() => {
        copyScenario(scenario.uuid, Number(project)).then(scenario => {
            if (!scenario.id) {
                return;
            }

            clearScenarioStore();
            switchProject(scenario.projectId);
            navigate(PAGE.SCENARIO_EDIT.replace(':id', String(scenario.id)));
            toastSuccess('Сценарий был успешно скопирован!');
        })
    }, [clearScenarioStore, copyScenario, navigate, project, scenario.uuid, switchProject]);

    return (
        <ModalBox open={opened} onClose={() => setOpened(false)} title='Копирование сценария' sx={{maxWidth: 500}}>
            {projects.length === 0 &&
                <Stack spacing={2}>
                    <Card variant="soft" color={'warning'} sx={{boxShadow: 'none'}}>
                        <Stack direction="row" spacing={2} alignItems={'center'}>
                            <Typography level="body-sm">
                                Создайте свой первый проект, чтобы в него можно было скопировать сценарий.
                            </Typography>
                        </Stack>
                    </Card>
                    <Button onClick={() => window.open(PAGE.PROJECT_CREATE, '_blank')}>
                        Создать проект
                    </Button>
                </Stack>
            }

            {projects.length > 0 &&
                <Form onSubmit={onSubmit}>
                    <Stack spacing={2}>
                        <Card variant="soft" color={'neutral'} sx={{boxShadow: 'none'}}>
                            <Stack direction="row" spacing={2} alignItems={'center'}>
                                <Typography level="body-sm">
                                    Выберите проект, в который будет скопирован сценарий.
                                </Typography>
                            </Stack>
                        </Card>

                        <Dropdown
                            placeholder={'Выберите проект..'}
                            value={project}
                            options={DropdownTypes}
                            setValue={setProject}
                            size={'md'}
                        />

                        <Button type='submit' disabled={!project} loading={copyingScenario}>Скопировать</Button>
                    </Stack>
                </Form>
            }
        </ModalBox>
    );
}
