export const MediaGroupMaxFiles = 10;

export enum FileType {
    Image = 'image',
    Document = 'document',
}

export type NodeFile = {
    id: number;
    url: string;
    type: string;
    objectKey: string;
}