import {Box, Button, Dropdown, IconButton, MenuButton, Sheet, Stack, Tooltip} from "@mui/joy";
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import React, {MouseEvent, useCallback, useEffect, useRef, useState} from "react";
import {
    AnswerWaitingNodeData,
    CommentNodeData,
    ConditionNodeData,
    ConstructorAvailableNodes,
    ConstructorMode,
    JumpNodeData,
    MessageNodeData,
    NodeType,
    PauseNodeData,
    PauseNodeType,
    RandomSelectionNodeData,
    TimeType,
} from "../Constructor/Nodes/types";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useScenariosStore} from "../Stores/ScenarioStore";
import {PAGE, Scenario} from "../types";
import {BarChart2, Settings, X, Zap} from "react-feather";
import {useScenarioUpdateStore} from "../Stores/ScenarioUpdateStore";
import {isEmpty} from "lodash";
import Typography from "@mui/joy/Typography";
import {ModalContent} from "../Components/ModalContent";
import {ConditionNodeType} from "../Constructor/Nodes/ConditionNode/type";
import {useScenariosAccessSettingsStore} from "../Stores/ScenarioAccessSettingsStore";
import {ScenarioSettingsBox} from "./ScenarioSettingsBox";
import {useNavigate} from "react-router-dom";
import {useCreateNode, useSchemePreparer} from "../Constructor/utils";
import {useBroadcastingStore} from "../Stores/BroadcastingStore";
import {useWindowDimensions} from "../utils";

type props = {
    projectId: number;
    scenario: Scenario;
}

export const ConstructorControls = (props: props) => {
    const navigate = useNavigate();
    const prepareScheme = useSchemePreparer();
    const scenariosStore = useScenariosStore();
    const scenario = props.scenario;

    const onSave = useCallback((afterSave: any = undefined) => {
        scenario.scheme = prepareScheme();

        if (scenario.id > 0) {
            scenariosStore.saveScenario(props.projectId, scenario, afterSave);
        } else {
            scenariosStore.createScenario(props.projectId, scenario, afterSave).then(scenario => {
                if (!scenario || scenario.id <= 0) {
                    return;
                }

                navigate(PAGE.SCENARIO_EDIT.replace(':id', String(scenario.id)));
            });
        }
    }, [prepareScheme, navigate, props.projectId, scenario, scenariosStore]);

    return (
        <ConstructorControlsContainer>
            <Stack spacing={1} direction="row" alignItems="center">
                <Button
                    className="noselect"
                    size="sm"
                    onClick={onSave}
                    color="primary"
                    variant="soft"
                    loading={scenariosStore.savingScenario}
                >
                    Сохранить
                </Button>

                <CreateNodeButton mode={ConstructorMode.Scenario}/>
                <SettingsButton projectId={props.projectId} scenarioId={scenario.id}/>
                <TestButton projectId={props.projectId} scenarioId={scenario.id}/>
                <StatShowButton projectId={props.projectId} scenarioId={scenario.id}/>
                <CloseButton onSave={onSave} mode={ConstructorMode.Scenario}/>
            </Stack>
        </ConstructorControlsContainer>
    );
};

export const ConstructorControlsContainer = ({children}: { children: any }) => {
    const sizes = useWindowDimensions();
    const ref = useRef<HTMLDivElement>(null);
    const [overflow, setOverflow] = useState('hidden')

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const containerWidth = ref.current.clientWidth;
        const clientWidth = sizes.width;
        const newOverflow = containerWidth >= clientWidth ? 'scroll' : 'hidden';

        if (newOverflow === overflow) {
            return;
        }

        setOverflow(newOverflow);
    }, [sizes, ref, overflow]);

    return (
        <Box ref={ref} sx={{
            p: 1,
            zIndex: 4,
            position: 'absolute',
            right: 0,
            maxWidth: '100%',
        }}>
            <Sheet
                variant="outlined"
                sx={{
                    p: 1,
                    borderColor: 'divider',
                    borderRadius: 'sm',
                    overflowX: overflow,
                }}
            >
                {children}
            </Sheet>
        </Box>
    );
};

export const CreateNodeButton = ({mode, disabled}: { mode: ConstructorMode, disabled?: boolean }) => {
    const createNode = useCreateNode();
    const allowedNodes = ConstructorAvailableNodes[mode];
    const isAllowed = (nodeType: NodeType): boolean => allowedNodes.includes(nodeType);

    const createMessageNode = useCallback((event: MouseEvent<HTMLDivElement>) => {
        const data = {
            title: createNodeTitle('Сообщение'),
            message: "",
            buttons: [],
            files: [],
            options: {
                disableLinkSnippets: false,
                emptyKeyboard: false,
                notInlineKeyboard: false,
                oneTimeKeyboard: false,
            },
        } as MessageNodeData;

        createNode(event, NodeType.MessageNode, data);
    }, [createNode]);

    const createPauseNode = useCallback((event: MouseEvent<HTMLDivElement>) => {
        const data = {
            title: createNodeTitle('Отложенная отправка'),
            seconds: 60,
            timeType: TimeType.Minutes,
            datetime: '',
            pauseType: PauseNodeType.Timer,
            weekday: [],
        } as PauseNodeData;

        createNode(event, NodeType.PauseNode, data);
    }, [createNode]);


    const createJumpNode = useCallback((event: MouseEvent<HTMLDivElement>) => {
        const data = {
            title: createNodeTitle('Переход к блоку'),
            nodeId: '',
        } as JumpNodeData;

        createNode(event, NodeType.JumpNode, data);
    }, [createNode]);

    const createRandomSelectionNode = useCallback((event: MouseEvent<HTMLDivElement>) => {
        const data = {
            title: createNodeTitle('Случайный выбор'),
            count: 1,
        } as RandomSelectionNodeData;

        createNode(event, NodeType.RandomSelectionNode, data);
    }, [createNode]);

    const createConditionNode = useCallback((event: MouseEvent<HTMLDivElement>) => {
        const data = {
            title: createNodeTitle('Условие'),
            type: ConditionNodeType.Strict,
            variants: [],
        } as ConditionNodeData;

        createNode(event, NodeType.ConditionNode, data);
    }, [createNode]);


    const createCommentNode = useCallback((event: MouseEvent<HTMLDivElement>) => {
        const data = {
            text: '',
        } as CommentNodeData;

        createNode(event, NodeType.CommentNode, data);
    }, [createNode]);

    const createAnswerWaitingNode = useCallback((event: MouseEvent<HTMLDivElement>) => {
        createNode(event, NodeType.AnswerWaitingNode, {
            title: createNodeTitle('Ожидание ответа'),
            seconds: 60,
            timeType: TimeType.Minutes,
        } as AnswerWaitingNodeData);
    }, [createNode]);

    return (
        <Dropdown>
            <MenuButton
                size="sm"
                color="primary"
                variant="soft"
                disabled={disabled}
                endDecorator={<ExpandMoreIcon/>}
            >Добавить
            </MenuButton>
            <Menu placement="bottom-end" className="ConstructorControlsCreateNodeMenuContainer">
                {isAllowed(NodeType.MessageNode) &&
                    <MenuItem onClick={createMessageNode}>
                        Сообщение
                    </MenuItem>
                }

                {isAllowed(NodeType.PauseNode) &&
                    <MenuItem onClick={createPauseNode}>
                        Отложенную отправку
                    </MenuItem>
                }

                {isAllowed(NodeType.JumpNode) &&
                    <MenuItem onClick={createJumpNode}>
                        Переход к блоку
                    </MenuItem>
                }

                {isAllowed(NodeType.RandomSelectionNode) &&
                    <MenuItem onClick={createRandomSelectionNode}>
                        Случайный выбор
                    </MenuItem>
                }

                {isAllowed(NodeType.ConditionNode) &&
                    <MenuItem onClick={createConditionNode}>
                        Условие
                    </MenuItem>
                }

                {isAllowed(NodeType.AnswerWaitingNode) &&
                    <MenuItem onClick={createAnswerWaitingNode}>
                        Ожидание ответа
                    </MenuItem>
                }

                {isAllowed(NodeType.CommentNode) &&
                    <MenuItem onClick={createCommentNode}>
                        Комментарий
                    </MenuItem>
                }

            </Menu>
        </Dropdown>
    )
}
export const CloseButton = ({onSave, mode}: {
    onSave: (afterSave: any) => void,
    mode: ConstructorMode
}): React.JSX.Element => {
    const [open, setOpen] = useScenarioUpdateStore(state => [state.confirmCloseModalOpen, state.setConfirmCloseModalOpen]);
    const [broadcastingSaving] = useBroadcastingStore(state => [state.saving]);
    const scenariosStore = useScenariosStore();
    const scenarioUpdatedStore = useScenarioUpdateStore();
    const navigate = useNavigate();

    const onClose = () => {
        if (mode === ConstructorMode.Scenario) {
            scenariosStore.setSelectedScenario(undefined);
            navigate(PAGE.SCENARIOS);
        } else if (mode === ConstructorMode.Broadcasting) {
            navigate(PAGE.BROADCASTING);
        }
    };

    const button = <IconButton
        className="nooutline"
        variant="plain"
        color="neutral"
        onClick={onClose}
        size="sm"
    >
        <X size={20}/>
    </IconButton>

    if (!scenarioUpdatedStore.hasUpdates) {
        return button;
    }

    return (
        <ModalContent
            open={open}
            setOpen={setOpen}
            width={450}
            title={'Подтверждение'}
            startButton={button}
        >
            <Typography textColor="text.tertiary" mb={3}>
                У вас есть несохраненные изменения, сохранить сценарий перед выходом?
            </Typography>

            <Box sx={{display: 'flex', gap: 2, mt: 2, justifyContent: 'flex-end'}}>
                <Button
                    onClick={() => setOpen(false)}
                    color="neutral"
                    variant="plain"
                    disabled={scenariosStore.savingScenario || broadcastingSaving}
                >
                    Отмена
                </Button>

                <Button
                    onClick={() => {
                        setOpen(false);
                        onClose();
                        scenarioUpdatedStore.clear();
                    }}
                    disabled={scenariosStore.savingScenario || broadcastingSaving}
                    color="danger"
                >
                    Не сохранять
                </Button>

                <Button
                    loading={scenariosStore.savingScenario || broadcastingSaving}
                    onClick={() => {
                        onSave(() => {
                            setOpen(false);
                            onClose();
                        })
                    }}
                    color="success"
                >
                    Сохранить
                </Button>
            </Box>

        </ModalContent>
    );
}

const TestButton = ({projectId, scenarioId}: { projectId: number, scenarioId: number }): React.JSX.Element => {
    const [testScenario, testing] = useScenariosStore(state => [state.test, state.testing]);

    if (scenarioId <= 0) {
        return <></>;
    }

    return (
        <Tooltip title="Тестировать сценарий" color={"neutral"} arrow={true} placement="bottom-end">
            <Button
                className="noselect"
                size="sm"
                onClick={() => {
                    if (testing) {
                        return;
                    }

                    testScenario(projectId, scenarioId, "");
                }}
                color='neutral'
                variant='soft'
                loading={testing}
            >
                <Zap size={20}/>
            </Button>
        </Tooltip>
    );
}

const StatShowButton = ({projectId, scenarioId}: { projectId: number, scenarioId: number }): React.JSX.Element => {
    const scenariosStore = useScenariosStore();
    const metricLoaded = !isEmpty(scenariosStore.scenarioMetrics)

    const onClick = () => {
        if (metricLoaded) {
            return scenariosStore.clearScenarioMetrics();
        }

        scenariosStore.fetchScenariosMetrics(projectId, scenarioId);
    };

    const button = (
        <Button
            className="noselect"
            size="sm"
            onClick={onClick}
            color={metricLoaded ? 'primary' : 'neutral'}
            variant={metricLoaded ? 'solid' : 'soft'}
            loading={scenariosStore.loadingMetrics}
        >
            <BarChart2 size={20}/>
        </Button>
    );

    if (scenarioId <= 0) {
        return <></>;
    }

    if (metricLoaded) {
        return button;
    }

    return (
        <Tooltip arrow title={'Статистика шагов'} size="md" placement={'bottom-end'} color='neutral'>
            {button}
        </Tooltip>
    );
}

const SettingsButton = ({scenarioId, projectId}: { scenarioId: number, projectId: number }) => {
    const [loadSettings, loadingSettings, settings] = useScenariosAccessSettingsStore(state => [state.getSettings, state.loadingSettings, state.settings])

    if (scenarioId <= 0) {
        return <></>;
    }

    return (
        <>
            <Tooltip arrow title={'Настройки сценария'} size="md" placement={'bottom-end'} color='neutral'>
                <Button
                    className="noselect"
                    size="sm"
                    onClick={() => {
                        loadSettings(scenarioId, projectId);
                    }}
                    loading={loadingSettings}
                    color={'neutral'}
                    variant={'soft'}
                >
                    <Settings size={20}/>
                </Button>
            </Tooltip>
            {settings && <ScenarioSettingsBox settings={settings} closeAfterSave/>}
        </>
    );
}

export const createNodeTitle = (prefix: string): string => {
    const r = (Math.random() + 1).toString(36).substring(7);

    if (prefix.length === 0) {
        return r.toUpperCase();
    }

    prefix = prefix.replace('Node', '');
    prefix = prefix.charAt(0).toUpperCase() + prefix.slice(1)

    return prefix + ` ${r.toUpperCase()}`
}
