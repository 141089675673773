import {Modal, Typography} from "@mui/joy";
import React from "react";
import ModalDialog from "@mui/joy/ModalDialog";
import {SxProps} from "@mui/joy/styles/types";
import {ModalCloseButton} from "./ModalCloseButton";
import Stack from "@mui/joy/Stack";

type props = {
    open: boolean;
    title?: string;
    sx?: SxProps;
    onClose?: () => any;
    closeIcon?: boolean;
    children: any;
}

export const ModalBox = ({open, title, sx, onClose, closeIcon, children}: props) => {
    const CloseButton = () => {
        /**
         * В режиме разработки почему-то не срабатывает клик на крестик, возможно этот компонент как-то криво собран,
         * либо криво вызывается, но в собранном прод. бандле такой проблемы нет.
         * Ничего лучше, кроме как в дев режиме просто добавить явный onClick я не придумал, дебаг особо результатов не дал.
         *
         * upd: вроде после обновлений mui отпустило
         */
        if (process.env.NODE_ENV === 'development') {
            // return (
            //     <Box onClick={onClose}>
            //         <ModalCloseButton/>
            //     </Box>
            // );
        }

        return (
            <ModalCloseButton/>
        );
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog
                aria-labelledby="basic-modal-dialog-title"
                aria-describedby="basic-modal-dialog-description"
                size="sm"
                sx={sx}
            >

                <Stack sx={{mb: 2, width: '90%'}}>
                    {title && <Typography level="title-md" fontWeight="bold" noWrap>{title}</Typography>}
                    {closeIcon !== false && <CloseButton/>}
                </Stack>

                {children}
            </ModalDialog>
        </Modal>
    )
}
