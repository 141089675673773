import * as React from "react";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import {DefaultColorPalette} from "@mui/joy/styles/types/colorSystem";
import {ModalBox} from "./ModalBox";

type props = {
    children: React.JSX.Element;
    title?: string;
    description?: string;
    cancelTitle?: string;
    acceptTitle?: string;
    acceptColor?: DefaultColorPalette;
    onConfirm: any;
}

export const ConfirmModal = (props: props): React.JSX.Element => {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            {React.cloneElement(props.children, {onClick: () => setOpen(true)})}
            {open &&
                <ModalBox open={open} onClose={() => setOpen(false)} title={props.title ?? "Подтверждение"}>
                    <Box>
                        <Typography textColor="text.tertiary" mb={3}>
                            {props.description ?? "Вы уверены, что хотите выполнить это действие?"}
                        </Typography>

                        <Box sx={{display: 'flex', gap: 1, justifyContent: 'flex-end'}}>
                            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
                                {props.cancelTitle ?? "Отмена"}
                            </Button>

                            <Button
                                variant="solid"
                                color={props.acceptColor ?? "danger"}
                                onClick={() => {
                                    setOpen(false);
                                    props.onConfirm();
                                }}
                            >
                                {props.acceptTitle ?? "Да"}
                            </Button>
                        </Box>
                    </Box>
                </ModalBox>
            }
        </React.Fragment>
    );
}
