import {usePaymentStore} from "../../Stores/PaymentStore";
import {Box, Sheet, Table, Typography} from "@mui/joy";
import {TransactionType} from "../../types";
import {ModalBox} from "../../Components/ModalBox";
import React from "react";

export const TransactionsModal = ({close}: { close: () => void }) => {
    const [transactions] = usePaymentStore(state => [state.transactions]);

    const TransactionsTable = () => {
        if (transactions.length === 0) {
            return (
                <Box>
                    <Typography color='neutral' sx={{padding: 11, pt: 7}}>
                        Операций не найдено
                    </Typography>
                </Box>
            );
        }

        return (
            <Sheet sx={{maxHeight: 350, overflow: 'auto'}}>
                <Table stickyHeader>
                    <thead>
                    <tr>
                        <th>Описание</th>
                        <th>Сумма</th>
                        <th>Дата</th>
                    </tr>
                    </thead>
                    <tbody>

                    {transactions.map(transaction => {
                        const isIncoming = transaction.type === TransactionType.Incoming;

                        return (
                            <tr key={transaction.id}>
                                <td>{transaction.description}</td>
                                <td>
                                    <Typography textColor={isIncoming ? 'success.600' : 'danger.700'}>
                                        {isIncoming ? '+' : '−'}{' '}
                                        {transaction.amount}
                                        <Typography textColor="text.neutral.100">{' '}₽</Typography>
                                    </Typography>
                                </td>
                                <td>{transaction.date}</td>
                            </tr>
                        );
                    })}

                    </tbody>
                </Table>
            </Sheet>
        );
    }

    return (
        <ModalBox open={true} onClose={close} title='История операций' sx={{maxWidth: 700}}>
            <TransactionsTable/>
        </ModalBox>
    );
}
