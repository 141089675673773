import {FileType, NodeFile} from "./types";
import {CardOverflow, Grid, IconButton} from "@mui/joy";
import AspectRatio from "@mui/joy/AspectRatio";
import React, {Dispatch, SetStateAction, useCallback} from "react";
import {ConfirmModal} from "../../../../Components/ConfirmModal";
import {Trash} from "react-feather";
import {useScenariosPreviewStore} from "../../../../Stores/ScenarioPreviewStore";


type props = {
    files: NodeFile[]
    setFiles: Dispatch<SetStateAction<NodeFile[]>>;
}
export const ImagesList = (props: props) => {
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    const deleteImage = useCallback((id: number) => {
        props.setFiles(props.files.filter(file => file.id !== id));
    }, [props]);

    return (
        <div>
            <Grid container spacing={2}>
                {
                    props.files.map((file, index) => {
                        if (file.type !== FileType.Image) {
                            return '';
                        }

                        let itemWidth = 6;

                        if (props.files.length % 2 === 1 && index === 0) {
                            itemWidth = 12;
                        }

                        return (
                            <Grid key={file.id} xs={itemWidth} md={itemWidth} lg={itemWidth}>
                                <CardOverflow sx={{display: 'block'}}>
                                    {!isPreviewMode &&
                                        <span className="MessageNode__imagesDeleteButton">
                                            <ConfirmModal
                                                onConfirm={() => deleteImage(file.id)}
                                                acceptTitle="Удалить"
                                                description="Вы уверены, что хотите удалить изображение из блока?"
                                            >
                                                 <IconButton
                                                     variant="soft"
                                                     color="neutral"
                                                     size="sm"
                                                     sx={{borderRadius: '50%', mt: 0.5, mr: 0.5}}
                                                 >
                                                    <Trash size={14}/>
                                                </IconButton>
                                            </ConfirmModal>
                                        </span>
                                    }

                                    <AspectRatio minHeight="100px" maxHeight="180px">
                                        <img
                                            className={'MessageNode__fileImage'}
                                            onClick={() => window.open(file.url, '_blank')}
                                            src={file.url}
                                            alt=''
                                            loading="lazy"
                                        />
                                    </AspectRatio>
                                </CardOverflow>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </div>
    );
}