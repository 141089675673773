import "./FormInput.css";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import {FormHelperText} from "@mui/joy";
import React, {BaseSyntheticEvent, useCallback, useState} from "react";
import {OverridableStringUnion} from "@mui/types";
import {SxProps, VariantProp} from "@mui/joy/styles/types";
import {InputPropsVariantOverrides} from "@mui/joy/Input/InputProps";
import {Eye, EyeOff} from "react-feather";
import Stack from "@mui/joy/Stack";

type props = {
    id?: string;
    label?: any;
    labelEndDecorator?: any;
    name?: string;
    disabled?: boolean;
    type: string;
    placeholder?: string;
    value?: string;
    errorText?: string;
    trimSpaces?: boolean;
    autoFocus?: boolean;
    autoComplete?: string
    endDecorator?: React.ReactNode;
    startDecorator?: React.ReactNode;
    className?: string;
    sx?: SxProps;
    skipOnBlurValidate?: boolean;
    validate?: (value: any) => void;
    setValue?: (value: any) => void;
    variant?: OverridableStringUnion<VariantProp, InputPropsVariantOverrides>;
}

export const FormInput = (props: props) => {
    const [actualType, setActualType] = useState(props.type);

    const onBlur = useCallback(() => {
        if (props.validate && (props.skipOnBlurValidate === undefined || !props.skipOnBlurValidate)) {
            props.validate(props.value)
        }
    }, [props]);

    const onChange = useCallback((evt: BaseSyntheticEvent) => {
        let value = evt.target.value

        if (props.trimSpaces) {
            value = value.trim();
        }

        if (props.setValue) {
            props.setValue(value);
        }

        if (props.validate) {
            props.validate(value)
        }
    }, [props]);

    const ErrorHelper = () => {
        if (props.errorText !== undefined && props.errorText.length > 0) {
            return (
                <FormHelperText sx={{color: "var(--joy-palette-danger-500)"}}>{props.errorText}</FormHelperText>
            );
        }

        return null;
    }

    let endDecorator = props.endDecorator;

    if (props.type === 'password') {
        const onMouseDown = () => setActualType('text');
        const onMouseUp = () => setActualType('password');

        const Icon = () => actualType === 'password' ?
            <Eye className={"FormInput__passwordShowButton--icon"} size={18}/> :
            <EyeOff className={"FormInput__passwordShowButton--icon"} size={18}/>

        endDecorator = (
            <span
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseUp}
                className={"FormInput__passwordShowButton"}>
            <Icon/>
        </span>
        );
    }

    return (
        <FormControl className={props.className} sx={props.sx}>
            <Stack direction="row" justifyContent="space-between">
                {props.label && <FormLabel>{props.label}</FormLabel>}
                {props.labelEndDecorator && props.labelEndDecorator}
            </Stack>
            <Input
                id={props.id}
                onBlur={onBlur}
                onChange={onChange}
                startDecorator={props.startDecorator}
                endDecorator={endDecorator}
                name={props.name}
                disabled={props.disabled}
                autoComplete={props.autoComplete}
                type={actualType}
                slotProps={{
                    input: {
                        autoFocus: props.autoFocus
                    },
                }}
                value={props.value}
                error={props.errorText !== undefined && props.errorText.length > 0}
                variant={props.variant}
                placeholder={props.placeholder}
            />

            <ErrorHelper/>
        </FormControl>
    )
}