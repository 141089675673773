import {Dispatch, SetStateAction, useState} from "react";
import {cloneDeep} from "lodash";

export interface inputValidator {
    func: (value: any, threshold: any, message?: string) => null | string;
    threshold?: any;
    message?: string;
}

export const validateRequired = (value: any, _: any, __?: string): null | string => {
    return (value === undefined || String(value).trim() === "") ? "Это обязательное поле" : null;
}

export const validateTelegramBotToken = (value: any, _: any, __?: string): null | string => {
    const regExp = /^([0-9]+:[a-zA-Z0-9_-]+)$/;
    const isTelegramBotToken = String(value).toLowerCase().match(regExp) !== null;

    return isTelegramBotToken ? null : "Введите корректный токен";
}

export const validateMinLength = (value: any, threshold: any, __?: string): null | string => {
    return value.length < threshold ? `Минимальная длина ${threshold} символов` : null;
}

export const validateMinValue = (value: any, threshold: any, message?: string): null | string => {
    message = message || `Минимальное значение ${threshold}`;
    return value < threshold ? message : null;
}

export const validateMaxValue = (value: any, threshold: any, message?: string): null | string => {
    message = message || `Максимальное значение ${threshold}`;
    return value > threshold ? message : null;
}

export const validateMaxLength = (value: any, threshold: any, __?: string): null | string => {
    return value.length > threshold ? `Максимальная длина ${threshold} символов` : null;
}

export const validateAlphaNumeric = (value: any, _: any, __?: string): null | string => {
    return String(value).match(/^[а-яА-Яa-zA-Z0-9\s]+$/) ? null : "Можно использовать только буквы и цифры";
}

export const validateTelegramUsername = (value: any, _: any, __?: string): null | string => {
    return String(value).match(/^[a-zA-Z0-9_]+$/) ? null : "Логин в телеграм может состоять только из символов a-z, 0-9 и _.";
}

export const validateNumeric = (value: any, _: any, __?: string): null | string => {
    return String(value).match(/^[0-9]+$/) ? null : "Можно использовать только цифры";
}

export const validateEmail = (value: any, _: any, __?: string): null | string => {
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmail = String(value).toLowerCase().match(regExp) !== null;

    return isEmail ? null : "Введите корректный email адрес";
}

export const validateUrl = (value: any, _: any, __?: string): null | string => {
    const regExp = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const isUrl = String(value).toLowerCase().match(regExp) !== null;

    return isUrl ? null : "Введите корректную ссылку";
}

export type InputErrors = Map<string, string>;
export type SetInputErrors = Dispatch<SetStateAction<InputErrors>>;

export const validateInput = (name: string, value: any, setInputErrors: SetInputErrors, ...validators: inputValidator[]): null | string => {
    let error: string | null = null;

    for (let i = 0; i < validators.length; i++) {
        error = validators[i].func(value, validators[i].threshold, validators[i].message);

        if (error !== null) {
            break;
        }
    }

    setInputErrors((prev) => {
        prev = cloneDeep(prev);

        if (error === null) {
            prev.delete(name);
        } else {
            prev.set(name, error);
        }

        return prev;
    });

    return error;
}

export const useInputErrors = (): [InputErrors, SetInputErrors] => {
    return useState<InputErrors>(new Map());
}
