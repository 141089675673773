import React from "react";
import {Channels} from "./Channels/Channels";
import {TopCounters} from "./TopCounters/TopCounters";
import {DashContainer} from "../Dash/DashContainer";
import {Subscription} from "./Subscription/Subscription";

export const Home = () => {
    return (
        <DashContainer breadcrumbsTitle='Главная'>
            <TopCounters/>
            <Channels/>
            <Subscription/>
        </DashContainer>
    );
}
