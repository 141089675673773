import {Form} from "react-router-dom";
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import {AUTH_COOKIE, PAGE} from "../types";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {getCookie} from "../utils";
import {AuthContainer} from "./AuthContainer";
import {FormInput} from "../Components/Form/FormInput";
import {
    useInputErrors,
    validateAlphaNumeric,
    validateEmail,
    validateInput,
    validateMaxLength,
    validateMinLength,
    validateRequired
} from "../Components/Form/helpers";
import {ApiClient} from "../api-client";
import {useAccountStore} from "../Stores/AccountStore";
import Stack from "@mui/joy/Stack";

export const SignUp = () => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [inputErrors, setInputErrors] = useInputErrors();
    const [isSending, setIsSending] = useState(false);
    const [register] = useAccountStore(state => [state.register]);

    useEffect(() => {
        if (getCookie(AUTH_COOKIE) !== undefined) {
            return ApiClient.redirect(PAGE.MAIN); // Раз юзер авторизован, то отправим его внутрь приложения.
        }
    }, []);

    const validators = useCallback(() => {
        return {
            name: (value: any) => validateInput(
                "name", value, setInputErrors,
                {func: validateRequired},
                {func: validateAlphaNumeric},
                {func: validateMinLength, threshold: 3},
                {func: validateMaxLength, threshold: 32}
            ),
            email: (value: any) => validateInput(
                "email", value, setInputErrors,
                {func: validateRequired}, {func: validateEmail}, {func: validateMaxLength, threshold: 128}
            ),
            password: (value: any) => validateInput(
                "password", value, setInputErrors,
                {func: validateRequired},
                {func: validateMinLength, threshold: 8},
                {func: validateMaxLength, threshold: 64}
            ),
        }
    }, [setInputErrors]);

    const send = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = [
            validators().name(name),
            validators().email(email),
            validators().password(password),
        ].filter((error) => error !== null);

        if (errors.length > 0) {
            return
        }

        setIsSending(true);
        register(name, email, password).finally(() => setIsSending(false));
    }, [email, name, password, register, validators]);

    return (
        <AuthContainer title="Регистрация нового аккаунта.">
            <Form onSubmit={send}>
                <Stack direction="column" spacing="15px">
                    <FormInput
                        label="Имя"
                        type="text"
                        value={name}
                        setValue={setName}
                        placeholder="Как к вам обращаться?"
                        validate={validators().name}
                        errorText={inputErrors.get("name")}
                    />

                    <FormInput
                        label="Почта"
                        type="email"
                        value={email}
                        setValue={setEmail}
                        validate={validators().email}
                        errorText={inputErrors.get("email")}
                        placeholder="Адрес электронной почты"
                    />

                    <FormInput
                        label="Пароль"
                        type="password"
                        value={password}
                        autoComplete="on"
                        trimSpaces
                        setValue={setPassword}
                        validate={validators().password}
                        errorText={inputErrors.get("password")}
                        placeholder="Придумайте пароль"
                    />
                    <Button loading={isSending} type="submit" sx={{mt: 1}}>Продолжить</Button>
                </Stack>
            </Form>
            
            <Stack direction="column" spacing="15px">
                <Typography level="body-xs" textColor="neutral.400" textAlign="center" fontWeight={400}>
                    Нажимая «Продолжить», вы принимаете{' '}

                    <TermsLink
                        url="https://legal.chatgun.io/ru-terms-of-use.pdf"
                        title="пользовательское соглашение"
                    />

                    {' '}и{' '}
                    <TermsLink
                        url="https://legal.chatgun.io/ru-privacy-policy.pdf"
                        title="политику конфиденциальности"
                    />.
                </Typography>

                <Typography
                    endDecorator={<Link onClick={() => ApiClient.redirect(PAGE.SIGN_IN)}>Авторизация</Link>}
                    fontSize="sm"
                    sx={{alignSelf: 'center'}}
                >Уже есть аккаунт?</Typography>
            </Stack>
        </AuthContainer>
    );
}

export const TermsLink = ({url, title}: { url: string, title: string }) => {
    return (
        <Link
            href={url}
            target="_blank" textColor="neutral.500"
            fontWeight={400}
            sx={{display: 'unset'}}
        >
            {title}
        </Link>
    );
}
