import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import IconButton from '@mui/joy/IconButton';
import Sheet from '@mui/joy/Sheet';
import {toggleSidebar} from '../utils';
import {Menu as MenuIcon} from "react-feather";
import {Box} from "@mui/joy";
import Typography from "@mui/joy/Typography";

export default function Header() {
    const SidebarToggle = () => {
        return (
            <IconButton
                onClick={() => toggleSidebar()}
                variant="plain"
                color="neutral"
                size="sm"
            >
                <MenuIcon size={23}/>
            </IconButton>
        )
    };

    return (
        <Sheet
            sx={{
                display: {xs: 'flex', md: 'none'},
                alignItems: 'center',
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: 'var(--Header-height)',
                zIndex: 4,
                py: 1,
                px: 2,
                gap: 1,
                boxShadow: 'sm',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Header-height': '52px',
                        [theme.breakpoints.up('md')]: {
                            '--Header-height': '0px',
                        },
                    },
                })}
            />

            <SidebarToggle />

            <Box sx={{display: 'flex', gap: 1, alignItems: 'center', ml: 1}}>
                <Typography level="title-md" fontWeight="xl">Chatgun.io</Typography>
            </Box>
        </Sheet>
    );
}
