import {create} from "zustand";
import {API_METHOD, ApiResponse, LS_KEY, Project} from "../types";
import {ApiClient} from "../api-client";
import {toastSuccess} from "../utils";

interface ProjectStore {
    selectedProject: Project | undefined;
    projects: Project[];
    projectsLoaded: boolean;
    tariffRenewing: boolean;
    projectsLoading: boolean;
    promoCodeUsing: boolean;

    setSelectedProject: (selectedProject: Project | undefined) => void;

    fetchProjects(): Promise<void>;

    switchProject(id: number): void;

    renewTariff(projectId: number): Promise<void>;

    usePromoCode(projectId: number, promoCode: string): Promise<ApiResponse>
}

export const useProjectStore = create<ProjectStore>((set) => ({
    selectedProject: undefined,
    projects: [],
    projectsLoaded: false,
    projectsLoading: false,
    tariffRenewing: false,
    promoCodeUsing: false,

    setSelectedProject: (selectedProject: Project | undefined) => {
        if (selectedProject) {
            localStorage.setItem(LS_KEY.PROJECT_ID, selectedProject.id.toString());
        }
        set({selectedProject})
    },

    switchProject: (id: number) => {
        set(store => {
            store.projects.forEach((project) => {
                if (project.id === id) {
                    store.selectedProject = project;
                    localStorage.setItem(LS_KEY.PROJECT_ID, project.id.toString());
                }
            })

            return store;
        })
    },

    fetchProjects() {
        set({projectsLoaded: false, projectsLoading: true});

        return ApiClient.get(API_METHOD.PROJECTS_LIST).then(r => {
            const projectId = parseInt(localStorage.getItem(LS_KEY.PROJECT_ID) || '0');
            const projects = r.data as Project[];
            const selectedProject = projects.find(project => project.id === projectId)

            set({projects, selectedProject, projectsLoaded: true});
        }).catch(ApiClient.toastError).finally(() => set({projectsLoading: false}));
    },

    renewTariff(projectId: number) {
        set({tariffRenewing: true});

        return ApiClient.post(API_METHOD.PROJECT_RENEW_TARIFF, {}, {params: {projectId}}).then((_) => {
            useProjectStore.getState().fetchProjects();
            toastSuccess('Подписка успешно активирована')
        }).catch(ApiClient.toastError).finally(() => set({tariffRenewing: false}))
    },

    usePromoCode: async (projectId: number, code: string) => {
        set({promoCodeUsing: true});

        return ApiClient.post(API_METHOD.PROJECT_USE_PROMO_CODE, {code: code}, {params: {projectId}})
            .finally(() => set({promoCodeUsing: false}));
    },
}));
