import Stepper from "@mui/joy/Stepper";
import classNames from "classnames";
import {Step} from "@mui/joy";
import * as React from "react";

export enum WizardStatus {
    Finished = 'finished',
    Active = 'active',
    Queued = 'queued'
}

export const Wizard = ({count, activeNumber}: { count: number, activeNumber: number }) => {
    return (
        <Stepper>
            {Array.from({length: count}).map((_, index) => {
                let status: WizardStatus = WizardStatus.Queued;

                if (index === activeNumber) {
                    status = WizardStatus.Active;
                } else if (index < activeNumber) {
                    status = WizardStatus.Finished;
                }

                const classes = classNames('indicator', {
                    'finished': status === WizardStatus.Finished,
                    'active': status === WizardStatus.Active,
                });

                return (
                    <Step
                        key={index}
                        className="Wizard__step"
                        indicator={<span className={classes}/>}
                        sx={{
                            '&::after': {
                                height: 2,
                                borderRadius: '24px',
                                marginLeft: '2px',
                                marginRight: '6px',
                                backgroundColor: '#d4ddeb',
                            }
                        }}
                    >
                    </Step>
                )
            })}
        </Stepper>
    )
}
