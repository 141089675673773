import {create} from "zustand";
import {API_METHOD, HomeTopCounters,} from "../types";
import {ApiClient} from "../api-client";

interface HomeTopCountersStore {
    counters: HomeTopCounters;
    loaded: boolean;

    fetchCounters(projectId: number): Promise<void>;
}

export const useHomeStatCountersStore = create<HomeTopCountersStore>((set) => ({
    loaded: false,
    counters: {
        activeUsers: 0,
        inactiveUsers: 0,
        totalUsers: 0,
        monthNewUsers: 0,
    } as HomeTopCounters,

    fetchCounters: async (projectId: number) => {
        return ApiClient.get(API_METHOD.HOME_TOP_COUNTERS, {params: {projectId}})
            .then(r => set({counters: r.data as HomeTopCounters, loaded: true}))
            .catch(ApiClient.toastError);
    },
}));
