/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from "react";
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import {useProjectStore} from "../Stores/ProjectStore";
import {DashContainer} from "../Dash/DashContainer";
import {Check, Slash} from "react-feather";
import {useUsersStore} from "../Stores/UsersStore";
import moment from "moment";
import styles from "./Users.module.css";
import {Pagination} from "../Components/Pagination";
import {Button, CircularProgress, Link, Skeleton} from "@mui/joy";
import {useSearchParams} from "react-router-dom";
import {ProjectType, VKRefreshUsersStatus} from "../types";
import {toastInfo} from "../utils";

export const ProjectUsers = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [project] = useProjectStore(state => [state.selectedProject]);
    const [load, total, perPage] = useUsersStore(state => [state.load, state.total, state.perPage]);
    const [page, setPage] = useState(Number(searchParams.get('page') ?? 1));

    const changePage = useCallback((page: number) => {
        if (!project) {
            return
        }

        setPage(page);
        setSearchParams((params) => {
            if (page === 1) {
                params.delete('page');
            } else {
                params.set('page', String(page));
            }

            return params;
        });

        load(project.id, page);
    }, [load, project, setSearchParams])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => changePage(1), [project]);

    if (!project) {
        return <></>
    }

    return (
        <DashContainer breadcrumbsTitle='Пользователи' rightHeader={<VKRefreshListButton/>}>
            <UsersTableContainer>
                <UsersList/>
                <SkeletonLoader/>
                <EmptyList/>
            </UsersTableContainer>

            <Pagination page={page} setPage={changePage} total={total} perPage={perPage}/>
        </DashContainer>
    );
}

const VKRefreshListButton = () => {
    const project = useProjectStore(state => state.selectedProject);
    const [state, refreshList, vkRefreshingList] = useUsersStore(state => [state.vkState, state.vkRefreshList, state.vkRefreshingList]);

    if (!project || project.type !== ProjectType.VK || !state) {
        return <></>
    }

    const inProgress = state.status === VKRefreshUsersStatus.Active || state.status === VKRefreshUsersStatus.Queued;

    return (
        <Button
            loading={vkRefreshingList}
            onClick={() => {
                if (inProgress) {
                    toastInfo('Список уже в процессе обновления. Перезагрузите страницу, чтобы увидеть результат.');
                    return;
                }

                refreshList(project.id)
            }}
            color="primary"
            startDecorator={inProgress ?
                <CircularProgress variant="solid" sx={{'--CircularProgress-size': '12px'}}/> :
                <></>
            }
            size="sm"
        >
            {inProgress ? 'Список обновляется...' : 'Обновить список'}
        </Button>
    );
}

const EmptyList = () => {
    const [loaded, loading, list] = useUsersStore(state => [state.loaded, state.loading, state.list]);

    if (loading || !loaded || list.length > 0) {
        return <></>
    }

    return (
        <tr>
            <td colSpan={4} className={styles.emptyList}>
                <Typography color="neutral" level="body-sm" variant="plain">
                    Пользователей не найдено
                </Typography>
            </td>
        </tr>
    );
}

const SkeletonLoader = () => {
    const [loaded, loading, list] = useUsersStore(state => [state.loaded, state.loading, state.list]);
    const iterates = Array(14).fill(1);

    if (!loading || loaded || list.length > 0) {
        return <></>;
    }

    return iterates.map((i, v) => (
        <tr key={v}>
            <td>
                <Skeleton animation="wave" variant="text" level="h2"/>
            </td>
            <td>
                <Skeleton animation="wave" variant="text" level="h2"/>
            </td>
            <td>
                <Skeleton animation="wave" variant="text" level="h2"/>
            </td>
            <td>
                <Skeleton animation="wave" variant="text" level="h2"/>
            </td>
        </tr>
    ));
}

const UsersList = () => {
    const [project] = useProjectStore(state => [state.selectedProject]);
    const [list] = useUsersStore(state => [state.list]);

    if (list.length === 0 || !project) {
        return <></>
    }

    const domains = {
        [ProjectType.VK]: "https://vk.com/",
        [ProjectType.Telegram]: "https://telegram.me/"
    }

    return list.map((row) => (
        <tr key={row.userId}>
            <td style={{textAlign: 'center'}}>
                <Typography level="body-xs">{row.userId}</Typography>
            </td>
            <td>
                <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                    <Avatar
                        size="sm">{row.username.length > 0 ? (Array.from(row.username)[0]).toUpperCase() : '?'}</Avatar>
                    <div>
                        <Typography level="body-xs">{row.firstname}</Typography>
                        <Typography level="body-xs">
                            {row.username.length === 0 && '-'}
                            {row.username.length > 0 &&
                                <Link href={domains[project.type] + row.username} target="_blank">
                                    {row.username}
                                </Link>
                            }
                        </Typography>
                    </div>
                </Box>
            </td>
            <td>
                <Chip
                    variant="soft"
                    size="sm"
                    startDecorator={row.isActive ? <Check size={12}/> : <Slash size={12}/>}
                    color={row.isActive ? 'primary' : 'neutral'}
                >
                    {row.isActive ? 'Активен' : 'Не активен'}
                </Chip>
            </td>
            <td>
                <Typography level="body-xs">
                    {moment(row.createdAt).format('YYYY-MM-DD HH:mm')}
                </Typography>
            </td>
        </tr>
    ));
}

const UsersTableContainer = ({children}: { children: any }) => {
    return (
        <Sheet
            className={styles.tableContainer}
            variant="outlined"
            sx={{
                width: '100%',
                borderRadius: 'sm',
                flexShrink: 1,
                overflow: 'auto',
                minHeight: 0,
                mt: 1
            }}
        >
            <Table
                aria-labelledby="usersList"
                hoverRow
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level2)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
                <thead>
                <tr style={{position: 'relative'}}>
                    <th style={{width: 120, textAlign: 'center', padding: '12px 6px'}}>
                        #
                    </th>

                    <th style={{width: 240, padding: '12px 6px'}}>Пользователь</th>
                    <th style={{width: 140, padding: '12px 6px'}}>Статус</th>
                    <th style={{width: 140, padding: '12px 6px'}}>Первый контакт</th>
                </tr>
                </thead>
                <tbody>{children}</tbody>
            </Table>
        </Sheet>
    );
}
