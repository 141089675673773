import {ConstructorMode, NodeData} from "./Nodes/types";
import Typography from "@mui/joy/Typography";
import React, {FormEvent, useCallback, useState} from "react";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {ConfirmModal} from "../Components/ConfirmModal";
import {Edge, useReactFlow} from "reactflow";
import {Trash, Users, Zap} from "react-feather";
import {Box, Card, Chip, Tooltip} from "@mui/joy";
import {useScenariosStore} from "../Stores/ScenarioStore";
import {isEmpty} from "lodash";
import Stack from "@mui/joy/Stack";
import {Form, useParams} from "react-router-dom";
import {FormInput} from "../Components/Form/FormInput";
import Button from "@mui/joy/Button";
import {
    useInputErrors,
    validateInput,
    validateMaxLength,
    validateMinLength,
    validateRequired
} from "../Components/Form/helpers";
import {useUpdateNode} from "./utils";
import {useScenarioUpdateStore} from "../Stores/ScenarioUpdateStore";
import classNames from "classnames";
import {useScenariosPreviewStore} from "../Stores/ScenarioPreviewStore";
import {NodeHandleRole, NodeTargetHandle} from "./Edges/NodeHandle";
import IconButton from "@mui/joy/IconButton";
import {useProjectStore} from "../Stores/ProjectStore";
import {useConstructorStore} from "../Stores/ConstructorStore";
import {ModalBox} from "../Components/ModalBox";

type props = {
    nodeId: string;
    title?: string;
    nodeTitle?: string;
    className?: string;
    children?: React.ReactNode;
    hideDragHandle?: boolean;
    deletable?: boolean;
    testable?: boolean;
    hasMetrics?: boolean;
    nodeOptions?: React.JSX.Element
    nodeHandleRole?: NodeHandleRole;
}

export enum NodeMetricType {
    Step = 'step',
    Button = 'button',
}

export const NodeHeader = (props: props) => {
    const isStartNode = props.nodeHandleRole === NodeHandleRole.StartScenarioNode ||
        props.nodeHandleRole === NodeHandleRole.StartBroadcastingNode;

    return (
        <Stack className={classNames("Node__headerContainer", "NodeDragHandle", props.className)} direction="row">
            {props.nodeHandleRole && !isStartNode &&
                <NodeTargetHandle id={props.nodeId} role={props.nodeHandleRole}/>
            }

            {isStartNode &&
                <Box sx={{
                    height: 'auto',
                    width: '3px',
                    backgroundColor: 'var(--joy-palette-success-400)'
                }}/>
            }

            <Box className="Node__header" sx={{width: '100%'}}>
                {props.title &&
                    <Stack direction="column" sx={{minWidth: 0, marginLeft: 1}}>
                        <Typography level="title-md" className="Node__headerTitle">{props.title}</Typography>
                        {props.nodeTitle && <NodeTitle nodeId={props.nodeId} title={props.nodeTitle}/>}

                        {isStartNode &&
                            <Typography level="title-sm" noWrap textColor="neutral.400">
                                Здесь все начинается
                            </Typography>
                        }
                    </Stack>
                }

                {props.children}

                <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ml: 'auto'}}>
                    {props.hasMetrics && <NodeMetrics nodeId={props.nodeId} type={NodeMetricType.Step}/>}
                    {props.nodeOptions && props.nodeOptions}
                    {props.testable && <NodeTestButton nodeId={props.nodeId}/>}
                    {props.deletable && <NodeRemoveButton nodeId={props.nodeId}/>}
                </Stack>
            </Box>
        </Stack>
    )
}

const NodeTitle = ({nodeId, title}: { nodeId: string, title: string }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    return (
        <>
            <Typography
                onClick={() => {
                    if (!isPreviewMode) {
                        setModalOpen(true);
                    }
                }}
                level="title-sm"
                noWrap
                textColor="neutral.400"
                className="Node__headerNodeTitle"
            >
                {title}
            </Typography>
            {modalOpen && <NodeTitleModal nodeId={nodeId} currentTitle={title} close={() => setModalOpen(false)}/>}
        </>
    );
}

const NodeTestButton = ({nodeId}: { nodeId: string }) => {
    const project = useProjectStore(state => state.selectedProject);
    const constructorMode = useConstructorStore(state => state.mode);
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const [testScenario, testing] = useScenariosStore(state => [state.test, state.testing]);
    const params = useParams();
    const scenarioId = Number(params.id);

    const onClick = useCallback(() => {
        if (testing || !project) {
            return;
        }

        testScenario(project.id, scenarioId, nodeId);
    }, [nodeId, project, scenarioId, testScenario, testing]);

    if (isPreviewMode || !project || !scenarioId || constructorMode === ConstructorMode.Broadcasting) {
        return <></>
    }

    return (
        <Box className="Node__headerButton Node__testButton">
            <Tooltip
                title="Тестовый запуск сценария с этого шага"
                size="sm"
                color={"neutral"}
                arrow={true}
                placement="top-end"
            >
                <IconButton variant="plain" onClick={onClick}>
                    <Zap size={18}/>
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export const NodeRemoveButton = ({nodeId}: { nodeId: string }) => {
    const reactFlowInstance = useReactFlow();
    const [setUpdated] = useScenarioUpdateStore((state) => [state.updated]);
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    const onNodeDelete = () => {
        reactFlowInstance.setNodes((nodes) => nodes.filter((node) => node.id !== nodeId));
        reactFlowInstance.setEdges((eds: Edge[]) => eds.filter((e) => e.source !== nodeId && e.target !== nodeId));
        setUpdated();
    };

    if (isPreviewMode) {
        return <></>
    }

    return (
        <Box className="Node__headerButton">
            <ConfirmModal
                onConfirm={onNodeDelete}
                acceptTitle="Удалить"
                description="Вы уверены, что хотите удалить этот блок?"
            >
                <IconButton variant="plain">
                    <Trash size={18}/>
                </IconButton>
            </ConfirmModal>
        </Box>
    )
}

export const NodeDragHandle = () => {
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    if (isPreviewMode) {
        return <></>
    }

    return (
        <div className="NodeDragHandle custom-drag-handle">
            <DragIndicatorIcon color={"inherit"}/>
        </div>
    );
}


const NodeTitleModal = ({nodeId, currentTitle, close}: { nodeId: string, currentTitle: string, close: () => void }) => {
    const updateNode = useUpdateNode();
    const [title, setTitle] = useState(currentTitle);
    const [inputErrors, setInputErrors] = useInputErrors();

    const validators = useCallback(() => {
        return {
            title: (value: any) => validateInput(
                "title", value, setInputErrors,
                {func: validateRequired},
                {func: validateMinLength, threshold: 3},
                {func: validateMaxLength, threshold: 36}
            ),
        }
    }, [setInputErrors]);

    const send = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = [
            validators().title(title),
        ].filter((error) => error !== null);

        if (errors.length > 0) {
            return;
        }

        updateNode(nodeId, {title: title} as NodeData);
        close();
    }, [close, nodeId, title, updateNode, validators]);

    return (
        <ModalBox open={true} onClose={close} title='Название блока' sx={{maxWidth: 400}}>
            <Form onSubmit={send}>
                <Stack spacing={2}>
                    <FormInput
                        type="text"
                        value={title}
                        setValue={setTitle}
                        placeholder='Введите название блока..'
                        validate={validators().title}
                        errorText={inputErrors.get("title")}
                    />

                    <Card variant="soft" color="primary" sx={{boxShadow: 'none'}}>
                        <Typography level="body-sm">
                            Название блока выводится только в редакторе сценариев, пользователи чат-бота не
                            будут его видеть.
                        </Typography>
                    </Card>

                    <Stack spacing={1}>
                        <Button type='submit'>Сохранить</Button>
                    </Stack>
                </Stack>
            </Form>
        </ModalBox>
    );
}

export const NodeMetrics = ({nodeId, type}: { nodeId: string, type: NodeMetricType }) => {
    const [loadingMetrics, scenarioMetrics] = useScenariosStore(state => [state.loadingMetrics, state.scenarioMetrics]);

    if (loadingMetrics || isEmpty(scenarioMetrics)) {
        return <></>
    }

    let tooltipTitle = 'Количество пользователей за месяц, запустивших шаг.';
    const count = nodeId in scenarioMetrics ? scenarioMetrics[nodeId] : 0;
    let title = count;

    if (count >= 1000) {
        title = (count / 1000).toFixed(2) + 'K';
    } else if (count >= 10000) {
        title = (count / 1000).toFixed(1) + 'K';
    } else if (count >= 100000) {
        title = (count / 1000).toFixed(0) + 'K';
    }

    if (type === NodeMetricType.Button) {
        tooltipTitle = 'Количество пользователей за месяц, нажавших на кнопку.';
    }

    return (
        <Tooltip arrow title={tooltipTitle} size="md" placement={'top-end'} color='neutral'>
            <div className="Node__headerMetrics">
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Chip variant="soft" size={'sm'} startDecorator={<Users size={12}/>}>
                        {title}
                    </Chip>
                </Box>
            </div>
        </Tooltip>
    );
}