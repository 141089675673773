import {DashContainer} from "../Dash/DashContainer";
import {Avatar, Box, Button, Card, Grid, IconButton, Sheet, Typography} from "@mui/joy";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import Stack from "@mui/joy/Stack";
import {Trash} from "react-feather";
import styles from './Employees.module.css';
import classNames from "classnames";
import {Form} from "react-router-dom";
import {FormInput} from "../Components/Form/FormInput";
import {useProjectStore} from "../Stores/ProjectStore";
import {useEmployeesStore} from "../Stores/EmployeesStore";
import {
    useInputErrors,
    validateEmail,
    validateInput,
    validateMaxLength,
    validateRequired
} from "../Components/Form/helpers";
import {Employee as EmployeeEntity, EmployeeRole} from "../types";
import Skeleton from "react-loading-skeleton";
import {ConfirmModal} from "../Components/ConfirmModal";
import {ModalBox} from "../Components/ModalBox";

export const Employees = () => {
    const [modalOpened, setModalOpened] = useState(false);
    const [project] = useProjectStore(state => [state.selectedProject]);
    const [employees] = useEmployeesStore(state => [state.employees]);

    useEffect(() => {
        if (!project) {
            return;
        }

        useEmployeesStore.getState().fetch(project.id);
    }, [project]);

    const EmployeesList = () => {
        if (employees.length === 0) {
            return (
                <>
                    <Skeleton height={74}/>
                </>
            );
        }

        return (
            <>
                {employees.map((employee) => <Employee key={employee.Id} employee={employee}/>)}
            </>
        );
    };

    return (
        <DashContainer
            breadcrumbsTitle='Сотрудники'
            rightHeader={<Button size="sm" onClick={() => setModalOpened(true)}>Добавить</Button>}
        >
            <Grid xs={12} md={12} lg={8}>
                <Box sx={{display: 'flex', gap: 1.3, flexDirection: 'column'}}>
                    <EmployeesList/>
                </Box>
            </Grid>

            <EmployeeCreateModal opened={modalOpened} close={() => setModalOpened(false)}/>
        </DashContainer>
    );
};

const Employee = ({employee}: { employee: EmployeeEntity }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const onDelete = useCallback(() => {
        setIsDeleting(true);
        useEmployeesStore.getState().remove(employee.ProjectId, employee.Id).finally(() => setIsDeleting(false));
    }, [employee.Id, employee.ProjectId]);

    const TrashAction = () => {
        if (employee.Role === EmployeeRole.Owner) {
            return (
                <></>
            );
        }

        return (
            <ConfirmModal
                onConfirm={onDelete}
                acceptTitle="Удалить"
                acceptColor="danger"
                description="Вы уверены, что хотите удалить этого сотрудника?"
            >
                <IconButton
                    className={classNames("nooutline", styles.removeIcon)}
                    disabled={isDeleting}
                    variant="plain"
                    color="danger"
                >
                    <Trash size={20}/>
                </IconButton>
            </ConfirmModal>
        );
    }

    return (
        <Sheet className={styles.container} variant="outlined" sx={{borderRadius: 'sm', p: 2}}>
            <Stack spacing={2} direction="row" alignItems="center">
                <Avatar variant='soft' color='primary'/>

                <Stack spacing={0} direction="column" sx={{minWidth: 0}}>
                    <Typography fontSize="sm" fontWeight="lg" noWrap>{employee.Email}</Typography>
                    <Typography level="body-xs" noWrap>{roleName(employee.Role)}</Typography>
                </Stack>

                <TrashAction/>
            </Stack>
        </Sheet>
    );
};

const EmployeeCreateModal = ({opened, close}: { opened: boolean, close: () => void }) => {
    const [email, setEmail] = useState('');
    const [inputErrors, setInputErrors] = useInputErrors();
    const [isSending, setIsSending] = useState(false);
    const [project] = useProjectStore(state => [state.selectedProject]);

    const validators = useCallback(() => {
        return {
            email: (value: any) => validateInput(
                "email", value, setInputErrors,
                {func: validateRequired}, {func: validateEmail}, {func: validateMaxLength, threshold: 128}
            ),
        }
    }, [setInputErrors]);

    const send = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = [
            validators().email(email),
        ].filter((error) => error !== null);

        if (errors.length > 0 || !project) {
            return;
        }

        setIsSending(true);
        useEmployeesStore.getState().create(project.id, email, close).finally(() => setIsSending(false));
    }, [close, email, project, validators]);

    return (
        <ModalBox
            open={opened}
            onClose={close}
            title='Добавить администратора'
            sx={{maxWidth: 400, minWidth: {sm: 300, md: 400}}}
        >
            <Form onSubmit={send}>
                <Stack spacing={2}>
                    <FormInput
                        type="text"
                        label="Почта"
                        value={email}
                        setValue={setEmail}
                        placeholder='Адрес электронной почты..'
                        validate={validators().email}
                        errorText={inputErrors.get("email")}
                    />

                    <Card variant="soft" color="primary" sx={{boxShadow: 'none'}}>
                        <Typography level="body-sm">
                            Администратор будет иметь полный доступ к управлению вашим проектом, включая доступ
                            к управлению сотрудниками.
                        </Typography>
                    </Card>

                    <Stack spacing={1}>
                        <Button type='submit' loading={isSending}>Добавить</Button>
                    </Stack>
                </Stack>
            </Form>
        </ModalBox>
    );
}

const roleName = (role: EmployeeRole) => {
    switch (role) {
        case EmployeeRole.Owner:
            return 'Владелец проекта';
        case EmployeeRole.Admin:
            return 'Администратор';
    }

    return 'undefined role';
}
