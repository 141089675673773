import React, {useMemo} from "react";
import {ButtonData, ButtonType, VKButtonColorMap} from "./types";
import {Button} from "@mui/joy";
import {ExternalLink} from "react-feather";
import {useScenariosStore} from "../../../../Stores/ScenarioStore";
import {isEmpty} from "lodash";
import {NodeMetrics, NodeMetricType} from "../../../NodeHeader";
import {useScenariosPreviewStore} from "../../../../Stores/ScenarioPreviewStore";
import {NodeHandleRole} from "../../../Edges/NodeHandle";
import {DefaultColorPalette} from "@mui/joy/styles/types/colorSystem";
import {useConstructorProjectType} from "../../../utils";
import {ProjectType} from "../../../../types";

type props = {
    nodeId: string;
    button: ButtonData,
    rowCount: number;
    setModalData: (value: ButtonData | null) => void;
}

export const KeyboardButton = (props: props): React.JSX.Element => {
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const projectType = useConstructorProjectType();

    const onButtonClick = () => {
        if (isPreviewMode) {
            return;
        }

        props.setModalData(props.button);
    }

    const widthShift = 10 - 10 / props.rowCount; // Потому что у кнопок есть еще margin-right 10px.
    const width = `calc(100% / ${props.rowCount} - ${widthShift}px)`;
    const linkDecorator = <ExternalLink size={20} className={"MessageNodeKeyboardButton__externalLink"}/>;

    const color = useMemo(() => {
        if (projectType !== ProjectType.VK || !props.button.vkColor || props.button.vkColor.length === 0) {
            return 'primary';
        }

        return VKButtonColorMap[props.button.vkColor] as DefaultColorPalette;
    }, [projectType, props.button.vkColor])

    return (
        <>
            <div
                id={props.button.id}
                data-button-name={props.button.name}
                data-handle-role={NodeHandleRole.MessageNodeButton}
                style={{width: width}}
                className="MessageNodeKeyboardButtonContainer"
            >
                <Button
                    startDecorator={props.button.type === ButtonType.URL ? linkDecorator : ''}
                    color={color}
                    className="MessageNodeKeyboardButton"
                    onClick={() => onButtonClick()}
                >
                    <ButtonTitle nodeId={props.button.id} name={props.button.name}/>
                </Button>
            </div>
        </>
    );
}

const ButtonTitle = ({nodeId, name}: { nodeId: string, name: string }) => {
    const [loadingMetrics, scenarioMetrics] = useScenariosStore(state => [state.loadingMetrics, state.scenarioMetrics]);

    if (loadingMetrics || isEmpty(scenarioMetrics)) {
        return <>{name}</>;
    }

    return <>
        <span style={{marginRight: 10}}>{name}</span>
        <NodeMetrics nodeId={nodeId} type={NodeMetricType.Button}/>
    </>;
}