import {create} from "zustand";
import {API_METHOD, Scenario, ScenarioMetrics} from "../types";
import {toastSuccess} from "../utils";
import {ReactFlowInstance} from "reactflow";
import {ApiClient} from "../api-client";
import {useScenarioUpdateStore} from "./ScenarioUpdateStore";
import {useScenariosListStore} from "./ScenariosListStore";

interface ScenariosStore {
    reactFlowInstance?: ReactFlowInstance;
    selectedScenario?: Scenario; // Текущий выбраный сценарий для редактирования
    processedScenarioId?: number; // Номер сценария, относительно которого сейчас выполняется запрос на бэк.
    loadingScenario: boolean; // Идет ли загрузка сценария для редактирования.
    scenarioMetrics: any // Статистика по уникам в блоках текущего сценария.
    savingScenario: boolean; // Идет ли сохранение сценария.
    loadingMetrics: boolean; // Идет загрузка метрик блоков сценария.
    testing: boolean; // В процессе отправки запроса на тест сценария.

    setSelectedScenario: (selectedScenario?: Scenario) => Promise<void>; // todo - кажется это уже не нужно??
    fetchScenario: (projectId: number, scenarioId: number) => Promise<void>;
    fetchScenariosMetrics: (projectId: number, scenarioId: number) => Promise<void>;
    createScenario: (projectId: number, scenario: Scenario, afterSave: () => void | undefined) => Promise<Scenario>;
    saveScenario: (projectId: number, scenario: Scenario, afterSave: () => void | undefined) => Promise<Scenario>;
    deleteScenario: (projectId: number, scenarioId: number) => Promise<void>;
    toggleEnabled: (projectId: number, scenarioId: number) => Promise<void>;
    test: (projectId: number, scenarioId: number, nodeId: string) => Promise<void>;
    setReactFlowInstance: (reactFlowInstance: ReactFlowInstance) => void;

    clearReactFlowInstance: () => void;
    clearScenario: () => void;
    clearScenarioMetrics: () => void;
    clear: () => void;
}

export const useScenariosStore = create<ScenariosStore>((set) => ({
    reactFlowInstance: undefined,
    selectedScenario: undefined,
    processedScenarioId: undefined,
    loadingScenario: false,
    loadingMetrics: false,
    scenarioMetrics: {},
    savingScenario: false,
    testing: false,

    /**
     * Загрузка сценария для редактирования.
     */
    fetchScenario: async (projectId: number, scenarioId: number) => {
        set({loadingScenario: true, selectedScenario: undefined});

        return ApiClient.get(API_METHOD.SCENARIO, {params: {projectId, scenarioId}}).then(r => {
            set({selectedScenario: r.data as Scenario, loadingScenario: false, scenarioMetrics: {}});
        }).catch(ApiClient.toastError).finally(() => set({loadingScenario: false}));
    },

    /**
     * Получение статистики по блокам сценария.
     */
    fetchScenariosMetrics: async (projectId: number, scenarioId: number) => {
        set({loadingMetrics: true, scenarioMetrics: {}});

        return ApiClient.get(API_METHOD.CHARTS_SCENARIO_METRICS, {params: {projectId, scenarioId}}).then(r => {
            const metrics = r.data as ScenarioMetrics
            set({scenarioMetrics: metrics.nodes, loadingMetrics: false})
        }).catch(ApiClient.toastError).finally(() => set({loadingMetrics: false}));
    },

    /**
     * Сохранение сценария.
     */
    saveScenario: async (projectId: number, scenario: Scenario, afterSave: () => void | undefined): Promise<Scenario> => {
        const data = {scheme: JSON.stringify(scenario.scheme)};
        const scenarioId = scenario.id;

        set({savingScenario: true});

        await ApiClient.post(API_METHOD.SCENARIO_SAVE, data, {params: {projectId, scenarioId}}).then(() => {
            const scenarios = useScenariosListStore.getState().list.map(s => s.id === scenario.id ? scenario : s);

            useScenariosListStore.getState().setScenariosList(scenarios);
            useScenarioUpdateStore.getState().clear();

            toastSuccess('Сценарий успешно сохранен!');

            if (typeof afterSave === 'function') {
                afterSave();
            }
        }).catch(ApiClient.toastError).finally(() => set({savingScenario: false}));

        return Promise.resolve(scenario);
    },

    /**
     * Создание сценария.
     */
    createScenario: async (projectId: number, scenario: Scenario, afterSave: () => void | undefined): Promise<Scenario> => {
        const data = {scheme: JSON.stringify(scenario.scheme)};

        set({savingScenario: true});

        await ApiClient.post(API_METHOD.SCENARIO_SAVE, data, {params: {projectId}}).then(r => {
            scenario = r.data as Scenario;

            toastSuccess('Сценарий успешно создан!');

            const scenarios = useScenariosListStore.getState().list;

            scenarios.push(scenario);
            useScenariosListStore.getState().setScenariosList(scenarios);

            set({selectedScenario: scenario});

            useScenarioUpdateStore.getState().clear();

            if (typeof afterSave === 'function') {
                afterSave();
            }
        }).catch(ApiClient.toastError).finally(() => set({savingScenario: false}));

        return Promise.resolve(scenario);
    },

    /**
     * Удаление сценария.
     */
    deleteScenario: async (projectId: number, scenarioId: number) => {
        set({processedScenarioId: scenarioId});

        return ApiClient.post(API_METHOD.SCENARIO_DELETE, null, {params: {projectId, scenarioId}}).then(() => {
            const scenarios = useScenariosListStore.getState().list.filter(s => s.id !== scenarioId);

            useScenariosListStore.getState().setScenariosList(scenarios);
            useScenariosStore.getState().clearScenario();
            toastSuccess('Сценарий успешно удален!');
        }).catch(ApiClient.toastError).finally(() => set({processedScenarioId: undefined}));
    },

    test: (projectId: number, scenarioId: number, nodeId: string) => {
        set({testing: true});

        return ApiClient.post(API_METHOD.SCENARIO_TEST, null, {params: {projectId, scenarioId, nodeId}}).then(() => {
            toastSuccess('Тест сценария успешно запущен!');
        }).catch(ApiClient.toastError).finally(() => set({testing: false}));
    },

    /**
     * Включение/выключение сценария.
     */
    toggleEnabled: async (projectId: number, scenarioId: number) => {
        set({processedScenarioId: scenarioId});

        return ApiClient.post(API_METHOD.SCENARIO_TOGGLE_ENABLED, null, {params: {projectId, scenarioId}}).then(() => {
            const scenarios = useScenariosListStore.getState().list.map(s => {
                if (s.id === scenarioId) {
                    s.enabled = Number(!s.enabled);
                    toastSuccess('Сценарий успешно ' + (s.enabled ? 'включен' : 'отключен') + '.');
                }

                return s;
            });

            useScenariosListStore.getState().setScenariosList(scenarios);
        }).catch(ApiClient.toastError).finally(() => set({processedScenarioId: undefined}));
    },

    setSelectedScenario: (selectedScenario: Scenario | undefined): Promise<void> => {
        set({selectedScenario})
        return Promise.resolve();
    },
    setReactFlowInstance: (reactFlowInstance: ReactFlowInstance) => set({reactFlowInstance}),
    clearScenario: () => set({selectedScenario: undefined, scenarioMetrics: {}}),
    clearScenarioMetrics: () => set({scenarioMetrics: {}}),
    clearReactFlowInstance: () => set({reactFlowInstance: undefined}),
    clear: () => set({
        selectedScenario: undefined,
        reactFlowInstance: undefined,
    }),
}));
