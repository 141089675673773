import {ApexOptions} from "apexcharts";
import {useTheme} from "@mui/joy";
import {alpha} from "@mui/system";
import merge from 'lodash/merge';
import GlobalStyles from "@mui/joy/GlobalStyles";

export const useApexOption = (options: ApexOptions): ApexOptions => {
    const theme = useTheme();

    const LABEL_TOTAL = {
        show: true,
        label: 'Total',
        color: theme.palette.text.secondary,
        fontSize: theme.typography["body-sm"].fontSize,
        fontWeight: theme.typography["body-sm"].fontWeight,
        lineHeight: theme.typography["body-sm"].lineHeight,
    };

    const LABEL_VALUE = {
        offsetY: 8,
        color: theme.palette.text.primary,
        fontSize: theme.typography.h3.fontSize,
        fontWeight: theme.typography.h3.fontWeight,
        lineHeight: theme.typography.h3.lineHeight,
    };

    const baseOptions = {
        // Theme
        theme: {
            mode: theme.palette.mode,
            palette: 'palette2',
            monochrome: {
                enabled: false,
                shadeTo: theme.palette.mode,
                shadeIntensity: 5
            },
        },

        // Chart
        chart: {
            // animations: { enabled: false },
            toolbar: {show: false},
            zoom: {enabled: false},
            fontFamily: theme.fontFamily.display,
        },

        // States
        states: {
            hover: {
                filter: {
                    type: 'lighten',
                    value: 0.04,
                },
            },
            active: {
                filter: {
                    type: 'darken',
                    value: 0.88,
                },
            },
        },

        // Fill
        fill: {
            opacity: 1,
            type: 'gradient',
            gradient: {
                type: 'vertical',
                shadeIntensity: 0,
                opacityFrom: 0.4,
                opacityTo: 0,
                stops: [0, 100],
            },
        },

        // Datalabels
        dataLabels: {enabled: false},

        // Stroke
        stroke: {
            width: 3,
            curve: 'smooth',
            lineCap: 'round',
        },

        // Grid
        grid: {
            strokeDashArray: 3,
            borderColor: theme.palette.divider,
            xaxis: {
                lines: {
                    show: false,
                },
            },
        },

        // Xaxis
        xaxis: {
            axisBorder: {show: false},
            axisTicks: {show: false},
        },

        // Markers
        markers: {
            size: 0,
            strokeColors: theme.palette.background.body,
        },

        // Tooltip
        tooltip: {
            shared: true,
            intersect: false,
        },

        // Legend
        legend: {
            show: true,
            fontSize: String(13),
            position: 'top',
            horizontalAlign: 'right',
            markers: {
                radius: 12,
            },
            fontWeight: 500,
            itemMargin: {horizontal: 12},
            labels: {
                colors: theme.palette.text.primary,
            },
        },

        // plotOptions
        plotOptions: {
            // Bar
            bar: {
                borderRadius: 4,
                columnWidth: '28%',
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
            },

            // Pie + Donut
            pie: {
                donut: {
                    labels: {
                        show: true,
                        value: LABEL_VALUE,
                        total: LABEL_TOTAL,
                    },
                },
            },

            // Radialbar
            radialBar: {
                track: {
                    strokeWidth: '100%',
                    background: alpha(theme.palette.neutral[500], 0.16),
                },
                dataLabels: {
                    value: LABEL_VALUE,
                    total: LABEL_TOTAL,
                },
            },

            // Radar
            radar: {
                polygons: {
                    fill: {colors: ['transparent']},
                    strokeColors: theme.palette.divider,
                    connectorColors: theme.palette.divider,
                },
            },

            // polarArea
            polarArea: {
                rings: {
                    strokeColor: theme.palette.divider,
                },
                spokes: {
                    connectorColors: theme.palette.divider,
                },
            },
        },

        // Responsive
        responsive: [
            {
                // sm
                breakpoint: theme.breakpoints.values.sm,
                options: {
                    plotOptions: {bar: {columnWidth: '40%'}},
                },
            },
            {
                // md
                breakpoint: theme.breakpoints.values.md,
                options: {
                    plotOptions: {bar: {columnWidth: '32%'}},
                },
            },
        ],
    } as ApexOptions;

    return merge(baseOptions, options);
};

const bgBlur = (props: any) => {
    const color = props?.color || '#000000';
    const blur = props?.blur || 6;
    const opacity = props?.opacity || 0.8;
    const imgUrl = props?.imgUrl;

    if (imgUrl) {
        return {
            position: 'relative',
            backgroundImage: `url(${imgUrl})`,
            '&:before': {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 9,
                content: '""',
                width: '100%',
                height: '100%',
                backdropFilter: `blur(${blur}px)`,
                WebkitBackdropFilter: `blur(${blur}px)`,
                backgroundColor: alpha(color, opacity),
            },
        };
    }

    return {
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity),
    };
}

export const ApexChartStyles = () => {
    const theme = useTheme();

    return (
        <GlobalStyles
            styles={{
                '.apexcharts-canvas': {
                    // Tooltip
                    '.apexcharts-xaxistooltip': {
                        ...bgBlur({color: theme.palette.background.body}),
                        border: 0,
                        color: theme.palette.text.primary,
                        boxShadow: theme.shadow.md,
                        borderRadius: Number(theme.radius.md) * 1.5,
                        '&:before': {borderBottomColor: 'transparent'},
                        '&:after': {borderBottomColor: alpha(theme.palette.background.body, 0.8)},
                    },
                    '.apexcharts-tooltip.apexcharts-theme-light': {
                        ...bgBlur({color: theme.palette.background.body}),
                        border: 0,
                        boxShadow: theme.shadow.md,
                        borderRadius: Number(theme.radius.md) * 1.5,
                        '.apexcharts-tooltip-title': {
                            border: 0,
                            textAlign: 'center',
                            fontWeight: theme.typography["body-md"].fontWeight,
                            backgroundColor: alpha(theme.palette.neutral[500], 0.16),
                            color: theme.palette.text[theme.palette.mode === 'light' ? 'secondary' : 'primary'],
                        },
                    },

                    // Legend
                    '.apexcharts-legend': {
                        padding: 0,
                    },
                    '.apexcharts-legend-series': {
                        display: 'flex !important',
                        alignItems: 'center',
                    },
                    '.apexcharts-legend-marker': {
                        marginRight: 8,
                    },
                    '.apexcharts-legend-text': {
                        lineHeight: '18px',
                        textTransform: 'capitalize',
                    },
                },
            }}
        />
    );
};