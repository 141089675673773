import {create} from "zustand";
import {API_METHOD} from "../types";
import {MetricType} from "../Charts/Charts";
import {ApiClient} from "../api-client";

interface ChartsStore {
    fetch(projectId: number, metrics: MetricType[]): Promise<ChartDatum>;
}

export interface ChartDatum {
    dates: string[];
    series: any;
}

export const useChartStore = create<ChartsStore>((_) => ({
    fetch(projectId: number, metrics: MetricType[]) {
        return ApiClient.get(API_METHOD.CHARTS_DATA, {params: {metrics, projectId}}).then(r => r.data as ChartDatum);
    },
}));
