import {usePaymentStore} from "../../Stores/PaymentStore";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {
    useInputErrors,
    validateInput, validateMaxValue,
    validateMinValue,
    validateNumeric,
    validateRequired
} from "../../Components/Form/helpers";
import {useProjectStore} from "../../Stores/ProjectStore";
import {ApiClient} from "../../api-client";
import {ModalBox} from "../../Components/ModalBox";
import {Form} from "react-router-dom";
import Stack from "@mui/joy/Stack";
import {FormInput} from "../../Components/Form/FormInput";
import {Button, Card, Link, Typography} from "@mui/joy";

export const TopUpModal = ({close}: { close: () => void }) => {
    const payments = usePaymentStore();
    const [amount, setAmount] = useState<number>()
    const [inputErrors, setInputErrors] = useInputErrors();
    const [isSending, setIsSending] = useState(false);
    const [project] = useProjectStore(state => [state.selectedProject]);
    const [orderPaymentUrl, setOrderPaymentUrl] = useState('')

    const validators = useCallback(() => {
        return {
            amount: (value: any) => validateInput(
                "amount", value, setInputErrors,
                {func: validateRequired},
                {func: validateNumeric},
                {func: validateMinValue, threshold: 1},
                {func: validateMaxValue, threshold: 99999}
            ),
        }
    }, [setInputErrors]);

    useEffect(() => {
        return () => {
            setIsSending(false);
            setAmount(undefined);
        }
    }, []);

    const send = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = [
            validators().amount(amount),
        ].filter((error) => error !== null);

        if (errors.length > 0 || !project || !amount) {
            return;
        }

        setIsSending(true);

        payments.createPaymentOrder(project.id, amount).then(payment => {
            setOrderPaymentUrl(payment.paymentUrl);
            window.open(payment.paymentUrl, '_blank');
        }).catch(ApiClient.toastError).finally(() => setIsSending(false))
    }, [amount, payments, project, validators]);


    return (
        <ModalBox open={true} onClose={close} title='Пополнение баланса' sx={{
            maxWidth: 400,
            minWidth: {sm: 300, md: 400},
        }}>
            <Form onSubmit={send}>
                <Stack spacing={2}>
                    <FormInput
                        type="text"
                        label="Сумма, в рублях"
                        value={amount ? String(amount) : ''}
                        setValue={setAmount}
                        placeholder='Введите сумму..'
                        disabled={orderPaymentUrl.length > 0}
                        validate={validators().amount}
                        errorText={inputErrors.get("amount")}
                    />

                    {orderPaymentUrl.length > 0 &&
                        <Card invertedColors variant="soft" color="neutral" sx={{boxShadow: 'none'}}>
                            <Typography level="body-sm">
                                Для пополнения счета перейдите по
                                {' '}<Link href={orderPaymentUrl} target="_blank">ссылке</Link>,
                                после оплаты обновите страницу.
                            </Typography>
                        </Card>
                    }

                    <Stack spacing={1}>
                        <Button
                            type='submit'
                            disabled={orderPaymentUrl.length > 0}
                            loading={isSending}>
                            Пополнить
                        </Button>
                    </Stack>
                </Stack>
            </Form>
        </ModalBox>
    );
}
