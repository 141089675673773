import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {AuthContainer} from "../Auth/AuthContainer";
import {Wizard} from "../Components/Wizard";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import {VKConnectButton, VKConnectButtonTerms} from "../Auth/SignIn";
import {PAGE, Project, ProjectType} from "../types";
import {useAccountStore} from "../Stores/AccountStore";
import {isAuthorized, isDev, isEmptyObject} from "../utils";
import {ConnectVKGroup} from "../Project/ProjectCreate";
import {useProjectStore} from "../Stores/ProjectStore";
import {Autocomplete, AutocompleteOption, ListItemDecorator, Skeleton} from "@mui/joy";
import Button from "@mui/joy/Button";
import Avatar from "@mui/joy/Avatar";
import FormControl from "@mui/joy/FormControl";
import AspectRatio from "@mui/joy/AspectRatio";
import {useScenariosPreviewStore} from "../Stores/ScenarioPreviewStore";
import {Step, useWizardVKStore} from "../Stores/WizardVKStore";
import {useChannelsStore} from "../Stores/ChannelsStore";
import {useScenariosAccessSettingsStore} from "../Stores/ScenarioAccessSettingsStore";


export const WizardVK = () => {
    const [step, setStep] = useWizardVKStore(state => [state.step, state.setStep]);

    const [account, loaded, fetchMe] = useAccountStore(state => [
        state.account,
        state.loaded,
        state.fetchMe,
    ]);

    const StepComponent = useMemo(() => {
        const steps = {
            [Step.Register]: RegisterStep,
            [Step.ConnectGroup]: ConnectGroupStep,
            [Step.ScenarioCreate]: CreateScenarioStep,
            [Step.AcceptRules]: AcceptRulesStep,
            [Step.WriteMessage]: FinishStep,
            [Step.Finish]: FinishStep,
        };

        return steps[step]
    }, [step]);

    useEffect(() => {
        if (isAuthorized()) {
            if (!loaded || isEmptyObject(account)) {
                fetchMe()
            } else if (!isEmptyObject(account) && account.vkUserId > 0) {
                if (useProjectStore.getState().selectedProject) {
                    setStep(Step.ScenarioCreate);
                } else {
                    setStep(Step.ConnectGroup);
                }
            }
        }

        if (isDev()) {
            // setStep(Step.ScenarioCreate);
        }
    }, [account, fetchMe, loaded, setStep]);

    return (
        <AuthContainer hideHeader width={450}>
            <Stack spacing={3}>
                <Wizard count={5} activeNumber={step}/>

                <Stack spacing={3} padding={0.5}>
                    <StepComponent/>
                </Stack>
            </Stack>
        </AuthContainer>
    );
}

const FinishStep = () => {
    const project = useProjectStore((state => state.selectedProject));

    const [getSettings, loadingSettings, settings] = useScenariosAccessSettingsStore(state => [state.getSettings, state.loadingSettings, state.settings])
    const [setStep, scenario] = useWizardVKStore(state => [state.setStep, state.createdScenario]);
    const [fetchChannels, channels, loaded] = useChannelsStore((state => [state.fetchChannels, state.channels, state.loaded]));

    const onClick = useCallback(() => {
        if (!scenario || !settings) {
            return;
        }

        setStep(Step.Finish);

        channels.forEach((channel) => {
            window.open(`https://vk.com/write-${channel.id}?ref=${settings.uuid}`, '_blank')?.focus();
            return;
        });
    }, [channels, scenario, setStep, settings]);

    useEffect(() => {
        if (!project || !scenario) {
            return;
        }

        fetchChannels(project.id);
        getSettings(scenario.id, project.id);
    }, [fetchChannels, getSettings, project, scenario]);

    return (
        <>
            <Header
                title={'Чат-бот создан'}
                text={'Чат-бот успешно создан и подключен к вашему сообществу, теперь вы можете проверить его в действии!'}
            />

            <Typography level="body-sm">
                Нажмите на кнопку ниже и напишите любое сообщение, в ответ чат-бот пришлет дальнейшие инструкции.
            </Typography>

            <Button loading={!loaded || loadingSettings} onClick={onClick}>Написать чат-боту</Button>
        </>
    )
}

const AcceptRulesStep = () => {
    const project = useProjectStore((state => state.selectedProject));

    const [getSettings, loadingSettings, settings] = useScenariosAccessSettingsStore(state => [state.getSettings, state.loadingSettings, state.settings])
    const [setStep, scenario] = useWizardVKStore(state => [state.setStep, state.createdScenario]);
    const [fetchChannels, channels, loaded] = useChannelsStore((state => [state.fetchChannels, state.channels, state.loaded]));


    const onClick = useCallback(() => {
        if (!scenario || !settings) {
            return;
        }

        channels.forEach((channel) => {
            window.open(`https://vk.com/club${channel.id}`, '_blank')?.focus();
            setStep(Step.WriteMessage);
            return;
        });
    }, [channels, scenario, setStep, settings]);

    useEffect(() => {
        if (!project || !scenario) {
            return;
        }

        fetchChannels(project.id);
        getSettings(scenario.id, project.id);
    }, [fetchChannels, getSettings, project, scenario]);

    return (
        <>
            <Header
                title={'Примите правила чат-ботов'}
                text={'Откройте ваше сообщество по кнопке ниже, и если вы ранее не подключали чат-бота в своем сообществе, то ВКонтакте предложит вам принять правила чат-ботов - их нужно принять.'}
            />

            <Typography level="body-sm">Если после открытия сообщества вы не увидели всплывающее окно с правилами,
                значит вы уже их принимали ранее.</Typography>

            <Button loading={!loaded || loadingSettings} onClick={onClick}>Открыть сообщество</Button>
        </>
    )
}

const CreateScenarioStep = () => {
    const [options, setOptions] = React.useState<Project[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<Project | null>();
    const [defaultValue, setDefaultValue] = React.useState<Project | null>(null);

    const [copyScenario, copyingScenario] = useScenariosPreviewStore(state => [state.copyScenario, state.copyingScenario]);
    const [setStep, setCreatedScenario] = useWizardVKStore(state => [state.setStep, state.setCreatedScenario]);
    const [project, projects, projectLoaded, projectsLoading, fetchProjects] = useProjectStore(state => [
        state.selectedProject,
        state.projects,
        state.projectsLoaded,
        state.projectsLoading,
        state.fetchProjects,
    ]);

    const onSubmit = useCallback(() => {
        if (!selectedGroup) {
            return;
        }

        copyScenario(String(process.env.REACT_APP_VK_WIZARD_SCENARIO_UUID), selectedGroup.id).then(scenario => {
            if (!scenario.id) {
                return;
            }

            setCreatedScenario(scenario);
            setStep(Step.AcceptRules);
        });

    }, [copyScenario, selectedGroup, setCreatedScenario, setStep]);

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);

    useEffect(() => {
        if (!projectLoaded || projectsLoading) {
            return;
        }

        const vkProjects = projects.filter((project) => project.type === ProjectType.VK);

        setOptions(vkProjects);

        if (project) {
            if (project.type === ProjectType.VK) {
                setSelectedGroup(project);
                setDefaultValue(project);
            } else if (vkProjects.length > 0) {
                setSelectedGroup(vkProjects[0]);
                setDefaultValue(vkProjects[0]);
            }
        } else if (vkProjects.length > 0) {
            setSelectedGroup(vkProjects[0]);
            setDefaultValue(vkProjects[0]);
        }

    }, [project, projectLoaded, projects, projectsLoading]);

    if (!projectLoaded || projectsLoading || !defaultValue) {
        return <CreateScenarioStepSkeleton/>
    }

    return (
        <>
            <Header
                title={'Первичная настройка сообщества'}
                text={'Мы сами сделаем все настройки, которые необходимы, чтобы чат-бот заработал в вашем сообществе.'}
            />

            <FormControl>
                <Autocomplete
                    id="vk-group-select"
                    placeholder="Выберите сообщество для настройки"
                    options={options}
                    defaultValue={defaultValue}
                    autoHighlight
                    sx={{py: '9px', px: '15px'}}
                    startDecorator={
                        selectedGroup ?
                            <Avatar size="sm" src={selectedGroup.photo}/> :
                            <Avatar size="sm" src='/images/community_200.png'/>
                    }
                    onChange={(_, value) => setSelectedGroup(value)}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <React.Fragment key={option.id}>
                            <AutocompleteOption {...props} sx={{}}>
                                <ListItemDecorator><Avatar size="sm" src={option.photo}/></ListItemDecorator>
                                <Stack sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                    <Typography level="body-md">{option.name}</Typography>
                                </Stack>
                            </AutocompleteOption>
                        </React.Fragment>
                    )}
                />
            </FormControl>

            <Stack spacing={1}>
                <Button disabled={!selectedGroup} loading={copyingScenario} onClick={onSubmit}>Настроить</Button>
                <BackStepButton toStep={Step.ConnectGroup}/>
            </Stack>
        </>
    )
}

const CreateScenarioStepSkeleton = () => {
    return (
        <>
            <Stack spacing={1}>
                <Typography level="title-lg">
                    <Skeleton>Lorem ipsum dolor.</Skeleton>
                </Typography>
                <Typography level="body-sm">
                    <Skeleton>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa quidem soluta
                        voluptatibus?
                    </Skeleton>
                </Typography>
            </Stack>

            <AspectRatio ratio={8.5}>
                <Skeleton/>
            </AspectRatio>

            <AspectRatio ratio={12.25}>
                <Skeleton/>
            </AspectRatio>
        </>
    )
}

const ConnectGroupStep = () => {
    const [setStep] = useWizardVKStore(state => [state.setStep, state.setCreatedScenario]);
    const [projects, projectLoaded, projectsLoading, fetchProjects] = useProjectStore(state => [
        state.projects,
        state.projectsLoaded,
        state.projectsLoading,
        state.fetchProjects,
    ]);

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);


    return (
        <>
            <Header
                title={'Подключите сообщество ВКонтакте'}
                text={'Выберите сообщество, к которому хотите подключить чат-бота, а дальше мы всё сделаем сами.'}
            />

            <Stack spacing={1.5}>
                <ConnectVKGroup redirectPage={PAGE.WIZARD_CONNECT_VK_GROUP}/>

                {projectLoaded && !projectsLoading && projects.length > 0 &&
                    <Button variant="soft" color="neutral" onClick={() => setStep(Step.ScenarioCreate)}>
                        Пропустить
                    </Button>
                }
            </Stack>
        </>
    )
}

const RegisterStep = () => {
    return (
        <>
            <Header
                title="Создайте чат-бота за пять шагов"
                text={'Всего несколько минут и вы получите работающего чат-бота для вашего сообщества, чтобы начать, войдите через ваш аккаунт ВКонтакте.'}
            />

            <Stack spacing={1}>
                <VKConnectButton redirectPage={PAGE.WIZARD_SIGN_IN_VK}/>
                <VKConnectButtonTerms/>
            </Stack>
        </>
    )
}

const BackStepButton = ({toStep}: { toStep: Step }) => {
    const setStep = useWizardVKStore(state => state.setStep);

    return (
        <Button variant="soft" color="neutral" onClick={() => setStep(toStep)}>Вернуться назад</Button>
    );
}

const Header = ({title, text}: { title: string, text: string }) => {
    return (
        <Stack spacing={1}>
            <Typography level="title-lg">{title}</Typography>
            <Typography level="body-sm">{text}</Typography>
        </Stack>
    );
}
