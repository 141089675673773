import {DashContainer} from "../../Dash/DashContainer";
import {Button, Chip, Grid, Sheet, Stack, Tooltip} from "@mui/joy";
import {Box} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useScenariosStore} from "../../Stores/ScenarioStore";
import {PAGE, Scenario} from "../../types";
import {ConfirmModal} from "../../Components/ConfirmModal";
import {Edit3, Settings, Trash, Unlock, Zap} from "react-feather";
import classNames from "classnames";
import styles from './ScenariosList.module.css';
import {useWindowDimensions} from "../../utils";
import Skeleton from "react-loading-skeleton";
import {StartScreen} from "./StartScreen";
import CircularProgress from "@mui/joy/CircularProgress";
import {useApexOption} from "../../Components/Charts/utils";
import ReactApexChart from "react-apexcharts";
import {useProjectStore} from "../../Stores/ProjectStore";
import {ApiClient} from "../../api-client";
import {useNavigate} from "react-router-dom";
import {useScenariosAccessSettingsStore} from "../../Stores/ScenarioAccessSettingsStore";
import {ScenarioSettingsBox} from "../ScenarioSettingsBox";
import Typography from "@mui/joy/Typography";
import {useScenariosListStore} from "../../Stores/ScenariosListStore";
import {CreateScenarioTemplatesBox} from "./CreateScenarioTemplatesBox";
import {useScenarioTemplatesStore} from "../../Stores/ScenarioTemplatesStore";

export const ScenariosList = () => {
    const [selectedProject] = useProjectStore(state => [state.selectedProject]);
    const [fetchScenarios, fetchScenariosUniqs, scenariosList] = useScenariosListStore(state => [state.fetchScenarios, state.fetchScenariosUniqs, state.list])
    const [isInit, setIsInit] = useState(false)

    useEffect(() => {
        if (!selectedProject) {
            return;
        }

        fetchScenarios(selectedProject.id).then(scenarios => {
            setIsInit(true);

            if (scenarios.length === 0) {
                return;
            }

            fetchScenariosUniqs(selectedProject.id, scenarios.map((scenario) => scenario.id))
        }).catch(ApiClient.toastError);

        // eslint-disable-next-line
    }, [selectedProject]);

    return (
        <DashContainer breadcrumbsTitle='Сценарии' rightHeader={scenariosList.length > 0 ? <CreateScenarioButton/> : <></>}>
            <CreateScenarioTemplatesBox/>
            <Loading/>
            {isInit && <StartScreen/>}

            {scenariosList.map((scenario) => <Item key={scenario.id} scenario={scenario}/>)}
        </DashContainer>
    );
};

const Item = ({scenario}: { scenario: Scenario }) => {
    const sizes = useWindowDimensions()
    const lgSize = sizes.width < 2000 ? 6 : 4;

    return (
        <Grid xs={12} md={12} lg={lgSize}>
            <Sheet variant="outlined" sx={{borderRadius: 'sm'}} className={styles.itemContainer}>
                <Box sx={{display: 'flex', gap: 2, p: 2}}>
                    <Status scenario={scenario}/>

                    <Box sx={{minWidth: 0, flex: 1}} className={styles.itemKeysContainer}>
                        <Stack direction="column" spacing={0.75}>
                            <Name scenario={scenario}/>
                            <Box>
                                <Keys scenario={scenario}/>
                            </Box>
                        </Stack>
                    </Box>

                    <Box className={styles.scenarioControls}>
                        <AccessIcon scenario={scenario}/>
                        <TestButton scenario={scenario}/>
                        <SettingsButton scenario={scenario}/>
                        <EditButton scenario={scenario}/>
                        <RemoveButton scenario={scenario}/>
                    </Box>
                </Box>

                <Box sx={{mt: 1}}>
                    <ItemChart scenario={scenario}/>
                </Box>
            </Sheet>
        </Grid>
    );
};

const ItemChart = ({scenario}: { scenario: Scenario }) => {
    const [stat] = useScenariosListStore(state => [state.statUniqs]);

    const options = useApexOption({
        xaxis: {
            type: 'datetime',
            categories: stat.dates,
            labels: {show: false},
            tooltip: {enabled: false},
        },
        yaxis: {
            labels: {show: false}
        },
        grid: {
            show: false,
            padding: {
                left: -10,
                right: 0,
                bottom: -7,
                top: -20,
            }
        },
        legend: {
            show: false
        },
        chart: {
            parentHeightOffset: 0,
            height: '100%',

        },
        fill: {
            gradient: {
                opacityFrom: 0.6
            }
        },
        tooltip: {
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(0)}`;
                    }
                    return y;
                },
            },
        },
    });

    if (!(scenario.id in stat.scenarios)) {
        return (
            <Skeleton height={96}/>
        );
    }

    const series = [{
        name: 'Запусков сценария',
        data: stat.scenarios[scenario.id]
    }];

    return (
        <ReactApexChart options={options} series={series} type="area" height={100}/>
    );
};

export const CreateScenarioButton = () => {
    const [fetchTemplates, templatesLoading] = useScenarioTemplatesStore(state => [state.fetch, state.loading])

    return (
        <Button
            color="primary"
            size="sm"
            onClick={fetchTemplates}
            className={styles.createButton}
            loading={templatesLoading}
        >
            Создать сценарий
        </Button>
    );
};

const EditButton = ({scenario}: { scenario: Scenario }) => {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(PAGE.SCENARIO_EDIT.replace(':id', String(scenario.id)))
    };

    return (
        <Tooltip title="Редактировать сценарий" color={"neutral"} arrow={true} placement="top-end">
            <Edit3 onClick={onClick} className={styles.editButton} size={20}/>
        </Tooltip>
    );
};

const RemoveButton = ({scenario}: { scenario: Scenario }) => {
    const [deleteScenario] = useScenariosStore(state => [state.deleteScenario]);

    return (
        <ConfirmModal
            onConfirm={() => deleteScenario(scenario.projectId, scenario.id)}
            acceptTitle="Удалить"
            description="Вы уверены, что хотите удалить этот сценарий?"
        >
            <Tooltip title="Удалить сценарий" color={"neutral"} arrow={true} placement="top-end">
                <Trash className={styles.removeButton} size={20}/>
            </Tooltip>
        </ConfirmModal>
    );
};

const AccessIcon = ({scenario}: { scenario: Scenario }) => {
    if (!scenario.publicViewAllowed) {
        return <></>;
    }

    let tooltip = 'Включен публичный просмотр сценария';

    if (scenario.publicCopyAllowed) {
        tooltip = 'Включен публичный просмотр и копирование сценария';
    }

    return (
        <Tooltip title={tooltip} color={"neutral"} arrow={true} placement="top-end">
            <Unlock className={styles.publicAccessUnlockedIcon} color={'var(--joy-palette-neutral-400)'} size={20}/>
        </Tooltip>
    );
};

const SettingsButton = ({scenario}: { scenario: Scenario }) => {
    const [getSettings, loadingSettings, settings] = useScenariosAccessSettingsStore(state => [state.getSettings, state.loadingSettings, state.settings])

    return (
        <>
            <Tooltip title="Настройки сценария" color={"neutral"} arrow={true} placement="top-end">
                <Settings className={styles.settingsIcon} size={20} onClick={() => {
                    if (loadingSettings) {
                        return;
                    }

                    getSettings(scenario.id, scenario.projectId);
                }}/>
            </Tooltip>
            {settings && settings.id === scenario.id && <ScenarioSettingsBox settings={settings} closeAfterSave/>}
        </>
    );
};

const TestButton = ({scenario}: { scenario: Scenario }) => {
    const [testScenario, testing] = useScenariosStore(state => [state.test, state.testing]);

    return (
        <Tooltip title="Тестировать сценарий" color={"neutral"} arrow={true} placement="top-end">
            <Zap className={styles.startButton} size={20}
                 onClick={() => {
                     if (testing) {
                         return;
                     }

                     testScenario(scenario.projectId, scenario.id, "");
                 }}
            />
        </Tooltip>
    );
};

const Name = ({scenario}: { scenario: Scenario }) => {
    if (!scenario.name || scenario.name.length === 0) {
        return (
            <Chip
                size="sm"
                color={"neutral"}
                variant={"soft"}
                sx={{borderRadius: 5}}
            >
                Название не задано
            </Chip>
        );
    }

    return <Typography level={'title-sm'}>{scenario.name}</Typography>
}

const Keys = ({scenario}: { scenario: Scenario }) => {
    if (!scenario.keys || scenario.keys.length === 0) {
        return (
            <Chip
                size="sm"
                color={"warning"}
                sx={{borderRadius: 5}}
                className={styles.itemKey}
            >
                Ключи не заданы
            </Chip>
        );
    }

    return (
        <>
            {scenario.keys.split(',').map((key, index) => {
                return (
                    <Chip
                        key={index}
                        size="sm"
                        variant={"soft"}
                        color={"primary"}
                        sx={{borderRadius: 5}}
                        className={styles.itemKey}
                    >
                        {key}
                    </Chip>
                );
            })}
        </>
    );
};

const Status = ({scenario}: { scenario: Scenario }) => {
    const [toggleEnabled] = useScenariosStore(state => [state.toggleEnabled]);

    const text = scenario.enabled ? 'Отключить сценарий' : 'Включить сценарий';
    const className = classNames(styles.itemStatus, {
        [styles.itemStatusEnabled]: scenario.enabled,
    });

    return (
        <Tooltip title={text} arrow={true} placement="top-start">
            <span onClick={() => toggleEnabled(scenario.projectId, scenario.id)} className={className}></span>
        </Tooltip>
    );
};

const Loading = (): React.JSX.Element => {
    const [loading] = useScenariosListStore(state => [state.isLoading]);

    if (!loading) {
        return <></>
    }

    return (
        <Box sx={{margin: 'auto', textAlign: 'center', mt: {lg: '20%', md: '25%', sm: '30%', xs: '35%'}}}>
            <CircularProgress variant="soft" size="lg" color="primary" sx={{'--CircularProgress-size': '70px'}}/>
        </Box>
    );
}
