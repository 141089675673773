import {create} from "zustand";
import {Scenario} from "../types";

interface WizardVKStore {
    createdScenario: Scenario | undefined;
    step: Step;

    setCreatedScenario: (createdScenario: Scenario) => void;
    setStep: (step: Step) => void;
}

export enum Step {
    Register = 0,
    ConnectGroup = 1,
    ScenarioCreate = 2,
    AcceptRules = 3,
    WriteMessage = 4,
    Finish = 5,
}

export const useWizardVKStore = create<WizardVKStore>((set) => ({
    createdScenario: undefined,
    step: Step.Register,

    setCreatedScenario: (createdScenario: Scenario) => {
        set({createdScenario})
    },

    setStep: (step: Step) => set({step})
}));
