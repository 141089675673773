import {JumpNodeData, NodeData, NodeType} from "../types";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNodes, useReactFlow} from "reactflow";
import {NodeHeader} from "../../NodeHeader";
import Typography from "@mui/joy/Typography";
import {Autocomplete, Card} from "@mui/joy";
import {useUpdateNode} from "../../utils";
import {useScenarioUpdateStore} from "../../../Stores/ScenarioUpdateStore";
import {useScenariosPreviewStore} from "../../../Stores/ScenarioPreviewStore";
import {NodeHandleRole} from "../../Edges/NodeHandle";
import Box from "@mui/joy/Box";
import FormLabel from "@mui/joy/FormLabel";

interface AutocompleteOption {
    label: string;
    id: string;
}

export const JumpNode = ({id, data}: { id: string; data: JumpNodeData; }) => {
    const {setViewport} = useReactFlow()
    const updateNode = useUpdateNode();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const nodes = useNodes();
    const [nodesUpdateDate] = useScenarioUpdateStore((state) => [state.date]);
    const [options, setOptions] = useState<AutocompleteOption[]>([]);

    const [selectedNodeId, setSelectedNodeId] = useState(data.nodeId)
    const [selectedNode, setSelectedNode] = useState<AutocompleteOption | null>(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (data.nodeId.length > 0) {
            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];

                if (!node || node.id !== data.nodeId) {
                    continue;
                }

                const nodeData = node.data as NodeData;

                setSelectedNode({label: nodeData.title, id: node.id});
                setInputValue(nodeData.title);

                break;
            }
        }

        useScenarioUpdateStore.getState().clear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateNode(id, {nodeId: selectedNodeId} as JumpNodeData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, selectedNodeId]);

    useEffect(() => {
        let opts: AutocompleteOption[] = [];

        nodes.forEach((node) => {
            if (node.type === NodeType.ScenarioStartNode || node.type === NodeType.JumpNode || node.type === NodeType.CommentNode || node.id === id) {
                return;
            }

            const data = node.data as NodeData;

            if (selectedNode && selectedNode.id === node.id && inputValue !== data.title) {
                setSelectedNodeId(node.id)
                setSelectedNode({label: data.title, id: node.id});
                setInputValue(data.title)
            }

            opts.push({id: node.id, label: data.title ? data.title : node.id})
        });

        setOptions(opts);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodesUpdateDate]);

    const showNode = useCallback((nodeId: string) => {
        const node = nodes.find((node) => node.id === nodeId);

        if (!node) {
            return node;
        }

        const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

        setViewport({
            x: -node.position.x - (node.width ? (node.width / 2) : 0) + (viewportWidth / 2),
            y: -node.position.y - (node.height ? (node.height / 2) : 0) + (viewportHeight / 2),
            zoom: 1.15
        }, {duration: 600});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodesUpdateDate, setViewport]);

    const Panel = () => {
        if (!selectedNode) {
            return <></>
        }

        return (
            <Card invertedColors variant="soft" color="neutral" sx={{boxShadow: 'none', mt: "15px"}}>
                <Typography level="body-sm">
                    Перейти к блоку «<Typography fontWeight={500} color="primary" sx={{cursor: 'pointer'}}><span
                    onClick={() => showNode(selectedNode.id)}>{selectedNode.label}</span></Typography>»
                </Typography>
            </Card>
        );
    }

    return useMemo(() => {
        return (
            <Box className="Node__container nowheel">
                <NodeHeader
                    title={"Переход к блоку"}
                    nodeTitle={data.title}
                    nodeId={id}
                    nodeHandleRole={NodeHandleRole.JumpNode}
                    deletable
                    testable
                />

                <Box className="Node__content">
                    <FormLabel sx={{mb: 1}}>Выберите блок</FormLabel>
                    <Autocomplete
                        placeholder="Выберите блок для перехода"
                        options={options}
                        value={selectedNode}
                        inputValue={inputValue}
                        disabled={isPreviewMode}
                        onChange={(_, value) => {
                            setSelectedNode(value);
                            setSelectedNodeId(value ? value.id : '');
                        }}
                        onInputChange={(_, value) => setInputValue(value)}
                        isOptionEqualToValue={(v, d) => v.id === d.id}
                    />

                    <Panel/>
                </Box>
            </Box>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, id, inputValue, options, selectedNode]);
}
