import {create} from "zustand";
import {API_METHOD, ScenarioSettings} from "../types";
import {ApiClient} from "../api-client";
import {toastSuccess} from "../utils";

interface ScenariosAccessSettingsStore {
    loadingSettings: boolean;
    savingSettings: boolean;
    settings?: ScenarioSettings;

    clearSettings(): void;

    getSettings(id: number, projectId: number): void;

    saveSettings(id: number, projectId: number, publicViewAllowed: boolean, publicCopyAllowed: boolean): Promise<ScenarioSettings>;
}

export const useScenariosAccessSettingsStore = create<ScenariosAccessSettingsStore>((set) => ({
    loadingSettings: false,
    savingSettings: false,
    settings: undefined,

    clearSettings() {
        set({settings: undefined})
    },

    getSettings(scenarioId: number, projectId: number) {
        set({loadingSettings: true, settings: undefined});

        return ApiClient.get(API_METHOD.SCENARIO_GET_SETTINGS, {params: {scenarioId, projectId}})
            .then(r => set({settings: r.data as ScenarioSettings}))
            .catch(ApiClient.toastError).finally(() => set({loadingSettings: false}));
    },

    saveSettings: async(scenarioId: number, projectId: number, publicViewAllowed: boolean, publicCopyAllowed: boolean) => {
        set({savingSettings: true})

        const params = {scenarioId, projectId};
        const data = {publicViewAllowed: Number(publicViewAllowed), publicCopyAllowed: Number(publicCopyAllowed)};
        let settings = {} as ScenarioSettings;

        await ApiClient.post(API_METHOD.SCENARIO_SAVE_SETTINGS, data, {params})
            .then(r => {
                toastSuccess('Настройки успешно сохранены');
                settings = r.data as ScenarioSettings;
            })
            .catch(ApiClient.toastError)
            .finally(() => set({savingSettings: false}));

        return Promise.resolve(settings);
    }
}));
