import {Box, Grid, Sheet, Typography} from "@mui/joy";
import React, {useEffect, useState} from "react";
import {MetricTitle} from "./utils";
import {ChartDatum, useChartStore} from "../Stores/ChartsStore";
import {MetricType} from "./Charts";
import Skeleton from "react-loading-skeleton";
import {useApexOption} from "../Components/Charts/utils";
import ReactApexChart from "react-apexcharts";
import {useWindowDimensions} from "../utils";
import {ApiClient} from "../api-client";
import {useProjectStore} from "../Stores/ProjectStore";

type props = {
    title: string;
    description: string;
    metrics: MetricType[];
}

export const Chart = ({title, description, metrics}: props) => {
    const [project] = useProjectStore(state => [state.selectedProject]);
    const [datum, setDatum] = useState<ChartDatum>();
    const [fetchDatum] = useChartStore(state => [state.fetch]);

    useEffect(() => {
        if (!project) {
            return;
        }

        fetchDatum(project.id, metrics).then(d => setDatum(d)).catch(ApiClient.toastError);
    }, [project, metrics, fetchDatum]);

    return (
        <Grid xs={12} md={12} lg={12}>
            <Sheet variant="outlined" sx={{borderRadius: 'sm', p: 3}}>
                <Box sx={{display: 'flex', gap: 0, mb: 3, flexDirection: 'column'}}>
                    <Typography level="title-md" fontWeight={"bold"}>{title}</Typography>
                    <Typography level="body-sm">{description}</Typography>
                </Box>

                <AreaChart metrics={metrics} datum={datum}/>
            </Sheet>
        </Grid>
    )
}

const AreaChart = ({metrics, datum}: { metrics: MetricType[]; datum: ChartDatum | undefined; }) => {
    const sizes = useWindowDimensions()
    const options = useApexOption({
        xaxis: {
            type: 'datetime',
            categories: datum ? datum.dates : [],
        },
        legend: {
            showForSingleSeries: true,
            horizontalAlign: sizes.width > 900 ? 'right' : 'center',
        },
        tooltip: {
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(0)}`;
                    }
                    return y;
                },
            },
        },
    });

    if (!datum) {
        return (
            <Skeleton height={311}/>
        );
    }

    const series = metrics.map((metric) => {
        return {
            name: MetricTitle(metric),
            data: datum.series[metric]
        }
    });

    return (
        <ReactApexChart options={options} series={series} type="area" height={300}/>
    );
}