import {useProjectStore} from "../Stores/ProjectStore";
import * as React from "react";
import {useEffect, useState} from "react";
import {Constructor} from "../Constructor/Flow";
import {useBroadcastingStore} from "../Stores/BroadcastingStore";
import {useParams} from "react-router-dom";
import {Broadcasting} from "../types";
import {useScenariosStore} from "../Stores/ScenarioStore";
import {LoadingScenario} from "../Scenarios/Scenarios";
import {BroadcastingConstructorControls} from "./BroadcastingConstructorControls";
import {BroadcastingStatus} from "./BroadcastingList";
import {ConstructorMode} from "../Constructor/Nodes/types";

export const BroadcastingEdit = () => {
    const params = useParams();
    const broadcastingId = Number(params.id);
    const project = useProjectStore(state => state.selectedProject);
    const [load] = useBroadcastingStore(state => [state.load]);
    const [broadcasting, setBroadcasting] = useState<Broadcasting | null>(null)

    useEffect(() => {
        useScenariosStore.getState().clear()

        if (!project) {
            return;
        }

        load(project.id, broadcastingId).then(b => {
            setBroadcasting(b);
        });
    }, [broadcastingId, load, project]);

    if (!project) {
        return <></>
    }

    return (
        <>
            {!broadcasting && <LoadingScenario/>}
            {broadcasting !== null &&
                <Constructor
                    project={project}
                    mode={ConstructorMode.Broadcasting}
                    scheme={broadcasting.scheme}
                    previewMode={broadcasting.status !== BroadcastingStatus.Created}
                    controls={
                        <BroadcastingConstructorControls
                            projectId={project.id}
                            broadcastingId={broadcastingId}
                            broadcastingStatus={broadcasting.status}
                        />
                    }
                />
            }
        </>
    );
}
