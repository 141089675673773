import {create} from "zustand";
import {API_METHOD, ApiResponse, ApiResponseSaveFile, ApiResponseUploadUrl, StorageResponsePutObject} from "../types";
import {ApiClient} from "../api-client";
import {storageClient, toastError, toastSuccess} from "../utils";

interface UploadStore {
    getUploadUrl: (projectId: number) => Promise<ApiResponseUploadUrl>;
    saveFile: (projectId: number, name: string, object: StorageResponsePutObject) => Promise<ApiResponseSaveFile>;
    uploadObject: (uploadUrlData: ApiResponseUploadUrl, file: File, projectId: number) => Promise<ApiResponseSaveFile | null>;
}

export const useUploadStore = create<UploadStore>((_) => ({
    getUploadUrl: async (projectId: number): Promise<ApiResponseUploadUrl> => {
        let data: ApiResponseUploadUrl = {url: '', maxSize: 0, types: ''};

        await ApiClient.get(API_METHOD.GET_UPLOAD_URL, {params: {projectId}}).then(r => {
            data = r.data as ApiResponseUploadUrl;
        }).catch(ApiClient.toastError);

        return Promise.resolve(data);
    },

    // Сохраняет данные уже загруженного файла.
    saveFile: async (projectId: number, name: string, object: StorageResponsePutObject): Promise<ApiResponseSaveFile> => {
        const data = {
            name: name,
            osmKey: object.key,
            osmUrl: object.url,
            osmSize: object.size,
            osmSign: object.sign
        };

        let response: ApiResponseSaveFile = {url: '', id: 0, type: '', objectKey: ''};
        await ApiClient.post(API_METHOD.SAVE_FILE, data, {params: {projectId}}).then(r => {
            response = r.data as ApiResponseSaveFile;
        }).catch(ApiClient.toastError);

        return Promise.resolve(response);
    },

    uploadObject: async (uploadUrlData: ApiResponseUploadUrl, file: File, projectId: number): Promise<ApiResponseSaveFile | null> => {
        const uploadUrl = uploadUrlData.url;
        const maxSize = uploadUrlData.maxSize;
        const formData = new FormData();
        const maxSizeMB = Math.floor((uploadUrlData.maxSize / 1024 / 1024));

        if (uploadUrl.length === 0) {
            return Promise.resolve(null);
        }

        if (file.size > maxSize) {
            toastError('Размер файла для загрузки не должен превышать ' + maxSizeMB + ' мегабайт.');
            return Promise.resolve(null);
        }

        formData.append('file', file);

        let result: StorageResponsePutObject = {url: '', key: '', size: 0, sign: ''};
        await storageClient.put(uploadUrl, formData, (response) => {
            const responseData = response.data as ApiResponse;
            result = responseData.data as StorageResponsePutObject;
        });

        if (!result.url) {
            return Promise.resolve(null);
        }

        const saveResponse = await useUploadStore.getState().saveFile(projectId, file.name, result);

        if (!saveResponse.id) {
            return Promise.resolve(null);
        }

        toastSuccess('Файл загружен');

        return Promise.resolve(saveResponse);
    },
}));
