import moment from "moment/moment";
import {MetricType} from "./Charts";

export const DateFormatter = (date: moment.MomentInput) => {
    return moment(date).format('DD MMMM');
};

export const MetricTitle = (name: string) => {
    switch (name) {
        case MetricType.Subscribes:
            return 'Новые';
        case MetricType.Blocks:
            return 'Заблокировали';
        case MetricType.Unblocks:
            return 'Разблокировали';
        case MetricType.SendMessages:
            return 'Отправленные';
        case MetricType.ReceiveMessages:
            return 'Полученные';
            case MetricType.UniqueDialogs:
            return 'Диалоги';
        case MetricType.PressButtons:
            return 'Нажатия';
    }

    return 'undefined';
};