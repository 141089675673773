import {create} from "zustand";

interface ScenarioUpdateStore {
    hasUpdates: boolean;
    confirmCloseModalOpen: boolean;
    date: string;

    clear(): void;

    updated(): void;

    setConfirmCloseModalOpen(v: boolean): void;
}

export const useScenarioUpdateStore = create<ScenarioUpdateStore>((set) => ({
    hasUpdates: false,
    confirmCloseModalOpen: false,
    date: '',

    clear() {
        set({hasUpdates: false, date: performance.now().toString()});
    },
    updated() {
        set({hasUpdates: true, date: performance.now().toString()});
    },
    setConfirmCloseModalOpen(v: boolean) {
        set({confirmCloseModalOpen: v})
    }
}));
