import {BroadcastingStartNodeData} from "../types";
import {NodeHeader} from "../../NodeHeader";
import {Position} from "reactflow";
import React, {useCallback, useEffect, useState} from "react";
import {Box, Card, Stack, Tooltip} from "@mui/joy";
import {FormInput} from "../../../Components/Form/FormInput";
import {AlertTriangle, HelpCircle} from "react-feather";
import {useUpdateNode} from "../../utils";
import {useScenariosPreviewStore} from "../../../Stores/ScenarioPreviewStore";
import {useInputErrors, validateInput, validateMaxLength} from "../../../Components/Form/helpers";
import {useScenarioUpdateStore} from "../../../Stores/ScenarioUpdateStore";
import {Dropdown} from "../../../Components/Dropdown";
import Typography from "@mui/joy/Typography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker, TimeField} from "@mui/x-date-pickers";
import moment from "moment";
import {FormErrorHelper, FormWarningHelper} from "../PauseNode/PauseNode";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import {NodeHandleRole, NodeSourceHandle} from "../../Edges/NodeHandle";

export enum BroadcastingStartRule {
    Manually = 1, // Рассылку нужно будет запустить вручную по кнопке.
    Time = 2, // Рассылка запустится сама в выбранное время.
}

export enum BroadcastingAudienceMode {
    All = 1 // Отправка рассылки всем активным пользователям.
}

export const BroadcastingMaxScheduleDays = 180;

export const BroadcastingMinScheduleSeconds = 60 * 5;

export const BroadcastingStartNode = ({id, data}: { id: string, data: BroadcastingStartNodeData }) => {
    useEffect(() => {
        useScenarioUpdateStore.getState().clear();
    }, []);

    return (
        <Box className="Node__container nowheel" sx={{width: 400}}>
            <NodeHeader
                title={"Старт рассылки"}
                nodeId={id}
                nodeHandleRole={NodeHandleRole.StartBroadcastingNode}
                hasMetrics
            />

            <Box sx={{p: 2}}>
                <Stack direction="column" spacing={2}>
                    <NameInput nodeId={id} currentName={data.name}/>
                    <Audience nodeId={id} mode={data.audienceMode}/>
                    <StartRule nodeId={id} rule={data.startRule}/>
                    <TimeSelector nodeId={id} startRule={data.startRule} currentDatetime={data.startDatetime}/>
                </Stack>
            </Box>

            <NodeSourceHandle id={id} role={NodeHandleRole.StartBroadcastingNode}/>
        </Box>
    );
}

const NameInput = ({nodeId, currentName}: { nodeId: string, currentName: string }) => {
    const updateNode = useUpdateNode();
    const [inputErrors, setInputErrors] = useInputErrors();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const [name, setName] = useState<string>(currentName);

    const validators = useCallback(() => {
        return {
            name: (value: any) => validateInput(
                "name", value, setInputErrors,
                {func: validateMaxLength, threshold: 48},
            ),
        }
    }, [setInputErrors]);

    useEffect(() => {
        validators().name(name);
    }, [name, validators]);

    useEffect(() => {
        updateNode(nodeId, {
            name: name,
        } as BroadcastingStartNodeData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, nodeId]);

    return (
        <FormInput
            type="text"
            label="Название"
            variant="soft"
            value={name}
            setValue={setName}
            validate={validators().name}
            errorText={inputErrors.get("name")}
            placeholder="Введите название.."
            disabled={isPreviewMode}
            endDecorator={
                <Tooltip
                    color={"primary"}
                    variant={'soft'}
                    arrow
                    title={'Название выводится только в  интерфейсе конструктора.'}
                    size={'md'}
                    placement={Position.Top}
                >
                    <HelpCircle style={{cursor: 'pointer'}} size={20}/>
                </Tooltip>
            }
        />
    )
}

const StartRule = ({nodeId, rule}: { nodeId: string, rule: BroadcastingStartRule }) => {
    const updateNode = useUpdateNode();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const [startRule, setStartRule] = useState<BroadcastingStartRule>(rule);

    const DropdownTypes = [
        {label: "Вручную", value: BroadcastingStartRule.Manually},
        {label: "По времени", value: BroadcastingStartRule.Time},
    ];

    useEffect(() => {
        updateNode(nodeId, {
            startRule: startRule
        } as BroadcastingStartNodeData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startRule, nodeId]);

    return (
        <Dropdown
            label={"Рассылка запустится"}
            value={startRule}
            options={DropdownTypes}
            setValue={setStartRule}
            size={'md'}
            variant={'soft'}
            disabled={isPreviewMode}
        />
    );
}

const Audience = ({nodeId, mode}: { nodeId: string, mode: BroadcastingAudienceMode }) => {
    const updateNode = useUpdateNode();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const [audienceMode, setAudienceMode] = useState<BroadcastingAudienceMode>(mode);

    const DropdownTypes = [
        {label: "Всем активным пользователям", value: BroadcastingAudienceMode.All},
    ];

    useEffect(() => {
        updateNode(nodeId, {
            audienceMode: audienceMode
        } as BroadcastingStartNodeData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audienceMode, nodeId]);

    return (
        <Dropdown
            label={"Кому отправить"}
            value={audienceMode}
            options={DropdownTypes}
            setValue={setAudienceMode}
            size={'md'}
            variant={'soft'}
            disabled={isPreviewMode}
        />
    )
}

const TimeSelector = ({nodeId, currentDatetime, startRule}: {
    nodeId: string,
    currentDatetime: string,
    startRule: BroadcastingStartRule
}) => {
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const [datetime, setDatetime] = useState<moment.Moment | null>(moment(currentDatetime));
    const [error, setError] = useState<string | undefined>();
    const [warning, setWarning] = useState<string | undefined>();
    const incorrectErrorText = `Введите корректное время и дату, не больше ${BroadcastingMaxScheduleDays} дней.`;
    const isPastErrorText = `Выбранная дата уже наступила.`;
    const updateNode = useUpdateNode();

    useEffect(() => {
        if (!datetime) {
            return;
        }

        if (datetime.isBefore(moment())) {
            setWarning(isPastErrorText);
        } else {
            setWarning(undefined);
        }

        if (!datetime.isValid() || datetime.isAfter(moment().add(BroadcastingMaxScheduleDays, 'days'))) {
            setError(incorrectErrorText);
            return;
        }

        setError(undefined);

        updateNode(nodeId, {
            startDatetime: datetime.format('YYYY-MM-DD HH:mm'),
        } as BroadcastingStartNodeData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datetime]);

    if (startRule !== BroadcastingStartRule.Time) {
        return <></>;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormControl>
                <FormLabel>Дата и время запуска</FormLabel>
                <Stack direction="row" spacing={1.5}>
                    <DatePicker
                        defaultValue={moment('2022-04-17')}
                        value={datetime}
                        slotProps={{textField: {size: 'small'}}}
                        disabled={isPreviewMode}
                        onChange={(v) => setDatetime(v)}
                    />
                    <TimeField
                        value={datetime}
                        size={'small'}
                        disabled={isPreviewMode}
                        onChange={(v) => setDatetime(v)}
                        format="HH:mm"
                    />
                </Stack>
                <FormErrorHelper error={error}/>
                <FormWarningHelper warning={warning}/>
                <TimeWarning/>
            </FormControl>
        </LocalizationProvider>
    );
}

const TimeWarning = () => {
    return (
        <Card variant="soft" color="neutral" sx={{boxShadow: 'none', mt: 1.5}}>
            <Stack direction="row" spacing={2} alignItems={'center'}>
                <AlertTriangle size={42} color="var(--joy-palette-neutral-500, #636B74)"/>
                <Typography level="body-sm">
                    Дата и время задаются по московскому времени (GMT+3).
                </Typography>
            </Stack>
        </Card>
    );
}
