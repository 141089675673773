import React from 'react';
import {DashContainer} from "../Dash/DashContainer";
import {Chart} from "./Chart";

export enum MetricType {
    Subscribes = 'new_subscriber',
    Blocks = 'block_bot',
    Unblocks = 'unblock_bot',
    SendMessages = 'send_message',
    ReceiveMessages = 'receive_message',
    PressButtons = 'press_button',
    UniqueDialogs = 'unique_dialogs'
}

export const Charts = () => {
    return (
        <DashContainer breadcrumbsTitle='Аналитика'>
            <Chart
                title={"Рост"}
                description={"Новые пользователи в боте, блокировки и разблокировки бота"}
                metrics={[MetricType.Subscribes, MetricType.Blocks, MetricType.Unblocks]}
            />

            <Chart
                title={"Диалоги"}
                description={"Уникальные диалоги с пользователями"}
                metrics={[MetricType.UniqueDialogs]}
            />

            <Chart
                title={"Сообщения"}
                description={"Отправленные и полученные ботом"}
                metrics={[MetricType.SendMessages, MetricType.ReceiveMessages]}
            />

            <Chart
                title={"Нажатия на кнопки"}
                description={"На клавиатурах во всех сценариях"}
                metrics={[MetricType.PressButtons]}
            />
        </DashContainer>
    );
}

