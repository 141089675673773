import {Button, FormHelperText, IconButton, Tooltip, Typography} from "@mui/joy";
import React, {useState} from "react";
import {useScenariosAccessSettingsStore} from "../Stores/ScenarioAccessSettingsStore";
import Switch from "@mui/joy/Switch";
import Stack from "@mui/joy/Stack";
import {Form} from "react-router-dom";
import {FormInput} from "../Components/Form/FormInput";
import {Copy} from "react-feather";
import {copyToClipboard} from "../utils";
import {Position} from "reactflow";
import {ScenarioSettings} from "../types";
import {useScenariosListStore} from "../Stores/ScenariosListStore";
import FormControl from "@mui/joy/FormControl";
import {ModalBox} from "../Components/ModalBox";

export const ScenarioSettingsBox = ({settings, closeAfterSave}: {
    settings: ScenarioSettings,
    closeAfterSave?: boolean
}) => {
    const [scenariosList, setScenariosList] = useScenariosListStore(state => [state.list, state.setScenariosList]);
    const [clearSettings, savingSettings, saveSettings] = useScenariosAccessSettingsStore(state => [state.clearSettings, state.savingSettings, state.saveSettings]);
    const [canViewPublic, setCanViewPublic] = useState(settings.publicViewAllowed);
    const [canCopyPublic, setCanCopyPublic] = useState(settings.publicCopyAllowed);

    const scenarioUrl = `https://web.chatgun.io/scenario-preview/${settings.uuid}`;
    const onSubmit = () => {
        saveSettings(settings.id, settings.projectId, canViewPublic, canCopyPublic).then(settings => {
            if (settings.id <= 0) {
                return;
            }

            if (scenariosList.length > 0) {
                setScenariosList(scenariosList.map(s => {
                    if (s.id !== settings.id) {
                        return s;
                    }

                    s.publicCopyAllowed = settings.publicCopyAllowed;
                    s.publicViewAllowed = settings.publicViewAllowed;

                    return s;
                }));
            }
        }).then(_ => {
            if (closeAfterSave) {
                clearSettings();
            }
        });
    };

    return (
        <ModalBox open={true} onClose={clearSettings} title='Настройки сценария' sx={{maxWidth: 500}}>
            <Form onSubmit={onSubmit}>
                <Stack spacing={2.5}>
                    <FormControl>
                        <FormInput
                            type={'text'}
                            value={settings.directStartLink}
                            label="Прямая ссылка для запуска"
                            className={'noselect'}
                            endDecorator={
                                <Tooltip
                                    color={"neutral"}
                                    variant={'soft'}
                                    arrow
                                    title={'Скопировать ссылку'}
                                    size={'sm'}
                                    placement={Position.Right}
                                >
                                    <IconButton
                                        className="nooutline"
                                        variant="soft"
                                        color="neutral"
                                        onClick={() => copyToClipboard(settings.directStartLink, 'Ссылка скопирована в буфер обмена.')}
                                    >
                                        <Copy/>
                                    </IconButton>
                                </Tooltip>
                            }
                        />
                        <FormHelperText>
                            <Typography level="body-sm" textColor="neutral.400">
                                При переходе по этой ссылке у пользователя запустится именно этот сценарий, если он
                                включен.
                            </Typography>
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <Typography component="label" sx={{cursor: 'pointer'}} className={'noselect'}
                                    startDecorator={
                                        <Switch
                                            checked={canViewPublic}
                                            onChange={(e) => setCanViewPublic(e.target.checked)}
                                            sx={{mr: 0.5}}
                                            color={canViewPublic ? 'success' : 'neutral'}
                                        />
                                    }>
                            Разрешить просмотр по ссылке
                        </Typography>

                        <FormHelperText>
                            <Typography level="body-sm" textColor="neutral.400">
                                Разрешение на просмотр даст возможность увидеть ваш сценарий всем, у кого есть ссылка
                                для просмотра.
                            </Typography>
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <Typography component="label" sx={{cursor: 'pointer'}} className={'noselect'}
                                    startDecorator={
                                        <Switch
                                            checked={canCopyPublic}
                                            onChange={(e) => setCanCopyPublic(e.target.checked)}
                                            sx={{mr: 0.5}}
                                            color={canCopyPublic ? 'success' : 'neutral'}
                                            disabled={!canViewPublic}
                                        />
                                    }>
                            Разрешить копировать сценарий
                        </Typography>

                        <FormHelperText>
                            <Typography level="body-sm" textColor="neutral.400">
                                Другие пользователи смогут скопировать этот сценарий со страницы просмотра.
                            </Typography>
                        </FormHelperText>
                    </FormControl>

                    <FormInput
                        type={'text'}
                        disabled={!canViewPublic}
                        value={scenarioUrl}
                        label="Ссылка для просмотра"
                        className={'noselect'}
                        endDecorator={
                            <Tooltip
                                color={"neutral"}
                                variant={'soft'}
                                arrow
                                title={'Скопировать ссылку'}
                                size={'sm'}
                                placement={Position.Right}
                            >
                                <IconButton
                                    className="nooutline"
                                    variant="soft"
                                    color="neutral"
                                    onClick={() => copyToClipboard(scenarioUrl, 'Ссылка скопирована в буфер обмена.')}
                                >
                                    <Copy/>
                                </IconButton>
                            </Tooltip>
                        }
                    />

                    <Button type='submit' loading={savingSettings}>Сохранить</Button>
                </Stack>
            </Form>
        </ModalBox>
    );
}
