import './Edges.css';
import React, {useCallback} from 'react';
import {getSmoothStepPath, useReactFlow} from 'reactflow';
import {EdgeProps} from "@reactflow/core/dist/esm/types/edges";
import ClearIcon from "@mui/icons-material/Clear";
import {useScenarioUpdateStore} from "../../Stores/ScenarioUpdateStore";
import {useScenariosPreviewStore} from "../../Stores/ScenarioPreviewStore";

const foreignObjectHeight = 18;
const foreignObjectWidth = 18;

/**
 * Пока что там просто крестик для удаления связи, но при желании можно отрисовать и лейбл, в нем название кнопки.
 */
export const RemovableEdge = (props: EdgeProps) => {
    const reactFlow = useReactFlow();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    const [edgePath, labelX, labelY] = getSmoothStepPath({
        borderRadius: 20,
        centerX: 0,
        centerY: 0,
        offset: 20,
        sourcePosition: props.sourcePosition,
        sourceX: props.sourceX,
        sourceY: props.sourceY,
        targetPosition: props.targetPosition,
        targetX: props.targetX,
        targetY: props.targetY
    });

    const onEdgeClick = useCallback((_: any) => {
        reactFlow.setEdges((eds) => eds.filter((e) => e.id !== props.id));
        useScenarioUpdateStore.getState().updated();
    }, [props.id, reactFlow]);

    return (
        <>
            <path
                id={props.id}
                style={props.style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={props.markerEnd}
                markerStart={props.markerStart}
            />
            <foreignObject
                width={foreignObjectHeight}
                height={foreignObjectWidth}
                x={labelX - foreignObjectHeight / 2}
                y={labelY - foreignObjectWidth / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                {!isPreviewMode &&
                    <div className="Edges__RemovableEdge noselect">
                        <ClearIcon className="Edges__RemovableEdgeIcon" onClick={onEdgeClick}/>
                    </div>
                }
            </foreignObject>
        </>
    );
}