import {Tooltip} from "@mui/joy";
import {Handle, Position} from "reactflow";
import React from "react";
import {ButtonData, ButtonType} from "./types";

export const RowHandlers = (props: { buttonsRow: ButtonData[] }): React.JSX.Element => {
    const buttons = props.buttonsRow.filter((button) => {
        return button.type === ButtonType.COMMON || button.type === ButtonType.URL;
    });

    if (!buttons.length) {
        return <></>
    }

    return (
        <div className="MessageNodeKeyboardRightHandlers">
            {
                buttons.map((button) => {
                    return (
                        <Tooltip key={button.id} arrow title={button.name} size="sm" placement="right">
                            <Handle
                                id={button.id}
                                isConnectable={true}
                                type="source"
                                position={Position.Right}
                                style={{position: "relative"}}
                            />
                        </Tooltip>
                    );
                })
            }
        </div>
    )
}