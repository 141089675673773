import './ProjectCreate.css';
import {AuthContainer} from "../Auth/AuthContainer";
import {FormInput} from "../Components/Form/FormInput";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {
    useInputErrors,
    validateInput,
    validateMaxLength,
    validateRequired,
    validateTelegramBotToken
} from "../Components/Form/helpers";
import {getCookie, groupConnectAuthUrl, numeralsLabel, pageLink, removeCookie, toastSuccess} from "../utils";
import {API_METHOD, AUTH_COOKIE, PAGE, Project} from "../types";
import {Form, useNavigate} from "react-router-dom";
import {
    Accordion,
    AccordionDetails,
    AccordionGroup,
    AccordionSummary,
    Autocomplete,
    AutocompleteOption,
    Box,
    Card,
    Link,
    ListItemDecorator,
    RadioGroup,
    Sheet
} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Radio, {radioClasses} from '@mui/joy/Radio';
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import {ConfirmModal} from "../Components/ConfirmModal";
import {ApiClient} from "../api-client";
import {useProjectStore} from "../Stores/ProjectStore";
import Stack from "@mui/joy/Stack";
import Avatar from "@mui/joy/Avatar";
import FormLabel from "@mui/joy/FormLabel";
import {useVKStore, VKGroup} from "../Stores/VKStore";
import FormControl from "@mui/joy/FormControl";

export enum ChannelType {
    VK = 'VK',
    Telegram = 'Telegram',
}

export const ProjectCreate = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [channelType, setChannelType] = useState<ChannelType>();

    useEffect(() => {
        if (getCookie(AUTH_COOKIE) === undefined) {
            navigate(PAGE.SIGN_IN); // Если не авторизован, то внутрь не пускаем.
            return
        }
    }, [navigate]);

    useEffect(() => {
        setLoading(true);
        useProjectStore.getState().fetchProjects().finally(() => setLoading(false));
    }, [navigate]);

    if (loading) {
        return (
            <AuthContainer hideHeader width={450}>
                <LoadingProjects/>
            </AuthContainer>
        );
    }

    const onLogout = () => {
        removeCookie(AUTH_COOKIE);
        navigate(PAGE.SIGN_IN);
        return toastSuccess("Вы успешно вышли из системы.");
    };

    const BackButton = () => {
        if (useProjectStore.getState().projects.length === 0) {
            return <></>
        }

        return <Button color="neutral" variant="outlined" onClick={() => navigate(PAGE.MAIN)}>
            Отмена
        </Button>
    };

    const LogoutButton = () => {
        if (useProjectStore.getState().projects.length > 0) {
            return <></>
        }

        return (
            <ConfirmModal
                onConfirm={onLogout}
                acceptTitle="Выйти"
                acceptColor="primary"
                description="Вы уверены, что хотите выйти из аккаунта?"
            >
                <Button variant="outlined">Выйти из аккаунта</Button>
            </ConfirmModal>
        )
    };

    return (
        <AuthContainer width={450} title="Подключение нового канала к сервису">
            <ChannelTypeSelector setChannelType={setChannelType}/>
            {channelType === ChannelType.Telegram && <ConnectTelegramBot/>}
            {channelType === ChannelType.VK &&
                <ConnectVKGroup redirectPage={PAGE.CONNECT_VK_GROUP} targetBlankConnectWindow={true}/>
            }
            <BackButton/>
            <LogoutButton/>
        </AuthContainer>
    )
}


const ChannelTypeSelector = ({setChannelType}: {
    setChannelType: React.Dispatch<React.SetStateAction<ChannelType | undefined>>
}) => {
    const RadioItem = (
        {value, name, avatar, label}: { value: ChannelType, name: string, label: string, avatar: string}
    ) => {
        return (
            <Sheet
                key={value}
                variant="outlined"
                sx={{
                    borderRadius: 'md',
                    boxShadow: 'sm',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1.5,
                    p: '12px',
                }}
            >
                <Radio id={value} value={value} checkedIcon={<></>}/>
                <Avatar src={avatar} size="sm"/>
                <Stack direction="column">
                    <FormLabel htmlFor={value}>{name}</FormLabel>
                    <Typography textColor="neutral.400" level="body-sm" fontSize={13}>{label}</Typography>
                </Stack>
            </Sheet>
        );
    }

    return (
        <Box>
            <RadioGroup
                overlay
                aria-label="channel-type"
                onChange={(v) => {
                    const value = v.target.value as ChannelType;

                    if (value === ChannelType.VK) {
                        // todo - сделать тут редирект на визард, но там надо нормально настроить переходы между шагами через параметр в урле
                        // navigate(PAGE.WIZARD_VK);
                        // return;
                    }

                    setChannelType(value);
                }}
                name="platform"
                sx={{
                    gap: 2,
                    [`& .${radioClasses.checked}`]: {
                        [`& .${radioClasses.action}`]: {
                            inset: -1,
                            border: '2px solid',
                            borderColor: 'primary.400',
                        },
                    },
                    [`& .${radioClasses.radio}`]: {
                        display: 'contents',
                        '& > svg': {
                            zIndex: 2,
                            position: 'absolute',
                            top: '-8px',
                            right: '-8px',
                            bgcolor: 'background.surface',
                            borderRadius: '50%',
                        },
                    },
                }}
            >
                <RadioItem
                    value={ChannelType.VK}
                    avatar="/images/logo_vk.svg"
                    name="Сообщество ВКонтакте"
                    label="Из списка администрируемых"
                />

                <RadioItem
                    value={ChannelType.Telegram}
                    avatar="/images/logo_telegram.svg"
                    name="Бот в Telegram"
                    label="Понадобится токен бота"
                />
            </RadioGroup>
        </Box>
    );
}


export const ConnectVKGroup = ({redirectPage, targetBlankConnectWindow}: {
    redirectPage: PAGE,
    targetBlankConnectWindow?: boolean
}) => {
    const [getGroups, groupsLoading] = useVKStore(state => [state.getGroups, state.groupsLoading]);
    const [options, setOptions] = useState<VKGroup[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<VKGroup | null>();
    const [selectGroupError, setSelectGroupError] = useState();

    useEffect(() => {
        getGroups().then(groups => setOptions(groups)).catch(err => setSelectGroupError(err.response.data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onConnect = useCallback(() => {
        if (!selectedGroup) {
            return;
        }

        window.open(
            groupConnectAuthUrl(selectedGroup.id, pageLink(redirectPage)),
            targetBlankConnectWindow ? '_blank' : '_self'
        )?.focus();
    }, [redirectPage, selectedGroup, targetBlankConnectWindow])


    if (selectGroupError) {
        return (
            <Card invertedColors variant="soft" color="danger">
                <Typography level="body-sm">
                    {selectGroupError}
                </Typography>
            </Card>
        );
    }

    return (
        <Stack gap={2}>
            <FormControl>
                <Autocomplete
                    id="vk-group-select"
                    loadingText={
                        <Stack direction='row' gap={2} alignItems='center'>
                            <CircularProgress size="sm" variant="soft"/>
                            <Typography level='body-sm' textColor='neutral.500'>
                                Загружаем список сообществ..
                            </Typography>
                        </Stack>
                    }
                    placeholder="Выберите сообщество для подключения"
                    loading={groupsLoading}
                    options={options}
                    autoHighlight
                    sx={{p: '15.75px'}}
                    startDecorator={
                        selectedGroup ?
                            <Avatar size="sm" src={selectedGroup.photo}/> :
                            <Avatar size="sm" src='/images/community_200.png'/>
                    }
                    onChange={(_, value) => setSelectedGroup(value)}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <React.Fragment key={option.id}>
                            <AutocompleteOption {...props} sx={{}}>
                                <ListItemDecorator>
                                    <Avatar size="sm" src={option.photo}/>
                                </ListItemDecorator>
                                <Stack sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                    <Typography level="body-md">
                                        {option.name}
                                    </Typography>
                                    <Typography textColor="neutral.400" level="body-sm" fontSize={13}>
                                        {option.membersCount}
                                        {' '}
                                        {numeralsLabel(option.membersCount, 'подписчик', 'подписчика', 'подписчиков')}
                                    </Typography>
                                </Stack>
                            </AutocompleteOption>
                        </React.Fragment>
                    )}
                />
            </FormControl>
            <Card invertedColors variant="soft" color="neutral">
                <Typography level="body-sm">
                    Подключить можно только то сообщество, где вы являетесь создателем или администратором.
                </Typography>
                <Typography level="body-sm">
                    Если сообщества все равно нет в списке, то попробуйте отписаться и подписаться на него заново,
                    а затем обновить эту страницу.
                </Typography>
            </Card>
            <Button disabled={!selectedGroup} onClick={onConnect}>Подключить</Button>
        </Stack>
    );
}

const ConnectTelegramBot = () => {
    const navigate = useNavigate();
    const [token, setToken] = React.useState("");
    const [inputErrors, setInputErrors] = useInputErrors();
    const [isSending, setIsSending] = useState(false);
    const [index, setIndex] = React.useState<number | null>(null);

    const AccordionItem = ({title, number, children}: { title: string, number: number, children: any }) => {
        return (
            <Accordion
                expanded={index === number}
                onChange={(_, expanded) => setIndex(expanded ? number : null)}
            >
                <AccordionSummary><Typography level="title-sm">{title}</Typography></AccordionSummary>
                <AccordionDetails><Typography level="body-sm">{children}</Typography></AccordionDetails>
            </Accordion>
        )
    };

    const validators = useCallback(() => {
        return {
            token: (value: any) => validateInput(
                "token", value, setInputErrors,
                {func: validateRequired},
                {func: validateTelegramBotToken},
                {func: validateMaxLength, threshold: 128}
            ),
        }
    }, [setInputErrors]);

    const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = [validators().token(token)].filter((error) => error !== null);

        if (errors.length > 0) {
            return
        }

        setIsSending(true);

        ApiClient.post(API_METHOD.TELEGRAM_CONNECT_BOT, {token}).then(r => {
            const project = r.data as Project;

            toastSuccess("Бот успешно подключен к сервису!");

            useProjectStore.getState().setSelectedProject(project);
            useProjectStore.getState().fetchProjects().then(() => navigate(PAGE.MAIN));
        }).catch(ApiClient.toastError).finally(() => setIsSending(false));
    }, [token, navigate, validators]);

    return (
        <Form onSubmit={onSubmit}>
            <Stack direction="column" spacing="15px">
                <FormInput
                    label="Токен бота"
                    type="text"
                    value={token}
                    setValue={setToken}
                    validate={validators().token}
                    errorText={inputErrors.get("token")}
                    placeholder="123847574:AAFGgjaflmLGysg..."
                />


                <AccordionGroup variant='soft' color='neutral' sx={{borderRadius: 6, mb: 0}}>
                    <AccordionItem title='Для чего нужен токен' number={0}>
                        Токен от вашего бота в Telegram позволит подключить бота к нашему сервису.
                    </AccordionItem>

                    <AccordionItem title='Как получить токен' number={2}>
                        Для получения токена уже существующего бота в Telegram напишите&nbsp;
                        <b><Link href="https://telegram.me/BotFather" target="_blank">@BotFather</Link></b>
                        &nbsp;команду <b>/token</b>, выберите нужного бота из списка и скопируйте из
                        ответного сообщения токен доступа в поле выше.
                    </AccordionItem>

                    <AccordionItem title='Как создать нового бота' number={1}>
                        Если у вас еще нет бота в Telegram, то вы можете создать его,
                        написав <Link href="https://telegram.me/BotFather" target="_blank">@BotFather</Link>{' '}
                        команду <b>/newbot</b> и следуя инструкциям. В последнем сообщении
                        от BotFather вы получите токен доступа к вашему боту, скопируйте его в поле выше.
                    </AccordionItem>

                </AccordionGroup>

                <Button type="submit" loading={isSending}>Подключить</Button>
            </Stack>
        </Form>
    );
}

const LoadingProjects = (): React.JSX.Element => {
    return (
        <div className="ProjectCreate__loader">
            <CircularProgress variant="soft" size="sm" color="primary" sx={{'--CircularProgress-size': '50px'}}/>
        </div>
    );
}