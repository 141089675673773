import * as React from "react";
import {useCallback, useMemo} from "react";
import {ModalBox} from "./ModalBox";
import {Box} from "@mui/joy";

type props = {
    title?: string;
    children: any;
    width?: number;
    open?: boolean; // Можно передать извне для управления открытием/закрытием
    setOpen?: (open: boolean) => void;
    onClose?: () => void;
    callClose?: () => void;
    startButton: React.JSX.Element;
}

export const ModalContent = (props: props): React.JSX.Element => {
    const [open, setOpen] = React.useState(false);
    const opened = useMemo(() => props.open === undefined ? open : props.open, [open, props.open]);

    const onClick = useCallback(() => {
        if (props.setOpen) {
            props.setOpen(true);
        } else {
            setOpen(true)
        }
    }, [props]);

    const onClose = useCallback(() => {
        if (props.setOpen) {
            props.setOpen(false);
        } else {
            setOpen(false);
        }
        props.onClose && props.onClose()
    }, [props])

    return (
        <React.Fragment>
            {React.cloneElement(props.startButton, {onClick: onClick})}
            <ModalBox open={opened} onClose={onClose} title={props.title} sx={{width: props.width}}>
                <Box>{props.children}</Box>
            </ModalBox>
        </React.Fragment>
    );
}
