import React, {FormEvent, useCallback, useState} from "react";
import {
    useInputErrors,
    validateAlphaNumeric,
    validateInput, validateMaxLength,
    validateMinLength,
    validateRequired
} from "../../Components/Form/helpers";
import {useProjectStore} from "../../Stores/ProjectStore";
import {toastSuccess} from "../../utils";
import {ApiClient} from "../../api-client";
import {ModalBox} from "../../Components/ModalBox";
import {Form} from "react-router-dom";
import Stack from "@mui/joy/Stack";
import {FormInput} from "../../Components/Form/FormInput";
import {Button} from "@mui/joy";

export const PromocodeModal = ({close}: { close: () => void }) => {
    const [promoCode, setPromoCode] = useState<string>('');
    const [inputErrors, setInputErrors] = useInputErrors();
    const [
        project,
        promoCodeUse,
        promoCodeUsing,
        fetchProjects
    ] = useProjectStore(state => [
        state.selectedProject,
        state.usePromoCode,
        state.promoCodeUsing,
        state.fetchProjects
    ]);

    const validators = useCallback(() => {
        return {
            promoCode: (value: any) => validateInput(
                "promoCode", value, setInputErrors,
                {func: validateRequired},
                {func: validateAlphaNumeric},
                {func: validateMinLength, threshold: 1},
                {func: validateMaxLength, threshold: 32}
            ),
        }
    }, [setInputErrors]);

    const send = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!project) {
            return;
        }

        const errors = [validators().promoCode(promoCode)].filter((error) => error !== null);

        if (errors.length > 0 || !project || !promoCode) {
            return;
        }

        promoCodeUse(project.id, promoCode).then(() => {
            fetchProjects();
            toastSuccess('Промокод был успешно активирован.');
            close();
        }).catch(error => ApiClient.setInputError(error, setInputErrors, 'promoCode'));
    }, [project, validators, promoCode, promoCodeUse, fetchProjects, close, setInputErrors]);

    return (
        <ModalBox open={true} onClose={close} title='Активация промокода' sx={{
            maxWidth: 400,
            minWidth: {sm: 300, md: 400},
        }}>
            <Form onSubmit={send}>
                <Stack spacing={2}>
                    <FormInput
                        type="text"
                        label="Промокод"
                        value={promoCode}
                        setValue={setPromoCode}
                        placeholder='Введите промокод..'
                        validate={validators().promoCode}
                        errorText={inputErrors.get("promoCode")}
                    />

                    <Stack spacing={1}>
                        <Button type='submit' loading={promoCodeUsing}>Активировать</Button>
                    </Stack>
                </Stack>
            </Form>
        </ModalBox>
    );
}
