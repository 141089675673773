import {create} from "zustand";
import {Account, API_METHOD, ApiResponseLogin, ApiResponseRegister, AUTH_COOKIE, PAGE} from "../types";
import {ApiClient} from "../api-client";
import {setCookie, toastSuccess} from "../utils";
import {useProjectStore} from "./ProjectStore";

interface AccountStore {
    loaded: boolean;
    account: Account;
    editModalOpened: boolean;
    updating: boolean;
    oneTapVKLoginProcessing:boolean;
    oneTapVKBindProcessing:boolean;

    openEditModal(): void;

    closeEditModal(): void;

    signIn(email: string, password: string): Promise<void>;

    loginOneTapVK(payload: any, redirectPage?: PAGE): Promise<void>;
    bindOneTapVK(payload: any, redirectPage?: PAGE): Promise<void>;

    register(name: string, email: string, password: string): Promise<void>;

    fetchMe(): Promise<void>;

    update(name: string, telegramUsername: string): Promise<void>;
}

export const useAccountStore = create<AccountStore>((set) => ({
    loaded: false,
    account: {} as Account,
    editModalOpened: false,
    updating: false,
    oneTapVKLoginProcessing: false,
    oneTapVKBindProcessing: false,

    openEditModal: () => set({editModalOpened: true}),
    closeEditModal: () => set({editModalOpened: false}),
    signIn(email: string, password: string) {
        return ApiClient.post(API_METHOD.ACCOUNT_LOGIN, {email, password}).then(r => {
            const data = r.data as ApiResponseLogin;

            useProjectStore.getState().setSelectedProject(undefined);
            setCookie(AUTH_COOKIE, data.token, {'max-age': 3600 * 24 * 365});

            if (data.projects > 0) {
                ApiClient.redirect(PAGE.MAIN);
            } else {
                ApiClient.redirect(PAGE.PROJECT_CREATE);
            }
        }).catch(ApiClient.toastError);
    },
    loginOneTapVK(payload: any, redirectPage?: PAGE) {
        set({oneTapVKLoginProcessing: true});
        return ApiClient.post(API_METHOD.ACCOUNT_LOGIN_ONE_TAP_VK, payload).then(r => {
            const data = r.data as ApiResponseLogin;

            useProjectStore.getState().setSelectedProject(undefined);
            setCookie(AUTH_COOKIE, data.token, {'max-age': 3600 * 24 * 365});

            if (redirectPage) {
                ApiClient.redirect(redirectPage);
            } else if  (data.projects > 0) {
                ApiClient.redirect(PAGE.MAIN);
            } else {
                ApiClient.redirect(PAGE.PROJECT_CREATE);
            }
        }).finally(() => set({oneTapVKLoginProcessing: true}));
    },
    bindOneTapVK(payload: any, redirectPage?: PAGE) {
        set({oneTapVKBindProcessing: true});

        return ApiClient.post(API_METHOD.ACCOUNT_BIND_ONE_TAP_VK, payload).then(r => {
            const data = r.data as ApiResponseLogin;

            if (redirectPage) {
                ApiClient.redirect(redirectPage);
            } else if (data.projects > 0) {
                ApiClient.redirect(PAGE.MAIN);
            } else {
                ApiClient.redirect(PAGE.PROJECT_CREATE);
            }
        }).finally(() => set({oneTapVKBindProcessing: true}));
    },
    register(name: string, email: string, password: string) {
        return ApiClient.post(API_METHOD.ACCOUNT_REGISTER, {name, email, password}).then(r => {
            const data = r.data as ApiResponseRegister;

            useProjectStore.getState().setSelectedProject(undefined);
            toastSuccess('Вы успешно зарегистрировались!');
            setCookie(AUTH_COOKIE, data.token, {'max-age': 3600 * 24 * 365});
            ApiClient.redirect(PAGE.MAIN);
        }).catch(ApiClient.toastError)
    },
    fetchMe: async () => {
        return ApiClient
            .get(API_METHOD.ACCOUNT_GET_ME)
            .then(r => set({account: r.data as Account, loaded: true}))
            .catch(ApiClient.toastError);
    },
    update: async (name: string, telegramUsername: string) => {
        const data = {name, telegramUsername};
        set({updating: true});

        return ApiClient.post(API_METHOD.ACCOUNT_UPDATE, data).then(r => {
            set({account: r.data as Account});
            toastSuccess('Данные профиля успешно сохранены.');
            useAccountStore.getState().closeEditModal();
        }).catch(ApiClient.toastError).finally(() => set({updating: false}));
    },
}));
