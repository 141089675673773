import {create} from "zustand";
import {API_METHOD, ProjectChannelUser, VKRefreshUsersStatus} from "../types";
import {ApiClient} from "../api-client";

interface UsersStore {
    loading: boolean;
    vkRefreshingList: boolean;
    loaded: boolean;
    list: ProjectChannelUser[];
    total: number;
    perPage: number;
    vkState?: VKRefreshUsersListStatus;

    load: (projectId: number, page: number) => Promise<void>;
    vkRefreshList: (projectId: number) => Promise<void>;
}

interface VKRefreshUsersListStatus {
    status: VKRefreshUsersStatus;
    setTime: string;
}

interface LoadUsersResponse {
    users: ProjectChannelUser[];
    total: number;
    perPage: number;
    vkState?: VKRefreshUsersListStatus;
}

export const useUsersStore = create<UsersStore>((set) => ({
    loading: false,
    loaded: false,
    list: [],
    vkState: undefined,
    vkRefreshingList: false,
    total: 0,
    perPage: 0,

    load: (projectId: number, page: number) => {
        set({loading: true, loaded: false});

        return ApiClient.get(API_METHOD.PROJECTS_USERS, {params: {projectId, page}}).then(r => {
            const response = r.data as LoadUsersResponse;
            set({
                list: response.users,
                total: response.total,
                perPage: response.perPage,
                vkState: response.vkState,
                loaded: true
            });
        }).catch(ApiClient.toastError).finally(() => set({loading: false}));
    },

    vkRefreshList: (projectId: number) => {
        set({vkRefreshingList: true});

        return ApiClient.post(API_METHOD.PROJECTS_VK_REFRESH_USERS_LIST, null, {params: {projectId}}).then(r => {
            console.log(r.data);
            set({vkState: r.data as VKRefreshUsersListStatus});
        }).catch(ApiClient.toastError).finally(() => set({vkRefreshingList: false}));
    }
}));
