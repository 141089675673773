import {useNavigate} from "react-router-dom";
import {useSchemePreparer} from "../Constructor/utils";
import {useBroadcastingStore} from "../Stores/BroadcastingStore";
import * as React from "react";
import {useCallback} from "react";
import {useScenarioUpdateStore} from "../Stores/ScenarioUpdateStore";
import {PAGE} from "../types";
import {CloseButton, ConstructorControlsContainer, CreateNodeButton} from "../Scenarios/ConstructorControls";
import {Button, Stack, Tooltip} from "@mui/joy";
import {ConstructorMode} from "../Constructor/Nodes/types";
import {BroadcastingStatus} from "./BroadcastingList";
import {Zap} from "react-feather";

export const BroadcastingConstructorControls = ({projectId, broadcastingId, broadcastingStatus}: {
    projectId: number,
    broadcastingId: number,
    broadcastingStatus: BroadcastingStatus,
}) => {
    const navigate = useNavigate();
    const prepareScheme = useSchemePreparer();
    const [save, saving] = useBroadcastingStore(state => [state.save, state.saving]);
    const isLocked = broadcastingStatus === BroadcastingStatus.Finished || broadcastingStatus === BroadcastingStatus.Error;

    const onSave = useCallback((afterSave: any = undefined) => {
        save(projectId, broadcastingId, prepareScheme(), afterSave).then((createdBroadcastingId) => {
            if (createdBroadcastingId === 0) {
                return; // Если не вернулся ID - значит не сохранилось (ошибка, например).
            }

            useScenarioUpdateStore.getState().clear();

            if (broadcastingId === 0) { // Если это было создание новой рассылки, то откроем её по ID.
                navigate(PAGE.BROADCASTING_EDIT.replace(':id', String(createdBroadcastingId)));
            }
        });
    }, [broadcastingId, navigate, prepareScheme, projectId, save]);

    return (
        <ConstructorControlsContainer>
            <Stack spacing={1} direction="row" alignItems="center">
                <Button
                    className="noselect"
                    size="sm"
                    onClick={onSave}
                    color="primary"
                    variant="soft"
                    loading={saving}
                    disabled={isLocked}
                >
                    Сохранить
                </Button>

                <CreateNodeButton mode={ConstructorMode.Broadcasting} disabled={isLocked}/>
                {/*<StatShowButton projectId={props.projectId} scenarioId={scenario.id}/>*/}

                <TestButton
                    projectId={projectId}
                    broadcastingId={broadcastingId}
                    broadcastingStatus={broadcastingStatus}
                />

                <CloseButton onSave={onSave} mode={ConstructorMode.Broadcasting}/>
            </Stack>
        </ConstructorControlsContainer>
    );
}

const TestButton = ({projectId, broadcastingId, broadcastingStatus}: {
    projectId: number,
    broadcastingId: number,
    broadcastingStatus: BroadcastingStatus
}): React.JSX.Element => {
    const [testBroadcasting, testing] = useBroadcastingStore(state => [state.test, state.testing]);

    if (!broadcastingId || broadcastingId <= 0) {
        return <></>
    }

    return (
        <Tooltip title="Тестовый запуск рассылки" color={"neutral"} arrow={true} placement="bottom-end">
            <Button
                className="noselect"
                size="sm"
                onClick={() => {
                    if (testing) {
                        return;
                    }

                    testBroadcasting(projectId, broadcastingId);
                }}
                color='neutral'
                variant='soft'
                disabled={broadcastingStatus !== BroadcastingStatus.Created}
                loading={testing}
            >
                <Zap size={20}/>
            </Button>
        </Tooltip>
    );
}