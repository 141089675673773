import {Box, Grid, Sheet, Typography} from "@mui/joy";
import React, {useEffect} from "react";
import {Item} from "./Item";
import {useChannelsStore} from "../../Stores/ChannelsStore";
import Skeleton from "react-loading-skeleton";
import {useProjectStore} from "../../Stores/ProjectStore";

export const Channels = () => {
    const project = useProjectStore((state => state.selectedProject));
    const [channels, loaded] = useChannelsStore((state => [state.channels, state.loaded]));

    useEffect(() => {
        if (project) {
            useChannelsStore.getState().fetchChannels(project.id);
        }
    }, [project]);

    return (
        <Grid xs={12} md={12} lg={8} sx={{mt: 2}}>
            <Box sx={{mb: 2}}>
                <Typography level="title-lg">Подключенный канал</Typography>
            </Box>

            <Sheet variant="outlined" sx={{borderRadius: 'sm', p: 2}}>
                {(!loaded || !project) && <Skeleton height={36}/>}

                {loaded && project && channels && channels.map((channel) => {
                    return <Item key={channel.id} channel={channel} projectType={project.type}/>
                })}
            </Sheet>
        </Grid>
    )
}