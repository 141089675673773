import Typography from "@mui/joy/Typography";
import {Button, Stack} from "@mui/joy";
import * as React from "react";
import {useScenariosListStore} from "../../Stores/ScenariosListStore";
import {ArrowForward} from "@mui/icons-material";
import {useScenarioTemplatesStore} from "../../Stores/ScenarioTemplatesStore";

export const StartScreen = () => {
    const [isLoading, scenarios] = useScenariosListStore(state => [state.isLoading, state.list]);
    const [fetchTemplates, templatesLoading] = useScenarioTemplatesStore(state => [state.fetch, state.loading])

    if (isLoading || scenarios.length > 0) {
        return <></>
    }

    return (
        <Stack
            flexDirection={{sm: 'row', xs: 'column'}}
            gap={3}
            maxWidth={'700px'}
            margin={'auto'}
            mt={{
                xl: '15%',
                lg: '17%',
                md: '25%',
                sm: '25%',
                xs: '10%'
            }}
        >
            <img height={220} src="/images/illustrations/marketing_2.svg" alt=""/>
            <Stack gap={2} alignItems={{sm: 'inherit', xs: 'center'}} sx={{px: {sm: 'inherit', xs: 2}}}>
                <Typography level="h4">
                    Создайте ваш первый сценарий
                </Typography>

                <Typography level="title-sm" textColor="text.secondary">
                    Сценарий - это список действий, которые бот выполнит в ответ на сообщение пользователя.
                </Typography>

                <Typography level="title-sm" textColor="text.secondary">
                    Вы можете создать несколько сценариев, которые запускаются в разных случаях, в зависимости от
                    сообщения или действия пользователя.
                </Typography>

                <Stack>

                    <Button
                        size="md"
                        endDecorator={<ArrowForward fontSize="medium"/>}
                        onClick={fetchTemplates}
                        loading={templatesLoading}
                        sx={{mt: 1, maxWidth: 'fit-content'}}
                    >
                        Создать сценарий
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
}
