import 'react-loading-skeleton/dist/skeleton.css'
import Box from '@mui/joy/Box';
import {Sidebar} from "./Sidebar";
import {useNavigate} from "react-router-dom";
import * as React from "react";
import {useEffect} from "react";
import {getCookie} from "../utils";
import {AUTH_COOKIE, PAGE} from "../types";
import Header from "./Header";
import {useProjectStore} from "../Stores/ProjectStore";
import {AppContainer} from "../Components/AppContainer";

type props = {
    children: any;
}

export const Dash = (props: props) => {
    const navigate = useNavigate();
    const [projects, projectsLoaded] = useProjectStore(state => [
        state.projects,
        state.projectsLoaded
    ]);

    useEffect(() => {
        if (getCookie(AUTH_COOKIE) === undefined) {
            navigate(PAGE.SIGN_IN); // Если не авторизован, то внутрь не пускаем.
            return;
        }
    }, [navigate]);

    useEffect(() => {
        if (projectsLoaded && projects.length === 0) {
            navigate(PAGE.PROJECT_CREATE);
            return;
        }
    }, [navigate, projects, projectsLoaded]);

    return (
        <AppContainer>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <Sidebar/>
                {props.children}
            </Box>
        </AppContainer>
    )
}
