import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {Avatar, Tooltip} from "@mui/joy";
import {ProjectType} from "../types";
import Typography from "@mui/joy/Typography";
import Skeleton from "react-loading-skeleton";
import Stack from "@mui/joy/Stack";
import {useProjectStore} from "../Stores/ProjectStore";
import {ProjectsModal} from "./ProjectsModal";
import Button from "@mui/joy/Button";
import {KeyboardArrowRight} from "@mui/icons-material";
import Divider from "@mui/joy/Divider";

export const SidebarHeader = () => {
    const [loading, setLoading] = useState(false);
    const [projectsOpened, setProjectsOpened] = useState(false);

    const [projects, selectedProject, fetchProjects] = useProjectStore(state => [
        state.projects,
        state.selectedProject,
        state.fetchProjects
    ]);

    useEffect(() => {
        setLoading(true);
        fetchProjects().finally(() => setLoading(false));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (useProjectStore.getState().projectsLoaded && projects.length > 0 && !selectedProject) {
            useProjectStore.getState().setSelectedProject(projects[0]);
        }
    }, [projects, selectedProject])

    const ProjectName = () => {
        if (loading || !selectedProject) {
            return (
                <Skeleton height={17}/>
            );
        }

        return (
            <>
                {selectedProject?.name}
            </>
        );
    };

    const logoImage = useMemo(() => {
        if (!selectedProject || selectedProject.type === ProjectType.Telegram) {
            return '/logo.png';
        }

        return selectedProject.photo.length > 0 ? selectedProject.photo : '/logo.png';
    }, [selectedProject]);

    return (
        <>
            {/*<Stack direction="row" justifyContent='center' mt={-1} mb={1} width={'100%'}>*/}
            {/*    <Typography level="title-lg" fontWeight="lg" textAlign='justify' textColor='neutral.400'>Chatgun.io</Typography>*/}
            {/*</Stack>*/}

            <Tooltip title="Переключить проект" color={"neutral"} arrow={true} placement="right">
                <Button
                    variant="soft"
                    color="neutral"
                    endDecorator={<KeyboardArrowRight color="disabled"/>}
                    sx={{px: 1.25, py: 0.75, mb: -0.50, mt: -1.5, overflow: 'hidden', justifyContent: 'space-between'}}
                    onClick={() => setProjectsOpened(true)}
                >
                    <Stack direction="row" gap={0.75} alignItems='center' width={'80%'} textOverflow='ellipsis'>
                        <Avatar
                            sx={{mr: 0.25}}
                            variant="outlined"
                            size="sm"
                            src={logoImage}
                        />
                        <Typography level="title-sm" noWrap>
                            <ProjectName/>
                        </Typography>
                    </Stack>
                </Button>
            </Tooltip>
            <Divider sx={{mb: -1.5}}/>


            {projectsOpened && <ProjectsModal opened={projectsOpened} close={() => setProjectsOpened(false)}/>}
        </>
    );
}
