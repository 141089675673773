import React, {useEffect} from "react";
import {DashContainer} from "../Dash/DashContainer";
import {useProjectStore} from "../Stores/ProjectStore";
import {Box} from "@mui/material";
import CircularProgress from "@mui/joy/CircularProgress";
import {useBroadcastingListStore} from "../Stores/BroadcastingListStore";
import {Broadcasting, PAGE} from "../types";
import {Button, Chip, Grid, Sheet, Stack, Tooltip} from "@mui/joy";
import styles from "../Scenarios/List/ScenariosList.module.css";
import Typography from "@mui/joy/Typography";
import {useNavigate} from "react-router-dom";
import {Copy, Edit3, Eye, Send, Trash, Zap} from "react-feather";
import {ConfirmModal} from "../Components/ConfirmModal";
import {useBroadcastingStore} from "../Stores/BroadcastingStore";
import {OverridableStringUnion} from "@mui/types";
import {ColorPaletteProp} from "@mui/joy/styles/types";
import {ChipPropsColorOverrides} from "@mui/joy/Chip/ChipProps";
import moment from "moment/moment";
import {BroadcastingStartRule} from "../Constructor/Nodes/BroadcastingStartNode/BroadcastingStartNode";
import {numeralsLabel} from "../utils";
import {StartScreen} from "./StartScreen";
import classNames from "classnames";

export enum BroadcastingStatus {
    Created = 1,
    Pending = 2,
    Started = 3,
    Finished = 4,
    Error = 5,
}

export const BroadcastingList = () => {
    const [project] = useProjectStore(state => [state.selectedProject]);
    const [list, load, loaded] = useBroadcastingListStore(state => [state.list, state.load, state.loaded]);

    useEffect(() => {
        if (!project) {
            return;
        }

        load(project.id);
    }, [load, project]);

    if (!project) {
        return <></>
    }

    return (
        <DashContainer breadcrumbsTitle='Рассылки' rightHeader={list.length > 0 ? <CreateButton/> : <></>}>
            <Loading/>
            <StartScreen/>
            {loaded && list.map((b) => <BroadcastingItem key={b.id} broadcasting={b}/>)}
        </DashContainer>
    );
};

const BroadcastingItem = ({broadcasting}: { broadcasting: Broadcasting }) => {
    return (
        <Grid xs={12} md={12} lg={12} xl={6}>
            <Sheet variant="outlined" sx={{borderRadius: 'sm', height: '100%'}} className={styles.itemContainer}>
                <Box sx={{gap: 2, p: 2}}>
                    <Stack direction="column" gap={1}>
                        <Stack direction="row" alignItems="center" gap={1.5}>
                            <Status broadcasting={broadcasting}/>

                            <Box sx={{minWidth: 0, flex: 1}} className={styles.itemKeysContainer}>
                                <Typography level={'title-sm'}>{broadcasting.name}</Typography>
                            </Box>

                            <Stack direction="row" alignItems="center" gap={0.5}>
                                <StartButton broadcasting={broadcasting}/>
                                <TestButton broadcasting={broadcasting}/>
                                <EditButton broadcasting={broadcasting}/>
                                <CopyButton broadcasting={broadcasting}/>
                                <RemoveButton broadcasting={broadcasting}/>
                            </Stack>
                        </Stack>

                        <Stack direction="column" gap={0.5}>
                            <StartInfo broadcasting={broadcasting}/>
                            <AudienceInfo broadcasting={broadcasting}/>
                        </Stack>
                    </Stack>
                </Box>
            </Sheet>
        </Grid>
    );
}


const AudienceInfo = ({broadcasting}: { broadcasting: Broadcasting }) => {
    return (
        <Box>
            <Stack direction="row" alignItems="baseline" gap={0.5}>
                <Typography level={'title-md'}>{broadcasting.audience}</Typography>
                <Typography level={'body-sm'}>
                    {numeralsLabel(broadcasting.audience, 'пользователь', 'пользователя', 'пользователей')}
                    {' '}в рассылке
                </Typography>
            </Stack>
        </Box>
    );
}

const StartInfo = ({broadcasting}: { broadcasting: Broadcasting }) => {
    const startTime = moment(broadcasting.status === BroadcastingStatus.Created ? broadcasting.schedule : broadcasting.startAt);
    const scheduledDatetime = startTime.format('DD.MM.YYYY в HH:mm');
    const showTime = broadcasting.startRule === BroadcastingStartRule.Time || broadcasting.status !== BroadcastingStatus.Created;

    if (broadcasting.status === BroadcastingStatus.Pending) {
        return (
            <Typography level={'body-sm'}>
                В очереди на отправку
            </Typography>
        );
    }

    if (broadcasting.status === BroadcastingStatus.Started) {
        return (
            <Typography level={'body-sm'}>
                В процессе отправки
            </Typography>
        );
    }

    if (broadcasting.status === BroadcastingStatus.Error) {
        return (
            <Typography level={'body-sm'}>
                Рассылка не отправлена, переподключите каналы и убедитесь, что оплачена подписка
            </Typography>
        );
    }

    return (
        <div>
            {showTime &&
                <Tooltip
                    title={`${scheduledDatetime} (GMT +3)`}
                    color={"neutral"}
                    arrow={true}
                    placement="top-start"
                >
                    <Typography level={'body-sm'} sx={{cursor: 'pointer'}}>
                        {broadcasting.status === BroadcastingStatus.Created && 'Будет запущена'}
                        {broadcasting.status === BroadcastingStatus.Finished && 'Была отправлена'}
                        {' '}{startTime.fromNow()}
                    </Typography>
                </Tooltip>
            }

            {broadcasting.startRule === BroadcastingStartRule.Manually && !showTime &&
                <Typography level={'body-sm'}>Время запуска не задано, запуск возможен вручную</Typography>
            }
        </div>
    );
}

const EditButton = ({broadcasting}: { broadcasting: Broadcasting }) => {
    const navigate = useNavigate();
    const isFinished = broadcasting.status === BroadcastingStatus.Finished || broadcasting.status === BroadcastingStatus.Error;
    const desc = isFinished ? 'Посмотреть рассылку' : 'Редактировать рассылку';

    const onClick = () => {
        navigate(PAGE.BROADCASTING_EDIT.replace(':id', String(broadcasting.id)))
    };

    return (
        <Tooltip title={desc} color={"neutral"} arrow={true} placement="top-end">
             <span>
                 {!isFinished && <Edit3 onClick={onClick} className={styles.editButton} size={20}/>}
                 {isFinished &&
                     <Eye onClick={onClick} className={classNames(styles.viewIcon, styles.editButton)} size={20}/>}
            </span>
        </Tooltip>
    );
};

const RemoveButton = ({broadcasting}: { broadcasting: Broadcasting }) => {
    const [deleteBroadcasting] = useBroadcastingStore(state => [state.delete]);
    const [load] = useBroadcastingListStore(state => [state.load]);

    if (
        broadcasting.status !== BroadcastingStatus.Created &&
        broadcasting.status !== BroadcastingStatus.Finished &&
        broadcasting.status !== BroadcastingStatus.Error
    ) {
        return <></>;
    }

    return (
        <ConfirmModal
            onConfirm={() => deleteBroadcasting(broadcasting.projectId, broadcasting.id).then(() => load(broadcasting.projectId))}
            acceptTitle="Удалить"
            description="Вы уверены, что хотите удалить эту рассылку?"
        >
            <Tooltip title="Удалить рассылку" color={"neutral"} arrow={true} placement="top-end">
                <Trash className={styles.removeButton} size={20}/>
            </Tooltip>
        </ConfirmModal>
    );
};

const StartButton = ({broadcasting}: { broadcasting: Broadcasting }) => {
    const [startBroadcasting] = useBroadcastingStore(state => [state.start]);
    const [load] = useBroadcastingListStore(state => [state.load]);

    if (broadcasting.status !== BroadcastingStatus.Created || broadcasting.startRule !== BroadcastingStartRule.Manually) {
        return <></>
    }

    return (
        <ConfirmModal
            onConfirm={() => startBroadcasting(broadcasting.projectId, broadcasting.id).then(() => load(broadcasting.projectId))}
            acceptTitle="Запустить"
            acceptColor="primary"
            description="Вы уверены, что хотите запустить эту рассылку прямо сейчас?"
        >
            <Tooltip title="Запустить рассылку" color={"neutral"} arrow={true} placement="top-end">
                <Send className={styles.startButton} size={20}/>
            </Tooltip>
        </ConfirmModal>
    );
};

const TestButton = ({broadcasting}: { broadcasting: Broadcasting }) => {
    const [testBroadcasting] = useBroadcastingStore(state => [state.test]);

    if (broadcasting.status !== BroadcastingStatus.Created) {
        return <></>
    }

    return (
        <Tooltip title="Тестировать рассылку" color={"neutral"} arrow={true} placement="top-end">
            <Zap className={styles.startButton} size={20}
                 onClick={() => testBroadcasting(broadcasting.projectId, broadcasting.id)}/>
        </Tooltip>
    );
};

const CopyButton = ({broadcasting}: { broadcasting: Broadcasting }) => {
    const navigate = useNavigate();
    const [copyBroadcasting, isCopying] = useBroadcastingStore(state => [state.copy, state.copying]);

    const onConfirm = () => {
        if (isCopying) {
            return;
        }

        copyBroadcasting(broadcasting.projectId, broadcasting.id).then(createdBroadcastingId => {
            if (Number(createdBroadcastingId) > 0) {
                navigate(PAGE.BROADCASTING_EDIT.replace(':id', String(createdBroadcastingId)));
            }
        })
    };

    return (
        <ConfirmModal
            onConfirm={onConfirm}
            acceptTitle="Создать копию"
            acceptColor="primary"
            description="Создать копию выбранной рассылки?"
        >
            <Tooltip title="Скопировать рассылку" color={"neutral"} arrow={true} placement="top-end">
                <Copy className={styles.startButton} size={20}/>
            </Tooltip>
        </ConfirmModal>
    );
};


const Status = ({broadcasting}: { broadcasting: Broadcasting }) => {
    let title = '';
    let color: OverridableStringUnion<ColorPaletteProp, ChipPropsColorOverrides> = 'neutral';

    switch (broadcasting.status) {
        case BroadcastingStatus.Created:
            title = 'Ожидает запуска';
            color = 'neutral'
            break;

        case BroadcastingStatus.Pending:
            title = 'Ожидает старта';
            color = 'success'
            break;

        case BroadcastingStatus.Started:
            title = 'Идет отправка';
            color = 'warning'
            break;


        case BroadcastingStatus.Finished:
            title = 'Отправлена';
            color = 'primary'
            break;

        case BroadcastingStatus.Error:
            title = 'Ошибка отправки';
            color = 'danger'
            break;
    }

    return (
        <Chip
            size="sm"
            color={color}
            variant={"soft"}
            sx={{borderRadius: 5}}
        >
            {title}
        </Chip>
    )
}

const CreateButton = () => {
    const navigate = useNavigate();

    return (
        <Button
            color="primary"
            size="sm"
            onClick={() => navigate(PAGE.BROADCASTING_CREATE)}
        >
            Создать рассылку
        </Button>
    );
};

const Loading = (): React.JSX.Element => {
    const [loading, loaded] = useBroadcastingListStore(state => [state.loading, state.loaded]);

    if (!loading || loaded) {
        return <></>
    }

    return (
        <Box sx={{margin: 'auto', textAlign: 'center', mt: {lg: '20%', md: '25%', sm: '30%', xs: '35%'}}}>
            <CircularProgress variant="soft" size="lg" color="primary" sx={{'--CircularProgress-size': '70px'}}/>
        </Box>
    );
}
