import MenuItem from "@mui/joy/MenuItem";
import {KeyMode} from "../types";
import Menu from "@mui/joy/Menu";
import React, {MouseEvent, useCallback} from "react";
import {useScenariosPreviewStore} from "../../../Stores/ScenarioPreviewStore";

type props = {
    children: React.ReactNode;
    setMode: (mode: KeyMode) => void;
}

export const ModeMenu = (props: props) => {
    const [modeAnchorEl, setModeAnchorEl] = React.useState(null);
    const modeMenuOpen = Boolean(modeAnchorEl);
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    const handleCloseModeMenu = () => setModeAnchorEl(null);
    const handleClickModeMenu = (event: any) => {
        if (isPreviewMode) {
            return;
        }

        setModeAnchorEl(event.currentTarget);
    }

    const onChangeMode = useCallback((event: MouseEvent<HTMLDivElement>, mode: number) => {
        props.setMode(mode);
        handleCloseModeMenu();
    }, [props]);

    return (
        <>
            <Menu
                id="projects-menu"
                anchorEl={modeAnchorEl}
                open={modeMenuOpen}
                size="md"
                onClose={handleCloseModeMenu}
                placement="bottom-start"
                aria-labelledby="projects-button"
            >
                <MenuItem key={1} onClick={(event) => onChangeMode(event, KeyMode.Strict)}>
                    Строго равно
                </MenuItem>
                <MenuItem key={2} onClick={(event) => onChangeMode(event, KeyMode.Soft)}>
                    Содержит
                </MenuItem>
            </Menu>
            <span onClick={handleClickModeMenu}>
                {props.children}
            </span>
        </>
    )
}