import React, {Dispatch, SetStateAction, useCallback, useMemo} from "react";
import {ButtonType, getMaxRowButtons, KeyboardRows} from "./types";
import {createButtonObj} from "./Keyboard";
import {useScenariosPreviewStore} from "../../../../Stores/ScenarioPreviewStore";
import IconButton from "@mui/joy/IconButton";
import {Plus} from "react-feather";
import {useConstructorProjectType} from "../../../utils";

type props = {
    rowId: string;
    rowLength: number;
    keyboard: KeyboardRows;
    setKeyboard: Dispatch<SetStateAction<KeyboardRows>>;
}

/**
 * Добавление новой кнопки в ряд
 */
export const AddButtonToRowButton = (props: props): React.JSX.Element => {
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const projectType = useConstructorProjectType();
    const maxRowButtons = useMemo(() => getMaxRowButtons(projectType), [projectType]);

    const addButton = useCallback(() => {
        const rows = props.keyboard.map((row) => {
            if (row.id === props.rowId && row.buttons.length < maxRowButtons) { // Максимум 8 кнопок в ряду.
                row.buttons.push(createButtonObj(ButtonType.COMMON));
            }

            return row;
        });

        props.setKeyboard([...rows]);
    }, [maxRowButtons, props]);

    if (isPreviewMode) {
        return <></>
    }

    return (
        <IconButton
            color="neutral"
            variant="plain"
            className="MessageNodeKeyboardRow__addButton"
            disabled={props.rowLength >= maxRowButtons}
            onClick={addButton}
            sx={{ml: '5px'}}
        >
            <Plus color={"var(--joy-palette-neutral-300)"} size={16}/>
        </IconButton>
    )
}
