import {Avatar, Box, Button, Link, Typography} from "@mui/joy";
import React, {useCallback, useState} from "react";
import {Channel, PAGE, ProjectType} from "../../types";
import {useChannelsStore} from "../../Stores/ChannelsStore";
import {RefreshTokenTelegram} from "./RefreshTokenTelegram";
import Stack from "@mui/joy/Stack";
import {groupConnectAuthUrl, pageLink} from "../../utils";
import {useVKStore} from "../../Stores/VKStore";

export const Item = (props: { channel: Channel, projectType: ProjectType }) => {
    const [isReconnecting, setReconnecting] = React.useState(false);
    const [reconnectChannelTelegram] = useChannelsStore(state => [state.reconnectChannelTelegram])
    const [reconnectVKCallbackServer] = useVKStore(state => [state.reconnectCallbackServer])

    const onReconnect = useCallback(() => {
        setReconnecting(true);
        if (props.projectType === ProjectType.Telegram) {
            reconnectChannelTelegram(props.channel.id, props.channel.projectId).then(() => setReconnecting(false));
        } else if (props.projectType === ProjectType.VK) {
            reconnectVKCallbackServer(props.channel.id, props.channel.projectId).then(() => setReconnecting(false))
        }
    }, [props.channel.id, props.channel.projectId, props.projectType, reconnectChannelTelegram, reconnectVKCallbackServer]);

    const refreshTokenVK = useCallback(() => {
        window.open(groupConnectAuthUrl(props.channel.id, pageLink(PAGE.RECONNECT_VK_GROUP)), '_blank')?.focus();
    }, [props.channel.id])

    const [channelLink] = useState({
        [ProjectType.VK]: "https://vk.com/" + props.channel.username,
        [ProjectType.Telegram]: "https://telegram.me/" + props.channel.username
    }[props.projectType]);

    const [avatarSrc] = useState({
        [ProjectType.VK]: "/images/logo_vk.svg",
        [ProjectType.Telegram]: "/images/logo_telegram.svg"
    }[props.projectType])

    return (
        <Stack direction={{xs: "column", md: "row"}} justifyContent="space-between" spacing={2}>
            <Stack direction="row" spacing={2} alignItems='center' justifyContent="center">
                <Avatar src={avatarSrc} size="sm"/>

                <Box sx={{minWidth: 0}}>
                    <Typography fontSize="sm" noWrap>{props.channel.name}</Typography>
                    <Typography level="body-xs" noWrap>
                        <Link href={channelLink} target="_blank">
                            {props.channel.username}
                        </Link>
                    </Typography>
                </Box>
            </Stack>

            <Stack direction={{xs: "column", md: "row"}} spacing={1} alignItems={{xs: "stretch", md: "center"}}>
                {props.projectType === ProjectType.Telegram &&
                    <RefreshTokenTelegram channel={props.channel}/>
                }

                {props.projectType === ProjectType.VK &&
                    <Button variant="soft" size="sm" onClick={refreshTokenVK}>Обновить токен</Button>
                }

                <Button variant="soft" size="sm" loading={isReconnecting} onClick={onReconnect}>Переподключить</Button>
            </Stack>
        </Stack>

    );
}