import '@fontsource/inter/cyrillic.css';
import '@fontsource/inter/latin.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    RouterProvider,
    useLocation,
    useNavigationType
} from "react-router-dom";
import {RouteErrorBoundary} from "./Errors/RouteErrorBoundary";
import {ScenarioCreate, ScenarioEdit} from "./Scenarios/Scenarios";
import {SignUp} from "./Auth/SignUp";
import {SignIn} from "./Auth/SignIn";
import {ForgotPassword} from "./Auth/ForgotPassword";
import {ProjectCreate} from "./Project/ProjectCreate";
import {Dash} from "./Dash/Dash";
import {Home} from "./Home/Home";
import {Charts} from "./Charts/Charts";
import moment from "moment/moment";
import 'moment-timezone';
import "moment/locale/ru";
import {PAGE} from "./types";
import {Employees} from "./Employees/Employees";
import {ApiClient} from "./api-client";
import {ToastContainer} from "react-toastify";
import {ScenarioPreview} from "./Scenarios/ScenarioPreview";
import {BroadcastingList} from "./Broadcasting/BroadcastingList";
import {ScenariosList} from "./Scenarios/List/ScenariosList";
import {BroadcastingCreate} from "./Broadcasting/BroadcastingCreate";
import {BroadcastingEdit} from "./Broadcasting/BroadcastingEdit";
import {ProjectUsers} from "./Users/Users";
import {VKConnect} from "./Auth/VKConnect";
import {VKConnectGroup} from "./Project/VKConnectGroup";
import {WizardVK} from "./WizardVK/WizardVK";
import * as Sentry from "@sentry/react";
import {BrokenComponent} from "./Home/BrokenComponent";
import {VKMiniApp} from "./VKApp/VKMiniApp";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

Sentry.init({
    dsn: String(process.env.REACT_APP_SENTRY_DSN),
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV7BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.25, //  Capture 25% of the transactions
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

const router = sentryCreateBrowserRouter([
    {
        path: "/",
        element: <Dash><Home/></Dash>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.SCENARIOS,
        element: <Dash><ScenariosList/></Dash>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.SCENARIO_EDIT,
        element: <Dash><ScenarioEdit/></Dash>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.SCENARIO_CREATE,
        element: <Dash><ScenarioCreate/></Dash>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: "/scenario-preview/:uuid",
        element: <ScenarioPreview/>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.BROADCASTING,
        element: <Dash><BroadcastingList/></Dash>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.BROADCASTING_CREATE,
        element: <Dash><BroadcastingCreate/></Dash>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.BROADCASTING_EDIT,
        element: <Dash><BroadcastingEdit/></Dash>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.CHARTS,
        element: <Dash><Charts/></Dash>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.EMPLOYEES,
        element: <Dash><Employees/></Dash>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.USERS,
        element: <Dash><ProjectUsers/></Dash>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.PROJECT_CREATE,
        element: <ProjectCreate/>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.SIGN_UP,
        element: <SignUp/>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.SIGN_IN,
        element: <SignIn/>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.SIGN_IN_VK,
        element: <VKConnect/>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: 'vk-mini-app',
        element: <VKMiniApp/>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.WIZARD_SIGN_IN_VK,
        element: <VKConnect redirectPage={PAGE.WIZARD_VK}/>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.WIZARD_CONNECT_VK_GROUP,
        element: <VKConnectGroup
            redirectPage={PAGE.WIZARD_CONNECT_VK_GROUP}
            backPage={PAGE.WIZARD_VK}
            successPage={PAGE.WIZARD_VK}
        />,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.CONNECT_VK_GROUP,
        element: <VKConnectGroup
            redirectPage={PAGE.CONNECT_VK_GROUP}
            backPage={PAGE.PROJECT_CREATE}
            successPage={PAGE.MAIN}
        />,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.RECONNECT_VK_GROUP,
        element: <VKConnectGroup
            redirectPage={PAGE.RECONNECT_VK_GROUP}
            isReconnect={true}
            backPage={PAGE.MAIN}
            successPage={PAGE.MAIN}
        />,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: PAGE.WIZARD_VK,
        element: <WizardVK/>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: "/restore-password",
        element: <ForgotPassword/>,
        errorElement: <RouteErrorBoundary/>,
    },
    {
        path: "/throw-error",
        element: <BrokenComponent/>,
        errorElement: <RouteErrorBoundary/>,
    }
]);


moment.locale('ru');
moment.tz.setDefault('Europe/Moscow');
ApiClient.setNavigate(router.navigate);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
        <ToastContainer
            position={"bottom-right"}
            hideProgressBar={true}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            theme={"light"}
        />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
