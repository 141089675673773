import {create} from "zustand";
import {API_METHOD, Employee} from "../types";
import {ApiClient} from "../api-client";
import {toastSuccess} from "../utils";
import {useAccountStore} from "./AccountStore";

interface EmployeesStore {
    employees: Employee[];

    fetch(projectId: number): Promise<void>;

    create(projectId: number, email: string, closeModal: () => void): Promise<void>;

    remove(projectId: number, employeeUserId: number): Promise<void>;
}

export const useEmployeesStore = create<EmployeesStore>((set) => ({
    employees: [],

    fetch: (projectId: number) => {
        return ApiClient.get(API_METHOD.PROJECT_EMPLOYEES, {params: {projectId}})
            .then(r => set({employees: r.data as Employee[]}))
            .catch(ApiClient.toastError);
    },

    create(projectId: number, email: string, closeModal: () => void) {
        return ApiClient.post(API_METHOD.PROJECT_EMPLOYEE_ADD, {email}, {params: {projectId}}).then(_ => {
            toastSuccess('Сотрудник успешно добавлен в проект.');
            this.fetch(projectId);
            closeModal();
        }).catch(ApiClient.toastError);
    },

    remove: (projectId: number, employeeUserId: number) => {
        return ApiClient.post(API_METHOD.PROJECT_EMPLOYEE_REMOVE, {employeeUserId}, {params: {projectId}}).then(_ => {
            toastSuccess('Сотрудник успешно удален из проекта.');

            if (employeeUserId === useAccountStore.getState().account.id) {
                window.location.reload(); // Если удаляем самого себя то нужно полностью перезагрузить страницу.
            } else {
                useEmployeesStore.getState().fetch(projectId);
            }
        }).catch(ApiClient.toastError);
    }
}));
