import * as React from 'react';
import Typography from '@mui/joy/Typography';
import {AuthContainer} from '../Auth/AuthContainer';
import Button from "@mui/joy/Button";
import {ArrowForward} from "@mui/icons-material";
import {PAGE} from "../types";
import {isRouteErrorResponse, useNavigate, useRouteError} from "react-router-dom";
import Stack from "@mui/joy/Stack";
import {Sheet} from "@mui/joy";
import * as Sentry from "@sentry/react";

export const RouteErrorBoundary = () => {
    const navigate = useNavigate();
    const error = useRouteError();

    const errorStatus = isRouteErrorResponse(error) ? error.status : undefined;
    let errorMessage = isRouteErrorResponse(error) ? error.statusText : undefined;
    let errorStack = undefined;
    const is404 = errorStatus && errorStatus === 404;
    const image = is404 ? 'illustration_404.svg' : 'maintance.svg';

    if (error instanceof Error) {
        errorMessage = error.message;
        errorStack = error.stack;
    }

    React.useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return (
        <AuthContainer hideHeader width={450}>
            <img height={220} src={"/images/illustrations/" + image} alt=""/>

            <Typography level="body-sm" textAlign="center" textColor="neutral.500" sx={{px: 5}}>
                {is404 && <>
                    Страница, которую вы попытались открыть не найдена,
                    попробуйте еще раз позднее или обратитесь в поддержку.
                </>}

                {!is404 && <>
                    Во время работы сайта возникла непредвиденная ошибка,
                    попробуйте еще раз позднее или обратитесь в поддержку.
                </>}
            </Typography>

            {(!is404 && (errorMessage || errorStack)) &&
                <Sheet color="neutral" variant="soft" sx={{mx: 2, px: 3, py: 2, borderRadius: 8}}>
                    {errorMessage &&
                        <Typography textAlign="center" level="body-xs" sx={{wordBreak: 'break-word'}}>
                            {errorMessage}
                        </Typography>
                    }

                    {(errorStack && false) &&
                        <Typography level="body-xs" sx={{wordBreak: 'break-word'}}>
                            {errorStack}
                        </Typography>
                    }
                </Sheet>
            }

            <Stack direction='row' justifyContent='center' sx={{mt: 1}}>
                <Button
                    size="md"
                    variant="soft"
                    endDecorator={<ArrowForward fontSize="small"/>}
                    onClick={() => navigate(PAGE.MAIN)}
                >
                    Вернуться на главную
                </Button>
            </Stack>
        </AuthContainer>
    );
}
