import {create} from "zustand";
import {API_METHOD, Scenario, ScenariosMetrics} from "../types";
import {ApiClient} from "../api-client";

interface ScenariosListStore {
    list: Scenario[]; // Список сценариев для выбранного проекта.
    statUniqs: ScenariosMetrics; // Данные для вывода статистики в графиках на странице со списком сценариев.
    isLoading: boolean; // Идет ли загрузка списка сценариев.
    loaded: boolean; // Загружен ли список сценариев.

    fetchScenarios: (projectId: number) => Promise<Scenario[]>;
    fetchScenariosUniqs: (projectId: number, scenariosIds: number[]) => void;
    setScenariosList: (list: Scenario[]) => void;
}

export const useScenariosListStore = create<ScenariosListStore>((set) => ({
    list: [],
    statUniqs: {dates: [], scenarios: []},
    isLoading: false,
    loaded: false,

    /**
     * Получение списка сценариев для текущего проекта.
     */
    fetchScenarios: async (projectId: number): Promise<Scenario[]> => {
        set({isLoading: true, list: []});

        return ApiClient.get(API_METHOD.SCENARIOS, {params: {projectId: projectId}}).then(r => {
            set({list: r.data as Scenario[]});
            return r.data as Scenario[]
        }).finally(() => set({isLoading: false, loaded: true}));
    },

    fetchScenariosUniqs: async (projectId: number, scenarios: number[]) => {
        return ApiClient.get(API_METHOD.CHARTS_SCENARIOS_UNIQS, {params: {scenarios, projectId}}).then(r => {
            set({statUniqs: r.data});
            return r;
        }).catch(ApiClient.toastError);
    },

    setScenariosList: (list: Scenario[]) => set({list})
}));