import * as React from "react";
import {ModalClose} from "@mui/joy";

export const ModalCloseButton = () => {
    return (
        <ModalClose
            variant="plain"
            color="neutral"
            size="sm"
            sx={{m: 1}}
        />
    );
}
