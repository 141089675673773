import {create} from "zustand";
import {API_METHOD, ApiResponsePaymentOrderCreate, PaymentTransaction, Tariff} from "../types";
import {ApiClient} from "../api-client";

interface PaymentStore {
    transactionsLoading: boolean;
    transactions: PaymentTransaction[];

    tariffsLoading: boolean;
    tariffs: Tariff[];

    createPaymentOrder(projectId: number, amount: number): Promise<ApiResponsePaymentOrderCreate>

    loadTransactionsHistory(projectId: number, limit: number, offset: number): Promise<void>
}

export const usePaymentStore = create<PaymentStore>((set) => ({
    transactionsLoading: false,
    transactions: [],

    tariffsLoading: false,
    tariffs: [],

    createPaymentOrder: async (projectId: number, amount: number) => {
        return ApiClient.post(
            API_METHOD.PAYMENTS_ORDER_CREATE,
            {amount: amount},
            {params: {projectId}}
        ).then(r => r.data as ApiResponsePaymentOrderCreate);
    },

    loadTransactionsHistory: async (projectId: number, limit: number, offset: number) => {
        set({transactionsLoading: true})

        return ApiClient.get(API_METHOD.PAYMENTS_TRANSACTIONS_HISTORY, {params: {projectId, limit, offset}})
            .then(r => set({transactions: r.data}))
            .finally(() => set({transactionsLoading: false}));
    },
}));
