import React, {useEffect, useMemo} from "react";
import {useHomeStatCountersStore} from "../../Stores/HomeTopCountersStore";
import {Box, Grid, Sheet, Tooltip, Typography} from "@mui/joy";
import Skeleton from "react-loading-skeleton";
import {ChevronRight, UserCheck, UserPlus, Users, UserX} from "react-feather";
import {useWindowDimensions} from "../../utils";
import {useProjectStore} from "../../Stores/ProjectStore";

export const TopCounters = (): React.JSX.Element => {
    const project = useProjectStore((state => state.selectedProject));
    const [counters, loaded] = useHomeStatCountersStore((state => [state.counters, state.loaded]));

    useEffect(() => {
        if (project) {
            useHomeStatCountersStore.getState().fetchCounters(project.id);
        }
    }, [project]);

    return (
        <>
            <Grid xs={12} md={12} lg={12}>
                <Typography level="title-lg">Пользователи бота</Typography>
            </Grid>
            <Item
                key="active-users"
                loaded={loaded}
                title="Активные"
                icon={<UserCheck size={28}/>}
                tooltip="Пользователи, которым может написать бот."
                count={counters.activeUsers}
            />

            <Item
                key="inctive-users"
                loaded={loaded}
                title="Неактивные"
                icon={<UserX size={28}/>}
                tooltip="Пользователи, которые остановили бота."
                count={counters.inactiveUsers}
            />

            <Item
                key="new-users"
                loaded={loaded}
                title="Новые"
                icon={<UserPlus size={28}/>}
                tooltip="Новые пользователи бота за текущий месяц."
                count={counters.monthNewUsers}
            />

            <Item
                key="total-users"
                loaded={loaded}
                title="Всего"
                icon={<Users size={28}/>}
                tooltip="Суммарное количество пользователей бота."
                count={counters.totalUsers}
            />
        </>
    );
}

const Item = (props: {
    title: string,
    icon: React.JSX.Element,
    count: number,
    loaded: boolean,
    tooltip: string
}): React.JSX.Element => {
    const sizes = useWindowDimensions()
    const gridSizes = useMemo(
        () => {
            let xs = 12;
            let md = 3;
            let lg = 3;

            if (sizes.width < 450) {
                xs = 12
            } else if (sizes.width < 900) {
                xs = 6;
            } else if (sizes.width < 1100) {
                md = 6;
            }

            return {xs, md, lg}
        },
        [sizes]
    );

    const SkeletonCounter = (): React.JSX.Element => {
        if (props.loaded) {
            return <></>
        }

        return (
            <Box sx={{lineHeight: '20px'}}>
                <Skeleton height={15}/>
            </Box>
        );
    };

    const Counter = (): React.JSX.Element => {
        if (!props.loaded) {
            return <></>
        }

        return (
            <Typography level="title-md" sx={{lineHeight: '20px'}} noWrap>
                {props.count}
            </Typography>
        );
    };

    return (
        <Grid xs={gridSizes.xs} md={gridSizes.md} lg={gridSizes.lg}>
            <Sheet variant="outlined" sx={{borderRadius: 'sm', p: 2}}>
                <Box
                    sx={{display: 'flex', gap: 2, flexDirection: 'row', alignItems: 'center'}}
                >
                    <Box sx={{display: 'flex', color: "var(--joy-palette-neutral-300)"}}>
                        {props.icon}
                    </Box>

                    <Box sx={{display: 'flex', gap: 0, flexDirection: 'column'}}>
                        <SkeletonCounter/>
                        <Counter/>
                        <Typography level="body-sm" color="neutral">{props.title}</Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        cursor: 'pointer',
                        marginLeft: 'auto',
                        color: "var(--joy-palette-neutral-300)"
                    }}>
                        <Tooltip title={props.tooltip} color={"neutral"} arrow={true}
                                 placement="top-end">
                            <ChevronRight size={20}/>
                        </Tooltip>
                    </Box>

                </Box>
            </Sheet>
        </Grid>
    );
}