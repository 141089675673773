import {ConditionNodeData, MomentDateFormat, MomentTimeFormat, WeekDay} from "../types";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Handle, Position} from "reactflow";
import {NodeHeader} from "../../NodeHeader";
import {useScenarioUpdateStore} from "../../../Stores/ScenarioUpdateStore";
import {Box, Card, Dropdown as DropdownMenu, IconButton, MenuButton, Tooltip} from "@mui/joy";
import {Dropdown, SelectOption} from "../../../Components/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import {useUpdateNode} from "../../utils";
import {
    ConditionNodeType,
    ConditionNodeVariant,
    ConditionNodeVariantItem,
    ConditionNodeVariantsConfig,
    ConditionNodeVariantsConfigItem,
    ConditionNodeVariantTypes,
    MaxVariants
} from "./type";
import {v4 as uuidv4} from "uuid";
import Typography from "@mui/joy/Typography";
import {numeralsLabel, toastError} from "../../../utils";
import moment from "moment";
import Stack from "@mui/joy/Stack";
import {AlertOctagon, Trash} from "react-feather";
import {useScenariosPreviewStore} from "../../../Stores/ScenarioPreviewStore";
import {NodeHandleRole} from "../../Edges/NodeHandle";
import Input from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import {FormErrorHelper} from "../PauseNode/PauseNode";
import styles from "./ConditionNode.module.css";

type VariantViewProps = {
    variant: ConditionNodeVariantItem,
    updateVariant: (variant: ConditionNodeVariantItem) => void
}

export const ConditionNode = ({id, data}: { id: string; data: ConditionNodeData; }) => {
    const updateNode = useUpdateNode();
    const [variants, setVariants] = useState<ConditionNodeVariantItem[]>(data.variants ?? []);
    const [hasAnyDatetime, setHasAnyDatetime] = useState(false);

    useEffect(() => {
        useScenarioUpdateStore.getState().clear();
    }, []);

    useEffect(() => {
        updateNode(id, {
            variants: variants,
        } as ConditionNodeData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variants]);


    const variantsList = useMemo(() => {
        let hasAnyDateTimeLocal = false;

        const list = variants.map((variant) => {
                const config = ConditionNodeVariantsConfig[variant.variant];

                if (config.isAnyDateTime) {
                    hasAnyDateTimeLocal = true;
                }

                return <VariantItem key={variant.id} variant={variant} setVariants={setVariants}/>;
            }
        );

        setHasAnyDatetime(hasAnyDateTimeLocal);

        return list;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variants]);

    return (
        <Box className="Node__container ConditionNode nowheel">
            <NodeHeader
                title={"Условие"}
                nodeTitle={data.title}
                nodeId={id}
                nodeHandleRole={NodeHandleRole.ConditionNode}
                testable
                deletable
            />

            <Box className="Node__content">
                <TypeSelector nodeId={id} type={data.type} variantsCount={variants.length}/>

                <Stack sx={{gap: "15px"}}>
                    {variantsList}
                </Stack>

                {variants.length === 0 && <ZeroVariantsError/>}

                {hasAnyDatetime &&
                    <Card invertedColors variant="soft" color="neutral" sx={{boxShadow: 'none', mt: '15px'}}>
                        <Typography level="body-sm">
                            Дата и время задаются по <strong>московскому</strong> часовому поясу (GMT+3).
                        </Typography>
                    </Card>
                }

                <VariantsMenu variants={variants} setVariants={setVariants}/>
            </Box>

            <SourceHandlers id={id}/>
        </Box>
    );
}

const ZeroVariantsError = () => {
    return (
        <Card variant="soft" color="warning" invertedColors sx={{boxShadow: 'none'}}>
            <Stack direction="row" spacing={2} alignItems={'center'}>
                <AlertOctagon size={28}/>
                <Typography level="body-sm">
                    Добавьте хотя бы одно условие.
                </Typography>
            </Stack>
        </Card>
    );
}

const TypeSelector = (props: { nodeId: string, type: ConditionNodeType, variantsCount: number }) => {
    const updateNode = useUpdateNode();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const [type, setType] = useState<ConditionNodeType>(props.type ?? ConditionNodeType.Strict);

    const DropdownTypes = [
        {label: "Одно из условий выполняется", value: ConditionNodeType.Soft},
        {label: "Все условия выполняются", value: ConditionNodeType.Strict},
    ];

    useEffect(() => {
        updateNode(props.nodeId, {
            type: type,
        } as ConditionNodeData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    if (props.variantsCount < 2) {
        return <></>
    }

    return (
        <Box sx={{mb: '15px'}}>
            <Dropdown
                label="Сочетание условий"
                value={type}
                options={DropdownTypes}
                setValue={setType}
                size={'md'}
                disabled={isPreviewMode}
            />
        </Box>
    );
}

const VariantItem = ({variant, setVariants}: {
    variant: ConditionNodeVariantItem,
    setVariants: React.Dispatch<React.SetStateAction<ConditionNodeVariantItem[]>>
}) => {
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const config = ConditionNodeVariantsConfig[variant.variant];
    const variantDropdownTypes: SelectOption[] = config.allowedVariantTypes.map((allowedType) => {
        return {label: ConditionNodeVariantTypes[allowedType], value: allowedType}
    });

    const updateVariant = (variant: ConditionNodeVariantItem) => {
        setVariants((variants) => variants.map((v) => v.id === variant.id ? variant : v));
    };

    const removeVariant = (variant: ConditionNodeVariantItem) => {
        setVariants((variants) => variants.filter((v) => v.id !== variant.id));
    };

    return (
        <Card
            invertedColors
            variant="soft"
            color="neutral"
            size="sm"
            sx={{boxShadow: 'none', gap: '15px', padding: '15px'}}
            className={styles.conditionContainer}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography level="title-sm" textColor="neutral.700">{config.title}</Typography>
                {!isPreviewMode &&
                    <IconButton size="sm" className={styles.removeButton} onClick={() => removeVariant(variant)}>
                        <Trash size={16}/>
                    </IconButton>
                }
            </Stack>

            <Dropdown
                value={variant.variantType}
                options={variantDropdownTypes}
                disabled={isPreviewMode}
                variant="soft"
                setValue={(v) => updateVariant({...variant, variantType: v})}
                size={'md'}
            />

            <VariantView variant={variant} updateVariant={updateVariant}/>
        </Card>
    )
}

const VariantView = ({variant, updateVariant}: VariantViewProps) => {
    const views = {
        [ConditionNodeVariant.CurrentDate]: VariantDatePicker,
        [ConditionNodeVariant.CurrentTime]: VariantTimePicker,
        [ConditionNodeVariant.CurrentWeekday]: VariantWeekday,
    }

    const View = views[variant.variant] ?? null;

    if (!View) {
        return <></>
    }

    return <View variant={variant} updateVariant={updateVariant}/>
}

const VariantWeekday = ({variant, updateVariant}: VariantViewProps) => {
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const [day, setDay] = useState<WeekDay | null>(variant.value[0] ? variant.value[0] as WeekDay : null);

    const weekDayOptions = [
        {label: "Понедельник", value: WeekDay.Monday},
        {label: "Вторник", value: WeekDay.Tuesday},
        {label: "Среда", value: WeekDay.Wednesday},
        {label: "Четверг", value: WeekDay.Thursday},
        {label: "Пятница", value: WeekDay.Friday},
        {label: "Суббота", value: WeekDay.Saturday},
        {label: "Воскресенье", value: WeekDay.Sunday},
    ];

    return (
        <Dropdown
            value={day}
            options={weekDayOptions}
            setValue={(v) => {
                setDay(v as WeekDay);
                updateVariant({...variant, value: [String(v)]});
            }}
            size={'md'}
            variant="soft"
            placeholder={'Выберите день недели'}
            disabled={isPreviewMode}
        />
    );
}

const VariantTimePicker = ({variant, updateVariant}: VariantViewProps) => {
    const defaultDatetime = variant.value[0] ? moment(variant.value[0]) : moment();
    const [error, setError] = useState<string | undefined>();
    const [time, setTime] = useState(defaultDatetime.format(MomentTimeFormat));
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const incorrectErrorText = `Введите корректное время`;

    const onChangeTime = useCallback((value: string) => {
        setTime(value);

        if (value.length <= 0) {
            setError(incorrectErrorText);
            return;
        }

        const datetime = moment(`${moment().format(MomentDateFormat)} ${value}`);

        if (!datetime.isValid()) {
            setError(incorrectErrorText);
            return;
        }

        setError(undefined);
        updateVariant({...variant, value: [datetime.format('YYYY-MM-DD HH:mm')]})
    }, [incorrectErrorText, updateVariant, variant]);

    return (
        <FormControl>
            <Input
                type="time"
                disabled={isPreviewMode}
                value={time}
                variant="soft"
                onChange={(value) => onChangeTime(value.target.value)}
            />
            <FormErrorHelper error={error}/>
        </FormControl>
    );
}

const VariantDatePicker = ({variant, updateVariant}: VariantViewProps) => {
    const defaultDatetime = variant.value[0] ? moment(variant.value[0]) : moment().add(1, 'days');
    const [date, setDate] = useState(defaultDatetime.format(MomentDateFormat));
    const [error, setError] = useState<string | undefined>();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const incorrectErrorText = `Введите корректную дату`;

    const onChangeDate = useCallback((value: string) => {
        setDate(value);

        if (value.length <= 0) {
            setError(incorrectErrorText);
            return;
        }

        const datetime = moment(value);

        if (!datetime.isValid()) {
            setError(incorrectErrorText);
            return;
        }

        setError(undefined);
        updateVariant({...variant, value: [datetime.format('YYYY-MM-DD HH:mm')]})
    }, [incorrectErrorText, updateVariant, variant])

    return (
        <FormControl>
            <Input
                type="date"
                value={date}
                disabled={isPreviewMode}
                variant="soft"
                onChange={(value) => onChangeDate(value.target.value)}
            />
            <FormErrorHelper error={error}/>
        </FormControl>
    );
}

export const VariantsMenu = ({variants, setVariants}: {
    variants: ConditionNodeVariantItem[],
    setVariants: React.Dispatch<React.SetStateAction<ConditionNodeVariantItem[]>>
}) => {
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    const addVariant = (variant: ConditionNodeVariantsConfigItem) => {
        if (variant.allowedVariantTypes.length > MaxVariants) {
            toastError(`Можно добавить максимум  ${numeralsLabel(MaxVariants, 'условие', 'условия', 'условий')}`)
            return;
        }

        const newVariant: ConditionNodeVariantItem = {
            id: uuidv4(),
            variant: variant.variant,
            variantType: variant.allowedVariantTypes[0],
            value: variant.defaultValue(),
        };

        setVariants([...variants, newVariant]);
    };

    if (isPreviewMode) {
        return <></>
    }

    return (
        <DropdownMenu>
            <MenuButton
                color="neutral"
                size="md"
                variant="plain"
                sx={{width: '100%', marginTop: '15px'}}
            >
                <Typography level="body-sm">
                    Добавить условие
                </Typography>
            </MenuButton>
            <Menu>
                {Object.values(ConditionNodeVariantsConfig).map((variant) => {
                    return (
                        <MenuItem key={variant.variant} onClick={() => addVariant(variant)}>
                            {variant.title}
                        </MenuItem>
                    )
                })}
            </Menu>
        </DropdownMenu>
    );
}

export const SourceHandlers = ({id}: { id: string }) => {
    return (
        <Stack spacing={1} sx={{textAlign: 'right', padding: '10px 20px 10px 20px'}}>
            <Typography textColor="neutral.300" level="body-xs">Условия выполняются</Typography>
            <Typography textColor="neutral.300" level="body-xs">Условия <strong>не</strong> выполняются</Typography>

            <Tooltip key={'yes-tooltip'} arrow title={'Условия выполняются'} size="sm" placement="right">
                <Handle
                    id={`${id}-yes`}
                    type="source"
                    position={Position.Right}
                    style={{marginTop: -30}}
                    className={'Node__sourceHandle ConditionNode_sourceHandleYes'}
                />
            </Tooltip>

            <Tooltip key={'no-toltip'} arrow title={'Условия не выполняются'} size="sm" placement="right">
                <Handle
                    id={`${id}-no`}
                    type="source"
                    position={Position.Right}
                    style={{marginTop: 30}}
                    className={'Node__sourceHandle ConditionNode_sourceHandleNo'}
                />
            </Tooltip>
        </Stack>
    );
}
