import * as React from 'react';
import {useState} from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Sheet from '@mui/joy/Sheet';
import {Briefcase, Home, Layers, LifeBuoy, PieChart, Send, Users} from "react-feather";
import {SidebarHeader} from "./SidebarHeader";
import Typography from "@mui/joy/Typography";
import {useLocation, useNavigate} from "react-router-dom";
import {PAGE} from "../types";
import {Box, Button, Card, IconButton, Link, ListItemContent, ListItemDecorator} from "@mui/joy";
import {Account} from "./Account";
import {closeSidebar, isDev} from "../utils";
import {InfoModal} from "../Components/InfoModal";
import Stack from "@mui/joy/Stack";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {Preferences, usePreferencesStore} from "../Stores/PreferencesStore";
import {useScenarioUpdateStore} from "../Stores/ScenarioUpdateStore";
import {useAccountStore} from "../Stores/AccountStore";

export const Sidebar = () => {
    const navigate = useNavigate();

    const SubscribeChannelCard = (): React.JSX.Element => {
        const [isVisible, setIsVisible] = useState(usePreferencesStore.getState().isVisible(Preferences.SubscribeChatgunTelegramChannel));
        const hide = () => {
            usePreferencesStore.getState().hide(Preferences.SubscribeChatgunTelegramChannel);
            setIsVisible(false);
        };

        if (!isVisible || true) { // пока отключим, не будем форсить телегу
            return <></>;
        }

        return (
            <Card invertedColors variant="soft" color="neutral" size="sm">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography level="title-sm">Обновления</Typography>
                    <IconButton size="sm" onClick={hide}>
                        <CloseRoundedIcon/>
                    </IconButton>
                </Stack>

                <Typography level="body-xs" sx={{mb: 1}}>
                    Подпишитесь на наш канал, чтобы следить за новостями и обновлениями.
                </Typography>

                <Button size="sm" variant="solid" onClick={() => window.open('https://t.me/chatgunio', '_blank')}>
                    Подписаться
                </Button>
            </Card>
        );
    };

    const SupportCard = (): React.JSX.Element => {
        const [account] = useAccountStore(state => [state.account]);
        return (
            <>
                <Card variant="soft" color="primary" sx={{mt: -3}}>
                    <Typography level="body-sm">
                        По любым вопросам можете обращаться к нам на
                        почту <Link href="mailto:support@chatgun.io" target="_blank">support@chatgun.io</Link>
                        {' '}или в наше{' '}
                        <Link href="https://vk.me/chatguntech" target="_blank">сообщество ВКонтакте</Link>.
                    </Typography>
                </Card>

                <Stack
                    gap={{sm: 0.5, xs: 1}}
                    alignItems={{xs: 'center'}}
                    divider={<Box sx={{display: {sm: 'inherit', xs: 'none'}}}>&nbsp;·&nbsp;</Box>}
                    direction={{sm: "row", xs: 'column'}}
                    sx={{mt: 2, mb: -1}}
                >
                    <Typography level="body-sm">
                        <Link href="https://legal.chatgun.io/ru-terms-of-use.pdf" target="_blank">
                            Пользовательское соглашение
                        </Link>
                    </Typography>
                    <Typography level="body-sm">
                        <Link href="https://legal.chatgun.io/ru-privacy-policy.pdf" target="_blank">
                            Политика конфиденциальности
                        </Link>
                    </Typography>
                </Stack>

                {(account.id === 1 || isDev()) &&
                    <Stack sx={{mt: 3, mb: -2}}>
                        <Button variant="soft" onClick={() => {
                            throw new Error('throwed by button click')
                        }}>Throw</Button>
                    </Stack>
                }
            </>
        )
    };

    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: {
                    xs: 'fixed',
                    md: 'sticky',
                },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 5,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 1.5,
                py: 3,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '224px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '256px',
                        },
                    },
                })}
            />

            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 10,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    bgcolor: 'background.body',
                    opacity: 'calc(var(--SideNavigation-slideIn, 0) - 0.2)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />

            <SidebarHeader/>

            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <List
                    sx={{
                        '--ListItem-radius': '8px',
                        '--List-gap': '0px',
                        '--List-nestedInsetStart': '40px',
                    }}
                >
                    <ListItem>
                        <SidebarItemButton to={PAGE.MAIN} title={"Главная"} icon={<Home size={20}/>}/>
                    </ListItem>
                    <ListItem>
                        <SidebarItemButton to={PAGE.SCENARIOS} title={"Сценарии"} icon={<Layers size={20}/>}/>
                    </ListItem>
                    <ListItem>
                        <SidebarItemButton to={PAGE.BROADCASTING} title={"Рассылки"} icon={<Send size={20}/>}/>
                    </ListItem>
                    <ListItem>
                        <SidebarItemButton to={PAGE.USERS} title={"Пользователи"} icon={<Users size={20}/>}/>
                    </ListItem>
                    <ListItem>
                        <SidebarItemButton to={PAGE.CHARTS} title={"Аналитика"} icon={<PieChart size={20}/>}/>
                    </ListItem>
                    <ListItem>
                        <SidebarItemButton to={PAGE.EMPLOYEES} title={"Сотрудники"} icon={<Briefcase size={20}/>}/>
                    </ListItem>
                    <ListItem>
                        <InfoModal title={"Поддержка и документы"} description={<SupportCard/>} hideClose>
                            <ListItemButton>
                                <ListItemDecorator><LifeBuoy size={20}/></ListItemDecorator>
                                <ListItemContent>
                                    Поддержка
                                </ListItemContent>
                            </ListItemButton>
                        </InfoModal>
                    </ListItem>
                </List>
            </Box>

            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 0,
                    mt: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <SubscribeChannelCard/>
            </Box>

            <Button
                size="sm"
                color="neutral"
                variant="soft"
                // sx={{justifyContent: 'space-evenly', alignItems: 'center'}}
                // endDecorator={<KeyboardArrowRight color="disabled"/>}
                onClick={() => navigate(PAGE.PROJECT_CREATE)}
            >
                Подключить бота
            </Button>
            <Divider/>
            <Account/>
        </Sheet>
    );
}

type SidebarItemProps = {
    title: string;
    icon: any;
    to?: string;
}
export const SidebarItemButton = (props: SidebarItemProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const selected = props.to ? props.to === location.pathname : undefined;

    const [setScenarioConfirmCloseOpen, hasUnsavedScenarioChanges] = useScenarioUpdateStore(state => [
        state.setConfirmCloseModalOpen,
        state.hasUpdates
    ]);

    return (
        <ListItemButton
            selected={selected}
            color={selected ? "primary" : undefined}
            variant={selected ? "soft" : undefined}
            className={'nooutline'}
            onClick={() => {
                if (props.to) {
                    if (hasUnsavedScenarioChanges) {
                        setScenarioConfirmCloseOpen(true);
                    } else {
                        navigate(props.to);
                    }
                }
                closeSidebar();
            }}
        >
            <ListItemDecorator>{props.icon}</ListItemDecorator>
            <ListItemContent>
                {props.title}
            </ListItemContent>
        </ListItemButton>
    );
}
