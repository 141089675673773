import {ModalBox} from "../Components/ModalBox";
import {useProjectStore} from "../Stores/ProjectStore";
import {useScenariosStore} from "../Stores/ScenarioStore";
import {Avatar, Button, Card, CardContent, Chip, Grid, Typography} from "@mui/joy";
import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {PAGE, Project, ProjectType} from "../types";
import {useLocation, useNavigate} from "react-router-dom";
import Stack from "@mui/joy/Stack";
import {useWindowDimensions} from "../utils";
import {ArrowForward} from "@mui/icons-material";
import {FormInput} from "../Components/Form/FormInput";
import Box from "@mui/joy/Box";

export const ProjectsModal = ({opened, close}: { opened: boolean; close: () => void }): React.JSX.Element => {
    const sizes = useWindowDimensions();

    return (
        <ModalBox open={opened} onClose={close} title="Ваши проекты" sx={{
            maxHeight: sizes.height - sizes.height / 100 * 15,
            width: {
                xl: 800,
                lg: 800,
                md: 600,
                sm: 500,
                xs: 350
            }
        }}>
            <Content closeModal={close}/>
        </ModalBox>
    );
}

const Content = ({closeModal}: { closeModal: () => any }) => {
    const [projects] = useProjectStore(state => [state.projects]);
    const [colSize, setColSize] = useState(4);
    const sizes = useWindowDimensions();
    const gridRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [filteredProjects, setFilteredProjects] = useState<Project[]>(projects);

    useEffect(() => {
        if (!gridRef.current) {
            return;
        }

        const gridWidth = gridRef.current.offsetWidth;

        if (gridWidth >= 700) {
            setColSize(4);
        } else if (gridWidth > 550 && gridWidth < 700) {
            setColSize(6);
        } else {
            setColSize(12);
        }

    }, [gridRef, sizes]);

    return (
        <Grid ref={gridRef} container spacing={1.5} alignItems="stretch" sx={{overflowY: 'auto'}}>
            <Grid xs={12}>
                <Stack direction={{sm: "row", xs: "column"}} spacing={1}>
                    <FormInput
                        value={search}
                        setValue={(value) => {
                            const filtered = projects.filter((project) => {
                                return value.trim().length === 0 || project.name.toLowerCase().includes(String(value).toLowerCase().trim())
                            });

                            setSearch(value);
                            setFilteredProjects(filtered);
                        }}
                        type="text"
                        placeholder="Поиск"
                        sx={{flexGrow: 1}}
                    />

                    <Button
                        size="sm"
                        variant="soft"
                        onClick={() => navigate(PAGE.PROJECT_CREATE)}
                        endDecorator={<ArrowForward fontSize="small"/>}
                    >
                        Подключить бота
                    </Button>
                </Stack>
            </Grid>

            {filteredProjects.length === 0 &&
                <Box sx={{p: 7, textAlign: 'center', width: '100%'}}>
                    <Typography level="body-sm" fontWeight="300" textColor="neutral.400">
                        Не нашли проектов с таким названием
                    </Typography>
                </Box>
            }

            {filteredProjects.map(project => <ProjectItem
                key={project.id}
                closeModal={closeModal}
                project={project}
                colSize={colSize}
            />)}
        </Grid>
    );
}

const ProjectItem = ({project, colSize, closeModal}: { project: Project, colSize: number, closeModal: () => any }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [clearSelectedScenario] = useScenariosStore(state => [state.clearScenario]);
    const [projects, selectedProject, setSelectedProject] = useProjectStore(state => [
        state.projects,
        state.selectedProject,
        state.setSelectedProject
    ]);

    const tariffActive = project.tariff.leftTime > 0;
    const avatarsSrc = useMemo(() => {
        return {
            [ProjectType.VK]: "/images/logo_vk.svg",
            [ProjectType.Telegram]: "/images/logo_telegram.svg"
        }
    }, []);

    const onChange = useCallback((projectId: number) => {
        if (projectId !== selectedProject?.id) {
            setSelectedProject(projects.find(project => project.id === projectId));
            clearSelectedScenario();

            const isScenarioEdit = location.pathname.match(/\/scenarios\/\d+/);
            const isBroadcastingEdit = location.pathname.match(/\/broadcasting\/\d+/);

            if (isScenarioEdit) {
                navigate(PAGE.SCENARIOS);  // Если были внутри сценария, то отправим на список при смене проекта.
            }

            if (isBroadcastingEdit) {
                navigate(PAGE.BROADCASTING);  // Если были внутри рассылки, то отправим на список при смене проекта.
            }

            closeModal();
        }
    }, [selectedProject?.id, setSelectedProject, projects, clearSelectedScenario, location.pathname, closeModal, navigate]);

    return (
        <Grid xs={colSize}>
            <Card
                orientation="horizontal"
                variant="outlined"
                onClick={() => onChange(project.id)}
                sx={{
                    cursor: 'pointer',
                    borderColor: selectedProject?.id === project.id ? 'primary.400' : '',
                    '&:hover': {boxShadow: 'md', borderColor: 'primary.300'},
                }}
            >
                <CardContent sx={{overflow: 'hidden'}}>
                    <Stack direction='row' alignItems='center'>
                        <Avatar variant="plain" size="sm">
                            <img height={17} src={avatarsSrc[project.type]} alt=""/>
                        </Avatar>
                        <Typography level='title-sm' noWrap sx={{pointerEvents: 'none'}}>
                            {project.name}
                        </Typography>
                    </Stack>

                    <Stack direction='row' spacing={1} sx={{ml: 0.5}}>
                        <Chip
                            variant="soft"
                            color={tariffActive ? "primary" : "warning"}
                            size="sm"
                            sx={{pointerEvents: 'none', mt: 0.35, "--Chip-radius": "6px"}}
                        >
                            {tariffActive ? "Подписка активна" : "Подписка не оплачена"}
                        </Chip>
                    </Stack>

                </CardContent>
            </Card>
        </Grid>
    );
}
