import Box from "@mui/joy/Box";
import IconButton, {iconButtonClasses} from "@mui/joy/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React from "react";

export const Pagination = ({page, setPage, total, perPage}: {
    page: number,
    setPage: (value: any) => void,
    total: number,
    perPage: number
}) => {
    const lastPage = Math.ceil(total / perPage);
    const buttonsCount = 5; // Сколько кнопок с номерами страниц максимум отображается, нечетное.
    const buttonsPerSide = Math.ceil((buttonsCount - 1) / 2); // По сколько кнопок справа и слева от текущей.

    if (total <= perPage) {
        return <></>
    }

    let pages = [];
    let start = page - buttonsPerSide;

    if (page + buttonsPerSide >= lastPage) {
        start = lastPage - buttonsCount + 1;
    } else if (page - buttonsPerSide <= 0) {
        start = 1
    }

    for (let i = start; pages.length < buttonsCount; i++) {
        if (i <= 0) {
            continue;
        }

        if (i > lastPage) {
            break;
        }

        pages.push(i);
    }

    return (
        <Box
            sx={{
                pt: 2,
                gap: 1,
                justifyContent: 'center',
                width: '100%',
                [`& .${iconButtonClasses.root}`]: {borderRadius: '50%'},
                display: 'flex',
            }}
        >
            <IconButton
                size="md"
                variant={'outlined'}
                color="neutral"
                disabled={page === 1}
                className="noselect"
                onClick={() => setPage(page - 1)}
            ><KeyboardArrowLeftIcon/></IconButton>

            {pages.map((p) => (
                <IconButton
                    key={p}
                    size="md"
                    variant='outlined'
                    className="noselect"
                    onClick={() => setPage(p)}
                    color={p === page ? 'primary' : 'neutral'}
                >{p}</IconButton>
            ))}

            <IconButton
                size="md"
                variant={'outlined'}
                color="neutral"
                className="noselect"
                disabled={page >= lastPage}
                onClick={() => setPage(page + 1)}
            ><KeyboardArrowRightIcon/></IconButton>
        </Box>
    );
}
