import {MessageNode} from "./MessageNode/MessageNode";
import {KeyboardRows} from "./MessageNode/Keyboard/types";
import {ScenarioStartNode} from "./ScenarioStartNode/ScenarioStartNode";
import {RemovableEdge} from "../Edges/RemovableEdge";
import {NodeFile} from "./MessageNode/Files/types";
import {PauseNode} from "./PauseNode/PauseNode";
import {JumpNode} from "./JumpNode/JumpNode";
import {RandomSelectionNode} from "./RandomSelectionNode/RandomSelectionNode";
import {ConditionNode} from "./ConditionNode/ConditionNode";
import {ConditionNodeType, ConditionNodeVariantItem} from "./ConditionNode/type";
import {CommentNode} from "./CommentNode/CommentNode";
import {
    BroadcastingAudienceMode,
    BroadcastingStartNode,
    BroadcastingStartRule
} from "./BroadcastingStartNode/BroadcastingStartNode";
import {AnswerWaitingNode} from "./AnswerWaitingNode/AnswerWaitingNode";
import {MessageNodeOptions} from "./MessageNode/types";

export const NodeDragHandleClass = '.NodeDragHandle';

export enum ConstructorMode {
    Scenario = 'scenario',
    Broadcasting = 'broadcasting'
}

export enum NodeType {
    BroadcastingStartNode = 'broadcastingStartNode', // Стартовая нода у рассылок
    ScenarioStartNode = 'startNode',  // Стартовая нода у сценариев
    MessageNode = 'messageNode',
    PauseNode = 'pauseNode',
    JumpNode = 'jumpNode',
    ConditionNode = 'conditionNode',
    RandomSelectionNode = 'randomSelectionNode',
    CommentNode = 'commentNode',
    AnswerWaitingNode = 'answerWaitingNode', // Ожидание ответа
}

export const NodeTypes = {
    broadcastingStartNode: BroadcastingStartNode,
    startNode: ScenarioStartNode,
    messageNode: MessageNode,
    pauseNode: PauseNode,
    jumpNode: JumpNode,
    conditionNode: ConditionNode,
    randomSelectionNode: RandomSelectionNode,
    commentNode: CommentNode,
    answerWaitingNode: AnswerWaitingNode,
};

export interface IConstructorAvailableNodes {
    [mode: string]: NodeType[];
}

export const ConstructorAvailableNodes: IConstructorAvailableNodes = {
    [ConstructorMode.Scenario]: [
        NodeType.MessageNode,
        NodeType.PauseNode,
        NodeType.JumpNode,
        NodeType.ConditionNode,
        NodeType.RandomSelectionNode,
        NodeType.CommentNode,
        NodeType.AnswerWaitingNode,
    ],
    [ConstructorMode.Broadcasting]: [
        NodeType.MessageNode,
        NodeType.JumpNode,
        NodeType.ConditionNode,
        NodeType.RandomSelectionNode,
        NodeType.CommentNode,
    ],
}

export interface NodeData {
    title: string;
}

export interface MessageNodeData extends NodeData {
    message: string;
    buttons: KeyboardRows;
    options: MessageNodeOptions;
    files: NodeFile[];
}

export interface PauseNodeData extends NodeData {
    seconds: number; // Сколько секунд пауза, для PauseType.Timer
    timeType: TimeType; // Тип таймера (минут, часов, дней), для PauseType.Timer
    pauseType: PauseNodeType; // Тип паузы - таймер, конкретная дата, конкретный день недели.
    datetime: string; // Дата и время, для PauseType.Date, а также для PauseType.Weekday (время)
    weekday: WeekDay[]; // Дни недели, для PauseType.Weekday
}

export interface JumpNodeData extends NodeData {
    nodeId: string;
}

export interface RandomSelectionNodeData extends NodeData {
    count: number;
}

export interface ConditionNodeData extends NodeData {
    type: ConditionNodeType;
    variants: ConditionNodeVariantItem[];
}

export interface AnswerWaitingNodeData extends NodeData {
    seconds: number; // Сколько секунд ждет действия пользователя перед тем как сработать.
    timeType: TimeType; // Минут, часов, дней.
}

export interface CommentNodeData {
    text: string;
}

export enum TimeType {
    Minutes = "minutes",
    Hours = "hours",
    Days = "days",
}

export enum WeekDay {
    Monday = "monday",
    Tuesday = "tuesday",
    Wednesday = "wednesday",
    Thursday = "thursday",
    Friday = "friday",
    Saturday = "saturday",
    Sunday = "sunday",
}

/**
 * Отложенная отправка
 *
 * 1. Таймер.
 * 2. Отправка в конкретную дату время.
 * 3. Отправка в конкретный день недели с выбором конкретного времени.
 */
export enum PauseNodeType {
    Timer = "timer", // Обычная отправка по таймеру.
    Date = "date", // Отправка в конкретную дату и время.
    Weekday = "weekday", // Отправка в конкретный день/дни недели с выбором конкретного времени.
}

export enum KeyMode {
    Strict = 1,
    Soft = 2,
}

export type ScenarioStartNodeData = {
    keys: string;
    name: string;
    keysMode: KeyMode;
}

export type BroadcastingStartNodeData = {
    name: string;
    startRule: BroadcastingStartRule;
    audienceMode: BroadcastingAudienceMode;
    startDatetime: string;
}

export const EdgeTypes = {
    removableEdge: RemovableEdge
}

export enum EdgeType {
    Removable = 'removableEdge',
}

export const MomentDayTimeFormat = 'YYYY-MM-DD HH:mm';
export const MomentDateFormat = 'YYYY-MM-DD';
export const MomentTimeFormat = 'HH:mm';

export const Minute = 60;
export const Hour = Minute * 60;
export const Day = Hour * 24;
