import axios, {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios";
import {NavigateFunction} from "react-router-dom";
import {ApiResponse, AUTH_COOKIE, PAGE} from "./types";
import qs from "qs";
import {getCookie, removeCookie, toastError} from "./utils";
import {SetInputErrors} from "./Components/Form/helpers";

export class ApiError {
    response: ApiResponse;
    axios: AxiosError;

    constructor(response: ApiResponse, axiosError: AxiosError) {
        this.response = response;
        this.axios = axiosError;
    }
}

export class ApiClientInstance {
    private navigate: NavigateFunction | undefined;
    private readonly axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({baseURL: `${process.env.REACT_APP_API_ENDPOINT}/web`});
        this.axios.interceptors.response.use(
            (res) => res,
            (error) => this.processError(error)
        );
    }

    setNavigate(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    redirect(route: string) {
        if (this.navigate !== undefined) {
            this.navigate(route);
        } else {
            window.location.href = route;
        }
    }

    get(query: string, config: AxiosRequestConfig | undefined = undefined) {
        let cfg = this.requestConfig();

        if (config !== undefined) {
            cfg = {...cfg, ...config}
        }

        return this.axios.get(query, cfg).then(((r) => r.data as ApiResponse));
    }

    post(method: string, data: any, config: AxiosRequestConfig | undefined = undefined) {
        const body = new FormData();
        let cfg = this.requestConfig();

        if (config !== undefined) {
            cfg = {...cfg, ...config}
        }

        if (data) {
            Object.entries(data).forEach(([key, value]) => {
                body.append(key, String(value));
            });
        }

        return this.axios.post(method, body, cfg).then((r => r.data as ApiResponse));
    }

    /**
     * Обертка для catch, пропустить ошибку.
     */
    skipError(_: ApiError) {
        return
    }

    /**
     * Обертка для catch, просто показывает toast с текстом ошибки.
     * Сработает для любых ошибок, кроме ошибки с кодом 401.
     */
    toastError(error: ApiError) {
        if (error.axios.response && error.axios.response.status === 401) {
            return; // Здесь toast не нужен, т.к. мы просто разлогиниваем и перенаправляем на страницу авторизации.
        }

        toastError(error.response.data);
    }

    /**
     * Обертка для catch, завернет текст ошибки в InputErrors для конкретного поля.
     */
    setInputError(error: any, setInputErrors: SetInputErrors, field: string) {
        if (error instanceof ApiError) {
            setInputErrors(state => new Map(state.set(field, error.response.data)))
        } else {
            console.error(error);
        }
    }

    private readonly requestConfig = (): AxiosRequestConfig => {
        const authCookie = getCookie(AUTH_COOKIE);

        return {
            headers: {
                Authorization: "Bearer " + (authCookie !== undefined ? authCookie : ''),
            },
            paramsSerializer: {
                serialize: (params: any) => {
                    return qs.stringify(params, {arrayFormat: "repeat"})
                }
            }
        }
    }

    private processError(error: AxiosError) {
        if (!axios.isAxiosError(error) || !error.response) {
            console.error(error);
            return Promise.reject(new ApiError({code: 0, data: 'Неизвестная ошибка', success: false}, error));
        }

        const response = error.response;
        const data = response.data as ApiResponse;

        if (response.status === 401) {
            removeCookie(AUTH_COOKIE);

            if (this.navigate !== undefined) {
                this.navigate(PAGE.SIGN_IN);
            } else {
                window.location.href = PAGE.SIGN_IN;
            }
        }

        return Promise.reject(new ApiError(data, error));
    }
}

export const ApiClient = new ApiClientInstance()
