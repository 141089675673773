import {create} from "zustand";
import {API_METHOD, ApiResponseLogin, AUTH_COOKIE, PAGE} from "../types";
import {ApiClient} from "../api-client";
import {setCookie, toastSuccess} from "../utils";
import {useProjectStore} from "./ProjectStore";

export enum Step {
    CreateCode,
    SendCode,
    ChangePassword,
}

interface RestorePasswordStore {
    step: Step;
    emailFound: boolean;
    codeAccepted: boolean;
    sending: boolean;

    createRestoreCode(email: string): Promise<void>;

    checkRestoreCode(email: string, code: string): Promise<void>;

    restorePassword(email: string, code: string, password: string): Promise<void>;
}

export const useRestorePasswordStore = create<RestorePasswordStore>((set) => ({
    step: Step.CreateCode,
    emailFound: false,
    codeAccepted: false,
    sending: false,

    createRestoreCode: async (email: string) => {
        set({sending: true});

        return ApiClient.post(API_METHOD.ACCOUNT_RESTORE_SEND_CODE, {email}).then(_ => {
            set({step: Step.SendCode, emailFound: true});
        }).catch(ApiClient.toastError).finally(() => set({sending: false}))
    },

    checkRestoreCode(email: string, code: string) {
        set({sending: true});

        return ApiClient.post(API_METHOD.ACCOUNT_RESTORE_CHECK_CODE, {email, code}).then(_ => {
            set({step: Step.ChangePassword, codeAccepted: true});
        }).catch(ApiClient.toastError).finally(() => set({sending: false}));
    },

    restorePassword(email: string, code: string, password: string): Promise<void> {
        set({sending: true});

        return ApiClient.post(API_METHOD.ACCOUNT_RESTORE_PASSWORD, {email, code, password}).then(r => {
            const data = r.data as ApiResponseLogin;

            useProjectStore.getState().setSelectedProject(undefined);
            setCookie(AUTH_COOKIE, data.token, {'max-age': 3600 * 24 * 365});
            toastSuccess("Пароль успешно изменен!")

            if (data.projects > 0) {
                ApiClient.redirect(PAGE.MAIN);
            } else {
                ApiClient.redirect(PAGE.PROJECT_CREATE);
            }
        }).catch(ApiClient.toastError).finally(() => set({sending: false}));
    }
}));
