import Stack from "@mui/joy/Stack";
import {Alert, Button, Grid, Link, Typography} from "@mui/joy";
import React, {useMemo, useState} from "react";
import {useAccountStore} from "../../Stores/AccountStore";
import {useWindowDimensions} from "../../utils";
import {TransactionsModal} from "./TransactionsModal";
import {usePaymentStore} from "../../Stores/PaymentStore";
import {ApiClient} from "../../api-client";
import {useProjectStore} from "../../Stores/ProjectStore";
import {TopUpModal} from "./TopupModal";
import {PromocodeModal} from "./PromocodeModal";
import {ProjectType} from "../../types";
import {ConfirmModal} from "../../Components/ConfirmModal";
import {KeyboardArrowRight} from "@mui/icons-material";

export const Footer = () => {
    const payments = usePaymentStore();
    const [project, tariffRenewing, renewTariff] = useProjectStore(state => [state.selectedProject, state.tariffRenewing, state.renewTariff]);
    const sizes = useWindowDimensions();

    const [transactionsModalOpen, setTransactionsModalOpen] = useState(false);
    const [topUpModalOpened, setTopUpModalOpened] = useState(false);
    const [promoCodeModalOpened, setPromoCodeModalOpened] = useState(false);

    const tariffIsActive = useMemo(() => project && project.tariff.leftTime > 0, [project]);
    const buttonDirection = useMemo(() => sizes.width < 600 ? 'column-reverse' : 'row', [sizes]);

    if (!project) {
        return <></>;
    }

    return (
        <>
            <Grid md={12} spacing={2} sx={{p: 1.5}} container>
                <Stack spacing={1.5} flexGrow={1}>
                    <FooterAlert/>

                    <Stack direction={buttonDirection} spacing={1}>
                        <Button
                            size="sm"
                            variant="soft"
                            color="neutral"
                            loading={payments.transactionsLoading}
                            sx={{flexGrow: 1}}
                            onClick={() => {
                                if (project) {
                                    payments.loadTransactionsHistory(project.id, 30, 0).then(() => {
                                        setTransactionsModalOpen(true)
                                    }).catch(ApiClient.toastError);
                                }
                            }}
                        >
                            Операции
                        </Button>

                        <Button
                            size="sm"
                            variant="soft"
                            color="neutral"
                            sx={{flexGrow: 1}}
                            onClick={() => setPromoCodeModalOpened(true)}
                        >
                            Промокод
                        </Button>

                        <Button
                            size="sm"
                            variant="soft"
                            sx={{flexGrow: 1}}
                            onClick={() => setTopUpModalOpened(true)}
                        >
                            Пополнить баланс
                        </Button>

                        <ConfirmModal
                            onConfirm={() => renewTariff(project.id)}
                            acceptTitle={tariffIsActive ? "Продлить" : "Активировать"}
                            acceptColor="primary"
                            description={tariffIsActive ? "Продлить подписку для текущего проекта?" : "Активировать подписку для текущего проекта?"}
                        >
                            <Button
                                size="sm"
                                variant="solid"
                                loading={tariffRenewing}
                                endDecorator={<KeyboardArrowRight/>}
                            >
                                {tariffIsActive ? "Продлить подписку" : "Активировать подписку"}
                            </Button>
                        </ConfirmModal>


                    </Stack>

                </Stack>
            </Grid>

            {transactionsModalOpen && <TransactionsModal close={() => setTransactionsModalOpen(false)}/>}
            {topUpModalOpened && <TopUpModal close={() => setTopUpModalOpened(false)}/>}
            {promoCodeModalOpened && <PromocodeModal close={() => setPromoCodeModalOpened(false)}/>}
        </>
    )
}

const FooterAlert = () => {
    const [project] = useProjectStore(state => [state.selectedProject]);
    const [openAccountEditModal] = useAccountStore(state => [state.openEditModal]);

    if (!project) {
        return <></>;
    }

    if (project.tariff.leftTime > 0) {
        return (
            <Alert size="sm" color="neutral" invertedColors sx={{px: 2}}>
                <Typography level="body-sm">
                    Подписка продлится автоматически, если на балансе проекта будет достаточно средств в день завершения
                    текущего периода.
                </Typography>
            </Alert>
        );
    }

    if (project.type === ProjectType.VK) {
        return (
            <Alert size="sm" color="warning" sx={{px: 2}}>
                <Typography level="body-sm" textColor="warning.600">
                    Подписка не активна, чат-бот работает только для сотрудников проекта, у которых привязан
                    аккаунт ВКонтакте в{' '}
                    <Link onClick={openAccountEditModal}>настройках профиля</Link>.
                </Typography>
            </Alert>
        );
    } else {
        return (
            <Alert size="sm" color="warning" sx={{px: 2}}>
                <Typography level="body-sm" textColor="warning.600">
                    Подписка не активна, чат-бот работает только для сотрудников проекта, у которых указан корректный
                    Telegram аккаунт в{' '}
                    <Link onClick={openAccountEditModal}>настройках профиля</Link>.
                </Typography>
            </Alert>
        );
    }
}
