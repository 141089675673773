import {AuthContainer} from "../Auth/AuthContainer";
import CircularProgress from "@mui/joy/CircularProgress";
import {LogIn} from "react-feather";
import React, {useEffect} from "react";
import Stack from "@mui/joy/Stack";
import vkBridge from "@vkontakte/vk-bridge";
import {useVKMiniAppStore} from "../Stores/VKMiniAppStore";
import {useNavigate} from "react-router-dom";
import {PAGE} from "../types";

export const VKMiniApp = () => {
    const setVKMiniApp = useVKMiniAppStore(state => state.setMiniApp);
    const navigate = useNavigate();

    useEffect(() => {
        vkBridge.send('VKWebAppInit');
        setVKMiniApp(true);
        navigate(PAGE.SIGN_IN);

        // todo - тут вместо редиректа на авторизацию делаем бесшовную авторизацию из переданных
        //  параметров запуска вк мини апа и поддерживаем во всем приложении нативные фичи
        //  вк бриджа, как сделано в старом приложении.

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthContainer title={'Авторизация в мини приложении ВКонтакте'}>
            <Stack alignItems="center" justifyContent="center" padding={7}>
                <CircularProgress color="neutral" sx={{'--CircularProgress-size': '80px'}}>
                    <LogIn color="var(--joy-palette-neutral-400)"/>
                </CircularProgress>
            </Stack>
        </AuthContainer>
    );
}
