import React, {FormEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {Keyboard} from "./Keyboard/Keyboard";
import {KeyboardRows} from "./Keyboard/types";
import {Message} from "./Message/Message";
import {MessageNodeData} from "../types";
import {NodeHeader} from "../../NodeHeader";
import {Files} from "./Files/Files";
import {NodeFile} from "./Files/types";
import {useIsPreviewMode, useUpdateNode} from "../../utils";
import {useScenarioUpdateStore} from "../../../Stores/ScenarioUpdateStore";
import Box from "@mui/joy/Box";
import {NodeHandleRole, NodeSourceHandle} from "../../Edges/NodeHandle";
import {Divider, Tooltip} from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import {Settings} from "react-feather";
import {ModalBox} from "../../../Components/ModalBox";
import {Form} from "react-router-dom";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import {SwitchControl} from "../../../Components/Form/SwitchControl";
import {MessageNodeOptions} from "./types";

type props = {
    id: string;
    data: MessageNodeData;
};

export const MessageNode = (props: props) => {
    const updateNode = useUpdateNode();
    const [keyboard, setKeyboard] = useState<KeyboardRows>(props.data.buttons);
    const [files, setFiles] = useState<NodeFile[]>(props.data.files ?? []);
    const [message, setMessage] = useState<string>(props.data.message);
    const canUploadMultipleFiles = message.length === 0 && keyboard.length === 0;

    useEffect(() => {
        updateNode(props.id, {
            message: message.trim(),
            files: files,
            buttons: keyboard,
        } as MessageNodeData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyboard, message, props.id, files]);

    useEffect(() => {
        useScenarioUpdateStore.getState().clear();
    }, []);

    const buttons = useMemo(
        () => <Keyboard
            nodeId={props.id}
            data={keyboard}
            isInline={!props.data.options.notInlineKeyboard}
            setKeyboard={setKeyboard}
            hasFiles={files.length > 0}
            hasMessage={message.length > 0}
        />,
        [files.length, keyboard, message.length, props.data.options.notInlineKeyboard, props.id]
    );

    const msg = useMemo(() => {
        return (
            <Message
                nodeId={props.id}
                message={message}
                setMessage={setMessage}
                setKeyboard={setKeyboard}
                keyboardRows={keyboard}
                files={files}
                options={props.data.options}
                setFiles={setFiles}
                canMultipleFiles={canUploadMultipleFiles}
            />
        );
    }, [canUploadMultipleFiles, files, keyboard, message, props.data.options, props.id])

    return (
        <Box className="Node__container nowheel" sx={{width: 'unset', minWidth: '350px'}}>
            <NodeHeader
                title={"Сообщение"}
                nodeTitle={props.data.title}
                nodeId={props.id}
                nodeHandleRole={NodeHandleRole.MessageNode}
                nodeOptions={
                    <NodeOptionsButton
                        nodeId={props.id}
                        hasKeyboard={keyboard.length > 0}
                        options={props.data.options ?? {}}
                    />
                }
                deletable
                testable
                hasMetrics
            />

            <Box className="Node__content">
                {files.length <= 1 && msg}
                {files.length <= 1 && buttons}
                <Files nodeId={props.id} files={files} setFiles={setFiles} canMultiple={canUploadMultipleFiles}/>
            </Box>

            <NodeSourceHandle id={props.id} role={NodeHandleRole.MessageNode}/>
        </Box>
    );
}


const NodeOptionsButton = ({nodeId, options, hasKeyboard}: {
    nodeId: string,
    hasKeyboard: boolean,
    options: MessageNodeOptions
}) => {
    const [opened, setOpened] = useState(false);

    if (useIsPreviewMode()) {
        return <></>
    }

    return (
        <Box className="Node__headerButton">
            <Tooltip
                title="Настройки сообщения и клавиатуры"
                size="sm"
                color={"neutral"}
                arrow={true}
                placement="top-end"
            >
                <IconButton variant="plain" onClick={() => setOpened(true)}>
                    <Settings size={18}/>
                </IconButton>
            </Tooltip>

            {opened &&
                <NodeOptions
                    nodeId={nodeId}
                    options={options}
                    hasKeyboard={hasKeyboard}
                    close={() => setOpened(false)}
                />
            }
        </Box>
    );
}

const NodeOptions = ({nodeId, options, close, hasKeyboard}: {
    nodeId: string,
    options: MessageNodeOptions,
    hasKeyboard: boolean,
    close: () => void
}) => {
    const updateNode = useUpdateNode();
    const [snippetsDisabled, setSnippetsDisabled] = useState('disableLinkSnippets' in options ? options.disableLinkSnippets : false);
    const [notInlineKeyboard, setNotInlineKeyboard] = useState('notInlineKeyboard' in options ? options.notInlineKeyboard : true);
    const [oneTimeKeyboard, setOneTimeKeyboard] = useState('oneTimeKeyboard' in options ? options.oneTimeKeyboard : false);
    const [emptyKeyboard, setEmptyKeyboard] = useState('emptyKeyboard' in options ? options.emptyKeyboard : false);

    const onSave = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        updateNode(nodeId, {
            options: {
                emptyKeyboard: emptyKeyboard,
                notInlineKeyboard: notInlineKeyboard,
                oneTimeKeyboard: oneTimeKeyboard,
                disableLinkSnippets: snippetsDisabled,
            }
        } as MessageNodeData);

        close();
    }, [updateNode, nodeId, emptyKeyboard, notInlineKeyboard, oneTimeKeyboard, snippetsDisabled, close]);

    return (
        <ModalBox
            open={true}
            onClose={close}
            title='Настройки сообщения и клавиутары'
            sx={{maxWidth: 500}}
        >
            <Form onSubmit={onSave}>
                <Stack spacing={2}>
                    <SwitchControl
                        label="Отключить сниппеты для ссылок"
                        text='Сниппет - это предварительный просмотр, прямо в сообщении от бота, веб-страницы, на которую ведет ссылка.'
                        checked={snippetsDisabled}
                        onChange={(value) => setSnippetsDisabled(value)}
                    />

                    <SwitchControl
                        label="Клавиатура под полем ввода"
                        text='Расположение кнопок под полем ввода сообщения, а не в самом сообщении от бота.'
                        checked={notInlineKeyboard}
                        disabled={!hasKeyboard}
                        onChange={(value) => setNotInlineKeyboard(value)}
                    />

                    <SwitchControl
                        label="Одноразовая клавиатура"
                        text='Кнопки клавиатуры пропадут сразу после нажатия на любую кнопку, работает только с клавиатурой под полем ввода.'
                        checked={oneTimeKeyboard}
                        disabled={!notInlineKeyboard || !hasKeyboard}
                        onChange={(value) => setOneTimeKeyboard(value)}
                    />

                    <SwitchControl
                        label="Очистить клавиатуру"
                        text='Удалит клавиатуру под полем ввода, которая была отправлена ранее, опция доступна если у сообщения не добавлены кнопки.'
                        checked={emptyKeyboard}
                        disabled={hasKeyboard}
                        onChange={(value) => setEmptyKeyboard(value)}
                    />

                    <Divider/>

                    <Stack spacing={1}>
                        <Button type='submit'>Сохранить</Button>
                    </Stack>
                </Stack>
            </Form>
        </ModalBox>
    );
}
