import './StartNode.css';
import {KeyMode, ScenarioStartNodeData} from "../types";
import React, {BaseSyntheticEvent, useCallback, useEffect, useState} from "react";
import {Position} from "reactflow";
import {NodeHeader} from "../../NodeHeader";
import {Box, Stack, Textarea, Tooltip} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {ModeMenu} from "./ModeMenu";
import {useUpdateNode} from "../../utils";
import {useScenarioUpdateStore} from "../../../Stores/ScenarioUpdateStore";
import {useScenariosPreviewStore} from "../../../Stores/ScenarioPreviewStore";
import {FormInput} from "../../../Components/Form/FormInput";
import {HelpCircle} from "react-feather";
import {useInputErrors, validateInput, validateMaxLength} from "../../../Components/Form/helpers";
import {NodeHandleRole, NodeSourceHandle} from "../../Edges/NodeHandle";

type props = {
    id: string;
    data: ScenarioStartNodeData;
};

export const ScenarioStartNode = (props: props) => {
    const updateNode = useUpdateNode();
    const [name, setName] = useState<string>(props.data.name ?? "");
    const [keys, setKeys] = useState<string>(props.data.keys);
    const [mode, setMode] = useState<KeyMode>(props.data.keysMode);
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const [inputErrors, setInputErrors] = useInputErrors();

    const onKeysChange = useCallback((evt: BaseSyntheticEvent) => {
        const value = evt.target.value.replace(/(\r\n|\n|\r)/gm, "");
        setKeys(value);
    }, []);

    const validators = useCallback(() => {
        return {
            name: (value: any) => validateInput(
                "name", value, setInputErrors,
                {func: validateMaxLength, threshold: 48},
            ),
        }
    }, [setInputErrors]);

    useEffect(() => {
        updateNode(props.id, {
            name: name,
            keys: keys,
            keysMode: mode,
        } as ScenarioStartNodeData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keys, name, mode, props.id]);

    useEffect(() => {
        useScenarioUpdateStore.getState().clear();
    }, []);

    useEffect(() => {
        validators().name(name);
    }, [name, validators]);

    return (
        <Box className="Node__container nowheel" sx={{width: '320px;'}}>
            <NodeHeader
                title={"Запуск сценария"}
                nodeId={props.id}
                nodeHandleRole={NodeHandleRole.StartScenarioNode}
                hasMetrics
            />

            <Box className="Node__content">
                <Stack direction="column" spacing={2}>
                    <FormInput
                        type="text"
                        label="Название"
                        variant="soft"
                        value={name}
                        setValue={setName}
                        validate={validators().name}
                        errorText={inputErrors.get("name")}
                        placeholder="Введите название сценария.."
                        disabled={isPreviewMode}
                        endDecorator={
                            <Tooltip
                                color={"primary"}
                                variant={'soft'}
                                arrow
                                title={'Название выводится только в  интерфейсе конструктора.'}
                                size={'md'}
                                placement={Position.Top}
                            >
                                <HelpCircle style={{cursor: 'pointer'}} size={20}/>
                            </Tooltip>
                        }
                    />

                    <Box>
                        <Typography level="body-sm" className="Node__headerTitle">
                            Сценарий запустится, если сообщение&thinsp;

                            <ModeMenu setMode={setMode}>
                        <span className="StartNode__keysModeSelector">
                            {mode === KeyMode.Strict ? 'строго равно' : 'содержит'}
                        </span>
                            </ModeMenu>

                            &thinsp;{mode === KeyMode.Strict ? 'одному из ключевых слов' : 'одно из ключевых слов'}:
                        </Typography>

                        <Textarea
                            color="neutral"
                            minRows={1}
                            maxRows={5}
                            size="sm"
                            value={keys}
                            onChange={onKeysChange}
                            disabled={isPreviewMode}
                            placeholder="Введите слова или фразы через запятую.."
                            variant="soft"
                            className="StartNode__keysInput"
                        />
                    </Box>
                </Stack>
            </Box>

            <NodeSourceHandle id={props.id} role={NodeHandleRole.StartScenarioNode}/>
        </Box>
    );
}
