import {FormInput} from "../../Components/Form/FormInput";
import React, {FormEvent, useCallback, useState} from "react";
import {Button, Card, Link} from "@mui/joy";
import {ModalContent} from "../../Components/ModalContent";
import {Form} from "react-router-dom";
import {
    useInputErrors,
    validateInput,
    validateMaxLength,
    validateRequired,
    validateTelegramBotToken
} from "../../Components/Form/helpers";
import {Channel} from "../../types";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {useChannelsStore} from "../../Stores/ChannelsStore";

export const RefreshTokenTelegram = (props: { channel: Channel }) => {
    const [open, setOpen] = React.useState(false);
    const [token, setToken] = React.useState("");
    const [inputErrors, setInputErrors] = useInputErrors();
    const [isSending, setIsSending] = useState(false);
    const [refresh] = useChannelsStore(state => [state.refreshTokenTelegram])

    const onClose = useCallback(() => {
        setToken("");
        setInputErrors(new Map());
        setIsSending(false);
        setOpen(false);
    }, [setInputErrors])

    const validators = useCallback(() => {
        return {
            token: (value: any) => validateInput(
                "token", value, setInputErrors,
                {func: validateRequired},
                {func: validateTelegramBotToken},
                {func: validateMaxLength, threshold: 128}
            ),
        }
    }, [setInputErrors]);

    const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errors = [validators().token(token)].filter((error) => error !== null);

        if (errors.length > 0) {
            return
        }

        setIsSending(true);
        refresh(props.channel.id, props.channel.projectId, token, onClose).then(() => setIsSending(false));
    }, [validators, token, refresh, props.channel.id, props.channel.projectId, onClose]);

    return (
        <ModalContent
            onClose={onClose}
            open={open}
            setOpen={setOpen}
            width={500}
            title={'Обновление токена доступа'}
            startButton={<Button variant="soft" size="sm">Обновить токен</Button>}
        >
            <Form onSubmit={onSubmit}>
                <FormInput
                    label="Токен бота"
                    type="text"
                    value={token}
                    setValue={setToken}
                    validate={validators().token}
                    errorText={inputErrors.get("token")}
                    placeholder="123847574:AAFGgjaflmLGysg..."
                />

                <Card variant="soft" color="primary" sx={{boxShadow: 'none', mt: 2}}>
                    <Typography level="body-sm">
                        Для получения нового токена для вашего бота в Telegram напишите&nbsp;
                        <b><Link href="https://telegram.me/BotFather" target="_blank">@BotFather</Link></b>
                        &nbsp;команду <i><b>/revoke</b></i>, выберите из списка нужного бота и скопируйте из
                        ответного сообщения токен доступа в поле выше.
                    </Typography>
                </Card>

                <Box sx={{display: 'flex', gap: 2, mt: 2, justifyContent: 'flex-end'}}>
                    <Button onClick={onClose} color="neutral" variant="outlined">Отмена</Button>
                    <Button loading={isSending} type="submit" variant="solid">Сохранить</Button>
                </Box>
            </Form>

        </ModalContent>
    )
}