import {FlowScheme} from "./Constructor/Flow";
import {
    BroadcastingAudienceMode,
    BroadcastingStartRule
} from "./Constructor/Nodes/BroadcastingStartNode/BroadcastingStartNode";

export const AUTH_COOKIE = 't';

export enum API_METHOD {
    PROJECTS_LIST = '/projects.list',
    PROJECTS_USERS = '/projects.getUsers',
    PROJECTS_VK_REFRESH_USERS_LIST = '/projects.refreshVKUsersList',

    SCENARIOS = '/scenarios.list',
    SCENARIO = '/scenarios.getOne',
    SCENARIO_GET_ONE_PREVIEW = '/scenarios.getOnePreview',
    SCENARIO_COPY = '/scenarios.copy',
    SCENARIO_GET_SETTINGS = '/scenarios.getSettings',
    SCENARIO_SAVE_SETTINGS = '/scenarios.saveSettings',
    SCENARIO_SAVE = '/scenarios.save',
    SCENARIO_DELETE = '/scenarios.delete',
    SCENARIO_TEST = '/scenarios.test',
    SCENARIO_TOGGLE_ENABLED = '/scenarios.toggleEnabled',
    SCENARIO_TEMPLATES = '/scenarios.getTemplates',

    BROADCASTING_SAVE = '/broadcasting.save',
    BROADCASTING_DELETE = '/broadcasting.delete',
    BROADCASTING_START = '/broadcasting.start',
    BROADCASTING_COPY = '/broadcasting.copy',
    BROADCASTING_GET = '/broadcasting.get',
    BROADCASTING_LIST = '/broadcasting.list',

    VK_GET_GROUPS = '/vk.getGroups',
    VK_CONNECT_GROUP = '/vk.connectGroup',
    VK_RECONNECT_CALLBACK_SERVER = '/vk.reconnectCallbackServer',

    TELEGRAM_CONNECT_BOT = '/telegram.connectBot',

    GET_UPLOAD_URL = '/files.uploadUrl',
    SAVE_FILE = '/files.save',
    ACCOUNT_REGISTER = '/account.register',
    ACCOUNT_LOGIN = '/account.login',
    ACCOUNT_LOGIN_ONE_TAP_VK = '/account.loginOneTapVK',
    ACCOUNT_BIND_ONE_TAP_VK = '/account.bindOneTapVK',
    ACCOUNT_RESTORE_SEND_CODE = '/account.restoreSendCode',
    ACCOUNT_RESTORE_CHECK_CODE = '/account.restoreCheckCode',
    ACCOUNT_RESTORE_PASSWORD = '/account.restorePassword',
    ACCOUNT_GET_ME = '/account.getMe',
    ACCOUNT_UPDATE = '/account.update',
    CHARTS_DATA = '/charts.getMetricData',
    PAYMENTS_ORDER_CREATE = '/payments.orderCreate',
    PAYMENTS_TRANSACTIONS_HISTORY = '/payments.transactionsHistory',
    CHARTS_SCENARIOS_UNIQS = '/charts.getScenariosUniqs',
    CHARTS_SCENARIO_METRICS = '/charts.getScenarioMetrics',
    CHANNELS_GET = '/channels.get',
    CHANNELS_TG_RECONNECT = '/channels.reconnect',
    CHANNELS_TG_REFRESH_TOKEN = '/channels.refreshToken',
    HOME_TOP_COUNTERS = '/home.stats',
    PROJECT_RENEW_TARIFF = '/projects.renewTariff',
    PROJECT_USE_PROMO_CODE = '/projects.usePromoCode',
    PROJECT_EMPLOYEES = '/projects.getEmployees',
    PROJECT_EMPLOYEE_ADD = '/projects.addEmployee',
    PROJECT_EMPLOYEE_REMOVE = '/projects.removeEmployee',
}

export enum PAGE {
    MAIN = '/',
    BROADCASTING = '/broadcasting',
    USERS = '/users',
    BROADCASTING_CREATE = '/broadcasting/create',
    BROADCASTING_EDIT = '/broadcasting/:id',
    SCENARIOS = '/scenarios',
    SCENARIO_EDIT = '/scenarios/:id',
    SCENARIO_CREATE = '/scenarios/create',
    CHARTS = '/charts',
    EMPLOYEES = '/employees',
    PROJECT_CREATE = '/project-create',
    SIGN_UP = '/sign-up',
    SIGN_IN = '/sign-in',
    SIGN_IN_VK = '/sign-in-vk',
    WIZARD_SIGN_IN_VK = '/wizard-sign-in-vk',
    WIZARD_VK = '/wizard-vk',
    CONNECT_VK_GROUP = '/connect-vk-group',
    RECONNECT_VK_GROUP = '/reconnect-vk-group',
    WIZARD_CONNECT_VK_GROUP = '/wizard-connect-vk-group',
    RESTORE_PASSWORD = '/restore-password',
}

export enum LS_KEY {
    PROJECT_ID = 'projectId',
    Preferences = 'preferences',
    ConstructorMapHidden = 'constructorMapHidden',
}

export enum EmployeeRole {
    Owner = 1,
    Admin = 2,
}

export enum TransactionType {
    Incoming = 1,
    Outgoing = 2,
}

export enum PaymentCurrency {
    RUB = 'RUB',
}

export interface ApiResponse {
    success: boolean;
    data: any;
    code: number;
}

export interface ApiResponsePaymentOrderCreate {
    paymentUrl: string
}

export interface ApiResponseRegister {
    token: string;
}

export interface ApiResponseLogin {
    token: string;
    projects: number;
}

export interface ApiResponseUploadUrl {
    url: string;
    maxSize: number;
    types: string;
}

export interface ApiResponseSaveFile {
    id: number;
    url: string;
    type: string;
    objectKey: string;
}

export interface StorageResponsePutObject {
    key: string;
    size: number;
    sign: string;
    url: string;
}


export enum ProjectType {
    VK = 'vk',
    Telegram = 'tg'
}

export enum VKRefreshUsersStatus {
    Queued = 1,
    Active = 2,
    Finished = 3,
    Error = 4,
}

export interface Project {
    id: number;
    type: ProjectType;
    name: string;
    photo: string;
    balance: string;
    tariff: ProjectTariff;
}

export interface ProjectTariff {
    id: number;
    price: string;
    leftTime: number;
    expiresAt: string;
    extendSufficientFunds: boolean;
}

export interface PaymentTransaction {
    id: number;
    type: TransactionType;
    description: string;
    amount: string;
    currency: PaymentCurrency;
    date: string;
}

export interface Tariff {
    id: number;
    price: string;
}

export interface Account {
    id: number;
    name: string;
    photo: string;
    email: string;
    telegramUsername: string;
    vkUserId: number;
}

export interface Channel {
    id: number;
    enabled: boolean;
    projectId: number;
    botId: number;
    username: string;
    name: string;
}

export interface HomeTopCounters {
    activeUsers: number;
    inactiveUsers: number;
    totalUsers: number;
    monthNewUsers: number;
}

export interface Scenario {
    id: number;
    uuid: string;
    name: string;
    keys: string;
    keysMode: number;
    projectId: number;
    type: number;
    enabled: number;
    scheme: FlowScheme;
    publicCopyAllowed: boolean;
    publicViewAllowed: boolean;
}

export interface ScenarioSettings {
    id: number;
    uuid: string;
    projectId: number;
    directStartLink: string;
    publicViewAllowed: boolean;
    publicCopyAllowed: boolean;
}

export interface Broadcasting {
    id: number;
    name: string;
    status: number;
    projectId: number;
    schedule: string;
    startAt: string;
    startRule: BroadcastingStartRule;
    audience: number;
    AudienceMode: BroadcastingAudienceMode;
    scheme: FlowScheme;
}

export interface ProjectChannelUser {
    userId: number;
    username: string;
    firstname: string;
    isActive: boolean;
    createdAt: string;
}

export interface Employee {
    Id: number;
    Name: string;
    ProjectId: number;
    Email: string;
    Role: EmployeeRole;
}

export interface ScenariosMetrics {
    dates: string[];
    scenarios: any;
}

export interface ScenarioMetrics {
    nodes: any
}
