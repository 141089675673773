import {create} from "zustand";
import {API_METHOD, Project} from "../types";
import {ApiClient} from "../api-client";
import {toastSuccess} from "../utils";


interface VKStore {
    groupsLoading: boolean;
    groupConnecting: boolean;

    getGroups: () => Promise<VKGroup[]>;
    connectGroup: (id: number, code: string, redirectUrl: string, isReconnect: boolean) => Promise<Project>;
    reconnectCallbackServer: (id: number, projectId: number) => Promise<void>;
}

export interface VKGroup {
    id: number;
    name: string;
    photo: string;
    screenName: string;
    membersCount: number;
}

export const useVKStore = create<VKStore>((set) => ({
    groupsLoading: false,
    groupConnecting: false,

    getGroups() {
        set({groupsLoading: true});

        return ApiClient.get(API_METHOD.VK_GET_GROUPS)
            .then(r => r.data as VKGroup[])
            .finally(() => set({groupsLoading: false}));
    },

    reconnectCallbackServer(channelId, projectId) {
        return ApiClient.post(API_METHOD.VK_RECONNECT_CALLBACK_SERVER, {channelId}, {params: {projectId}})
            .then(r => toastSuccess("Канал успешно переподключен."))
            .catch(ApiClient.toastError);
    },

    connectGroup(id, code, redirectUrl, isReconnect) {
        set({groupConnecting: true});

        const data = {
            groupId: id,
            code: code,
            redirectUrl: redirectUrl,
            isReconnect: isReconnect
        };

        return ApiClient.post(API_METHOD.VK_CONNECT_GROUP, data)
            .then(r => r.data as Project)
            .finally(() => set({groupConnecting: false}))
        }
}));
