import {Experimental_CssVarsProvider as MaterialCssVarsProvider} from "@mui/material/styles/CssVarsProvider";
import {experimental_extendTheme as materialExtendTheme, THEME_ID as MATERIAL_THEME_ID} from "@mui/material/styles";
import {CssVarsProvider as JoyCssVarsProvider} from "@mui/joy/styles/CssVarsProvider";
import {theme} from "../theme";
import CssBaseline from "@mui/joy/CssBaseline";
import {ApexChartStyles} from "./Charts/utils";
import * as React from "react";

const materialTheme = materialExtendTheme();

export const AppContainer = ({children}: { children: any }) => {
    return (
        <MaterialCssVarsProvider theme={{[MATERIAL_THEME_ID]: materialTheme}}>
            <JoyCssVarsProvider disableTransitionOnChange theme={theme}>
                <CssBaseline/>
                <ApexChartStyles/>

                {children}
            </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
    )
}
