import {create} from "zustand";
import {API_METHOD, Broadcasting} from "../types";
import {ApiClient} from "../api-client";

interface BroadcastingStore {
    loading: boolean;
    loaded: boolean;
    list: Broadcasting[];

    load: (projectId: number) => Promise<Broadcasting[]>;
}

export const useBroadcastingListStore = create<BroadcastingStore>((set) => ({
    loading: false,
    loaded: false,
    list: [],

    load: async (projectId: number) => {
        let list: Broadcasting[] = [];

        set({loading: true, loaded: false});

        await ApiClient.get(API_METHOD.BROADCASTING_LIST, {params: {projectId}}).then(r => {
            list = r.data as Broadcasting[];
            set({list: list, loaded: true});
        }).catch(ApiClient.toastError).finally(() => set({loading: false}));

        return Promise.resolve(list)
    },
}));
