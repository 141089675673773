import {useNavigate, useSearchParams} from "react-router-dom";
import CircularProgress from "@mui/joy/CircularProgress";
import {Key} from "react-feather";
import Stack from "@mui/joy/Stack";
import React, {useEffect, useRef, useState} from "react";
import {Box, Card} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import {ApiClient, ApiError} from "../api-client";
import {PAGE} from "../types";
import {AuthContainer} from "../Auth/AuthContainer";
import {useVKStore} from "../Stores/VKStore";
import {pageLink, toastSuccess} from "../utils";
import {useProjectStore} from "../Stores/ProjectStore";

export const VKConnectGroup = ({redirectPage, backPage, successPage, isReconnect}: {
    redirectPage: PAGE,
    backPage: PAGE,
    successPage: PAGE,
    isReconnect?: boolean
}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [code] = useState(searchParams.get('code'));
    const [groupId] = useState(Number(searchParams.get('state')));
    const [vkError] = useState(searchParams.get('error'))
    const [vkErrorDesc] = useState(searchParams.get('error_description'))
    const [error, setError] = useState('');
    const ref = useRef<HTMLDivElement>(null);
    const [connectGroup] = useVKStore(state => [state.connectGroup])

    useEffect(() => {
        if (!ref.current || !code) {
            return;
        }

        if (ref.current && ref.current.getAttribute('data-signing')) {
            return;
        }

        // Костыль для защиты от двойного срабатывания в dev режиме
        ref.current.setAttribute('data-signing', "1");

        connectGroup(groupId, String(code), pageLink(redirectPage), isReconnect || false).then(project => {
            toastSuccess("Бот успешно подключен к сервису!");
            useProjectStore.getState().setSelectedProject(project);
            useProjectStore.getState().fetchProjects().then(() => navigate(successPage));
        }).catch(err => {
            if (err instanceof ApiError) {
                setError(err.response.data);
            } else {
                console.error(err);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ErrorCard = ({error}: { error: string }) => {
        return (
            <Stack direction="column" spacing='15px'>
                <Card invertedColors variant="soft" color="danger">
                    <Typography level="body-sm">
                        {error}
                    </Typography>
                </Card>
                <Button variant="soft" color="neutral" onClick={() => {
                    ApiClient.redirect(backPage);
                }}>
                    Вернуться назад
                </Button>
            </Stack>
        );
    };

    return (
        <AuthContainer title="Подключаем ваше сообщество к сервису">
            <Box ref={ref}>
                {(!error && !vkError) &&
                    <Stack alignItems="center" justifyContent="center" padding={7}>
                        <CircularProgress color="neutral" sx={{'--CircularProgress-size': '80px'}}>
                            <Key color="var(--joy-palette-neutral-400)"/>
                        </CircularProgress>
                    </Stack>
                }

                {error && <ErrorCard error={error}/>}
                {vkError && <ErrorCard error={vkError + ' ' + vkErrorDesc}/>}
            </Box>
        </AuthContainer>
    )
}
