import {create} from "zustand";
import {API_METHOD, Channel,} from "../types";
import {toastSuccess} from "../utils";
import {ApiClient} from "../api-client";

interface ChannelsStore {
    channels: Channel[];
    loaded: boolean;

    fetchChannels: (projectId: number) => Promise<void>;
    reconnectChannelTelegram: (channelId: number, projectId: number) => Promise<void>;
    refreshTokenTelegram: (channelId: number, projectId: number, token: string, onClose: () => void) => Promise<void>;
}

export const useChannelsStore = create<ChannelsStore>((set) => ({
    channels: [],
    loaded: false,
    fetchChannels: async (projectId: number) => {
        return ApiClient.get(API_METHOD.CHANNELS_GET, {params: {projectId: projectId}})
            .then(r => set({channels: r.data as Channel[], loaded: true}))
            .catch(ApiClient.toastError);
    },
    reconnectChannelTelegram: async (channelId: number, projectId: number) => {
        return ApiClient.post(API_METHOD.CHANNELS_TG_RECONNECT, {channelId}, {params: {projectId}}).then(_ => {
            toastSuccess("Канал успешно переподключен.")
        }).catch(ApiClient.toastError);
    },

    refreshTokenTelegram: async (channelId: number, projectId: number, token: string, onClose: () => void) => {
        return ApiClient.post(API_METHOD.CHANNELS_TG_REFRESH_TOKEN, {
            channelId,
            token
        }, {params: {projectId}}).then(_ => {
            onClose();
            toastSuccess("Токен успешно обновлен.");
        }).catch(ApiClient.toastError);
    }
}));
