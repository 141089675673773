import {extendTheme} from '@mui/joy/styles';

export const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                background: {
                    body: 'rgb(240, 242, 245)',
                    backdrop: 'rgba(0,0,0, 0.58)',
                },
                divider: 'rgba(145, 158, 171, 0.2)',
                neutral: {
                    outlinedBorder: 'rgba(145, 158, 171, 0.2)',
                }
            },
            shadowChannel: '0 0 0 1px rgba(255 255 255 / 0.1)',
        },
        dark: {
            palette: {
                neutral: {
                    outlinedBorder: 'var(--joy-palette-neutral-700)',
                },
            },
        },
    },
    fontFamily: {
        display: "'Inter', var(--joy-fontFamily-fallback)",
        body: "'Inter', var(--joy-fontFamily-fallback)",
    },
});
