import {create} from "zustand";

interface VKMiniAppStore {
    isMiniApp: boolean;
    setMiniApp: (isMiniApp: boolean) => void;
}

export const useVKMiniAppStore = create<VKMiniAppStore>((set) => ({
    isMiniApp: false,

    setMiniApp: (isMiniApp) => {
        set({isMiniApp});
    }
}));
