import {FormHelperText, Typography} from "@mui/joy";
import Switch from "@mui/joy/Switch";
import FormControl from "@mui/joy/FormControl";
import React from "react";
import {ColorPaletteProp} from "@mui/joy/styles/types/colorSystem";

type props = {
    label: string;
    text?: string;
    checked: boolean;
    onChange: (value: boolean) => void;
    color?: ColorPaletteProp;
    disabled?: boolean;
}

export const SwitchControl = ({label, text, checked, onChange, color, disabled}: props) => {
    return (
        <FormControl>
            <Typography
                component="label"
                level="body-md"
                sx={{cursor: 'pointer'}}
                className={'noselect'}
                textColor={disabled ? 'neutral.400' : undefined}
                startDecorator={
                    <Switch
                        checked={checked}
                        onChange={(e) => onChange(e.target.checked)}
                        sx={{mr: 0.5}}
                        color={color}
                        disabled={disabled}
                    />
                }>
                {label}
            </Typography>

            {text &&
                <FormHelperText>
                    <Typography level="body-sm" textColor={disabled ? 'neutral.400' : 'neutral.500'}>
                        {text}
                    </Typography>
                </FormHelperText>
            }
        </FormControl>
    );
}