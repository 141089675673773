import {Handle, Position} from "reactflow";
import React from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

export enum NodeHandleRole {
    AnswerWaitingNode = 'AnswerWaitingNode',
    ConditionNode = 'ConditionNode',
    MessageNode = 'MessageNode',
    JumpNode = 'JumpNode',
    MessageNodeButton = 'MessageNodeButton',
    PauseNode = 'PauseNode',
    RandomSelectionNode = 'RandomSelectionNode',
    StartScenarioNode = 'StartScenarioNode',
    StartBroadcastingNode = 'StartBroadcastingNode',
}

export const NodeTargetHandle = ({id, position, role}: { id: string, position?: Position, role: NodeHandleRole }) => {
    if (!position) {
        position = Position.Left;
    }

    return (
        <>
            <Box sx={{
                height: 'auto',
                width: '3px',
                backgroundColor: 'var(--joy-palette-neutral-400)'
            }}/>
            <div id={`${id}-target`} data-handle-role={role}/>
            <Handle
                id={`${id}`}
                key="handle-top"
                type="target"
                className={'Node__targetHandle'}
                position={position}
            />
        </>
    );
}

export const NodeSourceHandle = ({id, position, role, title}: { id: string, position?: Position, role: NodeHandleRole, title ?: string }) => {
    if (!position) {
        position = Position.Right;
    }

    return (
        <>
            <Box sx={{textAlign: 'right', padding: 1.5, paddingTop: 0.5}}>
                <Typography textColor="neutral.300" level="body-xs">{title ?? 'Следующий шаг'}</Typography>
            </Box>
            <div id={`${id}-source`} data-handle-role={role}/>
            <Handle
                key="handle-bottom"
                id={`${id}`}
                className={'Node__sourceHandle'}
                type="source"
                position={position}
            />
        </>
    );
}
