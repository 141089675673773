import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import {DefaultColorPalette} from "@mui/joy/styles/types/colorSystem";
import {ModalBox} from "./ModalBox";
import Stack from "@mui/joy/Stack";

type props = {
    children: React.JSX.Element;
    title?: string;
    description: any;
    acceptTitle?: string;
    hideClose?: boolean;
    acceptColor?: DefaultColorPalette;
}

export const InfoModal = (props: props): React.JSX.Element => {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            {React.cloneElement(props.children, {onClick: () => setOpen(true)})}
            <ModalBox
                open={open}
                onClose={() => setOpen(false)}
                title={props.title}
                sx={{maxWidth: 500, minWidth: {sm: 500}}}
            >
                <Box sx={{mt: 2}}>
                    {props.description}
                </Box>

                <Stack justifyContent="flex-end" direction="row" sx={{mt: 2}}>
                    <Button variant="plain" color={props.acceptColor ?? "neutral"} onClick={() => setOpen(false)}>
                        {props.acceptTitle ?? 'Закрыть'}
                    </Button>
                </Stack>
            </ModalBox>
        </React.Fragment>
    )
}
