import {RandomSelectionNodeData} from "../types";
import React, {useCallback, useEffect, useState} from "react";
import {NodeHeader} from "../../NodeHeader";
import Typography from "@mui/joy/Typography";
import {Card} from "@mui/joy";
import {useUpdateNode} from "../../utils";
import {useScenarioUpdateStore} from "../../../Stores/ScenarioUpdateStore";
import {FormInput} from "../../../Components/Form/FormInput";
import {
    useInputErrors,
    validateInput,
    validateMaxValue,
    validateMinValue,
    validateRequired
} from "../../../Components/Form/helpers";
import {numeralsLabel} from "../../../utils";
import {useScenariosPreviewStore} from "../../../Stores/ScenarioPreviewStore";
import {NodeHandleRole, NodeSourceHandle} from "../../Edges/NodeHandle";
import Box from "@mui/joy/Box";

export const RandomSelectionNode = ({id, data}: { id: string; data: RandomSelectionNodeData; }) => {
    const [count, setCount] = useState(data.count);
    const [inputErrors, setInputErrors] = useInputErrors();
    const updateNode = useUpdateNode();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    const validators = useCallback(() => {
        return {
            count: (value: any) => validateInput(
                "count", value, setInputErrors,
                {func: validateRequired},
                {func: validateMinValue, threshold: 1},
                {func: validateMaxValue, threshold: 30},
            ),
        }
    }, [setInputErrors]);

    useEffect(() => {
        useScenarioUpdateStore.getState().clear();
    }, []);

    useEffect(() => {
        if (validators().count(count) !== null) {
            return;
        }

        updateNode(id, {
            count: count,
        } as RandomSelectionNodeData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, id, validators]);

    return (
        <Box className="Node__container RandomSelectionNode nowheel">
            <NodeHeader
                title={"Случайный выбор"}
                nodeTitle={data.title}
                nodeId={id}
                nodeHandleRole={NodeHandleRole.RandomSelectionNode}
                testable
                deletable
            />

            <Box className="Node__content">
                <FormInput
                    type="number"
                    label="Сколько блоков запустить"
                    value={String(count)}
                    setValue={(v) => setCount(Number(v))}
                    validate={validators().count}
                    errorText={inputErrors.get("count")}
                    disabled={isPreviewMode}
                    placeholder="Введите количество блоков.."
                />

                <Card invertedColors variant="soft" color="neutral" sx={{mt: '15px'}}>
                    <Typography level="body-md">
                        Будет{" "}
                        {numeralsLabel(count, "запущен", "запущено", "запущено")}{" "}
                        <strong>{count}</strong>{" "}
                        {numeralsLabel(count, "случайный блок", "случайных блока", "случайных блоков")} из списка
                        следующих.
                    </Typography>
                </Card>
            </Box>

            <NodeSourceHandle
                id={id}
                role={NodeHandleRole.RandomSelectionNode}
                title="Список шагов, из которых выбрать случайные"
            />
        </Box>
    );
}
