import './PauseNode.css';
import {
    Day,
    Hour,
    Minute,
    MomentDateFormat,
    MomentDayTimeFormat,
    MomentTimeFormat,
    PauseNodeData,
    PauseNodeType,
    TimeType,
    WeekDay
} from "../types";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useReactFlow} from "reactflow";
import {NodeHeader} from "../../NodeHeader";
import Typography from "@mui/joy/Typography";
import {Card, Divider, FormHelperText, Option, Select, Stack} from "@mui/joy";
import {FormInput} from "../../../Components/Form/FormInput";
import {
    useInputErrors,
    validateInput,
    validateMaxValue,
    validateMinValue,
    validateRequired
} from "../../../Components/Form/helpers";
import {numeralsLabel} from "../../../utils";
import {useUpdateNode} from "../../utils";
import {useScenarioUpdateStore} from "../../../Stores/ScenarioUpdateStore";
import {Dropdown} from "../../../Components/Dropdown";
import moment from "moment/moment";
import {useScenariosPreviewStore} from "../../../Stores/ScenarioPreviewStore";
import {NodeHandleRole, NodeSourceHandle} from "../../Edges/NodeHandle";
import Box from "@mui/joy/Box";
import Input from "@mui/joy/Input";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";

type props = {
    id: string;
    data: PauseNodeData;
};

const MaxDays = 90;
const MaxHours = MaxDays * 24;
const MaxMinutes = MaxHours * 60;

export const PauseNode = (props: props) => {
    const updateNode = useUpdateNode();
    const [type, setType] = useState<PauseNodeType>(props.data.pauseType ?? PauseNodeType.Timer);
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    useEffect(() => {
        useScenarioUpdateStore.getState().clear();
    }, []);

    useEffect(() => {
        updateNode(props.id, {
            pauseType: type,
        } as PauseNodeData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const PauseNodeDropdownTypes = [
        {label: "По таймеру", value: PauseNodeType.Timer},
        {label: "В конкретную дату", value: PauseNodeType.Date},
        {label: "В конкретный день недели ", value: PauseNodeType.Weekday},
    ];

    const sourceHandleTitle = {
        [PauseNodeType.Timer]: 'Следующий шаг, когда пройдет таймер',
        [PauseNodeType.Date]: 'Следующий шаг, когда наступит выбранная дата',
        [PauseNodeType.Weekday]: 'Следующий шаг, когда наступит выбранный день'
    }[type];

    return (
        <Box className="Node__container nowheel" sx={{width: 365}}>
            <NodeHeader
                title={"Отложенная отправка"}
                nodeTitle={props.data.title}
                nodeId={props.id}
                nodeHandleRole={NodeHandleRole.PauseNode}
                testable
                deletable
            />

            <Box className="Node__content">
                <Dropdown
                    label="Как отправить"
                    value={type}
                    options={PauseNodeDropdownTypes}
                    setValue={setType}
                    disabled={isPreviewMode}
                />

                <TypeWrapper id={props.id} type={type} data={props.data}/>
            </Box>

            <NodeSourceHandle id={props.id} role={NodeHandleRole.PauseNode} title={sourceHandleTitle}/>
        </Box>
    );
}

const TypeWrapper = ({id, type, data}: { id: string, type: PauseNodeType, data: PauseNodeData }) => {
    const wrappers = {
        timer: TimerType,
        date: DateType,
        weekday: WeekdayType,
    }

    const Wrapper = wrappers[type] ?? TimerType;

    return <Wrapper id={id} data={data}/>
};

/**
 * Отправка в конкретную дату и время.
 */
const DateType = (props: props): React.JSX.Element => {
    const updateNode = useUpdateNode();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const incorrectErrorText = `Введите корректное время и дату, максимальная длительность паузы ${MaxDays} дней.`;
    const isPastErrorText = `Выбранная дата уже наступила.`;

    const defaultDatetime = props.data.datetime ? moment(props.data.datetime) : moment().add(1, 'days');
    const [error, setError] = useState<string | undefined>();
    const [warning, setWarning] = useState<string | undefined>();
    const [date, setDate] = useState(defaultDatetime && defaultDatetime.isValid() ? defaultDatetime.format(MomentDateFormat) : '');
    const [time, setTime] = useState(defaultDatetime && defaultDatetime.isValid() ? defaultDatetime.format(MomentTimeFormat) : '')

    const onChangeDatetime = useCallback((date: string, time: string) => {
        if (date.length <= 0 || time.length <= 0) {
            setError(incorrectErrorText);
            return;
        }

        const datetime = moment(`${date} ${time}`);

        if (!datetime.isValid() || datetime.isAfter(moment().add(MaxDays, 'days'))) {
            setError(incorrectErrorText);
            return;
        }

        if (datetime.isBefore(moment())) {
            setWarning(isPastErrorText);
        } else {
            setWarning(undefined);
        }

        setError(undefined);

        updateNode(props.id, {
            datetime: datetime.format('YYYY-MM-DD HH:mm'),
        } as PauseNodeData);
    }, [incorrectErrorText, isPastErrorText, props.id, updateNode])

    return (
        <>
            <Box sx={{mt: '15px'}}>
                <FormLabel>Время и дата</FormLabel>

                <Stack spacing={1} sx={{mt: 1}} direction="row">
                    <Input
                        type="date"
                        value={date}
                        sx={{width: '60%'}}
                        disabled={isPreviewMode}
                        onChange={(value) => {
                            setDate(value.target.value);
                            onChangeDatetime(value.target.value, time);
                        }}
                    />

                    <Input
                        type="time"
                        sx={{width: '37%'}}
                        disabled={isPreviewMode}
                        value={time}
                        onChange={(value) => {
                            setTime(value.target.value);
                            onChangeDatetime(date, value.target.value);
                        }}
                    />
                </Stack>

                <FormErrorHelper error={error}/>
                <FormWarningHelper warning={warning}/>
            </Box>

            <Card invertedColors variant="soft" color="neutral" sx={{boxShadow: 'none', mt: '15px'}}>
                <Typography level="body-sm">
                    Время задается по <strong>московскому</strong> часовому поясу (GMT+3).
                </Typography>
            </Card>
        </>
    );
};

/**
 * Отправка в конкретный день/дни недели и время.
 */
const WeekdayType = (props: props): React.JSX.Element => {
    const defaultDatetime = props.data.datetime ? moment(props.data.datetime) : moment();
    const defaultWeekDays = props.data.weekday && Array.isArray(props.data.weekday) ? props.data.weekday : [];

    const [weekDays, setWeekDays] = useState<WeekDay[]>(defaultWeekDays);
    const [weekdaysError, setWeekdaysError] = useState<string | undefined>();
    const [timeError, setTimeError] = useState<string | undefined>();
    const incorrectErrorText = `Введите корректное время`;
    const updateNode = useUpdateNode();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const [time, setTime] = useState(defaultDatetime && defaultDatetime.isValid() ? defaultDatetime.format(MomentTimeFormat) : '')

    const weekDayOptions = [
        {label: "Понедельник", value: WeekDay.Monday},
        {label: "Вторник", value: WeekDay.Tuesday},
        {label: "Среда", value: WeekDay.Wednesday},
        {label: "Четверг", value: WeekDay.Thursday},
        {label: "Пятница", value: WeekDay.Friday},
        {label: "Суббота", value: WeekDay.Saturday},
        {label: "Воскресенье", value: WeekDay.Sunday},
    ];

    const onChangeWeekday = useCallback((value: WeekDay[]) => {
        if (value.length <= 0) {
            setWeekdaysError('Нужно выбрать хотя бы один день.');
        } else {
            setWeekdaysError(undefined);
        }

        setWeekDays(value);
        updateNode(props.id, {
            weekday: value,
        } as PauseNodeData);
    }, [props.id, updateNode]);

    const onChangeTime = useCallback((value: string) => {
        setTime(value);

        if (value.length <= 0) {
            setTimeError(incorrectErrorText);
            return;
        }

        const datetime = moment(`${moment().format(MomentDateFormat)} ${value}`);

        if (!datetime.isValid()) {
            setTimeError(incorrectErrorText);
            return;
        }

        setTimeError(undefined);
        updateNode(props.id, {
            datetime: datetime.format(MomentDayTimeFormat),
        } as PauseNodeData);
    }, [incorrectErrorText, props.id, updateNode]);

    return (
        <Stack spacing={'15px'} sx={{mt: "15px"}}>
            <FormControl>
                <Dropdown
                    value={weekDays}
                    multiple={true}
                    label="Дни недели"
                    options={weekDayOptions}
                    setValue={onChangeWeekday}
                    placeholder={'Не выбраны'}
                    disabled={isPreviewMode}
                />
                <FormErrorHelper error={weekdaysError}/>
            </FormControl>

            <FormControl>
                <FormLabel>Время</FormLabel>
                <Input
                    type="time"
                    disabled={isPreviewMode}
                    value={time}
                    onChange={(value) => onChangeTime(value.target.value)}
                />
                <FormErrorHelper error={timeError}/>
            </FormControl>

            <Card invertedColors variant="soft" color="neutral" sx={{boxShadow: 'none', mt: '15px'}}>
                <Typography level="body-sm">
                    Время задается по <strong>московскому</strong> часовому поясу (GMT+3).
                </Typography>
            </Card>
        </Stack>
    );
};

/**
 * Отправка по таймеру.
 */
const TimerType = (props: props): React.JSX.Element => {
    const reactFlowInstance = useReactFlow();
    const [seconds, setSeconds] = useState(props.data.seconds);
    const [timeType, setTimeType] = useState(props.data.timeType);
    const [inputErrors, setInputErrors] = useInputErrors();
    const updateNode = useUpdateNode();
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    const validators = useCallback(() => {
        let maxMessage = `Максимальное значение ${MaxMinutes} ${numeralsLabel(MaxMinutes, "минуту", "минуты", "минут")}`;
        let minMessage = `Минимальное значение 1 минута`;

        if (timeType === TimeType.Hours) {
            maxMessage = `Максимальное значение ${MaxHours} ${numeralsLabel(MaxHours, "час", "часа", "часов")}`;
            minMessage = `Минимальное значение 1 час`;
        } else if (timeType === TimeType.Days) {
            maxMessage = `Максимальное значение ${MaxDays} ${numeralsLabel(MaxDays, "день", "дня", "дней")}`;
            minMessage = `Минимальное значение 1 день`;
        }

        return {
            seconds: (value: any) => validateInput(
                "seconds", value, setInputErrors,
                {func: validateRequired},
                {func: validateMinValue, threshold: 1, message: minMessage},
                {func: validateMaxValue, threshold: MaxMinutes * 60, message: maxMessage},
            ),
        }
    }, [setInputErrors, timeType]);

    useEffect(() => {
        updateNode(props.id, {
            seconds: seconds,
            timeType: timeType,
        } as PauseNodeData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id, seconds, reactFlowInstance, timeType]);

    useEffect(() => {
        validators().seconds(seconds);
    }, [seconds, timeType, validators]);

    const onSecondsChange = useCallback((value: string) => {
        if (timeType === TimeType.Minutes) {
            setSeconds(Number(value) * Minute);
        } else if (timeType === TimeType.Hours) {
            setSeconds(Number(value) * Hour);
        } else {
            setSeconds(Number(value) * Day);
        }
    }, [timeType]);

    const getInputValue = useCallback(() => {
        if (timeType === TimeType.Minutes) {
            return Math.ceil(seconds / Minute);
        } else if (timeType === TimeType.Hours) {
            return Math.ceil(seconds / Hour);
        } else {
            return Math.ceil(seconds / Day);
        }
    }, [seconds, timeType]);

    useEffect(() => {
        onSecondsChange(String(getInputValue()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSecondsChange, timeType]);

    const typeSelector = useMemo(() => {
        return <TimerViewTypeSelector rawValue={getInputValue()} timeType={timeType} setTimeType={setTimeType}/>;
    }, [getInputValue, timeType]);

    return (
        <>
            <FormInput
                type="number"
                label="Сколько ждать"
                className="PauseNode__input"
                value={String(getInputValue())}
                endDecorator={typeSelector}
                setValue={onSecondsChange}
                validate={validators().seconds}
                errorText={inputErrors.get("seconds")}
                placeholder="Введите число.."
                disabled={isPreviewMode}
            />
        </>
    );
}

export const TimerViewTypeSelector = (props: {
    rawValue: number,
    timeType: TimeType,
    setTimeType: (value: TimeType) => void
}) => {
    const {rawValue, timeType, setTimeType} = props
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    return (
        <>
            <Divider orientation="vertical"/>
            <FormControl>
                <Select
                    variant="plain"
                    value={timeType}
                    onChange={(_, value) => {
                        if (value) {
                            setTimeType(value);
                        }
                    }}
                    disabled={isPreviewMode}
                    sx={{mr: -1.5, '&:hover': {bgcolor: 'transparent'}}}
                >
                    <Option value={TimeType.Minutes}>
                        {numeralsLabel(rawValue, "Минуту", "Минуты", "Минут")}
                    </Option>
                    <Option value={TimeType.Hours}>
                        {numeralsLabel(rawValue, "Час", "Часа", "Часов")}
                    </Option>
                    <Option value={TimeType.Days}>
                        {numeralsLabel(rawValue, "День", "Дня", "Дней")}
                    </Option>
                </Select>
            </FormControl>
        </>
    )
}

export const FormErrorHelper = ({error}: { error?: string }) => {
    if (error && error.length > 0) {
        return (
            <FormHelperText sx={{color: "var(--joy-palette-danger-500)", mt: 1, mb: 0.5}}>{error}</FormHelperText>
        );
    }

    return null;
};

export const FormWarningHelper = ({warning}: { warning?: string }) => {
    if (warning && warning.length > 0) {
        return (
            <FormHelperText sx={{color: "var(--joy-palette-warning-500)", mt: 1, mb: 0.5}}>{warning}</FormHelperText>
        );
    }

    return null;
};
