import './Keyboard.css';
import {ButtonData, ButtonType, KeyboardRows, VKButtonColor} from "./types";
import {Row} from "./Row";
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {Edge, useReactFlow, useUpdateNodeInternals} from "reactflow";
import {v4 as uuidv4} from "uuid";
import {UpdateModal} from "./UpdateModal";
import classNames from "classnames";
import {ReactSortable} from "react-sortablejs";
import {useScenariosPreviewStore} from "../../../../Stores/ScenarioPreviewStore";
import {AddRowButton} from "./AddRowButton";
import {FormErrorHelper} from "../../PauseNode/PauseNode";
import Box from "@mui/joy/Box";

type props = {
    nodeId: string;
    data: KeyboardRows;
    isInline: boolean;
    setKeyboard: Dispatch<SetStateAction<KeyboardRows>>;
    hasFiles: boolean;
    hasMessage: boolean;
}

export const Keyboard = (props: props): React.JSX.Element => {
    const reactFlowInstance = useReactFlow();
    const updateNodeInternals = useUpdateNodeInternals();
    const [keyboardModalData, setKeyboardModalData] = useState<ButtonData | null>(null);
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);

    useEffect(() => {
        updateNodeInternals(props.nodeId); // Каждый раз когда добавляем новый хендлер нужно оповестить об этом ReactFlow.
    }, [props, updateNodeInternals]);

    const deleteButton = useCallback((id: string) => {
        let buttons = props.data.map((row) => {
            return {
                id: row.id,
                buttons: row.buttons.filter((button) => {
                    return button.id !== id;
                })
            }
        });

        buttons = buttons.filter((row) => row.buttons.length !== 0);

        props.setKeyboard([...buttons]);

        reactFlowInstance.setEdges((eds) => eds.filter((e) => e.sourceHandle !== id));
    }, [props, reactFlowInstance]);

    const updateButton = useCallback((data: ButtonData) => {
        const buttons = props.data.map((row) => {
            return {
                id: row.id,
                buttons: row.buttons.map((button) => {
                    return button.id === data.id ? data : button;
                })
            }
        });

        props.setKeyboard([...buttons]);

        reactFlowInstance.setEdges((eds: Edge[]) => {
            eds = eds.map((e) => {
                if (e.sourceHandle === data.id) {
                    e.label = data.name; // После изменения имени кнопки нужно обновить и лейбл у Handle, если он есть.
                }

                return e;
            });

            return [...eds];
        });
    }, [props, reactFlowInstance]);

    const updateRow = (buttons: ButtonData[], rowId: string) => {
        let rows: KeyboardRows;

        if (buttons.length === 0) {
            rows = props.data.filter((row) => row.id !== rowId);
        } else {
            rows = props.data.map((row) => {
                if (row.id === rowId) {
                    return {id: rowId, buttons: [...buttons]};
                }

                return row;
            });
        }

        props.setKeyboard([...rows]);
    };

    const containerClassNames = classNames('MessageNodeKeyboardContainer', {
        'MessageNodeKeyboardContainer--not_empty': props.data.length > 0
    });

    const setRowSort = (rows: KeyboardRows) => {
        /**
         * ReactSortable делает фантомные сэты в момент инициализации,
         * что ломает фичу уведомления о несохраненных изменениях.
         *
         * Поэтому, не обновляем клавиатуру, если она не изменились.
         */
        if (JSON.stringify(rows) === JSON.stringify(props.data)) {
            return;
        }

        props.setKeyboard(rows);
    };

    return (
        <>
            {!props.hasFiles && !props.hasMessage && props.data.length > 0 &&
                <Box sx={{width: '350px'}}>
                    <FormErrorHelper error={"Нельзя прикрепить клавиатуру к сообщению без текста и вложений."}/>
                </Box>
            }
            <div className={containerClassNames}>
                <ReactSortable
                    handle='.MessageNodeKeyboardRow__drag'
                    draggable=".MessageNodeKeyboardRow"
                    list={props.data}
                    setList={setRowSort}
                    animation={200}
                    delay={2}
                    disabled={isPreviewMode}
                >
                    {props.data.map((row) => {
                        return (
                            <Row
                                key={"button-row-" + row.id}
                                nodeId={props.nodeId}
                                row={row}
                                updateRow={updateRow}
                                setKeyboardModalData={setKeyboardModalData}
                                setKeyboard={props.setKeyboard}
                                rows={props.data}
                            />
                        );
                    })}
                </ReactSortable>

                <AddRowButton setKeyboard={props.setKeyboard} isInline={props.isInline} data={props.data}/>

                <UpdateModal
                    onDelete={deleteButton}
                    onUpdate={updateButton}
                    modalData={keyboardModalData}
                    setModalData={setKeyboardModalData}
                />
            </div>
        </>
    );
}

export const createButtonObj = (type: ButtonType): ButtonData => {
    return {
        id: uuidv4(),
        name: "Кнопка",
        type: type,
        vkColor: VKButtonColor.PRIMARY,
        url: '',
    }
}