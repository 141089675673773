import React from "react";
import Box from "@mui/joy/Box";
import {Breadcrumbs, Grid, Link} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {ChevronRight, Home as FeatherHome} from "react-feather";
import {PAGE} from "../types";
import styles from './Dashboard.module.css';
import {useNavigate} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {useProjectStore} from "../Stores/ProjectStore";
import Stack from "@mui/joy/Stack";

type props = {
    breadcrumbsTitle: string;
    rightHeader?: any;
    children: any;
    containerHeight?: string;
}

export const DashContainer = (props: props) => {
    const selectedProject = useProjectStore(state => state.selectedProject);
    const navigate = useNavigate();

    return (
        <Box
            component="main"
            className="MainContent"
            sx={(theme) => ({
                px: {
                    xs: 2,
                    md: 6,
                },
                pt: {
                    xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
                    sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
                    md: 3,
                },
                pb: {
                    xs: 2,
                    sm: 2,
                    md: 3,
                },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 2,
                overflow: 'auto',
            })}
        >
            <Stack sx={{flexDirection: {sm: 'row', xs: 'column'}, alignItems: {sm: 'center', xs: 'inherit'}}}>
                <Breadcrumbs
                    size="sm"
                    aria-label="breadcrumbs"
                    separator={<ChevronRight size={16}/>}
                    sx={{
                        '--Breadcrumbs-gap': '1rem',
                        '--Icon-fontSize': '16px',
                        fontWeight: 'lg',
                        color: 'neutral.400',
                        px: 0,
                    }}
                >
                    <Link underline="none" color="neutral" fontSize="inherit" onClick={() => navigate(PAGE.MAIN)}>
                        <FeatherHome size={20}/>
                    </Link>

                    <Link underline="hover" color="neutral" fontSize="inherit" onClick={() => navigate(PAGE.MAIN)}>
                        {selectedProject ? selectedProject.name :
                            <Skeleton width={100} height={20} className={styles.ProjectSkeleton}/>}
                    </Link>

                    <Typography variant="plain">
                        {props.breadcrumbsTitle}
                    </Typography>
                </Breadcrumbs>

                {props.rightHeader &&
                    <Stack sx={{
                        ml: {sm: 'auto', xs: 'inherit'},
                        mt: {sm: 'inherit', xs: 0.5},
                    }}>
                        {props.rightHeader}
                    </Stack>}
            </Stack>


            <Grid container spacing={2} sx={{height: props.containerHeight ? props.containerHeight : 'unset'}}>
                {props.children}
            </Grid>
        </Box>
    );
}
