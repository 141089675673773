import {IconButton, Tooltip} from "@mui/joy";
import React, {Dispatch, SetStateAction, useCallback, useMemo} from "react";
import {ButtonRow, ButtonType, getMaxButtonRows, KeyboardRows} from "./types";
import {createButtonObj} from "./Keyboard";
import {v4 as uuidv4} from "uuid";
import {useScenariosPreviewStore} from "../../../../Stores/ScenarioPreviewStore";
import {Plus, Server} from "react-feather";
import {toastError} from "../../../../utils";
import {Position} from "reactflow";
import Box from "@mui/joy/Box";
import {useConstructorProjectType} from "../../../utils";

type props = {
    data: KeyboardRows;
    isInline: boolean;
    setKeyboard: Dispatch<SetStateAction<KeyboardRows>>;
}

/**
 * Добавление нового ряда кнопок
 */
export const AddRowButtonIcon = (props: props): React.JSX.Element => {
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const projectType = useConstructorProjectType();
    const maxButtonRows = useMemo(() => getMaxButtonRows(projectType, props.isInline), [projectType, props.isInline]);

    const addKeyboardRow = useCallback(() => {
        const rows = props.data;

        if (rows.length >= maxButtonRows) {
            toastError('Добавлено максимальное количество рядов у клавиатуры.')
            return;
        }

        rows.push({
            id: uuidv4(),
            buttons: [createButtonObj(ButtonType.COMMON)]
        } as ButtonRow);

        props.setKeyboard([...rows]);
    }, [maxButtonRows, props]);

    if (isPreviewMode) {
        return <></>
    }

    return (
        <Tooltip arrow title="Добавить ряд кнопок" size="sm" placement={Position.Bottom}>
            <IconButton
                variant="soft"
                color="neutral"
                onClick={addKeyboardRow}
            >
                <Server size={16}/>
            </IconButton>
        </Tooltip>
    );
}

export const AddRowButton = (props: props): React.JSX.Element => {
    const [isPreviewMode] = useScenariosPreviewStore(state => [state.isPreviewMode]);
    const projectType = useConstructorProjectType();
    const maxButtonRows = useMemo(() => getMaxButtonRows(projectType, props.isInline), [projectType, props.isInline]);

    const addRow = useCallback(() => {
        const rows = props.data;

        rows.push({
            id: uuidv4(),
            buttons: [createButtonObj(ButtonType.COMMON)]
        } as ButtonRow);

        props.setKeyboard([...rows]);
    }, [props]);


    if (isPreviewMode || props.data.length >= maxButtonRows || props.data.length === 0) {
        return <></>
    }

    return (
        <Box sx={{textAlign: 'center'}}>
            <IconButton
                color="neutral"
                variant="plain"
                size="sm"
                onClick={addRow}
                sx={{mt: '5px'}}
            >
                <Plus color={"var(--joy-palette-neutral-300)"} size={16}/>
            </IconButton>
        </Box>
    );
}