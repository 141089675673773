import {useEffect} from "react";

export const BrokenComponent = () => {
    useEffect(() => {
        // @ts-ignore
        document.getElementById('asdfasdf').removeChild(document.getElementById('asdfasdf'))
    }, []);

    return (
        <>broken</>
    );
}
